import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const CustomerLevelOptionsDropdown = ({
  label,
  noOptionsText,
  options,
  background,
  radius,
  width,
  height,
  iconSize,
  showResultOption,
  optionsWidth,
  optionHeight,
  padding,
  setSelectedId,
  selectedValue = '',
  setSelectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative ${width}`}>
      <div
        className={`flex items-center justify-between rounded-lg cursor-pointer font-quicksand ${padding} ${height} ${background}`}
        onClick={toggleDropdown}
      >
        <span
          className={`text-sm font-semibold  font-quicksand ${
            showResultOption ? 'block' : 'hidden'
          } ${
            selectedValue === '' ? 'text-gray-x' : 'text-clinder capitalize'
          } `}
        >
          {selectedValue === '' ? label : selectedValue}
        </span>
        <span
          className={`${radius} ${iconSize} flex items-center justify-center bg-white text-clinder shadow-soft flex-none`}
        >
          {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </span>
      </div>
      {isOpen && (
        <div
          className={`absolute right-0 z-10 mt-2 py-2 rounded-lg bg-neutral-gray overflow-y-auto ${optionsWidth} ${optionHeight}`}
        >
          {options?.length === 0 ? (
            <p className="px-4 py-1 text-sm font-medium font-quicksand">
              {noOptionsText}
            </p>
          ) : (
            <>
              {options?.map((option, index) => (
                <div
                  key={index}
                  className={`px-4 text-sm py-1 font-medium capitalize cursor-pointer font-quicksand`}
                  onClick={() => {
                    setSelectedId(option?.id);
                    setSelectedValue(option?.customerLevelName);
                    setIsOpen(false);
                  }}
                >
                  <div
                    className={`py-2 px-4 inline-block bg-[${option?.colorBackground}]`}
                  >
                    <span
                      className={`text-sm font-semibold font-quicksand capitalize text-${option?.colorFont}`}
                    >
                      {option?.customerLevelName}
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

CustomerLevelOptionsDropdown.defaultProps = {
  background: 'bg-neutral-gray',
  radius: 'rounded-lg',
  height: 'h-14',
  width: 'w-full',
  iconSize: 'w-8 h-8',
  showResultOption: true,
  label: null,
  optionsWidth: 'w-full',
  optionHeight: 'h-auto',
  padding: 'px-4',
};

export default CustomerLevelOptionsDropdown;
