import React from "react";

// import DataApiEmptyInfo from "../../DataApiEmptyInfo";
// import DataFilterEmptyInfo from "../../DataFilterEmptyInfo";

import searchBlue from "../../../assets/icons/searchBlue.svg";
import plus from "../../../assets/icons/plus.svg";
// import dots from "../../../assets/icons/dots.svg";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const FavoriteMenusList = ({
    today,
    setToday,
    setSelectedDayId,
    setFavoriteId,
    setIsNewFavoriteMenuModalOpen,
    favoriteMenus,
    setIsDeleteFavoriteMenuModalOpen,
    days,
    searchQuery,
}) => {
    return (
        <>
            <div className="flex gap-4 px-6 py-2 border-b-[1px] border-gray-x md:hidden bg-white sticky top-[164px] z-40">
                <img src={searchBlue} alt="search" />
                <input
                    type="text"
                    placeholder="Find your menu here"
                    className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
                />
                <button
                    type="button"
                    className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
                    onClick={() => setIsNewFavoriteMenuModalOpen(true)}
                >
                    <img src={plus} alt="add" className="mx-auto" />
                </button>
            </div>
            <div className="z-0 xl:z-50 w-[95%] md:w-full mx-auto mt-0 md:px-2 md:mt-6 xl:-mt-14">
                <div className="flex-none max-w-full overflow-hidden ">
                    <div className="xl:w-full 2xl:w-[1171px] bg-white rounded-t-lg xl:drop-shadow-none w-[620px] lg:w-full mx-auto flex justify-between gap-2 px-6 py-4 overflow-hidden md:py-0 md:px-0 hover:overflow-x-auto  mt-0 xl:mt-6">
                        {days.map((item, index) => (
                            <button
                                key={index}
                                className={`px-6 py-3 text-sm font-quicksand leading-5 duration-300 transform text-center flex-none rounded-lg group ${
                                    item.value === today && "bg-dew"
                                }`}
                                onClick={() => {
                                    setSelectedDayId(item.id);
                                    setToday(item.value);
                                }}
                            >
                                <span
                                    className={`group-hover:font-bold capitalize group-hover:text-grad-purpletty transition-all duration-300 ${
                                        item.value === today
                                            ? "text-aurora-blue font-bold bg-dew"
                                            : "text-manatee font-semibold"
                                    }`}
                                >
                                    {item.value}
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className="flex mt-6 md:mt-9 gap-[11px] 3xl:gap-0 flex-wrap max-w-full justify-center md:justify-start pb-4">
                        <UnderConstructionBanner />

                        {/* If result of search filter and favorite menus list are not empty */}
                        {/* 
                          NOTE ! disabled due to unready feature

                        {favoriteMenus.length !== 0 && (
                          <>
                            {favoriteMenus.map((menu, index) => (
                              <figure key={index} className="relative p-2">
                                <img
                                  src={menu.product.image}
                                  alt={menu.product.name}
                                  className="rounded-[4px] w-[149px] md:w-[163px] 2xl:w-[170px] 3xl:w-[220px] 4xl:w-[260px] 5xl:w-[368px] h-[75px] 5xl:h-[152px] object-cover"
                                  loading="lazy"
                                />
                                <button
                                  className="px-1 py-[5px] bg-black/20 absolute top-[14px] right-[12px] rounded-[2px]"
                                  onClick={() => {
                                    setFavoriteId(menu.favoritesProductId);
                                    setIsDeleteFavoriteMenuModalOpen(true);
                                  }}
                                >
                                  <img src={dots} alt="dots" />
                                </button>
                                <p className="mt-2 text-sm font-semibold capitalize font-quicksand w-[149px] md:w-[163px] 2xl:w-[170px] 3xl:w-[220px] 4xl:w-[260px] 5xl:w-[368px]">
                                  {menu.product.name}
                                </p>
                              </figure>
                            ))}
                          </>
                        )} */}

                        {/* If result of get favorite menus list by day is empty */}
                        {/* {favoriteMenus.length === 0 && searchQuery === '' && (
              <div className="mx-auto">
                <DataApiEmptyInfo
                  emptyInfoHeading={'Oops, Nothing Here Yet!'}
                  firstEmptyInfoDescription={
                    'Looks like this space is feeling a bit lonely right now. Why not kick things off by adding some info?'
                  }
                  secondEmptyInfoDescription={
                    "Just hit that 'Add New Favorite Menu' button, and let the good times (and data) roll!"
                  }
                />
              </div>
            )} */}

                        {/* If result of search filter is empty */}
                        {/* {favoriteMenus.length === 0 && searchQuery !== '' && (
              <div className="w-full">
                <DataFilterEmptyInfo
                  emptyInfoHeading={'Sorry, No Results Found'}
                  emptyInfoDescription={
                    "Well, we've searched high and low,but it seems like what your're looking for isn't our database (yet!)"
                  }
                />
              </div>
            )} */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FavoriteMenusList;
