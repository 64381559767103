import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import singleMenu from '../../../assets/images/singleMenu.png';
import packageMenu from '../../../assets/images/packageMenu.png';

const ChooseMenuTypeModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsNewSingleMenuModalOpen,
  setIsNewPackageMenuModalOpen,
}) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-clinder/60 ${
        !isModalOpen && 'hidden'
      }`}
      onClick={() => setIsModalOpen(false)}
    >
      <div
        className={`p-6 bg-white rounded-xl w-[600px] ${
          !isModalOpen && 'hidden'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center justify-between">
          <h2 className="text-base font-bold font-quicksand text-clinder">
            Choose Menu Type
          </h2>
          <button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <AiOutlineClose className="text-manatee" size={22} />
          </button>
        </div>
        <div className="flex gap-3 mt-9">
          <button
            className="w-1/2 border-[1px] border-charcoal-gray/20 rounded-lg p-3"
            onClick={() => setIsNewSingleMenuModalOpen(true)}
          >
            <img src={singleMenu} alt="single menu" className="mx-auto" />
            <h3 className="mt-4 text-xl font-bold text-aurora-blue font-quicksand">
              Single Menu
            </h3>
            <p className="text-xs leading-6 text-center font-satoshi text-manatee">
              Create single menu and structured with base products
            </p>
            <div
              className="w-full px-6 py-3 text-center rounded-lg border-[1px] border-blue-300 mt-9 font-quicksand text-aurora-blue font-bold text-xs"
              onClick={() => setIsNewSingleMenuModalOpen(true)}
            >
              Select this type
            </div>
          </button>
          <button
            className="w-1/2 border-[1px] border-charcoal-gray/20 rounded-lg p-3"
            onClick={() => setIsNewPackageMenuModalOpen(true)}
          >
            <img src={packageMenu} alt="package manu" className="mx-auto" />
            <h3 className="mt-4 text-xl font-bold text-aurora-blue font-quicksand">
              Package Menu
            </h3>
            <p className="text-xs leading-6 text-center font-satoshi text-manatee">
              Create package menu and structured with other menu
            </p>
            <div
              className="w-full px-6 py-3 text-center rounded-lg border-[1px] border-blue-300 mt-9 font-quicksand text-aurora-blue font-bold text-xs"
              onClick={() => setIsNewPackageMenuModalOpen(true)}
            >
              Select this type
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseMenuTypeModal;
