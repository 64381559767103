import React, { useState } from "react";

import DoubleIndicatorDropdown from "../../DoubleIndicatorDropdown";
import DeleteConfirmationModal from "../../DeleteConfirmationModal";

import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import edit from "../../../assets/icons/edit.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import DataFilterEmptyInfo from "../../DataFilterEmptyInfo";
import DataApiEmptyInfo from "../../DataApiEmptyInfo";

const TabletDesktopCategoryTable = ({
    nameSortOptions,
    creatorSortOptions,
    productSortOptions,
    locationSortOptions,
    categoryData,
    allCategories,
    onDeleteCategory,
    onGetCategoryById,
    setIsDetailCategoryModalOpen,
    setIsEditCategoryModalOpen,
    setCategoryId,
}) => {
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
    // console.log(categoryData);

    return (
        <div className="hidden overflow-x-auto md:block">
            <div className="w-full">
                <table className="w-full mt-3 border-collapse table-auto">
                    <thead className="bg-neutral-gray">
                        <tr>
                            <th
                                className="w-[28.5%] lg:w-[29.7%] xl:w-[21.23%] 5xl:w-1/5 text-start"
                                id="category-name"
                            >
                                <DoubleIndicatorDropdown options={nameSortOptions} />
                            </th>
                            <th
                                className="hidden w-[28.5%] lg:w-[29.7%] xl:w-full xl:block text-start"
                                id="creator"
                            >
                                <DoubleIndicatorDropdown options={creatorSortOptions} />
                            </th>
                            <th
                                className="w-[28.5%] lg:w-[29.7%] xl:w-[15.10%] 5xl:w-1/5 text-start"
                                id="product"
                            >
                                <DoubleIndicatorDropdown options={productSortOptions} />
                            </th>
                            <th
                                className="w-[28.5%] lg:w-[29.7%] xl:w-[21.23%] 5xl:w-1/5 text-start"
                                id="location"
                            >
                                <DoubleIndicatorDropdown options={locationSortOptions} />
                            </th>
                            <th
                                className="w-[12.66%] lg:w-[9%] xl:w-[21.23%] 5xl:w-1/5 text-start"
                                id="action"
                            >
                                <p className="p-[10px] text-sm font-medium hover:bg-gray-50 text-manatee font-quicksand">
                                    Action
                                </p>
                            </th>
                        </tr>
                    </thead>
                    {categoryData?.length > 0 && (
                        <tbody>
                            {categoryData?.map((category, index) => (
                                <tr key={index}>
                                    <td
                                        className={`text-sm font-semibold capitalize leading-5 font-quicksand text-clinder px-[10px]  ${
                                            index === 0 ? "pt-8 mb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="category-name"
                                    >
                                        <div className="flex items-center gap-2">
                                            <div
                                                className={`w-1 h-12 rounded-lg ${
                                                    category?.showOnApp && "bg-aurora-blue"
                                                }`}
                                            />
                                            <div>{category?.name}</div>
                                        </div>
                                    </td>
                                    <td
                                        className={`hidden xl:block px-[10px] text-sm font-semibold leading-5 font-quicksand text-clinder ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="creator"
                                    >
                                        <div className="flex items-center">
                                            <div>
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-semibold font-quicksand text-black-lighter leading-[150%] capitalize">
                                                        {category?.creator?.fullName}
                                                    </h3>
                                                    <h5 className="text-xs font-normal font-satoshi text-manatee leading-[200%]">
                                                        {category?.creator?.role}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className={`px-[10px] text-sm font-semibold font-quicksand text-clinder ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="product"
                                    >
                                        {category?.totalProduct} Item
                                        {category?.totalProduct > 1 && "s"}
                                    </td>
                                    <td
                                        className={`${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="location"
                                    >
                                        <div
                                            className={`inline px-4 py-[10px] bg-[${category?.areaOfCategoryBg}]`}
                                        >
                                            <span
                                                className={`leading-5 capitalize text-sm font-semibold font-quicksand text-${category?.areaOfCategoryColorFont}`}
                                            >
                                                {category?.areaOfCategoryName}
                                            </span>
                                        </div>
                                    </td>
                                    <td
                                        aria-labelledby="action"
                                        className={`px-[10px] ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                    >
                                        <button
                                            type="button"
                                            className="block px-3 mx-auto xl:hidden"
                                        >
                                            <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                            <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                                            <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                        </button>
                                        <div
                                            className={`hidden xl:flex items-center justify-center gap-1 mt-2 ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            <button
                                                type="button"
                                                className="flex items-center justify-center flex-1 gap-2 px-3 py-1 duration-300 transform rounded bg-neutral-gray hover:bg-gray-200"
                                                onClick={() => {
                                                    onGetCategoryById(category.id);
                                                    setIsDetailCategoryModalOpen(true);
                                                }}
                                            >
                                                <img
                                                    src={shieldSearch}
                                                    alt="shield search"
                                                    className="w-3 h-3"
                                                />
                                                <span className="text-sm font-semibold font-quicksand text-clinder">
                                                    Detail
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className="flex items-center justify-center flex-1 gap-2 px-3 py-1 duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200"
                                                onClick={() => {
                                                    onGetCategoryById(category.id);
                                                    setIsEditCategoryModalOpen(true);
                                                    setCategoryId(category.id);
                                                }}
                                            >
                                                <img src={edit} alt="edit" className="w-3 h-3" />
                                                <span className="text-sm font-semibold font-quicksand text-clinder">
                                                    Edit
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className="px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200"
                                                onClick={() => {
                                                    setCategoryIdToDelete(category.id);
                                                    setIsDeleteConfirmationModalOpen(true);
                                                }}
                                            >
                                                <img
                                                    src={trashRed}
                                                    alt="trash red"
                                                    className="w-3 h-3"
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}

                    {categoryData?.length === 0 && allCategories?.length !== 0 && (
                        <tbody>
                            <tr colSpan="6" className="pt-4">
                                <td>
                                    <DataFilterEmptyInfo
                                        emptyInfoHeading={"Sorry, No Results Found"}
                                        emptyInfoDescription={
                                            "Well, we've searched high and low,but it seems like what your're looking for isn't our database (yet!)"
                                        }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    )}

                    {allCategories?.length === 0 && categoryData?.length === 0 && (
                        <tbody>
                            <tr colSpan="6" className="pt-4">
                                <td>
                                    <DataApiEmptyInfo
                                        emptyInfoHeading={"Oops, Nothing Here Yet!"}
                                        firstEmptyInfoDescription={
                                            "Looks like this space is feeling a bit lonely right now. Why not kick things off by adding some info?"
                                        }
                                        secondEmptyInfoDescription={
                                            "Just hit that 'Create New Category' button, and let the good times (and data) roll!"
                                        }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>
            </div>

            {/* Delete Confirmation  */}
            <DeleteConfirmationModal
                isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
                setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
                shortDeleteConfirmatonMessage={"Delete this category"}
                longDeleteConfirmatonMessage={"Are you sure you want to delete this category?"}
                longDeleteButtonLabel={"Yes, Delete this category"}
                onDelete={onDeleteCategory}
                id={categoryIdToDelete}
            />
        </div>
    );
};

export default TabletDesktopCategoryTable;
