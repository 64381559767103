import React, { useEffect, useRef, useState } from 'react';

import close from '../../../assets/icons/close.svg';
import checked from '../../../assets/icons/checklist.svg';
import arrowRightWhite from '../../../assets/icons/arrow-right-white.svg';
import arrowLeftBlue from '../../../assets/icons/arrowLeftBlue.svg';
import archiveTick from '../../../assets/icons/archiveTick.svg';

import SingleIndicatorDropdown from '../../SingleIndicatorDropdown';
import MonthOptionDropdown from '../../customer/customer/MonthOptionDropdown';
import SuccessNotification from '../../SuccessNotification';
import CustomerLevelOptionsDropdown from '../../customer/customer/CustomerLevelOptionsDropdown';

const NewEmployeeModal = ({ isModalOpen, setIsModalOpen }) => {
  // === Component State ===
  const modalContainerRef = useRef(null);
  const [activeForm, setActiveForm] = useState('credential-data');
  const [isShowSuccessNotification, setIsShowSuccessNotification] =
    useState(false);
  const [countdown, setCountdown] = useState(5);

  // === Credential Data State ===
  const [customerlevels, setCustomerLevels] = useState([]);
  const [customerCredentialId, setCustomerCredentialId] = useState(null);
  const [customerLevel, setCustomerLevel] = useState('');
  const [generatedEmployeeId, setGeneratedEmployeeId] = useState('');
  const [employeeUserName, setEmployeeUserName] = useState('');
  const [employeeEmail, setEmployeeEmail] = useState('');
  const [fullEmployeeEmail, setFullEmployeeEmail] = useState('');

  // === Personal Data State ===
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [birthMonthTextValue, setBirthMonthTextValue] = useState('');
  const [birthMonthNumberValue, setBirthMonthNumberValue] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [fullBirthDate, setFullBirthDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');

  // === Get Started State ===
  const [EmployeeRoleId, setEmployeeRoleId] = useState(null);

  // handle close modal by timer
  useEffect(() => {
    if (isShowSuccessNotification) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      if (countdown === 0) {
        clearInterval(timer);
        setIsModalOpen(false);
        window.scrollTo(0, 0);
        setActiveForm('credential-data');
        setCountdown(5);
        setIsShowSuccessNotification(false);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, isShowSuccessNotification]);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const userId = localStorage.getItem('userId');

  // ===== Credential Data Methods =====
  // Handle Submit Credential Data
  const handleSubmitCredentialData = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      customerId: generatedEmployeeId,
      username: employeeUserName,
      email: fullEmployeeEmail,
    };
    // console.log(bodyRequest);

    // try {
    //   const response = await axiosInstance.post(
    //     `/customer-credentials`,
    //     bodyRequest,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'X-TENANT-ID': tenantId,
    //         Authorization: `Bearer ${token}`,
    //       },
    //       Credential,
    //     }
    //   );
    // setCustomerCredentialId(response?.data?.data?.id);
    setActiveForm('personal-data');
    modalContainerRef.current.scrollTop = 0;
    setCustomerLevel('');
    setGeneratedEmployeeId('');
    setEmployeeUserName('');
    setEmployeeEmail('');
    setFullEmployeeEmail('');
    // } catch (error) {
    //   // console.log(error);
    // }
  };

  const generateRandomAlphanumeric = (length) => {
    const characters =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  // ==== Personal Data Methods ====
  const getYears = (yearsBack) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - yearsBack; i--) {
      years.push(i.toString());
    }
    return years;
  };

  const daysOptions = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  const monthOptions = [
    { textValue: 'January', numberValue: 1 },
    { textValue: 'February', numberValue: 2 },
    { textValue: 'March', numberValue: 3 },
    { textValue: 'April', numberValue: 4 },
    { textValue: 'May', numberValue: 5 },
    { textValue: 'June', numberValue: 6 },
    { textValue: 'July', numberValue: 7 },
    { textValue: 'August', numberValue: 8 },
    { textValue: 'September', numberValue: 9 },
    { textValue: 'October', numberValue: 10 },
    { textValue: 'November', numberValue: 11 },
    { textValue: 'December', numberValue: 12 },
  ];
  const yearOptions = getYears(80);

  // Handle Submit personal Data
  const handleSubmitPersonalData = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      customerCredentialId,
      fullName: fullName,
      gender: gender,
      birthDate: fullBirthDate,
      phone: phoneNumber,
      address: address,
    };

    // console.log(bodyRequest);

    // try {
    //   const response = await axiosInstance.post(`/customers`, bodyRequest, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-TENANT-ID': tenantId,
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    setActiveForm('get-started');
    modalContainerRef.current.scrollTop = 0;
    setFullName('');
    setGender('');
    setBirthDate('');
    setBirthMonthTextValue('');
    setBirthYear('');
    setFullBirthDate('');
    setPhoneNumber('');
    setAddress('');
    // } catch (error) {
    //   // console.log(error);
    // }
  };

  //Handle Full birth date input
  useEffect(() => {
    setFullBirthDate(
      `${birthYear}-${
        birthMonthNumberValue.toString().length === 1
          ? `0${birthMonthNumberValue}`
          : birthMonthNumberValue
      }-${birthDate.length === 1 ? `0${birthDate}` : birthDate}`
    );
  }, [birthDate, birthMonthTextValue, birthYear]);

  // === Get Started Method ===
  const handleSubmitEmployeeRole = async (e) => {
    e.preventDefault();
    // if (customerCredentialId !== null) {
    //   try {
    //     const response = await axiosInstance.post(
    //       '/customer-level-relations',
    //       { customerCredentialId, customerLevelId },
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           'X-TENANT-ID': tenantId,
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );
    //     // console.log(response.data.data);
    //     onGetAllCustomers();
    setIsShowSuccessNotification(true);
    modalContainerRef.current.scrollTop = 0;
    // } catch (error) {
    //   // console.log(error);
    // }
    // }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
          activeForm === 'get-started' && setCountdown(5);
          setActiveForm('credential-data');
          modalContainerRef.current.scrollTop = 0;
        }}
      />
      <div
        ref={modalContainerRef}
        className={`px-6 md:px-9 pb-6 md:pb-9 bg-white w-full md:w-[600px] h-screen overflow-y-auto  flex flex-col gap-6 fixed  top-0 right-0 z-50 duration-300 transition ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className="sticky top-0 z-30 pt-6 bg-white md:pt-9 md:mx-0">
          <div>
            <div className="flex flex-row-reverse items-center justify-end gap-6 md:justify-between md:flex-row border-b-[1px] border-gray-x/40 md:border-b-0 -mx-6 px-6 md:px-6 pb-6 md:pb-0">
              <h2 className="text-xl font-semibold md:text-2xl md:font-bold font-quicksand text-aurora-blue">
                Add New Employee
              </h2>
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsModalOpen(false);
                  activeForm === 'get-started' && setCountdown(5);
                  setActiveForm('credential-data');
                  modalContainerRef.current.scrollTop = 0;
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="text-sm font-medium text-manatee font-satoshi w-10/12 mt-2  leading-[200%] hidden md:block">
              In this window you can set the emplyee profile and also manage
              their achievement to your restaurant.
            </p>
          </div>
          <div className="flex mt-0 md:mt-4 items-center justify-between gap-1 p-3 bg-neutral-gray border-b-[1px] md:border-b-[2px] border-[#0170a0] -mx-6 md:mx-0">
            <div>
              <div
                className={`hidden md:inline mx-auto text-xs font-semibold font-quicksand py-[5px] rounded-full ${
                  activeForm === 'credential-data'
                    ? 'bg-aurora-blue text-white px-[10px]'
                    : 'bg-lime-green px-[5.5px]'
                }`}
              >
                <span
                  className={`${activeForm !== 'credential-data' && 'hidden'}`}
                >
                  1
                </span>
                <img
                  src={checked}
                  alt="checked"
                  className={`${
                    activeForm === 'credential-data' ? 'hidden' : 'inline -mt-1'
                  }`}
                />
              </div>
              <span
                className={`text-sm ml-[10px] font-quicksand font-semibold md:font-bold ${
                  activeForm === 'credential-data'
                    ? 'text-aurora-blue'
                    : 'text-lime-green'
                }`}
              >
                Credential Data
              </span>
            </div>
            <div
              className={`hidden md:block w-3 h-[2px] ${
                activeForm !== 'credential-data'
                  ? 'bg-lime-green'
                  : 'bg-manatee'
              }`}
            />
            <div>
              <div
                className={`hidden md:inline py-1 mx-auto text-sm font-semibold rounded-full ${
                  activeForm === 'credential-data'
                    ? 'bg-gray-x px-[9px] text-manatee'
                    : activeForm === 'personal-data'
                    ? 'bg-aurora-blue px-[9px] text-white'
                    : 'bg-lime-green px-[5.5px]'
                } font-quicksand`}
              >
                <span
                  className={`text-sm font-semibold ${
                    activeForm === 'credential-data'
                      ? 'text-manatee'
                      : activeForm === 'personal-data'
                      ? 'text-white'
                      : 'hidden'
                  } font-quicksand`}
                >
                  2
                </span>
                <img
                  src={checked}
                  alt="checked"
                  className={`${
                    activeForm === 'get-started' ? 'inline -mt-1' : 'hidden'
                  }`}
                />
              </div>
              <span
                className={`text-sm ml-[10px] font-semibold md:font-semibold font-quicksand ${
                  activeForm === 'personal-data'
                    ? 'text-aurora-blue'
                    : activeForm === 'get-started'
                    ? 'text-lime-green'
                    : 'text-manatee'
                }`}
              >
                Personal Data
              </span>
            </div>
            <div className="hidden md:inline w-3 bg-manatee h-[2px]" />
            <div>
              <span
                className={`hidden md:inline py-1 px-[9px] mx-auto text-xs font-semibold rounded-full ${
                  activeForm === 'get-started'
                    ? 'bg-aurora-blue text-white'
                    : 'bg-gray-x text-manatee'
                } text-manatee font-quicksand`}
              >
                3
              </span>
              <span
                className={`text-sm ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'get-started'
                    ? 'text-aurora-blue'
                    : 'text-manatee'
                }`}
              >
                Get Started
              </span>
            </div>
          </div>
        </header>
        <form className="flex-1">
          {/* Credential Data Form  */}
          {activeForm === 'credential-data' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="mt-4">
                  <label
                    htmlFor="customer-id"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Customer ID
                  </label>
                  <div className="relative mt-2 h-14">
                    <input
                      type="text"
                      id="customer-id"
                      value={generatedEmployeeId}
                      placeholder="Create Employee ID"
                      required
                      className="absolute w-full py-2 pl-4  pr-[130px] h-14 focusable-input"
                      onChange={(e) => setGeneratedEmployeeId(e.target.value)}
                    />
                    <button
                      className="absolute flex-none px-3 py-1 text-xs font-semibold bg-white rounded shadow-soft top-4 right-4 button-generate"
                      type="button"
                      onClick={() =>
                        setGeneratedEmployeeId(generateRandomAlphanumeric(8))
                      }
                    >
                      <span className="font-quicksand text-aurora-blue">
                        Auto Generate
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="username"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Username
                  </label>
                  <div className="mt-2">
                    <input
                      value={employeeUserName}
                      type="text"
                      id="username"
                      placeholder="Create Employee username"
                      required
                      className="w-full px-4 h-14 focusable-input"
                      onChange={(e) => setEmployeeUserName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="email"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Email
                  </label>
                  <div className="relative mt-2 h-14">
                    <input
                      value={employeeEmail}
                      type="text"
                      id="email"
                      placeholder="Enter Employee Email"
                      required
                      className="absolute w-full px-4 pr-32 mt-2 h-14 focusable-input"
                      onChange={(e) => {
                        setEmployeeEmail(e.target.value);
                        setFullEmployeeEmail(e.target.value + '@gmail.com');
                      }}
                    />
                    <p className="absolute px-3 py-1 text-xs font-semibold bg-white rounded shadow-soft top-6 right-4">
                      <span className="font-quicksand text-aurora-blue">
                        @gmail.com
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full mb-10 xl:mb-0 mt-9 button-primary-lg"
                onClick={handleSubmitCredentialData}
              >
                <span className="text-base font-bold text-white font-quicksand">
                  Next
                </span>
                <img src={arrowRightWhite} alt="arrow right white" />
              </button>
            </div>
          )}

          {/* Personal Data Form */}
          {activeForm === 'personal-data' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div>
                  <label
                    htmlFor="full-name"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={fullName}
                    id="full-name"
                    placeholder="Enter Employee Full Name"
                    required
                    className="w-full px-4 mt-2 h-14 focusable-input"
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="customer-gender"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Choose Customer Gender{' '}
                    <span className="text-manatee">(optional)</span>
                  </label>
                  <div className="flex gap-2 mt-2">
                    <button
                      type="button"
                      className={`flex-1 flex gap-4 px-4 py-3 transition-all duration-500 text-start rounded-lg group ${
                        gender === 'men'
                          ? 'bg-aurora-blue'
                          : 'bg-neutral-gray hover:bg-[#0079a1]'
                      }`}
                      onClick={() => setGender('men')}
                    >
                      <div
                        className={`${
                          gender === 'men'
                            ? 'bg-white/20'
                            : 'bg-transparent border-[0.5px] group-hover:border-0 border-gray-x group-hover:bg-white/20'
                        } shadow-gentle rounded-full flex p-1 w-6 h-6`}
                      >
                        <div
                          className={`${
                            gender === 'men'
                              ? 'bg-white'
                              : 'bg-transparent  group-hover:bg-white'
                          }  rounded-full w-4 h-4`}
                        />
                      </div>
                      <span
                        className={`font-quicksand text-base font-bold ${
                          gender === 'men'
                            ? 'text-white font-bold'
                            : 'text-manatee font-semibold group-hover:text-white group-hover:font-bold'
                        }`}
                      >
                        Men
                      </span>
                    </button>
                    <button
                      type="button"
                      className={`flex-1 flex px-4 gap-4 py-3 rounded-lg transition-all duration-500 text-start group ${
                        gender === 'women'
                          ? 'bg-aurora-blue'
                          : 'bg-neutral-gray hover:bg-[#0079a1]'
                      }`}
                      onClick={() => setGender('women')}
                    >
                      <div
                        className={`${
                          gender === 'women'
                            ? 'bg-white/20'
                            : 'bg-transparent border-[0.5px] group-hover:border-0 border-gray-x group-hover:bg-white/20'
                        } shadow-gentle rounded-full flex p-1 w-6 h-6`}
                      >
                        <div
                          className={`${
                            gender === 'women'
                              ? 'bg-white'
                              : 'bg-transparent group-hover:bg-white'
                          }  rounded-full w-4 h-4`}
                        />
                      </div>
                      <span
                        className={`font-quicksand text-base font-bold ${
                          gender === 'women'
                            ? 'text-white font-bold'
                            : 'text-manatee group-hover:text-white group-hover:font-bold font-semibold'
                        }`}
                      >
                        Women
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="birth-date"
                    className="text-sm font-semibold font-quicksand"
                  >
                    Birth Date
                  </label>
                  <div className="flex flex-col gap-3 mt-2 sm:flex-row">
                    <div className="flex w-full gap-3 sm:w-2/3">
                      <SingleIndicatorDropdown
                        label={'Date'}
                        options={daysOptions}
                        radius={'rounded-[4px]'}
                        selected={birthDate}
                        setSelected={setBirthDate}
                        optionsWidth={'w-[100px]'}
                        optionHeight={'h-[150px]'}
                      />
                      <MonthOptionDropdown
                        label={'Month'}
                        options={monthOptions}
                        radius={'rounded-[4px]'}
                        selectedTextValue={birthMonthTextValue}
                        setSelectedTextValue={setBirthMonthTextValue}
                        selectedNumberValue={birthMonthNumberValue}
                        setSelectedNumberValue={setBirthMonthNumberValue}
                        optionHeight={'h-[150px]'}
                      />
                    </div>
                    <div className="w-full sm:w-1/3">
                      <SingleIndicatorDropdown
                        label={'Year'}
                        options={yearOptions}
                        radius={'rounded-[4px]'}
                        selected={birthYear}
                        setSelected={setBirthYear}
                        optionHeight={'h-[150px]'}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4 h-[100px]">
                  <label
                    htmlFor="phone-number"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Phone Number
                  </label>
                  <div className="relative items-center mt-2">
                    <p className="absolute z-10 px-3 py-3 text-xs font-semibold bg-white rounded top-3 left-4 shadow-soft">
                      <span className="font-quicksand text-aurora-blue">
                        +62
                      </span>
                    </p>
                    <input
                      type="text"
                      id="phone-number"
                      value={phoneNumber}
                      maxLength={11}
                      placeholder="Enter Employee Phone Number"
                      required
                      className="w-full absolute text-sm h-[66px] focusable-input pl-[74px]"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="address"
                    className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
                  >
                    Address
                  </label>
                  <textarea
                    value={address}
                    placeholder="Enter Employee Address"
                    name="address"
                    id="address"
                    className="w-full h-40 px-4 py-3 resize-none focusable-input"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex gap-[10px] w-full mt-9 mb-10 xl:mb-0">
                <button
                  type="button"
                  className="button-secondary-lg"
                  onClick={() => {
                    modalContainerRef.current.scrollTop = 0;
                    setActiveForm('credential-data');
                  }}
                >
                  <img
                    src={arrowLeftBlue}
                    alt="arrow left"
                    className="w-4 h-4"
                  />
                  <span className="text-base font-bold text-aurora-blue font-quicksand">
                    Back
                  </span>
                </button>
                <button
                  type="submit"
                  className="w-full button-primary-lg"
                  onClick={handleSubmitPersonalData}
                >
                  <span className="text-base font-bold text-white font-quicksand">
                    Next
                  </span>
                  <img src={arrowRightWhite} alt="arrow right white" />
                </button>
              </div>
            </div>
          )}

          {/* Get Started Section */}
          {activeForm === 'get-started' && (
            <>
              {isShowSuccessNotification ? (
                <div className="mx-auto w-full sm:w-4/6 md:w-[400px] mt-10 md:mt-12">
                  <SuccessNotification
                    title={'Employee Registration Successfull'}
                    message={'Your employee registration has been successful.'}
                    notificationCountdown={5}
                    path={'/admin-restaurant-employee'}
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-between h-full">
                  <div>
                    <div className="flex items-center justify-between mb-2">
                      <label className="inline-block mb-2 text-sm font-semibold font-quicksand text-clinder">
                        Choose Employee Role
                      </label>
                    </div>
                    <CustomerLevelOptionsDropdown
                      label={'Choose role for employee'}
                      noOptionsText={'- No Employee Role added yet -'}
                      selectedValue={customerLevel}
                      setSelectedValue={setCustomerLevel}
                      setSelectedId={setEmployeeRoleId}
                      options={customerlevels}
                      optionHeight={'h-[190px]'}
                    />
                  </div>

                  <button
                    type="submit"
                    className="mb-10 button-primary-lg mt-9 xl:mb-0"
                    onClick={handleSubmitEmployeeRole}
                  >
                    <img src={archiveTick} alt="arrow right white" />
                    <span className="text-base font-bold text-white font-quicksand">
                      Save Changes
                    </span>
                  </button>
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default NewEmployeeModal;
