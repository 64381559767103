import React, { useState } from "react";

import DoubleIndicatorDropdown from "../../../components/DoubleIndicatorDropdown";

import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import edit from "../../../assets/icons/edit.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import DeleteConfirmationModal from "../../DeleteConfirmationModal";
import formatDefaultDate from "../../../utils/dateFormate";

const TabletDesktopCustomerTable = ({
    customersData,
    nameSortOptions,
    levelSortOption,
    favoriteMenuSortOption,
    totalTransactionSortOption,
    setIsEditCustomerModalOpen,
    setIsDetailCustomerModalOpen,
    onDeleteCustomer,
    customerCredentialId,
    setCustomerCredentialId,
}) => {
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

    return (
        <div className="hidden w-full mt-6 mb-3 md:block">
            <table className="w-full table-auto border-collaplse ">
                <thead className="mb-2 bg-neutral-gray ">
                    <tr>
                        <th id="customer-name">
                            <DoubleIndicatorDropdown options={nameSortOptions} />
                        </th>
                        <th id="level">
                            <DoubleIndicatorDropdown options={levelSortOption} />
                        </th>
                        <th id="favorite-menu">
                            <DoubleIndicatorDropdown options={favoriteMenuSortOption} />
                        </th>
                        <th id="total-transaction">
                            <DoubleIndicatorDropdown options={totalTransactionSortOption} />
                        </th>
                        <th
                            id="action"
                            className="text-sm font-medium text-center font-quicksand text-manatee xl:text-start"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {customersData.map((customer, index) => {
                        // console.log("🚀 ~ {customersData.map ~ customer:", customer);
                        return (
                            <tr key={index}>
                                <td aria-labelledby="customer-name" className="px-[10px] w-1/5">
                                    <div className="flex items-center gap-3 py-3">
                                        <span className="text-sm font-semibold capitalize font-quicksand text-clinder">
                                            {customer?.CustomerCredential?.Customer?.fullName}
                                        </span>
                                    </div>
                                </td>
                                <td aria-labelledby="level" className="px-[10px] w-1/5">
                                    <div
                                        className={`py-2 px-6 rounded inline-block bg-[${customer?.CustomerLevel?.colorBackground}]`}
                                    >
                                        <span
                                            className={`text-sm font-semibold text-center capitalize text-${customer?.CustomerLevel?.colorFont} font-quicksand`}
                                        >
                                            {customer?.CustomerLevel?.customerLevelName}
                                        </span>
                                    </div>
                                </td>
                                <td aria-labelledby="favorite-menu" className="px-[10px] w-1/5">
                                    <div className="flex items-center gap-3 py-3">
                                        <span className="text-sm font-semibold font-quicksand">
                                            {customer?.CustomerCredential?.Customer?.phone}
                                        </span>
                                    </div>
                                </td>
                                <td aria-labelledby="total-transaction" className="px-[10px] w-1/5">
                                    <div className="py-[10px] px-[10px]">
                                        <span className="text-sm font-semibold font-quicksand">
                                            {formatDefaultDate(
                                                customer?.CustomerCredential?.Customer?.createdAt
                                            )}
                                        </span>
                                    </div>
                                </td>
                                <td aria-labelledby="action" className="p-[12px] w-[21.23%]">
                                    <div className="flex items-center justify-center gap-1 mt-2">
                                        <button
                                            type="button"
                                            className="block px-3 mx-auto xl:hidden"
                                        >
                                            <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                            <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                                            <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                        </button>
                                        <button
                                            type="button"
                                            className="items-center justify-center flex-1 hidden py-1 duration-300 transform rounded xl:flex bg-neutral-gray hover:bg-gray-200"
                                            onClick={() => {
                                                setCustomerCredentialId(
                                                    customer?.CustomerCredential?.id
                                                );
                                                setIsDetailCustomerModalOpen(true);
                                            }}
                                        >
                                            <img
                                                src={shieldSearch}
                                                alt="shield search"
                                                className="w-3 h-3"
                                            />
                                            <span className="ml-2 text-sm font-semibold font-quicksand text-clinder">
                                                Detail
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200 hidden xl:flex"
                                            onClick={() => {
                                                setIsEditCustomerModalOpen(true);
                                                setCustomerCredentialId(
                                                    customer?.CustomerCredential?.id
                                                );
                                            }}
                                        >
                                            <img src={edit} alt="edit" className="w-3 h-3" />
                                        </button>
                                        <button
                                            type="button"
                                            className="px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200 hidden xl:flex"
                                            onClick={() => {
                                                setIsDeleteConfirmationModalOpen(true);
                                                setCustomerCredentialId(
                                                    customer?.CustomerCredential?.id
                                                );
                                            }}
                                        >
                                            <img
                                                src={trashRed}
                                                alt="trash red"
                                                className="w-3 h-3"
                                            />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* Delete Confirmation  */}
            <DeleteConfirmationModal
                isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
                setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
                shortDeleteConfirmatonMessage={"Delete this customer"}
                longDeleteConfirmatonMessage={"Are you sure you want to delete this customer?"}
                longDeleteButtonLabel={"Yes, Delete this customer"}
                onDelete={onDeleteCustomer}
                id={customerCredentialId}
            />
        </div>
    );
};

export default TabletDesktopCustomerTable;
