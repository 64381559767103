import React from 'react';

const PromoLevelsOverviewModal = ({
  isModalOpen,
  setIsModalOpen,
  allPromoLevels,
}) => {
  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-start justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          setIsModalOpen(false);
        }}
      />
      <div
        className={`h-screen w-full  px-6 md:px-9 pb-6 md:pb-9 flex justify-center items-center gap-6 fixed top-0 right-0 z-50 duration-300 ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={() => setIsModalOpen(false)}
      >
        <div
          className="p-3 rounded-lg bg-midnight blur-10"
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-base font-bold text-white font-quicksand">
            {allPromoLevels.map((item, index) => (
              <>
                <span key={index} className="capitalize">
                  {item.customerLevelName}
                </span>{' '}
                {index !== allPromoLevels.length - 1 && <span>, </span>}
              </>
            ))}
          </p>
          <p className="text-sm font-medium font-quicksand text-gray-x leading-[200%]">
            All the levels above get this promo
          </p>
        </div>
      </div>
    </>
  );
};

export default PromoLevelsOverviewModal;
