import React, { useState } from "react";

import Pagination from "../../components/Pagination";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import NewEmployeeModal from "../../components/restaurant/employee/NewEmployeeModal";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";

import restuarantEmployeeBanner from "../../assets/images/restaurantEmployeeBanner.png";
import TabletDesktopEmployeeTable from "../../components/restaurant/employee/TabletDesktopEmployeeTable";
import MobileEmployeeTable from "../../components/restaurant/employee/MobileEmployeeTable";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const RestaurantEmployee = () => {
    const token = localStorage.getItem('token')
    const tenantId = localStorage.getItem('tenantId')

    const [activeTopNav, setActiveTopNav] = useState("employee");
    const [isNewEmployeeModalOpen, setIsNewEmployeeModalOpen] = useState(false);
    const topNavs = [
        {
            nav: "Employee",
            value: "employee",
            route: "admin-restaurant-employee",
        },
        {
            nav: "Preferences",
            value: "preferences",
            route: "admin-restaurant-preferences",
        },
        {
            nav: "Screen Cashier",
            value: "screen-cashier",
            route: "admin-restaurant-screen-cashier",
        },
    ];

    // fake data
    const employeeNameOptions = [
        { label: "Employee Name", value: "employee-name", disabled: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const roleOptions = [
        { label: "Role", value: "role", disabled: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const dateAddedOptions = [
        { label: "Date Added", value: "date-added", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const status = [
        { label: "Status", value: "date-added", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const employeeData = [
        {
            name: "savannah nguyen",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1618641986557-1ecd230959aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODU5NDcwODE&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "kris roher",
            noHP: 18874121031,
            profilePicture:
                "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEyMzg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "Serenity Fisher",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1519456264917-42d0aa2e0625?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEyNjY&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "Brooklyn Warren",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzMDc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "Franco Delort",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1463453091185-61582044d556?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzMzQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "Serenity Fisher",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODE0MzQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "Brooklyn Warren",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNjc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
        {
            name: "Franco Delort",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1594751439417-df8aab2a0c11?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNzg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            dateAdded: "18 Januari 2023",
            status: "Active",
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] bg-white flex-none h-[86px] sticky top-0 z-50">
                <SideBar isRestaurant={true} />
            </aside>

            <div className="flex flex-col w-full ml-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Restaurant"}
                    menuDescription={
                        "You can manage and organize your restaurant and other things here"
                    }
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        {/* Banner */}
                        <Banner
                            title="Employee"
                            description="On this menu you will be able to create, edit, and also delete the employee. Also you can manage it easily."
                            onOpenModal={() => setIsNewEmployeeModalOpen(true)}
                            openModalButtonLabel="Add New Employee"
                            searchPlaceholder={"Search Employee"}
                            image={restuarantEmployeeBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Employee"}
                            isDisable={true} // disabled due to unready feature
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={topNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Employee Table for Mobile */}
                        <MobileEmployeeTable
                            employeeData={employeeData}
                            onOpenModal={setIsNewEmployeeModalOpen}
                        />

                        {/* Employee Table for Tablet and Desktop */}
                        <TabletDesktopEmployeeTable
                            employeeData={employeeData}
                            employeeNameOptions={employeeNameOptions}
                            roleOptions={roleOptions}
                            dateAddedOptions={dateAddedOptions}
                            status={status}
                        />
                    </div>

                    {/* Pagination */}
                    <div className="static bottom-0 pt-2 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center px-3 py-2 rounded-lg md:justify-between bg-neutral-gray">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing 0 Employees
                            </p>
                            <Pagination totalPages={38} />
                        </div>
                    </div>

                    {/* New Employee Modal */}
                    <NewEmployeeModal
                        isModalOpen={isNewEmployeeModalOpen}
                        setIsModalOpen={setIsNewEmployeeModalOpen}
                    />
                </main>
            </div>
        </div>
    );
};

export default RestaurantEmployee;
