import React from 'react';

import searchBlue from '../../../assets/icons/searchBlue.svg';
import plus from '../../../assets/icons/plus.svg';

const MobileBaseProductTable = ({ baseProductData, setIsNewBaseModalOpen }) => {
  return (
    <div className="block md:hidden">
      <div className="flex gap-4 px-6 py-2 sticky top-[164px] bg-white border-b-[1px] border-gray-x">
        <img src={searchBlue} alt="search" />
        <input
          type="text"
          placeholder="Find your categories here"
          className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
        />
        <button
          type="button"
          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
          onClick={() => setIsNewBaseModalOpen(true)}
        >
          <img src={plus} alt="add" className="mx-auto" />
        </button>
      </div>
      {baseProductData.map((baseProduct, index) => (
        <div key={index} className="w-full">
          <div
            className={`flex items-center justify-between w-full py-4 pl-6 pr-3 bg-neutral-gray ${
              index === 0 ? 'border-b-[1px]' : 'border-y-[1px]'
            }`}
          >
            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder ">
              {baseProduct.baseProductName}
            </p>
            <button type="button" className="px-3">
              <div className="w-[4px] h-[4px] bg-black rounded-full" />
              <div className="w-[4px] h-[4px] bg-black rounded-full my-1" />
              <div className="w-[4px] h-[4px] bg-black rounded-full" />
            </button>
          </div>
          <div className="flex items-center justify-between px-4 mt-4">
            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
              Product
            </p>
            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
              {baseProduct.product} Menu
            </p>
          </div>
          <div className="flex items-center justify-between px-4 mt-4 mb-4 xs:justify-between">
            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
              Stock
            </p>
            <div className=" flex justify-end flex-wrap items-center gap-[3px] 3xl:gap-3">
              <div>
                <span className="text-sm font-semibold leading-5 font-quicksand text-clinder">
                  {baseProduct.stockOnTheInventory
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  &nbsp;Kg
                </span>
                <span className="text-xs font-semibold font-quicksand text-manatee">
                  / 10.000 Kg
                </span>
              </div>
              <div>
                {baseProduct.stockOnTheInventory < 2000 ? (
                  <span className="px-2 py-1 text-sm font-semibold rounded font-quicksand text-scarlet-red bg-coral">
                    Running Out
                  </span>
                ) : baseProduct.stockOnTheInventory < 6000 ? (
                  <span className="px-2 py-1 ml-[4px] text-sm font-semibold rounded font-quicksand text-golden-sun bg-beige">
                    Need Restock
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileBaseProductTable;
