import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const UniySystemsOption = ({
  label,
  options,
  background,
  radius,
  width,
  height,
  iconSize,
  showResultOption,
  optionsWidth,
  padding,
  selected,
  setSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelected(label);
  }, [label]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative ${width}`}>
      <div
        className={`flex items-center justify-between rounded-lg cursor-pointer font-quicksand ${padding} ${height} ${background}`}
        onClick={toggleDropdown}
      >
        <span
          className={`text-sm font-medium font-quicksand ${
            showResultOption ? 'block' : 'hidden'
          } ${selected === label ? 'text-gray-x' : 'text-clinder capitalize'} `}
        >
          {selected}
        </span>
        <span
          className={`${radius} ${iconSize} flex items-center justify-center bg-white text-clinder shadow-soft flex-none`}
        >
          {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </span>
      </div>
      {isOpen && (
        <div
          className={`absolute right-0 z-10 mt-2 rounded shadow bg-neutral-gray ${optionsWidth}`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={`flex justify-between items-center px-4 py-2 text-sm font-medium transition-all duration-300 cursor-pointer font-quicksand hover:bg-gray-200 ${
                option === selected && 'bg-gray-200'
              }`}
              onClick={() => {
                setSelected(option);
                setIsOpen(false);
              }}
            >
              <span className="capitalize">{option}</span>{' '}
              <span className="text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none ">
                {option === 'kilogram' ? 'kg' : option === 'gram' ? 'g' : 'pcs'}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

UniySystemsOption.defaultProps = {
  background: 'bg-neutral-gray',
  radius: 'rounded-lg',
  height: 'h-14',
  width: 'w-full',
  iconSize: 'w-8 h-8',
  showResultOption: true,
  label: null,
  optionsWidth: 'w-full',
  padding: 'px-4',
};

export default UniySystemsOption;
