import React, { useState } from "react";

import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ScreenCashierTemplateModal from "../../components/restaurant/screen_cashier/ScreenCashierTemplateModal";
import TopNavbar from "../../components/TopNavbar";

import screenCashierBanner from "../../assets/images/screenCashierBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";
import UnderConstructionBanner from "../../components/banner/UnderConstructionBanner";

const RestaurantScreenCashier = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [activeTopNav, setActiveTopNav] = useState("screen-cashier");
    const [isScreenCashierTemplateModalOpen, setIsScreenCashierTemplateModalOpen] = useState(false);
    const topNavs = [
        {
            nav: "Employee",
            value: "employee",
            route: "admin-restaurant-employee",
        },
        {
            nav: "Preferences",
            value: "preferences",
            route: "admin-restaurant-preferences",
        },
        {
            nav: "Screen Cashier",
            value: "screen-cashier",
            route: "admin-restaurant-screen-cashier",
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] bg-white flex-none h-[86px] sticky top-0 z-50">
                <SideBar isRestaurant={true} />
            </aside>

            <div className="flex flex-col w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Restaurant"}
                    menuDescription={
                        "You can manage and organize your restaurant and other things here"
                    }
                    topNavs={topNavs}
                />
                <Banner
                    title="Screen Cashier"
                    description="On this menu you will be able to manage preferences about Screen Cashier. Also you can manage it easily."
                    onOpenModal={() => setIsScreenCashierTemplateModalOpen(true)}
                    openModalButtonLabel="Choose Screen Cashier Template"
                    showPrintButton={false}
                    searchPlaceholder={"Search Screen Cashier Template"}
                    image={screenCashierBanner}
                    isDisable={true} // disabled due to unready feature
                />

                {/* Navbar for Mobile & Tablet */}
                <TopNavbar
                    topNavs={topNavs}
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                />

                {/* main content */}
                {/* <div className="mt-48">
                    <p className="text-2xl font-bold text-center">Menunggu Testing Screen</p>
                </div> */}
                <UnderConstructionBanner />

                {/* Choose Screen Cashier Template Modal */}
                <ScreenCashierTemplateModal
                    isModalOpen={isScreenCashierTemplateModalOpen}
                    setIsModalOpen={setIsScreenCashierTemplateModalOpen}
                />
            </div>
        </div>
    );
};

export default RestaurantScreenCashier;
