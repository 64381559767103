import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const DaysOptionDropdown = ({
  label,
  options,
  background,
  radius,
  width,
  height,
  iconSize,
  showResultOption,
  optionsWidth,
  optionHeight,
  padding,
  setSelectedId,
  selectedValue,
  setSelectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative ${width}`}>
      <div
        className={`flex items-center justify-between rounded-lg cursor-pointer font-quicksand ${padding} ${height} ${background}`}
        onClick={toggleDropdown}
      >
        <span
          className={`text-sm font-semibold  font-quicksand ${
            showResultOption ? 'block' : 'hidden'
          } ${
            selectedValue === '' ? 'text-gray-x' : 'text-clinder capitalize'
          } `}
        >
          {selectedValue === '' ? label : selectedValue}
        </span>
        <span
          className={`${radius} ${iconSize} flex items-center justify-center bg-white text-clinder shadow-soft flex-none`}
        >
          {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </span>
      </div>
      {isOpen && (
        <div
          className={`absolute right-0 z-10 mt-2 pb-4 rounded-lg bg-neutral-gray overflow-y-auto ${optionsWidth} ${optionHeight}`}
        >
          {options?.map((option, index) => (
            <div
              key={index}
              className="px-4 py-2 mt-4 text-sm font-medium capitalize cursor-pointer font-quicksand"
              onClick={() => {
                setSelectedId(option?.id);
                setSelectedValue(option?.value);
                setIsOpen(false);
              }}
            >
              <span
                className={`leading-5 text-sm font-semibold font-quicksand text-clinder`}
              >
                {option?.value}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DaysOptionDropdown.defaultProps = {
  background: 'bg-neutral-gray',
  radius: 'rounded-lg',
  height: 'h-14',
  width: 'w-full',
  iconSize: 'w-8 h-8',
  showResultOption: true,
  label: null,
  optionsWidth: 'w-full',
  optionHeight: 'h-auto',
  padding: 'px-4',
};

export default DaysOptionDropdown;
