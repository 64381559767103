import React, { useEffect, useRef, useState } from 'react';

import SingleIndicatorDropdown from '../../SingleIndicatorDropdown';
import SuccessNotification from '../../SuccessNotification';

import close from '../../../assets/icons/close.svg';
import arrowRightWhite from '../../../assets/icons/arrow-right-white.svg';
import checked from '../../../assets/icons/checklist.svg';
import arrowLeftBlue from '../../../assets/icons/arrowLeftBlue.svg';
import backSquare from '../../../assets/icons/backSquare.svg';
import { AiOutlineCheck } from 'react-icons/ai';

const NewRoleModal = ({ isModalOpen, setIsModalOpen }) => {
  const modalContainerRef = useRef(null);
  const [activeForm, setActiveForm] = useState('general');
  const [countdown, setCountdown] = useState(5);
  const MENUS = [
    {
      name: 'Dashboard',
      id: 1,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Stock',
      id: 2,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Customer',
      id: 3,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Restaurant',
      id: 4,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Design',
      id: 6,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Report',
      id: 7,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Role & Admin',
      id: 8,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Settings',
      id: 9,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Stock',
      id: 10,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Supply',
      id: 11,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  ];
  const [menus, setMenus] = useState(MENUS);
  const toggleAccessibility = (menuIndex, key) => {
    const updatedMenus = [...menus];
    updatedMenus[menuIndex][key] = !updatedMenus[menuIndex][key];
    setMenus(updatedMenus);
  };

  // Fake Data
  const statusOptions = ['Active', 'Inactive'];

  // handle close modal by timer
  useEffect(() => {
    if (activeForm === 'get-started') {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      if (countdown === 0) {
        clearInterval(timer);
        window.scrollTo(0, 0);
        setIsModalOpen(false);
        setActiveForm('general');
        setCountdown(5);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, activeForm]);

  // handle submit general form
  const handleGeneralForm = (e) => {
    e.preventDefault();
    setActiveForm('accessibility');
    modalContainerRef.current.scrollTop = 0;
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
          activeForm === 'get-started' && setCountdown(5);
          setActiveForm('general');
        }}
      />
      <div
        ref={modalContainerRef}
        className={`bg-white w-full lg:w-[820px] px-0 md:px-9 pb-6 md:pb-9 h-screen overflow-y-auto flex flex-col fixed top-0 right-0 z-50 duration-300 transition ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className="sticky top-0 z-50 px-6 bg-white -mx- md:mx-0 md:px-0">
          <div className="flex flex-row-reverse items-center pt-6 md:pt-9 justify-end gap-6 px-6 md:justify-between md:flex-row md:px-0 border-b-[1px] md:border-b-0 border-gray-x/40 pb-6 md:pb-0 -mx-6 md:mx-0">
            <h2 className="text-xl font-bold md:text-2xl font-quicksand text-aurora-blue">
              Create New Role
            </h2>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setIsModalOpen(false);
                activeForm === 'get-started' && setCountdown(5);
                setActiveForm('general');
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="font-satoshi text-sm font-medium leading-[200%] text-manatee w-8/12 mt-2 hidden md:block">
            In this window you can set the new role and also manage their
            accessibility to your restaurant.
          </p>
          <div className="flex items-center gap-1 p-3 m t-0 md:mt-4 bg-neutral-gray border-b-[2px] border-[#0170a0] -mx-6 md:mx-0">
            <button className="flex-1 cursor-auto">
              <div
                className={`text-sm font-semibold font-quicksand py-1 rounded-full hidden md:inline ${
                  activeForm === 'general'
                    ? 'bg-aurora-blue text-white px-[10px]'
                    : 'bg-lime-green px-[5.5px]'
                }`}
              >
                <span className={` ${activeForm !== 'general' && 'hidden'}`}>
                  1
                </span>
                <img
                  src={checked}
                  alt="checked"
                  className={`${
                    activeForm === 'general' ? 'hidden' : 'inline -mt-1'
                  }`}
                />
              </div>
              <span
                className={`text-sm ml-0 md:ml-[10px] font-bold font-quicksand ${
                  activeForm === 'general'
                    ? 'text-aurora-blue'
                    : 'text-lime-green'
                }`}
              >
                General
              </span>
            </button>
            <div
              className={`w-3 h-[2px] hidden md:block ${
                activeForm !== 'general' ? 'bg-lime-green' : 'bg-manatee'
              }`}
            />
            <button className="flex-1 cursor-auto">
              <div
                className={`hidden md:inline py-1 mx-auto text-sm font-semibold rounded-full ${
                  activeForm === 'general'
                    ? 'bg-gray-x px-[9px] text-manatee'
                    : activeForm === 'accessibility'
                    ? 'bg-aurora-blue px-[9px] text-white'
                    : 'bg-lime-green px-[5.5px]'
                } font-quicksand`}
              >
                <span
                  className={`text-sm font-semibold ${
                    activeForm === 'general'
                      ? 'text-manatee'
                      : activeForm === 'accessibility'
                      ? 'text-white'
                      : 'hidden'
                  } font-quicksand`}
                >
                  2
                </span>
                <img
                  src={checked}
                  alt="checked"
                  className={`${
                    activeForm === 'get-started' ? 'inline -mt-1' : 'hidden'
                  }`}
                />
              </div>
              <span
                className={`text-sm ml-0 md:ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'accessibility'
                    ? 'text-aurora-blue'
                    : activeForm === 'get-started'
                    ? 'text-lime-green'
                    : 'text-manatee'
                }`}
              >
                Accessibility
              </span>
            </button>
            <div className="w-3 h-[2px] bg-manatee hidden md:block" />
            <button className="flex-1 cursor-auto">
              <span
                className={`hidden md:inline py-1 px-[9px] mx-auto text-sm font-semibold rounded-full ${
                  activeForm === 'get-started'
                    ? 'bg-aurora-blue text-white'
                    : 'bg-gray-x text-manatee'
                } text-manatee font-quicksand`}
              >
                3
              </span>
              <span
                className={`text-sm ml-0 md:ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'get-started'
                    ? 'text-aurora-blue'
                    : 'text-manatee'
                }`}
              >
                Get Started
              </span>
            </button>
          </div>
        </header>
        {/* Form General */}
        {activeForm === 'general' && (
          <form
            className="flex flex-col justify-between flex-1 px-6 mt-6 md:px-0"
            onSubmit={handleGeneralForm}
          >
            <div>
              <div>
                <label
                  htmlFor="roleName"
                  className="text-sm font-semibold font-quicksand"
                >
                  Role Name
                </label>
                <input
                  type="text"
                  required
                  id="roleName"
                  placeholder="Create Role Name"
                  className="block w-full px-4 py-3 mt-2 focusable-input"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="roleName"
                  className="text-sm font-semibold font-quicksand"
                >
                  Status
                </label>
                <SingleIndicatorDropdown
                  options={statusOptions}
                  label={'Pick status for Role'}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="role-description"
                  className="text-sm font-semibold font-quicksand"
                >
                  Role Description
                </label>
                <textarea
                  type="text"
                  required
                  id="role-description"
                  placeholder="Create Role Name"
                  className="block w-full px-4 py-3 mt-2 resize-none h-44 focusable-input"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full mb-11 mt-9 md:mb-0 button-primary-lg"
            >
              <span className="text-base font-bold text-white font-quicksand">
                Next
              </span>
              <img src={arrowRightWhite} alt="arrow right white" />
            </button>
          </form>
        )}

        {/* Accessibility Form*/}
        {activeForm === 'accessibility' && (
          <form className="mt-0 md:mt-12 border-t-[1px] border-midnight-black/10 flex-1 flex-col justify-between">
            <div>
              <div className="flex justify-between px-6 py-4 mt-0 md:mt-9 md:px-0 md:py-0">
                <h3 className="text-sm font-semibold text-clinder font-quicksand">
                  Hak Akses
                </h3>
                <p className="text-sm font-semibold font-quicksand">
                  <span className="text-midnight-black">16</span>{' '}
                  <span className="text-manatee">Hak Akses dipilih </span>
                </p>
              </div>
              {/* Accessibility Form for Tablet and Desktop */}
              <div className="px-6 py-6 mt-4 border-[1px] border-smoky-black/20 rounded-lg hidden md:flex">
                <table className="w-full">
                  <thead>
                    <tr className="border-b-[1px] border-smoky-black/20 text-manatee">
                      <th>
                        <div className="w-[300px] pb-2 text-sm font-medium font-satoshi text-start">
                          Nama Menu
                        </div>
                      </th>
                      <th>
                        <div className="pb-2 text-sm font-medium font-satoshi w-[90px]">
                          View{' '}
                        </div>
                      </th>
                      <th>
                        <div className="pb-2 text-sm font-medium font-satoshi w-[90px] ">
                          Create{' '}
                        </div>
                      </th>
                      <th>
                        <div className="pb-2 text-sm font-medium font-satoshi w-[90px] ">
                          Edit{' '}
                        </div>
                      </th>
                      <th>
                        <div className="pb-2 text-sm font-medium font-satoshi w-[90px] ">
                          Delete{' '}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {menus.map((menu, index) => (
                      <tr key={index}>
                        <td
                          className={
                            'w-[100px] font-semibold capitalize text-clinder text-sm font-quicksand'
                          }
                        >
                          <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                            {menu.name}
                          </div>
                        </td>
                        <td className="w-[50px]">
                          <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                            <button
                              type="button"
                              className={`block w-6 h-6 mx-auto rounded ${
                                menu.view
                                  ? 'bg-aurora-blue'
                                  : 'bg-white border-[2px] border-[#0170a0]'
                              }`}
                              onClick={() => toggleAccessibility(index, 'view')}
                            >
                              {menu.view && (
                                <AiOutlineCheck
                                  color={'white'}
                                  size={25}
                                  className="mx-auto"
                                />
                              )}
                            </button>
                          </div>
                        </td>
                        <td className="w-[50px]">
                          <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                            <button
                              type="button"
                              className={`block w-6 h-6 mx-auto rounded ${
                                menu.create
                                  ? 'bg-aurora-blue'
                                  : 'bg-white border-[2px] border-[#0170a0]'
                              }`}
                              onClick={() =>
                                toggleAccessibility(index, 'create')
                              }
                            >
                              {menu.create && (
                                <AiOutlineCheck
                                  color={'white'}
                                  size={25}
                                  className="mx-auto"
                                />
                              )}
                            </button>
                          </div>
                        </td>
                        <td className="w-[50px]">
                          <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                            <button
                              type="button"
                              className={`block w-6 h-6 mx-auto rounded ${
                                menu.edit
                                  ? 'bg-aurora-blue'
                                  : 'bg-white border-[2px] border-[#0170a0]'
                              }`}
                              onClick={() => toggleAccessibility(index, 'edit')}
                            >
                              {menu.edit && (
                                <AiOutlineCheck
                                  color={'white'}
                                  size={25}
                                  className="mx-auto"
                                />
                              )}
                            </button>
                          </div>
                        </td>
                        <td className="w-[50px]">
                          <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                            <button
                              type="button"
                              className={`block w-6 h-6 mx-auto rounded ${
                                menu.delete
                                  ? 'bg-aurora-blue'
                                  : 'bg-white border-[2px] border-[#0170a0]'
                              }`}
                              onClick={() =>
                                toggleAccessibility(index, 'delete')
                              }
                            >
                              {menu.delete && (
                                <AiOutlineCheck
                                  color={'white'}
                                  size={25}
                                  className="mx-auto"
                                />
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Accessibility Form for Mobile */}
              <div className="block md:hidden">
                {menus.map((menu, index) => (
                  <div key={index} className="w-full">
                    <div className="py-4 px-6 bg-neutral-gray border-y-[1px] text-sm font-semibold leading-[150%] font-quicksand text-clinder capitalize">
                      {menu.name}
                    </div>
                    <div className="flex items-center justify-between w-full px-6 mt-4">
                      <p className="block text-sm font-semibold font-quicksand text-clinder">
                        View
                      </p>
                      <button
                        type="button"
                        className={`block w-6 h-6 rounded ${
                          menu.view
                            ? 'bg-aurora-blue'
                            : 'bg-white border-[2px] border-[#0170a0]'
                        }`}
                        onClick={() => toggleAccessibility(index, 'view')}
                      >
                        {menu.view && (
                          <AiOutlineCheck
                            color={'white'}
                            size={25}
                            className="mx-auto"
                          />
                        )}
                      </button>
                    </div>
                    <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                        Create
                      </p>
                      <button
                        type="button"
                        className={`block w-6 h-6 rounded ${
                          menu.create
                            ? 'bg-aurora-blue'
                            : 'bg-white border-[2px] border-[#0170a0]'
                        }`}
                        onClick={() => toggleAccessibility(index, 'create')}
                      >
                        {menu.create && (
                          <AiOutlineCheck
                            color={'white'}
                            size={25}
                            className="mx-auto"
                          />
                        )}
                      </button>
                    </div>
                    <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                        Edit
                      </p>
                      <button
                        type="button"
                        className={`block w-6 h-6 rounded ${
                          menu.edit
                            ? 'bg-aurora-blue'
                            : 'bg-white border-[2px] border-[#0170a0]'
                        }`}
                        onClick={() => toggleAccessibility(index, 'edit')}
                      >
                        {menu.edit && (
                          <AiOutlineCheck
                            color={'white'}
                            size={25}
                            className="mx-auto"
                          />
                        )}
                      </button>
                    </div>
                    <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                        Delete
                      </p>
                      <button
                        type="button"
                        className={`block w-6 h-6 rounded ${
                          menu.delete
                            ? 'bg-aurora-blue'
                            : 'bg-white border-[2px] border-[#0170a0]'
                        }`}
                        onClick={() => toggleAccessibility(index, 'delete')}
                      >
                        {menu.delete && (
                          <AiOutlineCheck
                            color={'white'}
                            size={25}
                            className="mx-auto"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex gap-[10px] w-full mt-9 px-9 pb-11 md:pb-0">
              <button
                type="button"
                className="button-secondary-lg"
                onClick={() => {
                  setActiveForm('general');
                  modalContainerRef.current.scrollTop = 0;
                }}
              >
                <img src={arrowLeftBlue} alt="arrow left" className="w-4 h-4" />
                <span className="text-base font-bold text-aurora-blue">
                  Back
                </span>
              </button>
              <button
                type="button"
                className="w-full button-primary-lg"
                onClick={() => {
                  setActiveForm('get-started');
                  modalContainerRef.current.scrollTop = 0;
                }}
              >
                <span className="text-base font-bold text-white font-quicksand">
                  Next
                </span>
                <img src={arrowRightWhite} alt="arrow right white" />
              </button>
            </div>
          </form>
        )}

        {/* Get Started section */}
        {activeForm === 'get-started' && (
          <div className="flex flex-col justify-between flex-1 px-6 md:px-0">
            <div>
              <div className="mt-9 3xl:mt-24 w-full xs:w-[390px] mx-auto">
                <SuccessNotification
                  title={'New Role Created Successfully'}
                  message={
                    'The new role has been successfully added to the restaurant management.'
                  }
                  notificationCountdown={5}
                  path={'/admin-role-and-admin-manage-role'}
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-full mb-11 mt-9 xl:mb-0 button-primary-lg"
              onClick={() => {
                window.scrollTo(0, 0);
                setIsModalOpen(false);
                activeForm === 'get-started' && setCountdown(5);
                setActiveForm('general');
              }}
            >
              <img src={backSquare} alt="arrow right white" />
              <span className="text-base font-bold text-white font-quicksand">
                Back to Homepage
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default NewRoleModal;
