import React, { useEffect, useState } from "react";

import axiosInstance from "../../utils/api/apiConfig";

import Pagination from "../../components/Pagination";
import NewBaseModal from "../../components/stock/base_product/NewBaseModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopBaseProductTable from "../../components/stock/base_product/TabletDesktopBaseProductTable";
import MobileBaseProductTable from "../../components/stock/base_product/MobileBaseProductTable";
import Loading from "../../components/Loading";
import RestockModal from "../../components/stock/base_product/RestockModal";
import DetailBaseProductModal from "../../components/stock/base_product/DetailBaseProductModal";

import baseProductBanner from "../../assets/images/baseProductBanner.png";
import FilterBaseProductModal from "../../components/stock/base_product/FilterBaseProductModal";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const StockBaseProduct = () => {
    // === Base Product State ===
    const [baseProducts, setBaseProducts] = useState([]);
    const [displayedBaseProducts, setDisplayedBaseProducts] = useState([]);
    const [baseProductId, setBaseProductId] = useState();
    const [baseProductCurrentStock, setIsBaseProductCurrentStock] = useState();
    const [baseProductUnitSystem, setIsBaseProductUnitSystem] = useState();
    const [baseProductTotalStock, setBaseProductTotalStock] = useState(0);

    // === Filtering State ===
    const [searchQuery, setSearchQuery] = useState("");
    const [filterNeedRestock, setFilterNeedRestock] = useState(null);
    const [selectedMenuName, setSelectedMenuName] = useState("");
    const [selectedMenuId, setSelectedMenuId] = useState(null);
    const [filteredBaseProductsByOptionFilter, setFilteredBaseProductsByOptionFilter] = useState(
        []
    );
    const [isRefreshActive, setIsRefreshActive] = useState(false);
    const [isOptionFilterActive, setIsOptionFilterActive] = useState(false);
    const [isOptionFilterToggleActive, setIsOptionFilterToggelActive] = useState(false);

    // === Pagination State ===
    const itemsPerPage = 3;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // === Component State ===
    const [isNewBaseModalOpen, setIsNewBaseModalOpen] = useState(false);
    const [isRestockModalOpen, setIsRestockModalOpen] = useState(false);
    const [isDetailBaseProductModalOpen, setIsDetailBaseProductModalOpen] = useState(false);
    const [isFilterBaseProductModalOpen, setIsFilterBaseProductModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("base-product");
    const topNavs = [
        { nav: "Category", value: "category", route: "admin-stock-category" },
        {
            nav: "Base Product",
            value: "base-product",
            route: "admin-stock-base-product",
        },
        { nav: "Menu", value: "menu", route: "admin-stock-menu" },
        { nav: "Price", value: "price", route: "admin-stock-price" },
        {
            nav: "Favorite Menu",
            value: "favorite-menu",
            route: "admin-stock-favorite-menu",
        },
        {
            nav: "Additional Notes",
            value: "additional-notes",
            route: "admin-stock-additional-notes",
        },
    ];

    // === State Condition ===
    const [isLoadingGetAllBaseProducts, setIsLoadingGetAllBaseProducts] = useState(false);
    const [isLoadingDeleteBaseProduct, setIsLoadingDeleteBaseProduct] = useState(false);
    const [isLoadingGetBaseProductById, setIsLoadingGetBaseProductById] = useState(false);

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    // Fake Data
    const baseProductNameOptions = [
        { label: "Base Product Name", value: "base product name", disabled: true },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];
    const stockOnTheInventoryOptions = [
        {
            label: "Stock on the Inventory",
            value: "stock on the Inventory",
            disabled: true,
        },
        { label: "Less than 2000", value: "<2000" },
        { label: "2000 - 6000", value: "2000-6000" },
        { label: "More than 6000", value: ">6000" },
    ];
    const popularMenuCreatedOptions = [
        {
            label: "Popular Menu Created",
            value: "popular menu created",
            disabled: true,
        },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];
    const productSortOptions = [
        { label: "product", value: "product", disabled: true },
        { label: "Less than 10", value: "<10" },
        { label: "10 - 20", value: "10-20" },
        { label: "21 - 30", value: "21-30" },
        { label: "41 - 50", value: "41-50" },
        { label: "More than 50", value: ">50" },
    ];
    const baseProduct = [
        {
            baseProductName: "Rhubarb",
            stockOnTheInventory: 5028,
            popularMenuCreated: "Sooji halwa",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Potatoes",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Dal fry with tadka",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Sweet potatoes",
            stockOnTheInventory: 1148,
            popularMenuCreated: "Imarti",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Sweet potatoes",
            stockOnTheInventory: 1148,
            popularMenuCreated: "Imarti",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Peppers",
            stockOnTheInventory: 4600,
            popularMenuCreated: "Bhatura",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Parsnip",
            stockOnTheInventory: 4152,
            popularMenuCreated: "Ennai kathirikkai",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Snap peas",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Dibba rotti",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "English peas",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Bhatura",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Radicchio",
            stockOnTheInventory: 9374,
            popularMenuCreated: "amri with rabdi",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Escarole",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Bhindi masala",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Arugula",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Naan",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Arugula",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Naan",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Arugula",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Naan",
            product: 26,
            unitSystem: "kilogram",
        },
        {
            baseProductName: "Arugula",
            stockOnTheInventory: 9374,
            popularMenuCreated: "Naan",
            product: 26,
            unitSystem: "kilogram",
        },
    ];

    // Handle display base products based on filtering and pagination
    useEffect(() => {
        const handleDisplayBaseProducts = () => {
            if (searchQuery !== "" && filterNeedRestock === null && selectedMenuId === null) {
                const searchedBaseProducts = baseProducts?.filter((baseProduct) =>
                    baseProduct?.baseProductName?.toLowerCase().includes(searchQuery?.toLowerCase())
                );
                setDisplayedBaseProducts(searchedBaseProducts?.slice(startIndex, endIndex));
                setTotalPages(Math.ceil(searchedBaseProducts?.length / itemsPerPage));
                setTotalData(searchedBaseProducts?.length);
            } else if (filterNeedRestock !== null || selectedMenuId !== null) {
                if (searchQuery !== "") {
                    const searchedBaseProducts = filteredBaseProductsByOptionFilter?.filter(
                        (baseProduct) =>
                            baseProduct?.baseProductName
                                ?.toLowerCase()
                                .includes(searchQuery?.toLowerCase())
                    );
                    setDisplayedBaseProducts(searchedBaseProducts?.slice(startIndex, endIndex));
                    setTotalPages(Math.ceil(searchedBaseProducts?.length / itemsPerPage));
                    setTotalData(searchedBaseProducts?.length);
                } else {
                    setDisplayedBaseProducts(
                        filteredBaseProductsByOptionFilter?.slice(startIndex, endIndex)
                    );
                    setTotalPages(
                        Math.ceil(filteredBaseProductsByOptionFilter?.length / itemsPerPage)
                    );
                    setTotalData(filteredBaseProductsByOptionFilter?.length);
                }
            } else {
                setDisplayedBaseProducts(baseProducts?.slice(startIndex, endIndex));
                setTotalPages(Math.ceil(baseProducts?.length / itemsPerPage));
                setTotalData(baseProducts?.length);
            }
        };

        handleDisplayBaseProducts();
    }, [baseProducts, currentPage, searchQuery, isOptionFilterToggleActive]);

    // Handle Active refresh (when search query and  option filter is active)
    useEffect(() => {
        if (searchQuery !== "" || isOptionFilterActive) {
            setIsRefreshActive(true);
        } else {
            setIsRefreshActive(false);
        }
    }, [searchQuery, isOptionFilterToggleActive]);

    // Handle delete filter
    const handleDeleteFilter = () => {
        setSearchQuery("");
        setFilterNeedRestock(null);
        setSelectedMenuId(null);
        setIsOptionFilterActive(false);
        setIsOptionFilterToggelActive(!isOptionFilterToggleActive);
    };

    useEffect(() => {
        getAllBaseProducts();
    }, []);

    // Handle Get All Base Products
    const getAllBaseProducts = async () => {
        setIsLoadingGetAllBaseProducts(true);
        try {
            const response = await axiosInstance.get(`/base-products`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setBaseProducts(response.data.data.data);
            setIsLoadingGetAllBaseProducts(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingGetAllBaseProducts(false);
        }
    };

    // Handle Delete Category
    const deleteBaseProduct = async (id) => {
        setIsLoadingDeleteBaseProduct(true);
        try {
            await axiosInstance.delete(`/base-products/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllBaseProducts();
            setIsLoadingDeleteBaseProduct(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingDeleteBaseProduct(false);
        }
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="md:w-[88px] 2xl:w-[200px] sticky top-0 z-50 w-full flex-none bg-white h-[86px]">
                <SideBar isStock={true} />
            </aside>

            <div className="flex flex-col w-full min-h-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Product"}
                    menuDescription={"You can manage and organize your product stock here"}
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title="Base Product"
                            description="On this menu you will be able to create, edit, and also delete the Additional Notes. Also you can manage it easily."
                            onOpenModal={() => setIsNewBaseModalOpen(true)}
                            // onOpenFilterModal={() => setIsFilterBaseProductModalOpen(true)}
                            openModalButtonLabel="Add New Base"
                            searchPlaceholder={"Find your base product here"}
                            image={baseProductBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Base Products"}
                            // isRefreshActive={isRefreshActive}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            onHandleDeleteFilter={handleDeleteFilter}
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={topNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Base Product Table For Mobile */}
                        <MobileBaseProductTable
                            baseProductData={baseProduct}
                            setIsNewBaseModalOpen={setIsNewBaseModalOpen}
                        />

                        {/* Base Product Table For Tablet & Desktop */}
                        {isLoadingGetAllBaseProducts ? (
                            <Loading />
                        ) : (
                            <TabletDesktopBaseProductTable
                                baseProductNameOptions={baseProductNameOptions}
                                stockOnTheInventoryOptions={stockOnTheInventoryOptions}
                                popularMenuCreatedOptions={popularMenuCreatedOptions}
                                productSortOptions={productSortOptions}
                                displayedBaseProducts={displayedBaseProducts}
                                allBaseProducts={baseProducts}
                                onDeleteBaseProduct={deleteBaseProduct}
                                setIsDetailBaseProductModalOpen={setIsDetailBaseProductModalOpen}
                                setIsRestockModalOpen={setIsRestockModalOpen}
                                setBaseProductId={setBaseProductId}
                                setBaseProductCurrentStock={setIsBaseProductCurrentStock}
                                setBaseProductUnitSystem={setIsBaseProductUnitSystem}
                                setBaseProductTotalStock={setBaseProductTotalStock}
                            />
                        )}
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between bg-neutral-gray">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing{" "}
                                <span className="text-clinder">
                                    {displayedBaseProducts?.length}
                                </span>{" "}
                                of <span className="text-clinder">{totalData}</span> Base Product
                                {totalData > 1 && "s"}
                            </p>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    </div>

                    {/* Add New Base Modal */}
                    <NewBaseModal
                        isModalOpen={isNewBaseModalOpen}
                        setIsModalOpen={setIsNewBaseModalOpen}
                        onGetAllBaseProducts={getAllBaseProducts}
                    />

                    {/* Restock Modal */}
                    <RestockModal
                        isModalOpen={isRestockModalOpen}
                        setIsModalOpen={setIsRestockModalOpen}
                        baseProductCurrentStock={baseProductCurrentStock}
                        baseProductUnitSystem={baseProductUnitSystem}
                        onGetAllBaseProducts={getAllBaseProducts}
                        totalStock={baseProductTotalStock}
                        setTotalStock={setBaseProductTotalStock}
                        baseProductId={baseProductId}
                    />

                    {/* Detail Base Product Modal */}
                    <DetailBaseProductModal
                        isModalOpen={isDetailBaseProductModalOpen}
                        setIsModalOpen={setIsDetailBaseProductModalOpen}
                        baseProductId={baseProductId}
                    />

                    {/* Filter Base Product Modal*/}
                    <FilterBaseProductModal
                        isModalOpen={isFilterBaseProductModalOpen}
                        setIsModalOpen={setIsFilterBaseProductModalOpen}
                        setIsOptionFilterActive={setIsOptionFilterActive}
                        isOptionFilterToggelActive={isOptionFilterToggleActive}
                        setIsOptionFilterToggleActive={setIsOptionFilterToggelActive}
                        filterNeedRestock={filterNeedRestock}
                        setFilterNeedRestock={setFilterNeedRestock}
                        selectedMenuName={selectedMenuName}
                        setSelectedMenuName={setSelectedMenuName}
                        selectedMenuId={selectedMenuId}
                        setSelectedMenuId={setSelectedMenuId}
                        baseProducts={baseProducts}
                        setFilteredBaseProductsByOptionFilter={
                            setFilteredBaseProductsByOptionFilter
                        }
                    />
                </main>
            </div>
        </div>
    );
};

export default StockBaseProduct;
