import React from 'react';
import { BsStarFill } from 'react-icons/bs';

import axiosInstance from '../../../utils/api/apiConfig';

const SelectionFovoriteMenuButton = ({
  choosedDayId,
  choosedDay,
  menuDay,
  productId,
  onGetFavoriteMenuByDay,
  isFavorites,
  favoritesProductId,
}) => {
  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const creator = localStorage.getItem('userFullName');

  // Handle Submit Favorite Menu
  const handleSubmitFavoriteMenu = async () => {
    const bodyRequest = [
      {
        dayListId: choosedDayId,
        productId: productId,
      },
    ];

    try {
      const response = await axiosInstance.post(`/favorites`, bodyRequest, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      onGetFavoriteMenuByDay(choosedDayId);
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle Delete Favorite Menu
  const handleDeleteFavoriteMenu = async () => {
    try {
      const response = await axiosInstance.delete(
        `/favorites/${favoritesProductId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onGetFavoriteMenuByDay(choosedDayId);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleFavoriteMenuStatus = () => {
    if (isFavorites === true && menuDay?.some((day) => day === choosedDay)) {
      handleDeleteFavoriteMenu();
      // console.log('true');
    } else {
      // console.log('chiuse false');
      handleSubmitFavoriteMenu();
    }
  };

  return (
    <button
      type="button"
      className="w-full"
      onClick={() => {
        handleFavoriteMenuStatus();
      }}
    >
      <BsStarFill
        className={`mx-auto block md:hidden ${
          isFavorites ? 'text-golden-sun' : 'text-gray-x'
        }`}
      />
      <div
        className={`py-1 w-full text-xs font-bold hidden md:block rounded font-quicksand  ${
          isFavorites && menuDay?.some((day) => day === choosedDay)
            ? 'button-primary-xs-no-icon text-white hidden'
            : 'button-secondary-xs-no-icon text-blue-500 hidden'
        }`}
      >
        {isFavorites && menuDay === choosedDay
          ? 'Menu Selected'
          : 'Choose Menu'}
      </div>
    </button>
  );
};

export default SelectionFovoriteMenuButton;
