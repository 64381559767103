import React from 'react';

import fixGraphic from '../../../assets/images/fixGraphic.svg';

const DesktopTopMenu = ({ topCustomer }) => {
  // fake data

  return (
    <div className="flex-col justify-between hidden w-full h-full overflow-hidden rounded-lg bg-neutral-gray xl:flex">
      <div className="mx-4 mt-4">
        <div>
          <p className="text-2xl font-quicksand">
            <span className="font-medium text-clinder">Top Customer</span>{' '}
            <br />
            <span className="font-semibold text-orange">This Week</span>
          </p>
          <p className="mt-3 text-xs font-medium font-satoshi text-manatee">
            10 - 12 Agustus 2023
          </p>
        </div>
        <div className="flex flex-col gap-2 mt-5">
          {topCustomer.map((menu, index) => (
            <button
              key={index}
              type="button"
              className="relative block h-10 cursor-pointer group"
              onClick={() => console.log('klik')}
            >
              <div className="z-10 group-hover:bg-white group-hover:shadow-md group-hover:p-[10px] group-hover:absolute -top-1/4 group-hover:rounded-lg transform duration-300 w-full ">
                <span className="hidden group-hover:block text-sm font-bold text-white font-quicksand absolute px-2 bg-orange shadow-[2px_2px_0px_0px_#464646] transform rotate-[8deg] right-0 -top-2 transition duration-300">
                  {index + 1}
                </span>
                <div className="flex text-xs font-semibold font-quicksand text-manatee group-hover:text-sm group-hover:font-bold group-hover:text-black group-hover:font-quicksand text-start">
                  <span className="mr-1 group-hover:hidden">{index + 1}.</span>
                  <span className="capitalize">{menu.menuName}</span>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
      <div>
        <img src={fixGraphic} alt="graphic" className="w-full" />
      </div>
    </div>
  );
};

export default DesktopTopMenu;
