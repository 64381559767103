import React, { useState } from "react";

import Pagination from "../../components/Pagination";
import NewVoucherModal from "../../components/customer/voucher/NewVoucherModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopVoucherTable from "../../components/customer/voucher/TabletDesktopVoucherTable";
import MobileVoucherTable from "../../components/customer/voucher/MobileVoucherTable";

import voucherBanner from "../../assets/images/voucherBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const CustomerVoucher = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [isNewVoucherModalOpen, setIsNewVoucherModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("voucher");
    const topNavs = [
        {
            nav: "Customer",
            value: "customer",
            route: "admin-customer",
        },
        { nav: "Promo", value: "promo", route: "admin-customer-promo" },
        { nav: "Voucher", value: "voucher", route: "admin-customer-voucher" },
    ];

    // Fake Data
    const voucherNameOptions = [
        { label: "voucher Name", value: "voucher-name", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const roleOptions = [
        { label: "Role", value: "role-name", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const dateAddedOptions = [
        { label: "Date Added", value: "date-added", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const dateExpireddOptions = [
        { label: "Date Expired", value: "date-expired", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const kuotaOptions = [
        { label: "Kuota", value: "kuota", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const statusOptions = [
        { label: "Status", value: "status", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];

    const voucherData = [
        {
            voucherName: "voucher grand opening",
            role: "konglomerat",
            countOfVoucher: 2,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            voucherName: "voucher valentine day",
            role: "warga",
            countOfVoucher: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] sticky top-0 z-50 bg-white 2xl:w-[200px] flex-none h-[86px]">
                <SideBar isCustomer={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Customer"}
                    menuDescription={
                        "You can manage and organize your customer and other things here"
                    }
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title="Voucher"
                            description="On this menu you will be able to create, edit, and also delete the voucher. Also you can manage it easily."
                            onOpenModal={() => setIsNewVoucherModalOpen(true)}
                            openModalButtonLabel="Add New Voucher"
                            searchPlaceholder={"Search Voucher"}
                            image={voucherBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Voucher"}
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={topNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Voucher Table for Mobile */}
                        <MobileVoucherTable
                            voucherData={voucherData}
                            onOpenModal={setIsNewVoucherModalOpen}
                        />

                        {/* Voucher Table for Tablet & Desktop*/}
                        <TabletDesktopVoucherTable
                            voucherData={voucherData}
                            voucherNameOptions={voucherNameOptions}
                            roleOptions={roleOptions}
                            dateAddedOptions={dateAddedOptions}
                            dateExpireddOptions={dateExpireddOptions}
                            kuotaOptions={kuotaOptions}
                            statusOptions={statusOptions}
                        />
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between bg-neutral-gray">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing 8 Vouchers
                            </p>
                            <Pagination totalPages={38} />
                        </div>
                    </div>
                </main>
            </div>

            {/* Add New Voucher modal */}
            <NewVoucherModal
                isModalOpen={isNewVoucherModalOpen}
                setIsModalOpen={setIsNewVoucherModalOpen}
            />
        </div>
    );
};

export default CustomerVoucher;
