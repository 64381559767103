import React, { useEffect, useRef, useState } from 'react';

import AppTypeInput from './AppTypeInput';
import SectionNavigator from '../../SectionNavigator';
import ChoosingMenuModal from './ChoosingMenuModal';
import DataApiEmptyInfo from '../../DataApiEmptyInfo';
import StatusPriceForm from './StatusPriceForm';
import NormalPriceForm from './NormalPriceForm';
import NewLevelModal from '../../customer/promo/NewLevelModal';

import generalColorful from '../../../assets/icons/generalColorful.svg';
import generalColorless from '../../../assets/icons/generalColorless.svg';
import boxColorless from '../../../assets/icons/boxColorless.svg';
import boxColorful from '../../../assets/icons/boxColorfull.svg';
import maskColorles from '../../../assets/icons/maskColorless.svg';
import maskColorful from '../../../assets/icons/maskColorful.svg';
import close from '../../../assets/icons/close.svg';
import archiveTick from '../../../assets/icons/archiveTick.svg';
import userAdd from '../../../assets/icons/userAdd.svg';
import gojekLogo from '../../../assets/icons/gojekLogo.svg';
import grabLogo from '../../../assets/icons/grabLogo.svg';

const PriceModal = ({
  isPriceModalOpen,
  setIsPriceModalOpen,
  menus,
  allCustomerLevels,
  isCustomerLevelUpdate,
  setIsCustomerLevelUpdate,
}) => {
  // === Component State ===
  const modalContainterRef = useRef(null);
  const [activeNav, setActiveNav] = useState('Normal Price');
  const menuNavs = [
    { title: 'Normal Price', menu: 'Normal' },
    { title: 'Status Price', menu: 'Status' },
    { title: 'App Price', menu: 'App' },
  ];
  const [isChoosingMenuModalOpen, setIsChoosingMenuModalOpen] = useState(false);
  const [isNewLevelModalOpen, setIsNewLevelModalOpen] = useState(false);

  // === normal price state ===
  const [selectedMenus, setSelectedMenus] = useState([]);

  // ===  status price state ===
  const [selectedMenusStatusPrice, setSelectedMenusStatusPrice] = useState([]);
  const [
    currentSelectedMenuNavStatusPrice,
    setCurrentSelectedMenuNavStatusPrice,
  ] = useState([]);
  const [statusPriceData, setStatusPriceData] = useState([]);

  //  === app price state ===
  const appData = [
    {
      name: 'gojek',
      image: gojekLogo,
      discountType: 'Percentage',
      discountValue: 0,
    },
    {
      name: 'grab',
      image: grabLogo,
      discountType: 'Percentage',
      discountValue: 0,
    },
  ];
  const [appTypePriceData, setAppTypePriceData] = useState(appData);

  // === Normal Price Method ===

  // Handle Normal Price Input,
  // To change normal price and price with tax in each menu. price with tax is normal price added with tax of 11% of normal price
  const handleNormalPriceChange = (menuIndex, event) => {
    const { value } = event.target;
    setSelectedMenus((prevMenus) => {
      const newMenus = [...prevMenus];
      newMenus[menuIndex]['price'] = parseInt(value);
      newMenus[menuIndex]['priceWithTax'] =
        parseInt(value) + parseInt(value) * 0.11;
      return newMenus;
    });
  };

  // Handle remove a menu from selected menus
  const handleRemoveMenu = (menuIndex) => {
    setSelectedMenus((prevMenus) => {
      const newMenus = [...prevMenus];
      newMenus.splice(menuIndex, 1);
      return newMenus;
    });
  };

  // Handle submit normal price
  const handleSubmitNormalPrice = async () => {
    // Add menus to statu price list
    const addedStatusMenu = selectedMenus.map((menu) => {
      return { ...menu, statusPrice: [] };
    });
    setSelectedMenusStatusPrice(addedStatusMenu);

    setCurrentSelectedMenuNavStatusPrice(addedStatusMenu[0]);
    // Navigate to status price section
    setActiveNav('Status Price');
    modalContainterRef.current.scrollTop = 0;

    // // console.log(selectedMenus);
  };

  // === Status Price and App Price  Method ===

  // Handle submit status price
  const handleSubmitStatusPrice = async () => {
    // // console.log('normal price submit');
    // console.log(statusPriceData);
    // setActiveNav('App Price');
    // modalContainterRef.current.scrollTop = 0;
  };

  // Handle generate status price data and app price data based on customer levels
  useEffect(() => {
    const handleGenerateStatusPriceData = () => {
      const generatedStatusPriceData = allCustomerLevels?.map(
        (customerLevel) => ({
          customerLevelId: customerLevel?.id,
          customerLevelName: customerLevel?.customerLevelName,
          discountType: 'Percentage',
          discountValue: 0,
        })
      );
      setStatusPriceData(generatedStatusPriceData);
    };

    handleGenerateStatusPriceData();
  }, [allCustomerLevels]);

  const handleSubmitAppPrice = async () => {
    // console.log(appTypePriceData);
  };

  // Handle change discount type
  const handleDiscountTypeChange = (index, value, data, setData) => {
    const updatedData = [...data];
    updatedData[index].discountType = value;

    setData(updatedData);
  };

  // Handle change discount value
  const handleDiscountValueChange = (index, value, data, setData) => {
    const updatedData = [...data];
    updatedData[index].discountValue = value;
    setData(updatedData);
  };

  // Handle decrement discount value
  const handleDecrementDiscountValue = (
    index,
    discountType,
    discountValue,
    data,
    setData
  ) => {
    const updatedData = [...data];
    if (discountType === 'Percentage' && discountValue >= 5) {
      updatedData[index].discountValue = discountValue - 5;
    } else if (discountType === 'Constant' && discountValue >= 1000) {
      updatedData[index].discountValue = discountValue - 1000;
    }
    setData(updatedData);
  };

  // Handle increment discount value
  const handleIncrementDiscountValue = (
    index,
    discountType,
    discountValue,
    data,
    setData
  ) => {
    const updatedData = [...data];
    if (discountType === 'Percentage') {
      updatedData[index].discountValue = discountValue + 5;
    } else {
      updatedData[index].discountValue = discountValue + 1000;
    }
    setData(updatedData);
  };

  // Handle reset discount value to 0
  const handleResetDiscountValue = (index, data, setData) => {
    const updatedData = [...data];
    updatedData[index].discountValue = 0;
    setData(updatedData);
  };

  return (
    <>
      <div
        className={`fixed inset-0 top-0 right-0 z-50 flex justify-end bg-clinder/60 ${
          !isPriceModalOpen && 'hidden'
        }`}
        onClick={() => setIsPriceModalOpen(false)}
      />
      <div
        className={`bg-white w-full md:w-[600px] lg:w-[800px] flex overflow-y-auto  min-h-screen h-auto fixed top-0 right-0 z-50  duration-300 transition-all ${
          isPriceModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* side navigation */}
        <div className="pt-6 lg:pt-9 border-r-[1px] sticky top-0 border-gray-x/40 w-[30%] lg:w-[26%] hidden md:block">
          <ul>
            <li>
              <button
                type="button"
                className={`duration-300 transition hover:bg-dew flex items-center pl-6 lg:pl-9  gap-3 py-4 w-full group ${
                  activeNav === 'Normal Price' ? 'bg-dew' : 'bg-white'
                }`}
                onClick={() => {
                  setActiveNav('Normal Price');
                  modalContainterRef.current.scrollTop = 0;
                }}
              >
                <img
                  src={
                    activeNav === 'Normal Price'
                      ? generalColorful
                      : generalColorless
                  }
                  alt="normal price"
                />
                <span
                  className={`${
                    activeNav === 'Normal Price'
                      ? 'text-aurora-blue font-bold'
                      : 'text-manatee font-semibold'
                  } text-base  font-quicksand group-hover:text-[#01639d] duration-300 transition group-hover:font-bold`}
                >
                  Normal Price
                </span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`flex duration-300 transition hover:bg-dew items-center w-full gap-3 py-4 pl-6 lg:pl-9 group ${
                  activeNav === 'Status Price' ? 'bg-dew' : 'bg-white'
                }`}
                onClick={() => {
                  setActiveNav('Status Price');
                  modalContainterRef.current.scrollTop = 0;
                }}
              >
                <img
                  src={
                    activeNav === 'Status Price' ? maskColorful : maskColorles
                  }
                  alt="status price"
                />
                <span
                  className={`${
                    activeNav === 'Status Price'
                      ? 'text-aurora-blue font-bold'
                      : 'text-manatee font-semibold'
                  } text-base font-quicksand group-hover:text-[#01639d] duration-300 transition group-hover:font-bold`}
                >
                  Status Price
                </span>
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`py-4 duration-300 transition hover:bg-dew flex items-center pl-6 lg:pl-9 w-full gap-3 group ${
                  activeNav === 'App Price' ? 'bg-dew' : 'bg-white'
                }`}
                onClick={() => {
                  setActiveNav('App Price');
                  modalContainterRef.current.scrollTop = 0;
                }}
              >
                <img
                  src={activeNav === 'App Price' ? boxColorful : boxColorless}
                  alt="box"
                />
                <span
                  className={`${
                    activeNav === 'App Price'
                      ? 'text-aurora-blue font-bold'
                      : 'text-manatee font-semibold'
                  } text-base font-quicksand group-hover:text-[#01639d] duration-300 transition group-hover:font-bold`}
                >
                  App Price
                </span>
              </button>
            </li>
          </ul>
        </div>

        {/* main content */}
        <div
          ref={modalContainterRef}
          className="flex-1 w-full md:w-[70%] lg:w-[74%] px-6 lg:px-9 flex flex-col h-screen overflow-y-auto"
        >
          <div className="border-b-[1px] border-gray-x/40  bg-white w-full md:w-auto fixed top-0 pt-6 lg:pt-9 z-20 -ml-6 md:ml-0 md:mr-6 lg:mr-9 pb-6 pl-4 md:pl-0">
            <div className="flex flex-row-reverse justify-end w-full md:flex-row md:justify-between">
              <h2 className="ml-4 text-xl font-semibold md:ml-0 md:text-2xl md:font-bold font-quicksand text-aurora-blue">
                <span className="inline md:hidden">Update New Price</span>
                <span className="hidden md:inline">{activeNav}</span>
              </h2>
              <button type="button" onClick={() => setIsPriceModalOpen(false)}>
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="mt-2 text-sm font-medium font-satoshi text-manatee leading-[200%] w-[85%] hidden md:block">
              in this window, you can quickly change the price in a menu and
              also view the price details of that menu.
            </p>
          </div>

          {/* Section Navigator for Mobile */}
          <h3 className="text-sm block md:hidden mt-[102px] font-semibold font-quicksand">
            Price Category
          </h3>
          <div className="-mx-6 px-6 fixed top-[77px] z-40 bg-white w-full">
            <SectionNavigator
              modalContainerRef={modalContainterRef}
              menuNavs={menuNavs}
              currentNav={activeNav}
              setCurrentNav={setActiveNav}
            />
          </div>

          {/* normal price form */}
          {activeNav === 'Normal Price' && (
            <form className="flex flex-col justify-between flex-1 mt-12 md:mt-[194px]">
              <div>
                <div className="p-3 mb-4 rounded-lg bg-aurora-blue">
                  <span className="block mb-2 text-sm font-semibold text-white font-quicksand">
                    Menu Name
                  </span>
                  <button
                    type="button"
                    className="bg-white/20 font-quicksand text-white w-full py-[10px] px-4 rounded-lg text-sm font-semibold"
                    onClick={() => setIsChoosingMenuModalOpen(true)}
                  >
                    Add Menu
                  </button>
                </div>

                {selectedMenus.length > 0 ? (
                  <>
                    {selectedMenus.map((menu, menuIndex) => {
                      return (
                        <NormalPriceForm
                          key={menuIndex}
                          menuIndex={menuIndex}
                          menuName={menu?.menuName}
                          normalPrice={menu?.price}
                          priceWithTaxNormalPrice={menu?.priceWithTax}
                          onHandleNormalPriceChange={handleNormalPriceChange}
                          onHandleRemoveMenu={handleRemoveMenu}
                        />
                      );
                    })}
                  </>
                ) : (
                  <DataApiEmptyInfo
                    emptyInfoHeading={'Oops, Nothing Here Yet!'}
                    firstEmptyInfoDescription={
                      'Looks like this space is feeling a bit lonely right now.'
                    }
                    secondEmptyInfoDescription={
                      "Just hit that 'Add Menu' button, and you can start to edit price for the menu!"
                    }
                  />
                )}
              </div>
              <button
                type="button"
                disabled={selectedMenus.length === 0}
                className={`flex justify-center gap-2 items-center w-full py-4 text-base font-bold rounded-lg mb-16 mt-9 xl:mb-9 ${
                  selectedMenus.length === 0
                    ? 'bg-gray-x text-manatee'
                    : 'bg-aurora-blue text-white hover:bg-[#0170a0]'
                } `}
                onClick={handleSubmitNormalPrice}
              >
                <img src={archiveTick} alt="archive tick" />
                <span
                  className={`font-bold font-quicksand ${
                    selectedMenus.length === 0 ? 'text-manatee' : 'text-white'
                  }`}
                >
                  Save Changes
                </span>
              </button>
            </form>
          )}

          {/* status price form*/}
          {activeNav === 'Status Price' && (
            <form className="mt-12 md:mt-48 lg:mt-[177px] pb-6 lg:pb-9 flex-1 flex-col flex justify-between">
              {/* Selected Menu Navigator */}
              <div>
                <button
                  type="button"
                  className="w-full my-4 button-secondary-lg"
                  onClick={() => setIsNewLevelModalOpen(true)}
                >
                  <img src={userAdd} alt="user add" />
                  <span className="text-xs font-bold text-white text-aurora-blue font-quicksand ">
                    Add New Level
                  </span>
                </button>
                {statusPriceData.map((statusPrice, statusPriceIndex) => (
                  <StatusPriceForm
                    key={statusPriceIndex}
                    statusPriceIndex={statusPriceIndex}
                    customerLevelName={statusPrice?.customerLevelName}
                    defaultDiscountType={statusPrice?.discountType}
                    discountValue={statusPrice?.discountValue}
                    statusPriceData={statusPriceData}
                    setStatusPriceData={setStatusPriceData}
                    onHandleDiscountTypeChange={handleDiscountTypeChange}
                    onHandleDiscountValueChange={handleDiscountValueChange}
                    onHandleDecrementDiscountValue={
                      handleDecrementDiscountValue
                    }
                    onHandleIncrementDiscountValue={
                      handleIncrementDiscountValue
                    }
                    onHandleResetDiscountValue={handleResetDiscountValue}
                  />
                ))}
              </div>

              <button
                type="button"
                className="w-full mb-10 mt-9 xl:mb-0 button-primary-lg"
                onClick={() => {
                  // window.scrollTo(0, 0);
                  // setIsPriceModalOpen(false);
                  // setActiveNav('Normal Price');
                  handleSubmitStatusPrice();
                }}
              >
                <img src={archiveTick} alt="archive tick" />
                <span className="text-base font-bold text-white font-quicksand">
                  Save Changes
                </span>
              </button>
            </form>
          )}

          {/* app price form*/}
          {activeNav === 'App Price' && (
            <form className="flex flex-col justify-between flex-1 pb-6 mt-12 md:mt-48 lg:mt-44 lg:pb-9">
              <div>
                {appTypePriceData.map((appType, appTypePriceIndex) => (
                  <AppTypeInput
                    key={appTypePriceIndex}
                    appTypePriceIndex={appTypePriceIndex}
                    appTypeImage={appType.image}
                    appTypeName={appType.name}
                    defaultDiscountType={appType.discountType}
                    discountValue={appType.discountValue}
                    appTypePriceData={appTypePriceData}
                    setAppTypePriceData={setAppTypePriceData}
                    onHandleDiscountTypeChange={handleDiscountTypeChange}
                    onHandleDiscountValueChange={handleDiscountValueChange}
                    onHandleDecrementDiscountValue={
                      handleDecrementDiscountValue
                    }
                    onHandleIncrementDiscountValue={
                      handleIncrementDiscountValue
                    }
                  />
                ))}
              </div>
              <button
                type="button"
                className="w-full mb-10 button-primary-lg xl:mb-0 mt-9"
                onClick={() => {
                  // window.scrollTo(0, 0);
                  // setIsPriceModalOpen(false);
                  // setActiveNav('Normal Price');
                  handleSubmitAppPrice();
                }}
              >
                <img src={archiveTick} alt="archive tick" />
                <span className="text-base font-bold text-white font-quicksand">
                  Save Changes
                </span>
              </button>
            </form>
          )}
        </div>
      </div>

      {/* Choosing Menu Modal used to select multiple menus*/}
      <ChoosingMenuModal
        isModalOpen={isChoosingMenuModalOpen}
        setIsModalOpen={setIsChoosingMenuModalOpen}
        allMenus={menus}
        selectedMenus={selectedMenus}
        setSelectedMenus={setSelectedMenus}
      />

      {/* New level modal to create new customer level */}
      <NewLevelModal
        isModalOpen={isNewLevelModalOpen}
        setIsModalOpen={setIsNewLevelModalOpen}
        isLevelUpdate={isCustomerLevelUpdate}
        setIsLevelUpdate={setIsCustomerLevelUpdate}
      />
    </>
  );
};

export default PriceModal;
