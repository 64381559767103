import React, { useEffect } from 'react';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import printerBlue from '../../../assets/icons/printerBlue.svg';
import documentDownload from '../../../assets/icons/document-download.svg';
import useGetDetailCustomerTransaction from '../../../hooks/customer/useGetDetailCustomerTransaction';
import formatDefaultDate from '../../../utils/dateFormate';

const CustomerDetailTransactionSection = ({
	setActiveSideNav,
	customerCredentialId,
	selectedTransactionId,
	setSelectedTransactionId,
	setDetailCustomerTransaction
}) => {
	const token = localStorage.getItem('token');
	const tenantId = localStorage.getItem('tenantId');

	/* HOOKS */
	const { loading, detailCustomerTransactionData } =
		useGetDetailCustomerTransaction(
			token,
			tenantId,
			customerCredentialId,
			selectedTransactionId
		);

	useEffect(() => {
		setDetailCustomerTransaction(detailCustomerTransactionData);
	}, [detailCustomerTransactionData, setDetailCustomerTransaction]);

	return (
		<div className='flex flex-col justify-between h-full pb-20 md:pb-0'>
			<div className='mt-[158px] md:mt-[70px]'>
				{/* Detail Transaction Header (displayed on mobile) */}
				{loading ? (
					<div className='h-40 w-full flex justify-center items-center'>
						<span className='text-xl text-black-coal animate-pulse'>
							Loading...
						</span>
					</div>
				) : !detailCustomerTransactionData ? (
					<div className='h-40 w-full flex justify-center items-center'>
						<span className='text-xl text-black-coal '>
							Transaction not found
						</span>
					</div>
				) : (
					detailCustomerTransactionData && (
						<>
							<header className='pb-4 border-b-[1px] border-gray-x/40 pt-6 block md:hidden'>
								<div className='flex items-center'>
									<button
										type='button'
										className='pr-2'
										onClick={() => {
											setSelectedTransactionId(0); //reset selectedTransactionId
											setActiveSideNav('Transactions');
										}}>
										<HiOutlineChevronLeft className='text-2xl text-manatee' />
									</button>
									<h2 className='text-base font-bold font-quicksand text-clinder'>
										Details Transaction
									</h2>
								</div>
								<div className='flex items-center justify-between mt-6'>
									<p className='text-xs font-normal text-end font-satoshi text-manatee'>
										Pesanan {detailCustomerTransactionData.invoiceNumber}
									</p>
									<p className='text-xs font-normal text-manatee font-satoshi'>
										{formatDefaultDate(detailCustomerTransactionData.createdAt)}
									</p>
								</div>
							</header>

							<div className='flex items-center gap-3 mt-2 md:mt-0 px-0 md:px-2 py-0 md:py-5 border-b-0 md:border-b-[1px] border-gray-x/40'>
								<img
									src={`https://avatar.iran.liara.run/public/boy?username=${detailCustomerTransactionData.accountData.owner}`}
									alt='profile'
									className='rounded-full w-9 h-9'
								/>
								<div>
									<h4 className='text-sm font-bold text-clinder'>
										{detailCustomerTransactionData.accountData.owner}
									</h4>
									<span className='text-xs text-manatee font-satoshi'>
										Owner | {detailCustomerTransactionData.accountData.phone}
									</span>
								</div>
							</div>

							<div className='mt-4'>
								<h2 className='text-xs font-semibold font-quicksand text-manatee'>
									Pesanan
								</h2>
								<div className='flex flex-col gap-5 mt-3'>
									{detailCustomerTransactionData?.menuCart?.map(
										(product, index) => {
											// sum quantity of the product
											const totalQtyPerProduct = product.menuItem.reduce(
												(total, item) => total + item.itemqty,
												0
											);

											return (
												<div key={index}>
													<div className='flex justify-between gap-4'>
														<h4 className='text-xs font-bold capitalize text-clinder font-quicksand w-[67%] sm:w-auto'>
															{product.name}
														</h4>
														<p className='flex gap-6 w-[33%] sm:w-auto'>
															<span className='text-xs font-semibold font-quicksand text-manatee'>
																x{totalQtyPerProduct}
															</span>
															<span className='text-xs font-bold text-clinder'>
																IDR {product.price}
															</span>
														</p>
													</div>

													{product.menuItem?.length > 0 &&
														product.menuItem.map((menuItem, index) => {
															return (
																<React.Fragment key={index}>
																	{menuItem.varian?.length > 0 && (
																		<div
																			key={index}
																			className='h-full px-1 mt-1 flex gap-2 items-center'>
																			<span className='text-xs font-semibold font-quicksand text-manatee'>
																				x{menuItem.itemqty}
																			</span>

																			<div className='py-1 pl-3 pr-1 border-l-[1px] border-gray-x mt-1'>
																				{menuItem.varian.map(
																					(variant, index) => {
																						return (
																							<p
																								key={index}
																								className='text-xs font-normal font-satoshi text-clinder leading-[200%]'>
																								{variant.varianName} :{' '}
																								{variant.Toppings?.length > 0 &&
																									variant.Toppings.map(
																										(topping, index) => {
																											return (
																												<span
																													key={index}
																													className='font-medium capitalize'>
																													{topping.toppingName}
																													{variant.Toppings
																														.length -
																														1 !==
																														index && ', '}
																												</span>
																											);
																										}
																									)}
																							</p>
																						);
																					}
																				)}
																			</div>
																		</div>
																	)}
																</React.Fragment>
															);
														})}
												</div>
											);
										}
									)}
								</div>
							</div>
							<div className='py-5 mt-5 border-t-[1px] border-gray-x/40 '>
								<h2 className='text-xs font-semibold text-manatee font-quicksand'>
									Detail Pembayaran
								</h2>
								<div className='mt-3'>
									<p className='flex justify-between gap-4 pb-3 border-b-[1px] border-gray-x/40 mt-5'>
										<span className='text-xs font-bold font-quicksand'>
											Total
										</span>
										<span className='text-xs font-bold font-quicksand text-orange'>
											IDR {detailCustomerTransactionData?.subtotal}
										</span>
									</p>

									<p className='flex justify-between gap-4 pb-3 border-b-[1px] border-gray-x/40 mt-5'>
										<span className='text-xs font-bold font-quicksand'>
											Bayar Pakai
										</span>
										<span className='text-xs font-bold font-quicksand text-orange'>
											{detailCustomerTransactionData.paymentMethod}
										</span>
									</p>
								</div>
							</div>
						</>
					)
				)}
			</div>
			<div className='flex gap-[10px] bg-white mt-9 mb-6 lg:mb-9'>
				<button
					type='button'
					className='flex flex-1 border-[1px] border-grad-bluetty rounded-lg py-4 justify-center items-center gap-3 w-[259px]'>
					<img src={printerBlue} alt='print' />
					<span className='hidden text-base font-bold sm:inline text-aurora-blue font-quicksand'>
						Print Receipt
					</span>
					<span className='inline text-base font-bold sm:hidden text-aurora-blue font-quicksand'>
						Print
					</span>
				</button>
				<button
					type='button'
					className='flex items-center justify-center flex-1 gap-3 py-4 rounded-lg bg-aurora-blue w-[259px]'>
					<img src={documentDownload} alt='download' />
					<span className='hidden text-base font-bold text-white sm:inline font-quicksand'>
						Download Receipt
					</span>
					<span className='inline text-base font-bold text-white sm:hidden font-quicksand'>
						Download
					</span>
				</button>
			</div>
		</div>
	);
};

export default CustomerDetailTransactionSection;
