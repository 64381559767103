import React, { useState } from 'react';

import close from '../../../assets/icons/close.svg';
import FilterOptionButton from '../../FilterOptionButton';

const PromoFilterModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsOptionFilterActive,
  isOptionFilterToggleActive,
  setIsOptionFilterToggleActive,
  customerLevels,
  selectedStatusFilter,
  setSelectedStatusFilter,
  selectedCustomerLevelFilter,
  setSelectedCusomerLevelFilter,
  promos,
  setOptionFilteredPromo,
}) => {
  const statusFilterOptions = [
    { name: 'Active', value: true },
    { name: 'Inactive', value: false },
  ];

  const customerLevelOptions = customerLevels?.map((customerLevel) => {
    return {
      name: customerLevel?.customerLevelName,
      value: customerLevel?.id,
    };
  });

  // Handling filtered menus
  const handleFilterPromos = (promos, statusFilter, customerLevelFilter) => {
    const filtered = promos.filter((promo) => {
      if (statusFilter !== null && promo?.status !== statusFilter) {
        return false;
      }
      if (
        customerLevelFilter !== null &&
        promo?.promoLevels?.every((item) => item?.id !== customerLevelFilter)
      ) {
        return false;
      }
      return true;
    });
    if (statusFilter !== null || customerLevelFilter !== null) {
      setIsOptionFilterActive(true);
    }
    setOptionFilteredPromo(filtered);
    setIsOptionFilterToggleActive(!isOptionFilterToggleActive);
  };
  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              Filter by
            </h2>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setIsModalOpen(false);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[90%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this window you can add filters for what data you want to display
            in the table.
          </p>
        </div>
        <div className="flex flex-col justify-between flex-1 pt-6">
          <div>
            {/* Status filter options */}
            <FilterOptionButton
              filterOPtions={statusFilterOptions}
              filterLabel={'Status'}
              selectedFilter={selectedStatusFilter}
              setSelectedFilter={setSelectedStatusFilter}
            />
            {/* Category filter options */}
            {customerLevels.length > 0 && (
              <FilterOptionButton
                filterOPtions={customerLevelOptions}
                filterLabel={'Levels'}
                selectedFilter={selectedCustomerLevelFilter}
                setSelectedFilter={setSelectedCusomerLevelFilter}
              />
            )}
          </div>
          <div className="flex items-center gap-4 font-bold mt-9 font-quicksand ">
            <button
              type="button"
              className="w-full text-aurora-blue"
              onClick={() => {
                setSelectedStatusFilter(null);
                setSelectedCusomerLevelFilter(null);
                setOptionFilteredPromo([]);
                setIsOptionFilterActive(false);
                setIsOptionFilterToggleActive(!isOptionFilterToggleActive);
              }}
            >
              Clear
            </button>
            <button
              type="button"
              className="w-full text-white button-primary-lg xl:mb-0"
              onClick={() => {
                handleFilterPromos(
                  promos,
                  selectedStatusFilter,
                  selectedCustomerLevelFilter
                );
                setIsModalOpen(false);
              }}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoFilterModal;
