const formatNumberWithCommas = inputValue => {
	if (!inputValue) {
		return '';
	}

	const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''));
	const formattedValue = numericValue.toLocaleString('id-ID'); // Ganti 'id-ID' dengan kode lokal yang sesuai
	return formattedValue;
};

export default formatNumberWithCommas;
