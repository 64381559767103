import React, { useState } from 'react';
import axiosInstance from '../../../utils/api/apiConfig';

import trush from '../../../assets/icons/trashRed.svg';
import edit from '../../../assets/icons/edit.svg';

const EditBaseProductInput = ({
  baseProduct,
  onGetSelectedBaseProducts,
  productDetailId,
}) => {
  const [isEditBaseProduct, setIsEditBaseProduct] = useState(false);
  const [baseProductQuantity, setBaseProductQuantity] = useState(
    baseProduct.qty
  );
  const [baseProductMultiple, setBaseProductMultiple] = useState(
    baseProduct.multiple
  );

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const creator = localStorage.getItem('userFullName');

  // Handle Delete Base Product From Menu
  const handleDeleteBaseProductFromMenu = async (productDetailId) => {
    try {
      await axiosInstance.delete(`/product-details/${productDetailId}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      onGetSelectedBaseProducts();
      // setIsLoadingDeleteMenu(false);
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle Patch Quantity ofBase Product From Menu
  const handleAdjustQuantityAndMultiple = async (productDetailId) => {
    try {
      await axiosInstance.patch(
        `/product-details/${productDetailId}`,
        {
          qty: baseProductQuantity,
          multiple: 1,
          // multiple: baseProductMultiple
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditBaseProduct(false);
      onGetSelectedBaseProducts();
      // setIsLoadingDeleteMenu(false);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <tr className="py-3 mt-3">
      <td className="p-2 pt-4 text-sm font-semibold capitalize font-quicksand text-clinder">
        {baseProduct.baseProductName}{' '}
        <span className="lowercase text-aurora-blue">
          ({baseProduct.unitSystem})
        </span>
      </td>
      <td className="px-[6px]">
        <input
          value={baseProductQuantity === 0 ? '' : baseProductQuantity}
          disabled={isEditBaseProduct === false ? true : false}
          required
          type="number"
          placeholder={baseProductQuantity === 0 ? 0 : 'Add quantity'}
          className={`w-full h-[36px] valid:bg-dew px-4 text-sm font-medium transition duration-300 border rounded-lg bg-neutral-gray border-neutral-gray font-quicksand placeholder:text-gray-x hover:outline-black focus:border-grad-bluetty hover:border-gray-x focus:outline-none hover:bg-white focus:bg-white ${
            baseProductQuantity === 0 || baseProductQuantity === '0'
              ? 'text-gray-x'
              : 'text-clinder'
          }`}
          onChange={(e) => setBaseProductQuantity(e.target.value)}
        />
      </td>
      {/* <td className="px-[6px]">
        <input
          value={baseProductMultiple}
          disabled={isEditBaseProduct === false ? true : false}
          required
          type="number"
          placeholder="Add multiple"
          className={`w-full h-[36px] valid:bg-dew px-4 text-sm font-medium transition duration-300 border rounded-lg bg-neutral-gray border-neutral-gray font-quicksand placeholder:text-gray-x hover:outline-black focus:border-grad-bluetty hover:border-gray-x focus:outline-none hover:bg-white focus:bg-white ${
            baseProductMultiple === 0 || baseProductMultiple === '0'
              ? 'text-gray-x'
              : 'text-clinder'
          }`}
          onChange={(e) => setBaseProductMultiple(e.target.value)}
        />
      </td> */}
      <td className="px-[6px]">
        {isEditBaseProduct ? (
          <button
            type="button"
            disabled={
              baseProductQuantity === 0 ||
              baseProductMultiple === 0 ||
              baseProductQuantity === '0' ||
              baseProductMultiple === '0' ||
              baseProductQuantity === '' ||
              baseProductMultiple === ''
                ? true
                : false
            }
            className={`w-full px-6 py-[7px] rounded-lg 
                  ${
                    baseProductQuantity === 0 ||
                    baseProductMultiple === 0 ||
                    baseProductQuantity === '0' ||
                    baseProductMultiple === '0' ||
                    baseProductQuantity === '' ||
                    baseProductMultiple === ''
                      ? 'bg-gray-x/60'
                      : 'bg-aurora-blue'
                  }`}
            onClick={() => handleAdjustQuantityAndMultiple(productDetailId)}
          >
            <span
              className={`text-xs font-bold text-center font-quicksand ${
                baseProductQuantity === 0 ||
                baseProductMultiple === 0 ||
                baseProductQuantity === '0' ||
                baseProductMultiple === '0' ||
                baseProductQuantity === '' ||
                baseProductMultiple === ''
                  ? 'text-manatee'
                  : 'text-white'
              }`}
            >
              Save
            </span>
          </button>
        ) : (
          <div className="flex justify-end gap-3 text-sm font-semibold font-quicksand text-manatee">
            <button
              type="button"
              className="w-full px-2 py-3 rounded bg-coral"
              onClick={() => setIsEditBaseProduct(true)}
            >
              <img src={edit} alt="edit" className="w-3 h-3 mx-auto" />
            </button>
            <button
              type="button"
              className="w-full px-2 py-3 rounded bg-coral"
              onClick={() => handleDeleteBaseProductFromMenu(productDetailId)}
            >
              <img src={trush} alt="delete" className="w-3 h-3 mx-auto" />
            </button>
          </div>
        )}
      </td>
    </tr>
  );
};

export default EditBaseProductInput;
