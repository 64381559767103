import React from 'react';
import searchStatus from '../assets/icons/searchStatus.svg';

const DataFilterEmptyInfo = ({ emptyInfoHeading, emptyInfoDescription }) => {
  return (
    <div>
      <img src={searchStatus} alt="search" className="mx-auto" />
      <h3 className="my-4 text-2xl font-bold text-center font-quicksand">
        {emptyInfoHeading}
      </h3>
      <p className="text-sm font-medium text-center text-manatee font-quicksand">
        {emptyInfoDescription}
      </p>
    </div>
  );
};

export default DataFilterEmptyInfo;
