import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/api/apiConfig";

// import AnalyticsInfo from '../../components/customer/customer/AnaliticsInfo';
import Pagination from "../../components/Pagination";
import AddNewCustomerModal from "../../components/customer/customer/AddNewCustomerModal";
import EditCustomerModal from "../../components/customer/customer/EditCustomerModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopCustomerTable from "../../components/customer/customer/TabletDesktopCustomerTable";
import DesktopTopMenu from "../../components/customer/customer/DesktopTopMenu";
import MobileTabletTopMenu from "../../components/customer/customer/MobileTabletTopMenu";
import MobileCustomerTable from "../../components/customer/customer/MobileCustomerTable";

import customerBanner from "../../assets/images/customerBanner.png";
import DetailCustomerModal from "../../components/customer/customer/DetailCustomer";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const Customer = () => {
    const [isNewCustomerModalOpen, setIsNewCustomerModalOpen] = useState(false);
    const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("customer");
    const [customers, setCustomers] = useState([]);
    const [isLoadingGetAllCustomers, setIsLoadingGetAllCustomers] = useState(false);
    const [customerCredentialId, setCustomerCredentialId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [isLoadingDeleteCustomer, setIsLoadingDeleteCustomer] = useState(false);
    const [isDetailCustomerModalOpen, setIsDetailCustomerModalOpen] = useState(false);

    const topNavs = [
        {
            nav: "Customer",
            value: "customer",
            route: "admin-customer",
        },
        { nav: "Promo", value: "promo", route: "admin-customer-promo" },
        // { nav: 'Voucher', value: 'voucher', route: 'admin-customer-voucher' },
    ];

    // fake data
    const topCustomer = [
        { menuName: "Slamet Riyadi" },
        { menuName: "Agus Salim" },
        { menuName: "M.T. Haryono" },
    ];
    const customersData = [
        {
            customerName: "Odis Rhinehart",
            level: "Warga",
            favoriteMenu: "Chicken & Ribs Combo",
            totalTransaction: "IDR 194,700",
        },
        {
            customerName: "Kris Roher",
            level: "Warga",
            favoriteMenu: "Surf & Turf Gift Basket",
            totalTransaction: "IDR 631,200",
        },
        {
            customerName: "Serenity Fisher",
            level: "Juragan",
            favoriteMenu: "Fried Chicken Dinne",
            totalTransaction: "IDR 1,040.920",
        },
        {
            customerName: "Brooklyn Warren",
            level: "Sultan",
            favoriteMenu: "Surf & Turf Gift Basket",
            totalTransaction: "IDR 730,500",
        },
        {
            customerName: "Franco Delort",
            level: "Juragan",
            favoriteMenu: "Chicken & Ribs Combo",
            totalTransaction: "IDR 96,000",
        },
        {
            customerName: "Saul Geoghegan",
            level: "Juragan",
            favoriteMenu: "Surf & Turf Gift Basket",
            totalTransaction: "IDR 256,000",
        },
        {
            customerName: "Alfredo Vetrovs",
            level: "Juragan",
            favoriteMenu: "Dark & Stormy",
            totalTransaction: "IDR 590,080",
        },
        {
            customerName: "Cristofer Vetrovs",
            level: "Konglomerat",
            favoriteMenu: "Shaking Beef Tri-Tip",
            totalTransaction: "IDR 782,600",
        },
        {
            customerName: "Calvin Steward",
            level: "Konglomerat",
            favoriteMenu: "BBQ Rib Dinner",
            totalTransaction: "IDR 467,500",
        },
        {
            customerName: "John Rambo",
            level: "Konglomerat",
            favoriteMenu: "BBQ Rib Dinner",
            totalTransaction: "IDR 467,500",
        },
        {
            customerName: "John Rambo",
            level: "Konglomerat",
            favoriteMenu: "BBQ Rib Dinner",
            totalTransaction: "IDR 467,500",
        },
        {
            customerName: "John Rambo",
            level: "Konglomerat",
            favoriteMenu: "BBQ Rib Dinner",
            totalTransaction: "IDR 467,500",
        },
        {
            customerName: "John Rambo",
            level: "Konglomerat",
            favoriteMenu: "BBQ Rib Dinner",
            totalTransaction: "IDR 467,500",
        },
        {
            customerName: "John Rambo",
            level: "Konglomerat",
            favoriteMenu: "BBQ Rib Dinner",
            totalTransaction: "IDR 467,500",
        },
    ];

    const nameSortOptions = [
        { label: "Customer Name", value: "customer name", disabled: true },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];

    const levelSortOption = [
        { label: "Level", value: "level", disabled: true },
        { label: "Warga", value: "warga" },
        { label: "Juragan", value: "juragan" },
        { label: "Sultan", value: "sultan" },
        { label: "Kolongmerat", value: "kolongmerat" },
    ];

    const favoriteMenuSortOption = [
        { label: "Number Phone", value: "favorite menu", disabled: true },
        { label: "Chicken & Ribs Combo", value: "chicken & ribs combo" },
        { label: "Fried Chicken Dinne", value: "Fried Chicken Dinne" },
        { label: "Surf & Turf Gift Basket", value: "surf & turf gift basket" },
        { label: "Dark & Stormy", value: "dark & stormy" },
        { label: "Shaking Beef Tri-Tip", value: "shaking beef tri-tip" },
        { label: "BBQ Rib Dinner", value: "bbq rib dinner" },
    ];

    const totalTransactionSortOption = [
        { label: "Join Date", value: "Total Transaction", disabled: true },
        { label: "Less than 100,000", value: "<100,000" },
        { label: "100,000 - 200,000", value: "100,000-200,000" },
        { label: "200,000 - 300,000", value: "200,000-300,000" },
        { label: "300,000 - 400,000", value: "300,000-400,000" },
        { label: "400,000 - 500,000", value: "400,000-500,000" },
        { label: "More than 500,000", value: ">500,000" },
    ];

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("userId");

    // ==== Handle Get All customer ====
    useEffect(() => {
        getAllCustomers();
    }, []);

    const getAllCustomers = async () => {
        setIsLoadingGetAllCustomers(true);
        try {
            const response = await axiosInstance.get(`/customer-credentials`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setCustomers(response.data.data);
            setIsLoadingGetAllCustomers(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingGetAllCustomers(false);
        }
    };

    // Handle Delete customer
    const deleteCustomer = async (customerCredentialId) => {
        setIsLoadingDeleteCustomer(true);
        try {
            await axiosInstance.delete(`/customer-credentials/${customerCredentialId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllCustomers();
            setIsLoadingDeleteCustomer(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingDeleteCustomer(false);
        }
    };

    // Handle search filter
    const searchFilter = (data) => {
        return data.filter((item) =>
            item?.customer?.fullName.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="2xl:w-[200px] flex-none sticky top-0 bg-white z-50 w-full h-[86px] md:w-[88px]">
                <SideBar isCustomer={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full min-h-full ml-0 md:ml-6 md:mr-4 md:w-auto">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Customer"}
                    menuDescription={
                        "You can manage and organize your customer and other things here"
                    }
                    topNavs={topNavs}
                />
                <main className="flex flex-1 w-full gap-4">
                    {/* Left Section */}
                    <div className="flex flex-col w-[77.52%] flex-1 justify-between">
                        <div>
                            <Banner
                                title="Customer"
                                description="On this menu you will be able to create, edit, and also delete the Additional Notes. Also you can manage it easily."
                                onOpenModal={() => setIsNewCustomerModalOpen(true)}
                                openModalButtonLabel="Add New Customer"
                                searchPlaceholder={"Search Customer"}
                                showPrintButtonLabel={false}
                                image={customerBanner}
                                setSearchQuery={setSearchQuery}
                            />

                            {/* Navbar for Mobile & Tablet */}
                            <TopNavbar
                                topNavs={topNavs}
                                activeTopNav={activeTopNav}
                                setActiveTopNav={setActiveTopNav}
                            />

                            {/* Top Menu for Mobile & Tablet */}
                            <MobileTabletTopMenu topCustomer={topCustomer} />

                            {/* Customer Table for Mobile & Tablet */}
                            <MobileCustomerTable
                                customerData={customers}
                                setIsNewCustomerModalOpen={setIsNewCustomerModalOpen}
                            />

                            {/* Customer Table for Tablet &  Desktop  */}
                            <TabletDesktopCustomerTable
                                // customersData={searchFilter(customers)}
                                customersData={customers}
                                nameSortOptions={nameSortOptions}
                                levelSortOption={levelSortOption}
                                favoriteMenuSortOption={favoriteMenuSortOption}
                                totalTransactionSortOption={totalTransactionSortOption}
                                setIsEditCustomerModalOpen={setIsEditCustomerModalOpen}
                                setIsDetailCustomerModalOpen={setIsDetailCustomerModalOpen}
                                customerCredentialId={customerCredentialId}
                                setCustomerCredentialId={setCustomerCredentialId}
                                onDeleteCustomer={deleteCustomer}
                            />
                        </div>

                        {/* pagination */}
                        <div className="static bottom-0 pb-6 bg-white md:sticky">
                            <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between bg-neutral-gray">
                                <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                    Showing 10 Data Customer
                                </p>
                                <Pagination totalPages={38} />
                            </div>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="w-[251px] hidden xl:flex flex-col gap-4 mt-4">
                        {/* <AnalyticsInfo /> */}
                        <DesktopTopMenu topCustomer={topCustomer} />
                    </div>
                </main>
            </div>

            {/* Add New Customer Modal */}
            <AddNewCustomerModal
                isModalOpen={isNewCustomerModalOpen}
                setIsModalOpen={setIsNewCustomerModalOpen}
                onGetAllCustomers={getAllCustomers}
            />

            {/* Edit Customer Modal */}
            {isEditCustomerModalOpen && (
                <EditCustomerModal
                    isModalOpen={EditCustomerModal}
                    setIsModalOpen={setIsEditCustomerModalOpen}
                    customerCredentialId={customerCredentialId}
                    customerPersonalId={customerCredentialId}
                    onGetAllCustomers={getAllCustomers}
                />
            )}

            {/* Detail Customer Modal */}
            {isDetailCustomerModalOpen && (
                <DetailCustomerModal
                    isModalOpen={isDetailCustomerModalOpen}
                    setIsModalOpen={setIsDetailCustomerModalOpen}
                    customerCredentialId={customerCredentialId}
                    customerPersonalId={customerCredentialId}
                />
            )}
        </div>
    );
};

export default Customer;
