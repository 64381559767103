import React from "react";
import SideBar from "../components/Sidebar";
import orderSizeIcon from "../assets/icons/order-size-icon.svg";
import happyBagIcon from "../assets/icons/bag-happy.svg";
import shoppingBagIcon from "../assets/icons/shopping-bag.svg";
import sendMoneyIcon from "../assets/icons/money-send-icon.svg";
import paperClipIcon from "../assets/icons/paperclip-2.svg";
import PaymentMethodBadge from "../components/molecules/badge/PaymentMethodBadge";
import useGetOrderStatistic from "../hooks/dashboard/useGetOrderStatistic";
import formatNumberWithCommas from "../utils/formatNumber";
import useGetLatestTransaction from "../hooks/transactions/useGetLatestTransaction";
import { Link } from "react-router-dom";
import formatDefaultDate, { formatDateToHour } from "../utils/dateFormate";
import NeedLoginAlert from "../components/alert/NeedLoginAlert";

const Dashboard = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    /* HOOKS */
    const { loading: orderLoading, orderStatsData } = useGetOrderStatistic(token, tenantId);
    const {
        tokenHasExpired,
        loading: transactionLoading,
        latestTransactionData,
    } = useGetLatestTransaction(token, tenantId, 5);

    if (!token || !tenantId) return <NeedLoginAlert />;

    // // console.log("🚀 ~ Dashboard ~ latestTransactionData:", latestTransactionData);
    return (
        <>
            {token && tenantId && (
                <div className="flex flex-col min-h-screen md:flex-row">
                    <aside className="md:w-[88px] 2xl:w-[200px] sticky top-0 z-50 w-full flex-none bg-white h-[86px]">
                        <SideBar isDashboard={true} />
                    </aside>
                    <div className="flex flex-col w-full min-h-full px-6 md:px-0 ml-0 mr-0 md:ml-6 md:mr-4">
                        <header className="pb-4 pt-6 h-auto hidden md:flex flex-col md:flex-row items-end justify-between xl:h-28 border-b-[1px] border-gray-lighter sticky top-0 z-50 bg-white">
                            <div className="flex flex-col justify-center w-full h-full md:w-auto">
                                <h2 className="mt-0 md:mt-1 font-quicksand text-2xl text-black leading-[36px] font-bold">
                                    Dashboard
                                </h2>
                                <p className="mt-0 text-sm md:mt-1 font-satoshi text-manatee">
                                    Manage important feature or shortcut here
                                </p>
                            </div>
                        </header>

                        <main className="w-full h-auto flex flex-col gap-6 borde border-blue-600 my-5">
                            <div className="w-full h-auto flex flex-col gap-6 borde border-rose-600">
                                <div className="w-full h-auto flex justify-between">
                                    <span className="font-bold text-base md:text-2xl text-[#98949E] font-quicksand capitalize">
                                        Order Review Statistics
                                    </span>

                                    <span className="font-semibold text-base md:text-lg text-[#98949E] font-quicksand capitalize">
                                        {`today, ${formatDefaultDate(new Date())}`}
                                    </span>
                                </div>

                                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 borde border-yellow-600">
                                    <div className="h-auto flex flex-col gap-7 bg-[#FAFAFA] rounded-lg px-4 py-3">
                                        <div className="w-fit bg-white p-3 rounded-xl">
                                            <img
                                                width={20}
                                                height={20}
                                                src={orderSizeIcon}
                                                alt=""
                                            />
                                        </div>

                                        <div className="w-full flex flex-col">
                                            <span className="font-semibold text-sm text-[#98949E] font-quicksand capitalize">
                                                Average Order Size
                                            </span>

                                            <div className="w-full flex gap-2 items-center">
                                                <span className="font-bold text-2xl text-[#D1D0D3] font-quicksand capitalize">
                                                    IDR
                                                </span>
                                                <span className="font-bold text-[32px] text-[#F46B45] font-quicksand capitalize">
                                                    {!orderLoading && orderStatsData
                                                        ? formatNumberWithCommas(
                                                              orderStatsData.averageOrderSize.toLocaleString()
                                                          )
                                                        : "-"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-auto flex flex-col gap-7 bg-[#FAFAFA] rounded-lg px-4 py-3">
                                        <div className="w-fit bg-white p-3 rounded-xl">
                                            <img width={20} height={20} src={happyBagIcon} alt="" />
                                        </div>

                                        <div className="w-full flex flex-col">
                                            <span className="font-semibold text-sm text-[#98949E] font-quicksand capitalize">
                                                Sale of Items
                                            </span>

                                            <div className="w-full flex gap-2 items-center">
                                                <span className="font-bold text-[32px] text-[#43A6EF] font-quicksand capitalize">
                                                    {!orderLoading && orderStatsData
                                                        ? orderStatsData.averageOrderItems
                                                        : "-"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-auto flex flex-col gap-7 bg-[#FAFAFA] rounded-lg px-4 py-3">
                                        <div className="w-fit bg-white p-3 rounded-xl">
                                            <img
                                                width={20}
                                                height={20}
                                                src={shoppingBagIcon}
                                                alt="bag-icon"
                                            />
                                        </div>

                                        <div className="w-full flex flex-col">
                                            <span className="font-semibold text-sm text-[#98949E] font-quicksand capitalize">
                                                Best Selling Items
                                            </span>

                                            <div className="w-full flex gap-2 items-center">
                                                <span className="font-bold text-[32px] text-[#24C286] font-quicksand capitalize">
                                                    {!orderLoading && orderStatsData
                                                        ? orderStatsData.bestSellingItem.name
                                                        : "-"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="h-auto flex flex-col gap-7 bg-[#FAFAFA] rounded-lg px-4 py-3">
                                        <div className="w-fit bg-white p-3 rounded-xl">
                                            <img
                                                width={20}
                                                height={20}
                                                src={sendMoneyIcon}
                                                alt="money-icon"
                                            />
                                        </div>

                                        <div className="w-full flex flex-col">
                                            <span className="font-semibold text-sm text-[#98949E] font-quicksand capitalize">
                                                Total Sales
                                            </span>

                                            <div className="w-full flex gap-2 items-center">
                                                <span className="font-bold text-2xl text-[#D1D0D3] font-quicksand capitalize">
                                                    IDR
                                                </span>
                                                <span className="font-bold text-[32px] text-[#AE00FF] font-quicksand capitalize">
                                                    {!orderLoading && orderStatsData
                                                        ? formatNumberWithCommas(
                                                              orderStatsData.totalSales.toLocaleString()
                                                          )
                                                        : "-"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full h-auto grid grid-cols-1 lg:grid-cols-2 gap-6 borde border-yellow-600">
                                {/* ALL TRANSACTION */}
                                <div className="h-auto flex flex-col gap-5 borde border-rose-600">
                                    <div className="w-full h-fit py-4 px-4 bg-[#FAFAFA] rounded-lg">
                                        <span className="font-bold text-xl text-[#110D17] font-quicksand capitalize">
                                            all transaction
                                        </span>
                                    </div>

                                    <div className="w-full h-auto flex flex-col gap-5 p-6 border border-[#D1D0D3] border-opacity-40 rounded-lg">
                                        {transactionLoading ? (
                                            <div className="h-40 w-full flex justify-center items-center">
                                                <span className="text-xl text-black-coal animate-pulse">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : !latestTransactionData ||
                                          latestTransactionData?.length === 0 ? (
                                            <div className="h-40 w-full flex justify-center items-center">
                                                <span className="text-xl text-[#8f8b95]">
                                                    There are no transaction found
                                                </span>
                                            </div>
                                        ) : (
                                            latestTransactionData && (
                                                <>
                                                    {latestTransactionData.map(
                                                        (transaction, index) => (
                                                            <div
                                                                key={index}
                                                                className="w-full h-24 flex gap-2 border-b-2"
                                                            >
                                                                <div className="flex-1 flex flex-col gap-2">
                                                                    <h1 className="font-bold font-quicksand text-lg text-[#110D17]">
                                                                        The transaction was
                                                                        successful
                                                                    </h1>

                                                                    <div className="w-full h-auto flex gap-4 items-center">
                                                                        <span className="font-medium font-quicksand text-base text-[#98949E] capitalize">
                                                                            Payment Method :
                                                                        </span>

                                                                        <PaymentMethodBadge
                                                                            paymentMethod={
                                                                                transaction.paymentMethod
                                                                            }
                                                                        />
                                                                    </div>

                                                                    <div className="w-full h-auto flex gap-2">
                                                                        <div className="w-fit pr-4 border-r-[1px] flex items-center">
                                                                            <span className="font-medium font-quicksand text-xs text-[#98949E] capitalize">
                                                                                {formatDateToHour(
                                                                                    transaction.createdAt
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div className="w-fit pr-4 border-r-[1px] flex items-center">
                                                                            <span className="font-medium font-quicksand text-xs text-[#98949E] capitalize">
                                                                                {
                                                                                    transaction.invoiceNumber
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="w-fit  flex items-center">
                                                                            <span className="font-medium font-quicksand text-xs text-[#98949E] capitalize">
                                                                                {
                                                                                    transaction
                                                                                        .accountData
                                                                                        .owner
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="w-fit h-full flex items-center justify-center">
                                                                    <button className="w-fit h-fit p-4 bg-[#FAFAFA] rounded">
                                                                        <img
                                                                            width={20}
                                                                            height={20}
                                                                            src={paperClipIcon}
                                                                            alt="icon"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </>
                                            )
                                        )}

                                        {!transactionLoading &&
                                            latestTransactionData?.length > 0 && (
                                                <div className="w-full flex items-center justify-center">
                                                    <Link
                                                        to={"/admin-report-transaction"}
                                                        className="cursor-pointer"
                                                    >
                                                        See Other Transactions
                                                    </Link>
                                                </div>
                                            )}
                                    </div>
                                </div>

                                {/* STOCK OVERVIEW */}
                                <div className="h-fit pb-3 flex flex-col bg-[#FFFAF5] rounded-lg">
                                    <div className="w-full h-fit py-4 px-4">
                                        <span className="font-bold text-xl text-[#994900] font-quicksand capitalize">
                                            Top{" "}
                                            {`${
                                                orderStatsData?.topThreeBestProductData?.length ||
                                                ""
                                            }`}{" "}
                                            best selling menu
                                        </span>
                                    </div>

                                    <div
                                        className={`w-full ${
                                            orderStatsData?.topThreeBestProductData?.length
                                                ? "h-fit"
                                                : "h-40"
                                        } flex flex-col gap-2`}
                                    >
                                        {orderLoading ? (
                                            <div className="h-40 w-full flex justify-center items-center">
                                                <span className="text-xl text-black-coal animate-pulse">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : !orderStatsData ? (
                                            <div className="h-40 w-full flex justify-center items-center">
                                                <span className="text-xl text-[#8f8b95]">
                                                    There are no transaction found
                                                </span>
                                            </div>
                                        ) : (
                                            orderStatsData?.topThreeBestProductData.length > 0 &&
                                            orderStatsData.topThreeBestProductData.map(
                                                (bestSellingItem, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="w-full h-28 flex gap-4 py-4 px-6 border-b-2 border-[#D1D0D3] border-opacity-40"
                                                        >
                                                            <img
                                                                width={80}
                                                                height={80}
                                                                src={bestSellingItem.url}
                                                                alt="product-image"
                                                                className="rounded-full"
                                                            />

                                                            <div className="flex-1 h-full flex flex-col gap-2 justify-center">
                                                                <span className="font-bold text-2xl text-[#110D17] font-quicksand capitalize">
                                                                    {bestSellingItem.name}
                                                                </span>
                                                                <span className="font-semibold text-sm text-[#98949E] font-quicksand capitalize">
                                                                    {`${bestSellingItem.productServings} Servings`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
