import React, { useState } from "react";

import DoubleIndicatorDropdown from "../../../components/DoubleIndicatorDropdown";

import restock from "../../../assets/icons/restock.svg";
import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import DeleteConfirmationModal from "../../DeleteConfirmationModal";
import DataFilterEmptyInfo from "../../DataFilterEmptyInfo";
import DataApiEmptyInfo from "../../DataApiEmptyInfo";

const TabletDesktopBaseProductTable = ({
    baseProductNameOptions,
    stockOnTheInventoryOptions,
    popularMenuCreatedOptions,
    productSortOptions,
    displayedBaseProducts,
    allBaseProducts,
    onDeleteBaseProduct,
    setIsDetailBaseProductModalOpen,
    setIsRestockModalOpen,
    setBaseProductId,
    setBaseProductCurrentStock,
    setBaseProductUnitSystem,
    setBaseProductTotalStock,
}) => {
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [baseProductIdToDelete, setBaseProductIdToDelete] = useState();

    return (
        <div className="hidden w-full md:block">
            <table className="w-full mt-3 border-collapse table-auto">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th className="w-[31.77%] xl:w-[21.22%] text-start" id="stock-inventory">
                            <DoubleIndicatorDropdown options={baseProductNameOptions} />
                        </th>
                        <th className="w-[31.77%] xl:w-[21.22%] text-start" id="creator">
                            <DoubleIndicatorDropdown options={stockOnTheInventoryOptions} />
                        </th>
                        <th className="hidden w-full text-start xl:block" id="popular-menu">
                            <DoubleIndicatorDropdown options={popularMenuCreatedOptions} />
                        </th>
                        <th className="w-[23.73%] xl:w-[15.10%] text-start" id="product">
                            <DoubleIndicatorDropdown options={productSortOptions} />
                        </th>
                        <th className="w-[12.66%] xl:w-[21.22%] text-start" id="action">
                            <p className="p-[10px] text-sm font-medium hover:bg-gray-50 text-manatee font-quicksand">
                                Action
                            </p>
                        </th>
                    </tr>
                </thead>
                {/* If base product data is not empty */}
                {displayedBaseProducts?.length > 0 && (
                    <tbody>
                        {displayedBaseProducts?.map((baseProduct, index) => {
                            return (
                                <tr key={index}>
                                    <td
                                        className={`text-sm font-semibold leading-5 capitalize font-quicksand text-clinder px-[10px] ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="category-name"
                                    >
                                        {baseProduct?.baseProductName}
                                    </td>
                                    <td
                                        aria-labelledby="stock-inventory"
                                        className={`pl-[10px] ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                    >
                                        <div className="flex flex-wrap items-center gap-[3px] 3xl:gap-3">
                                            <span className="text-sm font-semibold leading-5 font-quicksand text-clinder">
                                                {baseProduct?.stockOnTheInventory
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                                &nbsp;
                                                {baseProduct?.unitSystem === "kilogram"
                                                    ? "Kg"
                                                    : baseProduct?.unitSystem === "gram"
                                                    ? "g"
                                                    : "Pcs"}
                                            </span>
                                            {baseProduct?.stockOnTheInventory <
                                                baseProduct?.requiredStock && (
                                                <span className="px-2 py-1 ml-[4px] text-sm font-semibold rounded font-quicksand text-golden-sun bg-beige">
                                                    Need Restock
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        className={`px-[10px] hidden xl:table-cell py-[14px] text-sm font-semibold leading-5 font-quicksand min-h-full ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="popular-menu"
                                    >
                                        {baseProduct?.popularMenuCreated?.length > 0 ? (
                                            <span className="capitalize text-clinder">
                                                {baseProduct?.popularMenuCreated}
                                            </span>
                                        ) : (
                                            <span className="text-manatee">
                                                No popular menu yet
                                            </span>
                                        )}
                                    </td>
                                    <td
                                        className={`px-[10px] py-[14px] leading-5 text-sm font-semibold font-quicksand ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="product"
                                    >
                                        {baseProduct?.totalMenu} Menu
                                    </td>
                                    <td
                                        className={`py-[14px] ${
                                            index === 0 ? "pt-8 pb-[14px]" : "py-[14px]"
                                        }`}
                                        aria-labelledby="action"
                                    >
                                        <div className="flex items-center justify-center gap-x-1">
                                            <button
                                                type="button"
                                                className="block px-3 mx-auto xl:hidden"
                                            >
                                                <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                                <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                                                <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                            </button>
                                            <button
                                                type="button"
                                                className="items-center justify-center flex-1 hidden px-6 py-1 duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200 xl:flex"
                                                onClick={() => {
                                                    setIsRestockModalOpen(true);
                                                    setBaseProductId(baseProduct?.baseProductId);
                                                    setBaseProductCurrentStock(
                                                        baseProduct?.stockOnTheInventory
                                                    );
                                                    setBaseProductUnitSystem(
                                                        baseProduct?.unitSystem
                                                    );
                                                    setBaseProductTotalStock(
                                                        baseProduct?.stockOnTheInventory
                                                    );
                                                }}
                                            >
                                                <img src={restock} alt="restock" />
                                                <span className="ml-2 text-sm font-semibold leading-5 font-quicksand text-clinder">
                                                    Restock
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className="items-center justify-center flex-1 hidden gap-2 px-3 py-1 duration-300 transform rounded bg-neutral-gray hover:bg-gray-200 xl:flex"
                                                onClick={() => {
                                                    setBaseProductId(baseProduct?.baseProductId);
                                                    setIsDetailBaseProductModalOpen(true);
                                                }}
                                            >
                                                <img
                                                    src={shieldSearch}
                                                    alt="shield search"
                                                    className="w-3 h-3"
                                                />
                                                <span className="text-sm font-semibold font-quicksand text-clinder">
                                                    Detail
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className="hidden xl:flex px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200"
                                                onClick={() => {
                                                    setBaseProductIdToDelete(
                                                        baseProduct?.baseProductId
                                                    );
                                                    setIsDeleteConfirmationModalOpen(true);
                                                }}
                                            >
                                                <img
                                                    src={trashRed}
                                                    alt="trash red"
                                                    className="w-3 h-3"
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}

                {/* If no base product data match the search filter criteria  */}
                {/* {allBaseProducts?.length !== 0 &&
          displayedBaseProducts?.length === 0 && (
            <tbody>
              <td colSpan="6" className="pt-4">
                <DataFilterEmptyInfo
                  emptyInfoHeading={'Sorry, No Results Found'}
                  emptyInfoDescription={
                    "Well, we've searched high and low, but it seems like what your're looking for isn't our database (yet!)"
                  }
                />
              </td>
            </tbody>
          )} */}

                {/* If base product data on data base is empty */}
                {allBaseProducts?.length === 0 && displayedBaseProducts?.length === 0 && (
                    <tbody>
                        <td colSpan="6" className="pt-4">
                            <DataApiEmptyInfo
                                emptyInfoHeading={"Oops, Nothing here yet!"}
                                firstEmptyInfoDescription={
                                    "Looks like this space is feeling a bit lonely right now. Why not kick things off by adding some info?"
                                }
                                secondEmptyInfoDescription={
                                    "Just hit that 'Add New Base' button, and let the good times (and data) roll!"
                                }
                            />
                        </td>
                    </tbody>
                )}
            </table>

            {/* Delete Confirmation  */}
            <DeleteConfirmationModal
                isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
                setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
                shortDeleteConfirmatonMessage={"Delete this base product?"}
                longDeleteConfirmatonMessage={"Are you sure you want to delete this base product?"}
                longDeleteButtonLabel={"Yes, Delete this base product"}
                onDelete={onDeleteBaseProduct}
                id={baseProductIdToDelete}
            />
        </div>
    );
};

export default TabletDesktopBaseProductTable;
