import React, { useState } from "react";
import search from "../../../assets/icons/search.svg";
import searchBlue from "../../../assets/icons/searchBlue.svg";
import reserveGoldenRod from "../../../assets/icons/reserveGoldenRod.svg";
import useGetAllCustomerTransactionWithFilterAndSearch from "../../../hooks/customer/useGetAllCustomerTransactionWithFilterAndSearch";
import formatDefaultDate from "../../../utils/dateFormate";
import SingleIndicatorDropdown from "../../SingleIndicatorDropdown";

const CustomerTransactionSection = ({
    setActiveSideNav,
    customerCredentialId,
    setSelectedTransactionId,
}) => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const [selectedFilter, setSelectedFilter] = useState("monthly");

    /* HOOKS */
    const { loading, allCustomerTransactionData, setSearchInvoiceNumber } =
        useGetAllCustomerTransactionWithFilterAndSearch(
            token,
            tenantId,
            customerCredentialId,
            selectedFilter
        );

    const handleOnChange = (e) => {
        setSearchInvoiceNumber(e.target.value);
    };

    return (
        <div className="mt-0 md:mt-32">
            <div className="flex gap-3 fixed md:static top-[157px] -mx-6 md:mx-0 w-full px-6 md:px-0 bg-white py-2 md:py-0 border-b-[1px] md:border-b-0 border-gray-x/40">
                <div className="flex items-center justify-between flex-1 py-2 pl-0 pr-0 bg-white rounded-lg md:pr-1 md:pl-4 md:bg-neutral-gray">
                    <div className="flex items-center w-full">
                        <img src={search} alt="search" className="hidden md:block" />
                        <img src={searchBlue} alt="search" className="block md:hidden" />
                        <input
                            type="text"
                            placeholder="Search Customer"
                            onChange={handleOnChange}
                            className="w-full px-2 py-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
                        />
                    </div>
                    <button
                        type="button"
                        className="hidden p-2 text-sm font-bold text-white rounded-lg md:block sm:px-4 bg-aurora-blue hover:bg-blue-700 font-quicksand"
                    >
                        Search
                    </button>
                </div>
                <div className="w-[168px]">
                    <SingleIndicatorDropdown
                        height={"h-12"}
                        iconSize={"w-5 h-5"}
                        radius={"rounded"}
                        options={["Weekly", "Monthly", "Yearly"]}
                        setSelected={(option) => setSelectedFilter(option)}
                        selected={selectedFilter}
                    />
                </div>
            </div>

            <div className="flex flex-col gap-2 mt-[230px] md:mt-6 pb-20 md:pb-0">
                {loading ? (
                    <div className="h-20 w-full flex justify-center items-center">
                        <span className="text-xl text-black-coal animate-pulse">Loading...</span>
                    </div>
                ) : allCustomerTransactionData?.length === 0 ? (
                    <div className="h-20 w-full flex justify-center items-center">
                        <span className="text-xl text-black-coal ">
                            There is no transaction not found
                        </span>
                    </div>
                ) : (
                    allCustomerTransactionData?.length > 0 &&
                    allCustomerTransactionData.map((transaction, index) => (
                        <div
                            key={index}
                            className="flex gap-4 border-b-[1px] border-gray-x/40 pb-3 pt-2 cursor-pointer"
                            onClick={() => {
                                setSelectedTransactionId(transaction.id);
                                setActiveSideNav("detail-transaction");
                            }}
                        >
                            <figure className="p-4 rounded-lg h-14 bg-creamy-white">
                                <img src={reserveGoldenRod} alt="menu" />
                            </figure>
                            <div className="flex flex-col justify-between flex-1 md:flex-row">
                                <div className="w-[217px]">
                                    <h3 className="w-full text-sm font-bold capitalize truncate font-quicksand leading-[150%]">
                                        {transaction.menuCart[0]?.name}
                                    </h3>
                                    <p className="w-48 text-xs  font-medium capitalize truncate font-satoshi text-manatee leading-[200%]">
                                        {transaction.menuCart.map((product) => product.name + ", ")}
                                    </p>
                                    <span className="hidden px-2 py-1 mt-3 text-xs font-semibold rounded bg-neutral-gray text-manatee font-quicksand md:inline">
                                        Total {transaction.totalqty} Items
                                    </span>
                                </div>
                                <div>
                                    <h3 className="text-sm font-bold font-quicksand text-clinder text-start md:text-end leading-[150%]">
                                        {"IDR. " + transaction.subtotal}
                                    </h3>
                                    <span className="text-end text-manatee font-medium leading-[200%] text-xs">
                                        {formatDefaultDate(transaction.createdAt)}
                                    </span>
                                    <span className="flex-none block w-24 px-2 py-1 mt-3 text-xs font-semibold rounded bg-neutral-gray text-manatee font-quicksand md:hidden">
                                        Total {transaction.totalqty} Items
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default CustomerTransactionSection;
