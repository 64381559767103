import React, { useEffect, useState } from "react";

import axiosInstance from "../../utils/api/apiConfig";

import Pagination from "../../components/Pagination";
import ChooseMenuTypeModal from "../../components/stock/menu/ChooseMenuTypeModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopMenuTable from "../../components/stock/menu/TabletDesktopMenuTable";
import MobileMenuTable from "../../components/stock/menu/MobileMenuTable";
import MenuFilterModal from "../../components/stock/menu/MenuFilterModal";
import NewSingleMenuModal from "../../components/stock/menu/NewSingleMenuModal";
import NewPackageMenuModal from "../../components/stock/menu/NewPackageMenuModal";
import DetailSingleMenuModal from "../../components/stock/menu/DetailSingleMenuModal";

import menuBanner from "../../assets/images/menuBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const StockMenu = () => {
    // === Menu State ===
    const [menus, setMenus] = useState([]);
    const [menuId, setMenuId] = useState(null);
    const [displayedMenus, setDisplayedMenus] = useState(menus);
    const [categories, setCategories] = useState([]);

    const [productTypes, setProductTypes] = useState([]);
    const [isProductTypesUpdate, setIsProductTypesUpdate] = useState(false);
    const [isNewSingleMenuModalOpen, setIsNewSingleMenuModalOpen] = useState(false);
    const [isDetailSingleMenuOpen, setIsDetailSingleMenuOpen] = useState(false);
    const [isNewPackageMenuModalOpen, setIsNewPackageMenuModalOpen] = useState(false);
    const [isChooseMenuTypeModalOpen, setIsChooseMenuTypeModalOpen] = useState(false);
    const [isMenuFilterModalOpen, setIsMenuFilterModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("menu");
    const topNavs = [
        { nav: "Category", value: "category", route: "admin-stock-category" },
        {
            nav: "Base Product",
            value: "base-product",
            route: "admin-stock-base-product",
        },
        { nav: "Menu", value: "menu", route: "admin-stock-menu" },
        { nav: "Price", value: "price", route: "admin-stock-price" },
        {
            nav: "Favorite Menu",
            value: "favorite-menu",
            route: "admin-stock-favorite-menu",
        },
        {
            nav: "Additional Notes",
            value: "additional-notes",
            route: "admin-stock-additional-notes",
        },
    ];

    // === State Condition ===
    const [isLoadingGetAllMenus, setIsLoadingGetAllMenus] = useState(false);
    const [isLoadingDeleteMenu, setIsLoadingDeleteMenu] = useState(false);

    // === Filtering State ===
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [isRefreshActive, setIsRefreshActive] = useState(false);
    const [isOptionFilterActive, setIsOptionFilterActive] = useState(false);
    const [isOptionFilterToggleActive, setIsOptionFilterToggleActive] = useState(false);

    // === Pagination State ===
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const creator = localStorage.getItem("userFullName");

    // Fake data
    const stockMenuFakeData = [
        {
            menuName: "Prime Rib Brioche",
            baseProduct: 67,
            category: "dinner",
            stock: 194,
            price: 40000,
        },
        {
            menuName: "Build-Your-Own",
            baseProduct: 23,
            category: "lunch",
            stock: 218,
            price: 47000,
        },
        {
            menuName: "featured",
            baseProduct: 46,
            category: "dinner",
            stock: 293,
            price: 18000,
        },
        {
            menuName: "turkey galantine",
            baseProduct: 78,
            category: "dinner",
            stock: 235,
            price: 25000,
        },
        {
            menuName: "G&G roast turkey wrap",
            baseProduct: 24,
            category: "dessert",
            stock: 246,
            price: 18000,
        },
        {
            menuName: "kinds grilled cheese",
            baseProduct: 32,
            category: "drinks",
            stock: 218,
            price: 32000,
        },
        {
            menuName: "pesto chicken",
            baseProduct: 12,
            category: "dinner",
            stock: 162,
            price: 20000,
        },
    ];

    // Handle Display Menus
    useEffect(() => {
        const handleDisplayMenus = () => {
            if (
                searchQuery !== "" &&
                selectedStatusFilter === null &&
                selectedCategoryFilter === null
            ) {
                const searchedMenus = menus?.filter((item) =>
                    item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setDisplayedMenus(searchedMenus?.slice(startIndex, endIndex));
                setTotalPages(Math?.ceil(searchedMenus.length / itemsPerPage));
                setTotalData(searchedMenus?.length);
            } else if (selectedStatusFilter !== null || selectedCategoryFilter !== null) {
                if (searchQuery !== "") {
                    const optionalFilteredMenusSearch = filteredMenus?.filter((menu) =>
                        menu?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
                    );
                    setDisplayedMenus(optionalFilteredMenusSearch?.slice(startIndex, endIndex));
                    setTotalPages(Math.ceil(optionalFilteredMenusSearch?.length / itemsPerPage));
                    setTotalData(optionalFilteredMenusSearch?.length);
                } else {
                    setDisplayedMenus(filteredMenus?.slice(startIndex, endIndex));
                    setTotalPages(Math.ceil(filteredMenus?.length / itemsPerPage));
                    setTotalData(filteredMenus?.length);
                }
            } else {
                setDisplayedMenus(menus?.slice(startIndex, endIndex));
                setTotalPages(Math.ceil(menus?.length / itemsPerPage));
                setTotalData(menus?.length);
            }
        };

        handleDisplayMenus();
    }, [menus, currentPage, searchQuery, isOptionFilterToggleActive]);

    // Handle active refresh
    useEffect(() => {
        if (searchQuery !== "" || isOptionFilterActive) {
            setIsRefreshActive(true);
        } else {
            setIsRefreshActive(false);
        }
    }, [searchQuery, isOptionFilterToggleActive]);

    // Handle delete filter
    const handleDeleteFilter = () => {
        setSearchQuery("");
        setSelectedStatusFilter(null);
        setSelectedCategoryFilter(null);
        setIsOptionFilterActive(false);
        setIsOptionFilterToggleActive(!isOptionFilterToggleActive);
    };

    // option data on table header
    const menuNameOptions = [
        {
            label: "Menu Name",
            value: "Menu Name",
            disabled: true,
        },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];
    const baseProductOptions = [
        {
            label: "Base Product",
            value: "Base Product",
            disabled: true,
        },
        { label: "Less than 50", value: "<50" },
        { label: "50 - 75", value: "50-75" },
        { label: "More than 100", value: ">100" },
    ];
    const categoryOptions = [
        {
            label: "Category",
            value: "Category",
            disabled: true,
        },
        { label: "Dinner", value: "Dinner" },
        { label: "Lunch", value: "Lunch" },
        { label: "Dessert", value: "Dessert" },
        { label: "Drinks", value: "Drinks" },
    ];
    const stockOptions = [
        {
            label: "Stock",
            value: "Stock",
            disabled: true,
        },
        { label: "Less than 50", value: "<50" },
        { label: "50 - 75", value: "50-75" },
        { label: "More than 100", value: ">100" },
    ];
    const priceOptions = [
        {
            label: "Price (No Tax)",
            value: "Price (No Tax)",
            disabled: true,
        },
        { label: "Less than 20.000", value: "<20.000" },
        { label: "21.000 - 50.000", value: "21.000-50.000" },
        { label: "More than 50.000", value: ">50.000" },
    ];

    // ==== Handle Get All Menu ====
    useEffect(() => {
        getAllMenus();
    }, []);
    const getAllMenus = async () => {
        setIsLoadingGetAllMenus(true);
        try {
            const response = await axiosInstance.get(`/products`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setMenus(response.data.data);
            setIsLoadingGetAllMenus(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingGetAllMenus(false);
        }
    };

    // Handle Delete Menu
    const deleteMenu = async (id) => {
        setIsLoadingDeleteMenu(true);
        try {
            await axiosInstance.delete(`/products/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllMenus();
            setIsLoadingDeleteMenu(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingDeleteMenu(false);
        }
    };

    // Handle Get All Categories
    useEffect(() => {
        const getAllCategories = async () => {
            // setIsLoadingGetCategories(true);
            try {
                const response = await axiosInstance.get(`/product-categories`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-TENANT-ID": tenantId,
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCategories(response.data.data.results);
            } catch (error) {
                // console.log(error);
                // setIsLoadingGetCategories(false);
            }
        };

        getAllCategories();
    }, []);

    // Handle Get All Menu Types
    useEffect(() => {
        const getAllProductTypes = async () => {
            try {
                const response = await axiosInstance.get(`/menu-types`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-TENANT-ID": tenantId,
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProductTypes(response.data.data);
            } catch (error) {
                // console.log(error);
            }
        };

        getAllProductTypes();
    }, [isProductTypesUpdate]);

    // Handle post onSale status
    const handlePostOnSaleStatus = async (productId, onSaleSatus) => {
        const bodyRequest = {
            productId: productId,
            onSale: !onSaleSatus,
        };

        try {
            const response = await axiosInstance.post(`/product-on-sales`, bodyRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllMenus();
        } catch (error) {
            // console.log(error);
        }
    };

    // Handle post onSale status
    const handlePutOnSaleStatus = async (onSaleId, onSaleSatus) => {
        const bodyRequest = {
            onSale: !onSaleSatus,
        };
        try {
            const response = await axiosInstance.put(`/product-on-sales/${onSaleId}`, bodyRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllMenus();
        } catch (error) {
            // console.log(error);
        }
    };

    // Handle Change onSale status
    const handleChangeOnSaleStatus = async (productId, onSaleId, onSaleSatus) => {
        if (onSaleId === null) {
            handlePostOnSaleStatus(productId, onSaleSatus);
        } else {
            handlePutOnSaleStatus(onSaleId, onSaleSatus);
        }
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="md:w-[88px] 2xl:w-[200px] flex-none sticky top-0 bg-white z-50 w-full h-[86px]">
                <SideBar isStock={true} />
            </aside>

            <div className="flex flex-col w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Product"}
                    menuDescription={"You can manage and organize your product stock here"}
                    topNavs={topNavs}
                />

                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title="Menu"
                            description="On this menu you will be able to create, edit, and also delete the Menu of the restaurant. Also you can manage it
              easily."
                            // onOpenModal={() => setIsChooseMenuTypeModalOpen(true)}
                            onOpenModal={() => setIsNewSingleMenuModalOpen(true)}
                            onOpenFilterModal={() => setIsMenuFilterModalOpen(true)}
                            openModalButtonLabel="Add New Menu"
                            searchPlaceholder={"Find your menu here"}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            image={menuBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Menus"}
                            isRefreshActive={isRefreshActive}
                            onHandleDeleteFilter={handleDeleteFilter}
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={topNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Stock Menu Table for Mobile */}
                        <MobileMenuTable
                            stockMenuData={stockMenuFakeData}
                            setIsNewSingleMenuModalOpen={setIsNewSingleMenuModalOpen}
                        />

                        {/* Stock Menu Table for Tablet and Desktop */}
                        <TabletDesktopMenuTable
                            stockMenuData={displayedMenus}
                            allMenus={menus}
                            onDeleteMenu={deleteMenu}
                            menuNameOptions={menuNameOptions}
                            baseProductOptions={baseProductOptions}
                            categoryOptions={categoryOptions}
                            stockOptions={stockOptions}
                            priceOptions={priceOptions}
                            onHandleChangeOnSaleStatus={handleChangeOnSaleStatus}
                            selectedStatusFilter={selectedStatusFilter}
                            selectedCategoryFilter={selectedCategoryFilter}
                            searchQuery={searchQuery}
                            setIsMenuId={setMenuId}
                            isDetailSingleMenuOpen={isDetailSingleMenuOpen}
                            setIsDetailSingleMenuOpen={setIsDetailSingleMenuOpen}
                        />
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing{" "}
                                <span className="text-clinder">{displayedMenus.length}</span> out of{" "}
                                <span className="text-clinder">{totalData}</span> Menus
                            </p>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isPageNumberColorGray={true}
                            />
                        </div>
                    </div>
                    {/* Choose Menu Modal */}
                    <ChooseMenuTypeModal
                        isModalOpen={isChooseMenuTypeModalOpen}
                        setIsModalOpen={setIsChooseMenuTypeModalOpen}
                        setIsNewSingleMenuModalOpen={setIsNewSingleMenuModalOpen}
                        setIsNewPackageMenuModalOpen={setIsNewPackageMenuModalOpen}
                    />

                    {/* New Single Menu Modal */}
                    <NewSingleMenuModal
                        isModalOpen={isNewSingleMenuModalOpen}
                        setIsModalOpen={setIsNewSingleMenuModalOpen}
                        categories={categories}
                        onGetAllMenus={getAllMenus}
                        // *-------- Hide menu type (for the meantime) -----------*/
                        // productTypes={productTypes}
                        // isProductTypeUpdate={isProductTypesUpdate}
                        // setIsProductTypeUpdate={setIsProductTypesUpdate}
                    />

                    {/* New Package Menu Modal */}
                    <NewPackageMenuModal
                        isModalOpen={isNewPackageMenuModalOpen}
                        setIsModalOpen={setIsNewPackageMenuModalOpen}
                        categories={categories}
                        menus={menus}
                        productTypes={productTypes}
                        isProductTypeUpdate={isProductTypesUpdate}
                        setIsProductTypeUpdate={setIsProductTypesUpdate}
                        onGetAllMenus={getAllMenus}
                    />

                    {/* Detail Single Menu */}
                    <DetailSingleMenuModal
                        isModalOpen={isDetailSingleMenuOpen}
                        setIsModalOpen={setIsDetailSingleMenuOpen}
                        categories={categories}
                        onGetAllMenus={getAllMenus}
                        menuId={menuId}
                        // *-------- Hide menu type (for the meantime) -----------*/
                        // productTypes={productTypes}
                        // isProductTypeUpdate={isProductTypesUpdate}
                        // setIsProductTypeUpdate={setIsProductTypesUpdate}
                    />
                </main>
            </div>

            <MenuFilterModal
                isModalOpen={isMenuFilterModalOpen}
                setIsModalOpen={setIsMenuFilterModalOpen}
                categories={categories}
                selectedStatusFilter={selectedStatusFilter}
                setSelectedStatusFilter={setSelectedStatusFilter}
                selectedCategoryFilter={selectedCategoryFilter}
                setSelectedCategoryFilter={setSelectedCategoryFilter}
                menus={menus}
                filteredMenus={filteredMenus}
                setFilteredMenus={setFilteredMenus}
                isOptionFilterToggleActive={isOptionFilterToggleActive}
                setIsOptionFilterToggleActive={setIsOptionFilterToggleActive}
                setIsOptionFilterActive={setIsOptionFilterActive}
            />
        </div>
    );
};

export default StockMenu;
