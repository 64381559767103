import React, { useEffect, useState } from "react";
import { AiTwotoneDelete } from "react-icons/ai";

import Banner from "../../components/Banner";
import SideBar from "../../components/Sidebar";
import NewTableDesign from "../../components/table/NewTableDesign";

import tableBanner from "../../assets/images/tableBanner.png";
import axiosInstance from "../../utils/api/apiConfig";
import DataApiEmptyInfo from "../../components/DataApiEmptyInfo";
import EditTableDesignModal from "../../components/table/EditTableDesignModal";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const Table = () => {
    const [isNewTableModalOpen, setIsNewTableModalOpen] = useState(false);
    const [isEditTableModalOpen, setIsEditTableModalOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);
    const [currentTableData, setCurrentTableData] = useState([]);
    const [currentSectionId, setCurrentSectionId] = useState(null);
    const [sections, setSections] = useState([]);
    const [currentSectionData, setCurrentSectionData] = useState({});

    // Data from Local Storage
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    useEffect(() => {
        getAllTableSections();
    }, []);

    // Fetch all table sections
    const getAllTableSections = async () => {
        try {
            const response = await axiosInstance.get("/table-positions", {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            const sectionsData = response.data.data || [];
            setSections(sectionsData);
            if (sectionsData.length > 0) {
                const firstSection = sectionsData[0];
                setCurrentTableData(firstSection.tableDatas || []);
                setCurrentSectionId(firstSection.id);
                setCurrentSectionData(firstSection);
                setCurrentSection(0);
            } else {
                resetCurrentSectionData();
            }
        } catch (error) {
            // console.error("Error fetching table sections:", error);
        }
    };

    // Delete a section
    const deleteTableSection = async (id) => {
        try {
            await axiosInstance.delete(`/table-positions/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
        } catch (error) {
            // console.error("Error deleting table section:", error);
        } finally {
            getAllTableSections();
        }
    };

    // Handle section change
    const handleSectionChange = (sectionIndex) => {
        const section = sections[sectionIndex];
        setCurrentSection(sectionIndex);
        setCurrentTableData(section?.tableDatas || []);
        setCurrentSectionId(section?.id || null);
        setCurrentSectionData(section || {});
    };

    // Reset current section data
    const resetCurrentSectionData = () => {
        setCurrentTableData([]);
        setCurrentSectionId(null);
        setCurrentSection(0);
        setCurrentSectionData({});
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="md:w-[88px] 2xl:w-[200px] flex-none bg-white z-50 w-full h-[86px] sticky top-0">
                <SideBar isTable={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full ml-0 mr-0 overflow-x-hidden md:ml-6 md:mr-4">
                <header className="pb-4 pt-6 h-auto hidden md:flex flex-col md:flex-row items-end justify-between xl:h-28 border-b-[1px] border-gray-lighter sticky top-0 z-50 bg-white">
                    <div className="flex flex-col justify-center w-full h-full md:w-auto">
                        <h2 className="mt-0 md:mt-1 font-quicksand text-2xl text-black leading-[36px] font-bold">
                            Table
                        </h2>
                        <p className="mt-0 text-sm md:mt-1 font-satoshi text-manatee">
                            On this menu you will be able to create, edit, and also delete the table
                            design. Also you can manage it easily.
                        </p>
                    </div>
                </header>
                <main className="flex flex-col flex-1">
                    <Banner
                        title="Table Design"
                        description="On this menu you will be able to create, edit, and also delete the Additional Notes. Also you can manage it easily."
                        onOpenModal={() => setIsNewTableModalOpen(true)}
                        openModalButtonLabel="Add New Section"
                        searchPlaceholder="Search your table Number"
                        // showSearchInput={false}
                        image={tableBanner}
                        showPrintButton={false}
                        showFilterButton={false}
                    />

                    {/* Table and Chair Design */}
                    <div className="flex flex-col flex-1 w-full max-w-full pb-2 overflow-x-hidden">
                        <div className="flex items-center justify-between flex-wrap mt-5 mb-[14px]">
                            <div className="flex items-center gap-8">
                                <h3 className="text-base font-semibold font-quicksand">Section</h3>
                                <div className="flex items-center gap-3 w-4/5 xl:w-[600px] overflow-x-auto">
                                    {sections.map((section, sectionIndex) => (
                                        <button
                                            type="button"
                                            key={sectionIndex}
                                            className={`px-3 py-2 rounded-lg flex-none text-sm font-quicksand capitalize ${
                                                currentSection === sectionIndex
                                                    ? "font-bold text-white bg-aurora-blue"
                                                    : "border-[1px] border-gray-x/40 font-medium text-clinder"
                                            }`}
                                            onClick={() => handleSectionChange(sectionIndex)}
                                        >
                                            {section.placeName}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="flex items-center gap-2">
                                    <div className="w-4 h-4 rounded-full bg-[#00B9AE] flex-none" />
                                    <span className="text-sm font-semibold text-clinder font-quicksand">
                                        Available
                                    </span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="w-4 h-4 rounded-full bg-[#F17300] flex-none" />
                                    <span className="text-sm font-semibold text-clinder font-quicksand">
                                        Not Available
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    className="px-3 py-2 text-sm font-semibold border rounded-lg text-clinder font-quicksand border-gray-x/40"
                                    onClick={() => setIsEditTableModalOpen(true)}
                                >
                                    Edit Section
                                </button>
                                <button
                                    type="button"
                                    className="flex items-center gap-2 px-3 py-2 text-sm font-semibold border rounded-lg text-clinder font-quicksand border-gray-x/40"
                                    onClick={() => deleteTableSection(currentSectionId)}
                                >
                                    <AiTwotoneDelete className="text-xl text-[#8F0A13] flex-none" />
                                    <span className="text-[#8F0A13]">Delete this section</span>
                                </button>
                            </div>
                        </div>

                        <div
                            className={`flex-1 border-[1px] max-w-full overflow-x-auto border-gray-x/40 bg-[#F0F0F0] rounded-[10px] pt-9 pl-9 pb-[39px] pr-[54px] ${
                                currentTableData.length === 0 && "flex justify-center items-center"
                            }`}
                        >
                            {/* If No Data on server */}
                            {currentTableData.length === 0 && (
                                <DataApiEmptyInfo
                                    emptyInfoHeading="Oops, Nothing Here Yet!"
                                    firstEmptyInfoDescription="Looks like this space is feeling a bit lonely right now."
                                    secondEmptyInfoDescription="Just hit that 'Add New Section' button, and you can start to add table and section"
                                />
                            )}

                            {/* Table Design */}
                            {currentTableData.length > 0 && (
                                <div className="flex flex-wrap gap-14 w-[1140px] mx-auto">
                                    {currentTableData.map((table, tableIndex) => (
                                        <div
                                            key={tableIndex}
                                            className="flex flex-col items-center"
                                        >
                                            <div className="bg-white border-[0.5px] border-gray-x/40 rounded h-6 w-12 mx-auto" />
                                            <div className="flex items-center mt-2 gap-x-2">
                                                <div className="bg-white border-[0.5px] border-gray-x/40 rounded h-12 w-6" />
                                                <div className="flex">
                                                    <div className="bg-white border-[0.5px] border-gray-x/40 rounded-lg w-[72px] h-[72px] flex justify-center items-center">
                                                        <div
                                                            className={`text-center rounded-full w-9 h-9 leading-[36px] text-white font-medium font-quicksand ${
                                                                table.tableStatus === "available"
                                                                    ? "bg-[#00B9AE]"
                                                                    : "bg-[#F17300]"
                                                            }`}
                                                        >
                                                            {table.number}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-white border-[0.5px] border-gray-x/40 rounded h-12 w-6" />
                                            </div>
                                            <div className="bg-white border-[0.5px] border-gray-x/40 rounded h-6 w-12 mx-auto mt-2" />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>

            {/* New Table Design Modal */}
            <NewTableDesign
                isModalOpen={isNewTableModalOpen}
                setIsModalOpen={setIsNewTableModalOpen}
                onGetAllTableSections={getAllTableSections}
            />

            {/* Edit Table Design Modal */}
            <EditTableDesignModal
                isModalOpen={isEditTableModalOpen}
                setIsModalOpen={setIsEditTableModalOpen}
                onGetAllTableSections={getAllTableSections}
                currentSectionData={currentSectionData}
            />
        </div>
    );
};

export default Table;
