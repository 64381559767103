import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import trashRed from '../assets/icons/trashRed.svg';
import key from '../assets/icons/key.svg';
import keyHighlight from '../assets/icons/key-highlight.svg';
import eyeSlash from '../assets/icons/eye-slash.svg';
import eye from '../assets/icons/eye.svg';

const ConfirmationPasswordModal = ({
	isModalOpen,
	setIsModalOpen,
	onDeleteBranch,
	id
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [isPasswordClicked, setIsPasswordClicked] = useState(false);
	const [password, setPassword] = useState('');

	//  Handle post to check password
	const handleCheckPassword = () => {
		// console.log(password);
		// If Delete branch success
		onDeleteBranch(id);
		setPassword('');
	};

	return (
		<>
			{isModalOpen && (
				<div
					className='fixed inset-0 z-50 flex items-center justify-center bg-clinder/60'
					onClick={() => setIsModalOpen(false)}>
					<div
						className='bg-white w-[342px] md:w-[579px] rounded-lg p-8'
						onClick={e => e.stopPropagation()}>
						<div className='items-center justify-between pb-6 border-b-[1px] border-gray-x hidden md:flex'>
							<h2 className='text-xl font-bold font-quicksand text-black-coal'>
								Action Confirmation
							</h2>
							<button
								className='text-xl font-medium text-manatee font-quicksand'
								onClick={() => setIsModalOpen(false)}>
								<AiOutlineClose size={25} />
							</button>
						</div>
						<div>
							<p className='mt-6 text-sm font-semibold text-center font-quicksand text-clinder'>
								Enter your password before deleting this branch.
							</p>
							<div
								className='relative mt-4 h-14'
								onClick={() => setIsPasswordClicked(true)}
								onBlur={() => setIsPasswordClicked(false)}>
								<img
									src={isPasswordClicked ? keyHighlight : key}
									alt='password icon'
									className='absolute z-10 w-5 h-5 top-[18px] left-4'
								/>
								<input
									type={showPassword ? 'text' : 'password'}
									required
									placeholder='Password'
									value={password}
									className='absolute w-full px-12 h-14 focusable-input'
									onChange={e => setPassword(e.target.value)}
								/>
								<button
									type='button'
									className='bg-white shadow-[0_4px_8px_rgba(155,155,155,0.05)] rounded-lg p-2 absolute z-10 top-3 right-4'
									onClick={() => setShowPassword(!showPassword)}>
									<img
										src={showPassword ? eye : eyeSlash}
										alt={
											showPassword
												? 'show password icon'
												: 'hidden password icon'
										}
										className='w-4 h-4'
									/>
								</button>
							</div>
						</div>
						<div className='flex items-center justify-center mt-8 gap-4 md:gap-[10px]'>
							<button
								className='flex items-center justify-center flex-1 w-1/2 gap-4 px-3 py-4 rounded md:flex-none bg-coral'
								onClick={() => handleCheckPassword()}>
								<img
									src={trashRed}
									className='hidden md:block'
									alt='trash red'
								/>{' '}
								<span className='inline text-base font-semibold font-quicksand text-red-dark'>
									Confirm
								</span>
							</button>
							<button
								className='flex-1 w-1/2 p-4 text-base font-semibold rounded font-quicksand bg-neutral-gray text-black-coal'
								onClick={() => setIsModalOpen(false)}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ConfirmationPasswordModal;
