import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import axiosInstance from '../../../utils/api/apiConfig';

const LevelOptionDropdown = ({
  customRadius,
  customPadding,
  selectedCustomerLevelName,
  setSelectedCustomerLevelName,
  selectedCustomerLevelFontColor,
  setSelectedCustomerLevelFontColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customerlevels, setCustomerLevels] = useState([]);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  // Handle Get All Customer Levels
  useEffect(() => {
    const getAllCustomerLevels = async () => {
      try {
        const response = await axiosInstance.get(`/customer-levels`, {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setCustomerLevels(response.data.data);
        // // console.log(response.data.data);
      } catch (error) {
        // console.log(error);
      }
    };

    getAllCustomerLevels();
  }, []);

  return (
    <div className="relative w-auto h-full">
      <button
        type="button"
        className={`px-2 hover:bg-gray-50 flex items-center justify-between gap-3 cursor-pointer bg-white border-[0.5px] border-gray-x/40 w-full ${customRadius} ${customPadding}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`text-sm capitalize font-quicksand font-semibold text-${selectedCustomerLevelFontColor}`}
        >
          {selectedCustomerLevelName}
        </span>
        <div>
          <HiChevronUp className="-mb-2" aria-hidden="true" />
          <HiChevronDown aria-hidden="true" />
        </div>
      </button>
      <div
        className={`absolute right-0 z-10 mt-1 bg-white shadow-lg rounded-md ${
          isOpen ? 'block' : 'hidden'
        } `}
      >
        {customerlevels.map((customerlevel, index) => (
          <button
            key={index}
            type="button"
            className={`capitalize hover:bg-gray-100 text-xs font-quicksand px-4 py-2 w-full text-start pr-8`}
            onClick={() => {
              setSelectedCustomerLevelName(customerlevel?.customerLevelName);
              setSelectedCustomerLevelFontColor(customerlevel?.colorFont);
              setIsOpen(false);
            }}
          >
            <span
              className={`font-semibold capitalize text-${customerlevel?.colorFont}`}
            >
              {customerlevel?.customerLevelName}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

LevelOptionDropdown.defaultProps = {
  customRadius: 'rounded-[4px]',
  customPadding: 'py-1',
};

export default LevelOptionDropdown;
