import React, { useState } from "react";

import Pagination from "../../components/Pagination";
import CreateNewRoleModal from "../../components/role_and_admin/manage_role/NewRoleModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopRoleTable from "../../components/role_and_admin/manage_role/TabletDesktopRoleTable";
import MobileRoleTable from "../../components/role_and_admin/manage_role/MobileRoleTable";

import manageRoleBanner from "../../assets/images/manageRoleBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const ManageRole = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [isNewRoleModalOpen, setIsNewRoleModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("manage-role");
    const topNavs = [
        {
            nav: "Manage Role",
            value: "manage-role",
            route: "admin-role-and-admin-manage-role",
        },
        {
            nav: "Administrator List",
            value: "administrator-list",
            route: "admin-role-and-admin-administrator-list",
        },
    ];

    const mobileTopNavs = [
        {
            nav: "Role",
            value: "manage-role",
            route: "admin-role-and-admin-manage-role",
        },
        {
            nav: "Admin",
            value: "administrator-list",
            route: "admin-role-and-admin-administrator-list",
        },
    ];

    // fake data
    const roleNameOptions = [
        { label: "Role Name & Description", value: "role-name", disabled: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const numberOfMembersOptions = [
        { label: "Number of Members", value: "nuber-of-member", disabled: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const statusOptions = [
        { label: "Status", value: "status", disabled: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
    ];
    const roleData = [
        {
            name: "food quality",
            descripton:
                "This role is only used for people who play a role in assessing the quality of food.",
            numberOfMembers: 8,
            company: "resto pak min",
            status: "Active",
        },
        {
            name: "food tester",
            descripton:
                "This role is only used for people who play a role in assessing the quality of food.",
            numberOfMembers: 8,
            company: "resto pak min",
            status: "Active",
        },
        {
            name: "cashier",
            descripton:
                "This role is only used for people who play a role in assessing the quality of food.",
            numberOfMembers: 4,
            company: "resto pak min",
            status: "Active",
        },
        {
            name: "manajemen",
            descripton:
                "This role is only used for people who play a role in assessing the quality of food.",
            numberOfMembers: 2,
            company: "resto pak min",
            status: "Inactive",
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="md:w-[88px] 2xl:w-[200px] flex-none sticky top-0 z-50 w-full bg-white h-[86px]">
                <SideBar isRoleAndAdmin={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full min-h-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Role & Admin"}
                    menuDescription={
                        "You can manage and organize report document and other things here"
                    }
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title="Manage Role"
                            description=" On this menu you will be able to create, edit, and also delete the role on your restaurant. Also you can manage it easily."
                            onOpenModal={() => setIsNewRoleModalOpen(true)}
                            openModalButtonLabel="Create New Role"
                            searchPlaceholder={"Search Roles"}
                            image={manageRoleBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Roles"}
                            isDisable={true}
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={mobileTopNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Role Table for Mobile */}
                        <MobileRoleTable
                            roleData={roleData}
                            setIsNewRoleModalOpen={setIsNewRoleModalOpen}
                        />

                        {/* role table for Tablet and Desktop  */}
                        <TabletDesktopRoleTable
                            roleData={roleData}
                            roleNameOptions={roleNameOptions}
                            numberOfMembersOptions={numberOfMembersOptions}
                            statusOptions={statusOptions}
                        />
                    </div>

                    {/* Pagination */}
                    <div className="static bottom-0 pb-20 bg-white md:pb-6 mt-9 md:mt-0 md:sticky">
                        <div className="flex justify-between p-2 rounded-lg bg-neutral-gray">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand sm:block">
                                Showing 5 Roles
                            </p>
                            <Pagination totalPages={38} />
                        </div>
                    </div>
                </main>

                {/* Create New Role Modal */}
                <CreateNewRoleModal
                    isModalOpen={isNewRoleModalOpen}
                    setIsModalOpen={setIsNewRoleModalOpen}
                />
            </div>
        </div>
    );
};

export default ManageRole;
