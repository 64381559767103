import React from 'react';

import close from '../../../assets/icons/close.svg';

const NotesFilterModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsFilteringByDateActive,
  isFilteringByDateToggleActive,
  setIsFilteringByDateToggleActive,
  filterStartDate,
  setFilterStartDate,
  currentDate,
  filterEndDate,
  setFilterEndDate,
  notes,
  setNotesFilteredByDate,
}) => {
  const statusFilterOptions = [
    { name: 'Active', value: true },
    { name: 'Inactive', value: false },
  ];

  // Handle Filter notes by date
  const handleFilterNotesByDate = (notes, filterStartDate, filterEndDate) => {
    // console.log(notes);
    const startDate = new Date(filterStartDate);
    const endDate = new Date(filterEndDate);
    endDate.setHours(23, 59, 59, 999);

    const filteredNotes = notes.filter((note) => {
      const notesCreatedAt = new Date(note?.createdAt);
      return notesCreatedAt >= startDate && notesCreatedAt <= endDate;
    });

    if (filterStartDate !== '' && filterEndDate !== '') {
      setIsFilteringByDateActive(true);
    }
    // console.log('filteredNotes', filteredNotes);
    setIsFilteringByDateToggleActive(!isFilteringByDateToggleActive);
    setNotesFilteredByDate(filteredNotes);
  };

  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              Filter by
            </h2>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setIsModalOpen(false);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[90%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this window you can add filters for what data you want to display
            in the table.
          </p>
        </div>
        <div className="flex flex-col justify-between flex-1 pt-6">
          <div>
            <p className="text-sm font-semibold font-quicksand text-clinder">
              Date Range
            </p>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray w-44">
                <input
                  value={filterStartDate}
                  type="date"
                  id="start-promo-date"
                  required
                  className="w-full h-full text-sm font-semibold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none  md:placeholder-Pick\ Promo\ Date"
                  onChange={(e) => setFilterStartDate(e.target.value)}
                />
              </div>

              <span className="text-sm font-semibold font-quicksand text-clinder">
                To
              </span>

              <div className="flex items-center gap-4 px-4 mt-2 rounded-lg w-44 h-14 bg-neutral-gray">
                <input
                  value={filterEndDate}
                  type="date"
                  id="start-promo-date"
                  required
                  className="w-full h-full text-sm font-semibold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none  md:placeholder-Pick\ Promo\ Date"
                  onChange={(e) => setFilterEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 font-bold mt-9 font-quicksand ">
            <button
              type="button"
              className="w-full text-aurora-blue"
              onClick={() => {
                setFilterStartDate('');
                setFilterEndDate(currentDate);
                setNotesFilteredByDate([]);
                setIsFilteringByDateActive(false);
                setIsFilteringByDateToggleActive(
                  !isFilteringByDateToggleActive
                );
              }}
            >
              Clear
            </button>
            <button
              type="button"
              className="w-full text-white button-primary-lg xl:mb-0"
              onClick={() => {
                handleFilterNotesByDate(notes, filterStartDate, filterEndDate);
                setIsModalOpen(false);
              }}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesFilterModal;
