import React, { useState } from "react";
import SingleIndicatorDropdown from "../../SingleIndicatorDropdown";
import coffee from "../../../assets/icons/coffee.svg";
import reserveGoldenRod from "../../../assets/icons/reserveGoldenRod.svg";
import wallet from "../../../assets/icons/wallet.svg";
import timer from "../../../assets/icons/timer.svg";
import useGetCustomerAnalytics from "../../../hooks/customer/useGetCustomerAnalytics";

const CustomerAnalyticSection = ({ customerCredentialId }) => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [selectedFilter, setSelectedFilter] = useState("monthly");

    /* HOOKS */
    const { loading, customerAnalyticData } = useGetCustomerAnalytics(
        token,
        tenantId,
        customerCredentialId,
        selectedFilter
    );

    return (
        <>
            <div className="mt-[175px] md:mt-36">
                <div className="flex items-center justify-between">
                    <h3 className="text-sm font-semibold font-quicksand text-clinder">
                        Customer Visit Chart
                    </h3>
                    <div className="w-[168px]">
                        <SingleIndicatorDropdown
                            height={"h-10"}
                            iconSize={"w-5 h-5"}
                            radius={"rounded"}
                            options={["Weekly", "Monthly", "Yearly"]}
                            setSelected={(option) => setSelectedFilter(option)}
                            selected={selectedFilter}
                        />
                    </div>
                </div>

                {/* FIXME: INACTIVE FEATURE */}
                {/* <div className="h-[270px] mt-6 border-[0.5px] border-clinder/10">
                    <p className="leading-[270px] text-center">
                        Customer Visit Chart (Coming soon)
                    </p>
                </div> */}
            </div>
            <div className="pb-20 mt-9">
                {/* FIXME: INACTIVE FEATURE */}
                {/* <h3 className="text-sm font-semibold font-quicksand text-clinder">
                    Business Explonation
                </h3>
                <div className="p-3 mt-3 border-[0.5px] border-gray-x rounded">
                    <div className="flex gap-2">
                        <h3 className="p-2 text-xs font-semibold font-quicksand bg-neutral-gray border-[0.5px] border-gray-x rounded">
                            😎 Loyal Customer
                        </h3>
                        <h3 className="rounded border-[0.5px] border-lime-green p-2 text-xs font-semibold font-quicksand text-lime-dark bg-ivory-cream">
                            Good Visiting Time
                        </h3>
                    </div>
                    <p className="font-satoshi text-manatee text-sm font-medium leading-[200%] mt-3">
                        Ignasius is a really good customer. He loyal at this restaurant and also
                        keep the table clean after the order. Keep him and treat him better !
                    </p>
                </div> */}
                <div className="flex flex-wrap gap-6 py-3 pl-3 mt-6">
                    <div className="flex items-center gap-3 h-11 w-60">
                        <figure className="p-3 rounded-full h-11 w-11 bg-tender-rose">
                            <img src={reserveGoldenRod} alt={"Frequently Food Order"} />
                        </figure>
                        <div>
                            <h3 className="text-xs font-semibold font-quicksand text-manatee">
                                Frequently Food Order
                            </h3>

                            {!loading && (
                                <h3 className="mt-2 text-sm font-semibold capitalize font-quicksand text-clinder">
                                    {customerAnalyticData?.frequentlyOrderedProduct?.name || "-"}
                                </h3>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center gap-3 h-11 w-60">
                        <figure className="p-3 rounded-full h-11 w-11 bg-tender-rose">
                            <img src={coffee} alt={"Frequently Food Order"} />
                        </figure>
                        <div>
                            <h3 className="text-xs font-semibold font-quicksand text-manatee">
                                Frequently Category Order
                            </h3>

                            {!loading && (
                                <h3 className="mt-2 text-sm font-semibold capitalize font-quicksand text-clinder">
                                    {customerAnalyticData?.frequentlyOrderedCategory?.name || "-"}
                                </h3>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center gap-3 h-11 w-60">
                        <figure className="p-3 rounded-full h-11 w-11 bg-tender-rose">
                            <img src={wallet} alt={"Frequently Payment Method"} />
                        </figure>
                        <div>
                            <h3 className="text-xs font-semibold font-quicksand text-manatee">
                                Frequently Payment Method
                            </h3>

                            {!loading && (
                                <h3 className="mt-2 text-sm font-semibold capitalize font-quicksand text-clinder">
                                    {customerAnalyticData?.frequentlyUsedPaymentMethod
                                        ?.paymentMethodName || "-"}
                                </h3>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center gap-3 h-11 w-60">
                        <figure className="p-3 rounded-full h-11 w-11 bg-tender-rose">
                            <img src={timer} alt={"Frequently Time Order"} />
                        </figure>
                        <div>
                            <h3 className="text-xs font-semibold font-quicksand text-manatee">
                                Frequently Time Order
                            </h3>

                            {!loading && (
                                <h3 className="mt-2 text-sm font-semibold capitalize font-quicksand text-clinder">
                                    {`${customerAnalyticData?.frequentlyOrderedTime?.hour || "-"}`}
                                </h3>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerAnalyticSection;
