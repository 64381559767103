import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import tickSuccess from "../assets/icons/tickSuccess.gif";

const SuccessNotification = ({ title, message, notificationCountdown, path, size }) => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(notificationCountdown);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            clearInterval(timer);
            navigate(path);
        }

        return () => {
            clearInterval(timer);
        };
    }, [countdown, path, navigate]);

    return (
        <div className="flex flex-col-reverse w-full md:flex-col">
            <div>
                <h2 className="text-xl font-bold text-center md:text-2xl font-quicksand text-clinder">
                    {title}
                </h2>
                <p className="px-[10px] mt-2 text-xs font-medium leading-6 text-center font-satoshi text-manatee">
                    {message} You will be redirected to the home page in ( 0{countdown} s )
                </p>
            </div>
            <img
                src={tickSuccess}
                alt="checklist icon"
                className={`mx-auto w-[187px] h-[187px] ${
                    size === "small" ? "md:w-[225] md:h-[225px]" : "2xl:w-[300px] 2xl:h-[300px]"
                }`}
            />
        </div>
    );
};

// Default Props
SuccessNotification.defaultProps = {
    size: "large",
};

export default SuccessNotification;
