import React, { useState } from 'react';
import { TbDiscount2 } from 'react-icons/tb';

import DoubleIndicatorDropdown from '../../DoubleIndicatorDropdown';
import DataFilterEmptyInfo from '../../DataFilterEmptyInfo';
import DataApiEmptyInfo from '../../DataApiEmptyInfo';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';

import shieldSearch from '../../../assets/icons/shieldSearch.svg';
import trashRed from '../../../assets/icons/trashRed.svg';
import smallToggle from '../../../assets/icons/smallToggle.svg';
import lightSmallToggle from '../../../assets/icons/lightSmallToggle.svg';

const TabletDesktopMenuTable = ({
  stockMenuData,
  allMenus,
  onDeleteMenu,
  menuNameOptions,
  baseProductOptions,
  categoryOptions,
  stockOptions,
  priceOptions,
  onHandleChangeOnSaleStatus,
  setIsMenuId,
  setIsDetailSingleMenuOpen,
}) => {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [menuIdToDelete, setMenuIdToDelete] = useState(null);

  return (
    <div className="hidden mt-6 md:block">
      <table className="w-full border-collapse table-auto">
        <thead className="bg-neutral-gray">
          <tr>
            <th
              className="w-[17.87%] xl:w-[15.27%] 3xl:w-1/6 2xl:w-[17%] text-start"
              id="menu-name"
            >
              <DoubleIndicatorDropdown options={menuNameOptions} />
            </th>
            <th
              className="w-[17.87%] xl:w-[15.27%] 3xl:w-1/6 2xl:w-[17%] text-start"
              id="base-product"
            >
              <DoubleIndicatorDropdown options={baseProductOptions} />
            </th>
            <th
              className="w-[17.87%] xl:w-[15.27%] 3xl:w-1/6 2xl:w-[17%] text-start"
              id="category"
            >
              <DoubleIndicatorDropdown options={categoryOptions} />
            </th>
            <th
              className="w-[15.82%] xl:w-[15.27%] 3xl:w-1/6 2xl:w-[17%] text-start"
              id="stock"
            >
              <DoubleIndicatorDropdown options={stockOptions} />
            </th>
            <th
              className="w-[17.87%] xl:w-[13.42%] 3xl:w-1/6 2xl:w-[15.10%] text-start"
              id="price"
            >
              <DoubleIndicatorDropdown options={priceOptions} />
            </th>
            <th
              className="w-[12.66%] xl:w-[15.27%]  w-/3xl:w-1/6 2xl:w-[17%] text-start"
              id="action"
            >
              <p className="p-[10px] text-sm font-medium hover:bg-gray-50 text-manatee font-quicksand">
                Action
              </p>
            </th>
          </tr>
        </thead>
        {stockMenuData?.length > 0 && (
          <tbody>
            {stockMenuData.map((product, index) => {
              return (
                <tr key={index}>
                  <td
                    className="text-sm font-semibold leading-5 font-quicksand px-[10px] py-6 capitalize flex gap-2 items-center"
                    aria-labelledby="menu-name"
                  >
                    {product?.isHavePromo && (
                      <TbDiscount2 className="text-[#EF473A] text-xl flex-none" />
                    )}
                    <span
                      className={`${
                        product?.isHavePromo
                          ? 'text-aurora-blue'
                          : 'text-clinder'
                      }`}
                    >
                      {product?.name}
                    </span>
                  </td>
                  <td
                    aria-labelledby="base-product"
                    className="px-[10px] capitalize py-6"
                  >
                    {product?.totalBaseProduct} item
                    {product?.totalBaseProduct > 1 && 's'}
                  </td>
                  <td aria-labelledby="category" className="px-[10px] py-6">
                    <span
                      className={`leading-5 capitalize text-sm font-semibold font-quicksand `}
                    >
                      {product?.productCategoryName}
                    </span>
                  </td>
                  <td
                    className="px-[10px] py-6 leading-5 text-sm font-semibold font-quicksand"
                    aria-labelledby="stock"
                  >
                    <span>{product?.productStock}</span>{' '}
                    <span className="hidden xl:inline">
                      Serving{product?.productStock > 1 && 's'}
                    </span>
                  </td>
                  <td
                    className="px-[10px] py-6 leading-5 text-sm font-semibold font-quicksand"
                    aria-labelledby="price"
                  >
                    {product?.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  </td>
                  <td
                    className="flex items-center justify-center py-6 gap-x-1"
                    aria-labelledby="action"
                  >
                    <button
                      type="button"
                      className="block px-3 mx-auto xl:hidden"
                    >
                      <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                      <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                      <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                    </button>
                    <button
                      type="button"
                      className="items-center justify-center hidden w-full gap-2 px-3 py-1 duration-300 transform rounded-md xl:flex bg-neutral-gray hover:bg-gray-200"
                      onClick={() =>
                        onHandleChangeOnSaleStatus(
                          product?.id,
                          product?.onSaleId,
                          product?.onSale
                        )
                      }
                    >
                      <span
                        className={`text-sm font-semibold leading-5 font-quicksand ${
                          product?.onSale ? 'text-blue-700' : 'text-manatee'
                        }`}
                      >
                        {product?.onSale ? 'On Sale' : 'Not Sale'}
                      </span>
                      <img
                        src={product?.onSale ? lightSmallToggle : smallToggle}
                        alt="product status"
                      />
                    </button>
                    <button
                      type="button"
                      className="items-center flex-none hidden px-3 py-2 duration-300 transform rounded-md xl:flex bg-neutral-gray hover:bg-gray-200"
                      onClick={() => {
                        setIsMenuId(product?.id);
                        setIsDetailSingleMenuOpen(true);
                      }}
                    >
                      <img
                        src={shieldSearch}
                        alt="shield search"
                        className="w-3 h-3"
                      />
                    </button>
                    <button
                      type="button"
                      className="flex-none hidden px-3 py-2 duration-300 transform rounded-md xl:inline-block bg-neutral-gray hover:bg-gray-200"
                      onClick={() => {
                        setMenuIdToDelete(product?.id);
                        setIsDeleteConfirmationModalOpen(true);
                      }}
                    >
                      <img src={trashRed} alt="trash red" className="w-3 h-3" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}

        {stockMenuData?.length === 0 && allMenus?.length !== 0 && (
          <tbody>
            <td colSpan="6" className="pt-4">
              <DataFilterEmptyInfo
                emptyInfoHeading={'Sorry, No Results Found'}
                emptyInfoDescription={
                  "Well, we've searched high and low,but it seems like what your're looking for isn't our database (yet!)"
                }
              />
            </td>
          </tbody>
        )}

        {allMenus?.length === 0 && stockMenuData?.length === 0 && (
          <tbody>
            <td colSpan="6" className="pt-4">
              <DataApiEmptyInfo
                emptyInfoHeading={'Oops, Nothing Here Yet!'}
                firstEmptyInfoDescription={
                  'Looks like this space is feeling a bit lonely right now. Why not kick things off by adding some info?'
                }
                secondEmptyInfoDescription={
                  "Just hit that 'Create New Menu' button, and let the good times (and data) roll!"
                }
              />
            </td>
          </tbody>
        )}
      </table>

      {/* Delete Confirmation Modal*/}
      <DeleteConfirmationModal
        isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
        setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
        shortDeleteConfirmatonMessage={'Delete this menu'}
        longDeleteConfirmatonMessage={
          'Are you sure you want to delete this menu?'
        }
        longDeleteButtonLabel={'Yes, Delete this menu'}
        onDeleteMenu={onDeleteMenu}
        id={menuIdToDelete}
        onDelete={onDeleteMenu}
      />
    </div>
  );
};

export default TabletDesktopMenuTable;
