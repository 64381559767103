import React, { useEffect, useState } from 'react';

const BaseProductSelectionCheckbox = ({
  baseProductIndex,
  baseProductName,
  baseProductId,
  productId,
  baseProductUnitSystem,
  setSelectedBaseProductsToPost,
  isSelectionReset,
}) => {
  const [isBaseProductSelected, setIsBaseProductSelected] = useState(false);

  useEffect(() => {
    setIsBaseProductSelected(false);
  }, [isSelectionReset]);

  const handleBaseProductSelection = ({
    baseProductId,
    isBaseProductSelected,
  }) => {
    if (isBaseProductSelected) {
      setSelectedBaseProductsToPost((prevSelected) => [
        ...prevSelected,
        {
          baseProductId: baseProductId,
          productId: productId,
          qty: 0,
          multiple: 1,
          unitSystem: baseProductUnitSystem,
        },
      ]);
    } else {
      setSelectedBaseProductsToPost((prevSelected) =>
        prevSelected.filter((item) => item.baseProductId !== baseProductId)
      );
    }
  };

  const toggleSelection = () => {
    setIsBaseProductSelected(!isBaseProductSelected);
    handleBaseProductSelection({
      baseProductId,
      isBaseProductSelected: !isBaseProductSelected,
    });
  };

  return (
    <div
      className="flex items-center justify-between w-full gap-3 p-3 rounded cursor-pointer"
      onClick={toggleSelection}
    >
      <label
        htmlFor={`base-product-${baseProductIndex}`}
        className="text-sm font-semibold capitalize text-clinder font-quicksand"
      >
        {baseProductName}
      </label>
      <input
        id={`base-product-${baseProductIndex}`}
        type="checkbox"
        required
        checked={isBaseProductSelected}
        className="w-4 h-4 mt-1 hover:cursor-pointer"
        onClick={(e) => e.stopPropagation()}
        onChange={toggleSelection}
      />
    </div>
  );
};

export default BaseProductSelectionCheckbox;
