import React, { useState } from "react";

import Pagination from "../../components/Pagination";
import NewAdministratorModal from "../../components/role_and_admin/administrator_list/NewAdministratorModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopEmployeeTable from "../../components/role_and_admin/administrator_list/TabletDesktopEmployeeTable";
import MobileEmployeeTable from "../../components/role_and_admin/administrator_list/MobileEmployeeTable";

import administratorListBanner from "../../assets/images/administratorListBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const AdministratorList = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [isNewAdministratorModalOpen, setIsNewAdministratorModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("administrator-list");
    const topNavs = [
        {
            nav: "Manage Role",
            value: "manage-role",
            route: "admin-role-and-admin-manage-role",
        },
        {
            nav: "Administrator List",
            value: "administrator-list",
            route: "admin-role-and-admin-administrator-list",
        },
    ];
    const mobileTopNavs = [
        {
            nav: "Role",
            value: "manage-role",
            route: "admin-role-and-admin-manage-role",
        },
        {
            nav: "Admin",
            value: "administrator-list",
            route: "admin-role-and-admin-administrator-list",
        },
    ];

    // fake data
    const employeeNameOptions = [
        { label: "Employee Name", value: "employee-name", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const roleOptions = [
        { label: "Role", value: "role" },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const createdByOptions = [
        { label: "Created By", value: "created-by" },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const statusOptions = [
        { label: "Status", value: "status" },
        { label: "Active", value: "active" },
        { label: "Non-Active", value: "non-active" },
    ];
    const employeeData = [
        {
            name: "Sukriye Efil",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1618641986557-1ecd230959aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODU5NDcwODE&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "food quality",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "kris roher",
            noHP: 18874121031,
            profilePicture:
                "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEyMzg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "food tester",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Inactive",
        },
        {
            name: "Serenity Fisher",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1519456264917-42d0aa2e0625?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEyNjY&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "Brooklyn Warren",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzMDc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "Franco Delort",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1463453091185-61582044d556?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzMzQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "cashier",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "Serenity Fisher",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODE0MzQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "manajemen",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "Brooklyn Warren",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNjc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "manajemen",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "Franco Delort",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1594751439417-df8aab2a0c11?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNzg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "manajemen",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
        {
            name: "Brooklyn Warren",
            noHP: 19976075181,
            profilePicture:
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNjc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            role: "manajemen",
            createdBy: {
                name: "savannah nguyen",
                role: "food quality manager",
                profilePicture:
                    "https://cdn.discordapp.com/attachments/1093818778107793489/1126728949347663902/Frame_2882.png",
            },
            status: "Active",
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] flex-none sticky top-0 z-50 bg-white h-[86px]">
                <SideBar isRoleAndAdmin={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full min-h-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Role & Admin"}
                    menuDescription={
                        "You can manage and organize report document and other things here"
                    }
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title="Role & Admin"
                            description="On this menu you will be able to create, edit, and also delete the admin on your restaurant. Also you can manage it easily."
                            onOpenModal={() => setIsNewAdministratorModalOpen(true)}
                            openModalButtonLabel="Add New Administrator"
                            searchPlaceholder={"Search Administrator"}
                            image={administratorListBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Administrator"}
                            isDisable={true}
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={mobileTopNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Employee Table for Mobile */}
                        <MobileEmployeeTable
                            employeeData={employeeData}
                            setIsNewAdministratorOpen={setIsNewAdministratorModalOpen}
                        />

                        {/* Employee Table For Tablet and Desktop */}
                        <TabletDesktopEmployeeTable
                            employeeData={employeeData}
                            employeeNameOptions={employeeNameOptions}
                            roleOptions={roleOptions}
                            createdByOptions={createdByOptions}
                            statusOptions={statusOptions}
                        />
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-20 bg-white md:sticky md:pb-6 mt-9 md:mt-0">
                        <div className="flex items-center justify-between w-full px-3 py-2 rounded-lg bg-neutral-gray">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand sm:block">
                                Showing 8 items
                            </p>
                            <Pagination totalPages={38} />
                        </div>
                    </div>
                </main>
            </div>

            {/* Add New Administrator */}
            <NewAdministratorModal
                isModalOPen={isNewAdministratorModalOpen}
                setIsmodalOpen={setIsNewAdministratorModalOpen}
            />
        </div>
    );
};

export default AdministratorList;
