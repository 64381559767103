import React, { useEffect, useState } from 'react';

import SingleIndicatorDropdown from '../../SingleIndicatorDropdown';
import SuccessNotification from '../../SuccessNotification';

import close from '../../../assets/icons/close.svg';
import plus from '../../../assets/icons/plus.svg';
import { AiOutlineCheck } from 'react-icons/ai';

const NewAdministratorModal = ({ isModalOPen, setIsmodalOpen }) => {
  const [isCreatedSuccessfully, setIsCreatedSuccessfully] = useState(false);
  const [countDown, setCountDown] = useState(5);
  const MENUS = [
    {
      name: 'Dashboard',
      id: 1,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Stock',
      id: 2,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Customer',
      id: 3,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Restaurant',
      id: 4,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Design',
      id: 6,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Report',
      id: 7,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Role & Admin',
      id: 8,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Settings',
      id: 9,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Stock',
      id: 10,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    {
      name: 'Supply',
      id: 11,
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  ];
  const [menus, setMenus] = useState(MENUS);

  const toggleAccessibility = (menuIndex, key) => {
    const updatedMenus = [...menus];
    updatedMenus[menuIndex][key] = !updatedMenus[menuIndex][key];
    setMenus(updatedMenus);
  };

  // fake data
  const employees = ['employee 1', 'employee 2', 'employee 3'];
  const roles = ['role 1', 'role 2', 'role 3'];

  //   handle close modal by timer
  useEffect(() => {
    if (isCreatedSuccessfully) {
      const timer = setInterval(() => {
        setCountDown((pervCountDown) => pervCountDown - 1);
      }, 1000);
      if (countDown === 0) {
        clearInterval(timer);
        window.scrollTo(0, 0);
        setIsmodalOpen(false);
        setIsCreatedSuccessfully(false);
        setCountDown(5);
      }
      return () => {
        clearInterval(timer);
      };
    }
  }, [countDown, isCreatedSuccessfully]);

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex justify-end bg-clinder/60 ${
          !isModalOPen && 'hidden'
        }`}
        onClick={() => setIsmodalOpen(false)}
      />
      <div
        className={`bg-white w-full lg:w-[820px] md:px-9 h-screen overflow-y-auto px-0 pb-6 md:pb-9 flex flex-col fixed top-0 right-0 z-50 duration-300 transition ${
          isModalOPen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className="sticky top-0 z-50 bg-white border-b-[1px] md:border-b-[2px] border-gray-x pb-6 pt-6 md:pt-9">
          <div className="flex flex-row-reverse items-center justify-end gap-6 px-6 md:px-0 md:justify-between md:flex-row">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold font-quicksand text-aurora-blue">
              Add New Administrator
            </h2>
            <button
              onClick={() => {
                setIsmodalOpen(false);
                isCreatedSuccessfully && setCountDown(5);
                setIsCreatedSuccessfully(false);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="font-satoshi text-sm font-medium leading-[200%] text-manatee w-3/5 mt-2 hidden md:block">
            In this window you can set the new administrator and also manage
            their accessibility to your restaurant.
          </p>
        </header>
        <>
          {/* Administrator Form */}
          {!isCreatedSuccessfully ? (
            <form className="flex flex-col justify-between flex-1 mt-6 md:mt-9">
              <div>
                <div className="flex flex-col gap-4 px-6 md:flex-row md:px-0">
                  <div className="w-full">
                    <label
                      htmlFor="employee-name"
                      className="block mb-2 text-sm font-semibold text-clinder font-quicksand"
                    >
                      Employee Name{' '}
                    </label>
                    <SingleIndicatorDropdown
                      label={'Pick Employee Name'}
                      options={employees}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="status"
                      className="block mb-2 text-sm font-semibold text-clinder font-quicksand"
                    >
                      Status
                    </label>
                    <SingleIndicatorDropdown
                      label={'Pick status for Role'}
                      options={['active', 'non-active']}
                    />
                  </div>
                </div>
                <div className="px-6 md:px-0">
                  <label
                    htmlFor="role"
                    className="block mt-4 mb-2 text-sm font-semibold text-clinder font-quicksand"
                  >
                    Role
                  </label>
                  <SingleIndicatorDropdown
                    label={'Pick Role for the employee'}
                    options={roles}
                  />
                </div>
                <div className="flex justify-between px-6 py-4 mt-14 md:px-0 md:py-0">
                  <h3 className="text-sm font-semibold text-clinder font-quicksand">
                    Hak Akses
                  </h3>
                  <p className="text-sm font-semibold font-quicksand">
                    <span className="text-midnight-black">16</span>{' '}
                    <span className="text-manatee">Hak Akses dipilih </span>
                  </p>
                </div>
                {/* Accessibility Form for Tablet and Desktop */}
                <div className="px-6 py-6 mt-4 border-[1px] border-smoky-black/20 rounded-lg hidden md:flex">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b-[1px] border-smoky-black/20 text-manatee">
                        <th>
                          <div className="w-[300px] pb-2 text-sm font-medium font-satoshi text-start">
                            Nama Menu
                          </div>
                        </th>
                        <th>
                          <div className="pb-2 text-sm font-medium font-satoshi w-[90px]">
                            View{' '}
                          </div>
                        </th>
                        <th>
                          <div className="pb-2 text-sm font-medium font-satoshi w-[90px] ">
                            Create{' '}
                          </div>
                        </th>
                        <th>
                          <div className="pb-2 text-sm font-medium font-satoshi w-[90px] ">
                            Edit{' '}
                          </div>
                        </th>
                        <th>
                          <div className="pb-2 text-sm font-medium font-satoshi w-[90px] ">
                            Delete{' '}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {menus.map((menu, index) => (
                        <tr key={index}>
                          <td
                            className={
                              'w-[100px] font-semibold capitalize text-clinder text-sm font-quicksand'
                            }
                          >
                            <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                              {menu.name}
                            </div>
                          </td>
                          <td className="w-[50px]">
                            <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                              <button
                                type="button"
                                className={`block w-6 h-6 mx-auto rounded ${
                                  menu.view
                                    ? 'bg-aurora-blue'
                                    : 'bg-white border-[2px] border-[#0170a0]'
                                }`}
                                onClick={() =>
                                  toggleAccessibility(index, 'view')
                                }
                              >
                                {menu.view && (
                                  <AiOutlineCheck
                                    color={'white'}
                                    size={25}
                                    className="mx-auto"
                                  />
                                )}
                              </button>
                            </div>
                          </td>
                          <td className="w-[50px]">
                            <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                              <button
                                type="button"
                                className={`block w-6 h-6 mx-auto rounded ${
                                  menu.create
                                    ? 'bg-aurora-blue'
                                    : 'bg-white border-[2px] border-[#0170a0]'
                                }`}
                                onClick={() =>
                                  toggleAccessibility(index, 'create')
                                }
                              >
                                {menu.create && (
                                  <AiOutlineCheck
                                    color={'white'}
                                    size={25}
                                    className="mx-auto"
                                  />
                                )}
                              </button>
                            </div>
                          </td>
                          <td className="w-[50px]">
                            <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                              <button
                                type="button"
                                className={`block w-6 h-6 mx-auto rounded ${
                                  menu.edit
                                    ? 'bg-aurora-blue'
                                    : 'bg-white border-[2px] border-[#0170a0]'
                                }`}
                                onClick={() =>
                                  toggleAccessibility(index, 'edit')
                                }
                              >
                                {menu.edit && (
                                  <AiOutlineCheck
                                    color={'white'}
                                    size={25}
                                    className="mx-auto"
                                  />
                                )}
                              </button>
                            </div>
                          </td>
                          <td className="w-[50px]">
                            <div className={`${index === 0 ? 'mt-4' : 'mt-3'}`}>
                              <button
                                type="button"
                                className={`block w-6 h-6 mx-auto rounded ${
                                  menu.delete
                                    ? 'bg-aurora-blue'
                                    : 'bg-white border-[2px] border-[#0170a0]'
                                }`}
                                onClick={() =>
                                  toggleAccessibility(index, 'delete')
                                }
                              >
                                {menu.delete && (
                                  <AiOutlineCheck
                                    color={'white'}
                                    size={25}
                                    className="mx-auto"
                                  />
                                )}
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Accessibility Form for Mobile */}
                <div className="block md:hidden">
                  {menus.map((menu, index) => (
                    <div key={index} className="w-full">
                      <div className="py-4 px-6 bg-neutral-gray border-y-[1px] text-sm font-semibold leading-[150%] font-quicksand text-clinder capitalize">
                        {menu.name}
                      </div>
                      <div className="flex items-center justify-between w-full px-6 mt-4">
                        <p className="block text-sm font-semibold font-quicksand text-clinder">
                          View
                        </p>
                        <button
                          type="button"
                          className={`block w-6 h-6 rounded ${
                            menu.view
                              ? 'bg-aurora-blue'
                              : 'bg-white border-[2px] border-[#0170a0]'
                          }`}
                          onClick={() => toggleAccessibility(index, 'view')}
                        >
                          {menu.view && (
                            <AiOutlineCheck
                              color={'white'}
                              size={25}
                              className="mx-auto"
                            />
                          )}
                        </button>
                      </div>
                      <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                        <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                          Create
                        </p>
                        <button
                          type="button"
                          className={`block w-6 h-6 rounded ${
                            menu.create
                              ? 'bg-aurora-blue'
                              : 'bg-white border-[2px] border-[#0170a0]'
                          }`}
                          onClick={() => toggleAccessibility(index, 'create')}
                        >
                          {menu.create && (
                            <AiOutlineCheck
                              color={'white'}
                              size={25}
                              className="mx-auto"
                            />
                          )}
                        </button>
                      </div>
                      <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                        <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                          Edit
                        </p>
                        <button
                          type="button"
                          className={`block w-6 h-6 rounded ${
                            menu.edit
                              ? 'bg-aurora-blue'
                              : 'bg-white border-[2px] border-[#0170a0]'
                          }`}
                          onClick={() => toggleAccessibility(index, 'edit')}
                        >
                          {menu.edit && (
                            <AiOutlineCheck
                              color={'white'}
                              size={25}
                              className="mx-auto"
                            />
                          )}
                        </button>
                      </div>
                      <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                        <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                          Delete
                        </p>
                        <button
                          type="button"
                          className={`block w-6 h-6 rounded ${
                            menu.delete
                              ? 'bg-aurora-blue'
                              : 'bg-white border-[2px] border-[#0170a0]'
                          }`}
                          onClick={() => toggleAccessibility(index, 'delete')}
                        >
                          {menu.delete && (
                            <AiOutlineCheck
                              color={'white'}
                              size={25}
                              className="mx-auto"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mx-6 md:mx-0">
                <button
                  type="button"
                  className="w-full button-primary-lg mt-9"
                  onClick={() => {
                    setIsCreatedSuccessfully(true);
                  }}
                >
                  <img src={plus} alt="plus" />
                  <span className="text-base font-bold text-white font-quicksand">
                    {' '}
                    Add New Administrator{' '}
                  </span>
                </button>
              </div>
            </form>
          ) : (
            // Success Notification
            <div className="w-full sm:w-[470px] mx-auto mt-20 md:mt-36 px-4 md:px-0">
              <SuccessNotification
                title={'New Administrator Created Successfully'}
                message={
                  'The new administrator has been successfully added to the specified employee.'
                }
                notificationCountdown={5}
                path={'/admin-role-and-admin-administrator-list'}
              />
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default NewAdministratorModal;
