import React from "react";
import underConstructionImage from "../../assets/illustration/under-construction.svg";

const UnderConstructionBanner = () => {
    return (
        <section className={`w-full h-fi py-10  mt-6 rounded-lg overflow-hidden`}>
            <div className="w-full h-full flex flex-col gap-5 items-center justify-center">
                <img
                    src={underConstructionImage}
                    alt="under-construction"
                    width={500}
                    height={400}
                    className="mt-5 -mb-2"
                />

                <h1 className="text-3xl font-semibold text-zinc-500 text-center uppercase mt-2">
                    under development
                </h1>

                <span className="text-xl font-semibold text-zinc-400 text-center -mb-2">
                    Oops, Sorry this page is still in development.
                </span>
                <span className="text-xl font-semibold text-zinc-400 text-center capitalize-first">
                    {" "}
                    Stay tuned for updates!
                </span>
            </div>
        </section>
    );
};

export default UnderConstructionBanner;
