import React, { useEffect, useState } from 'react';

import CustomerLevelSelectionCheckbox from './CustomerLevelSelectionCheckbox';

import close from '../../../assets/icons/close.svg';
import search from '../../../assets/icons/search.svg';
import addSquare from '../../../assets/icons/addSquare.svg';

const CustomerLevelSelectionModal = ({
  isModalOpen,
  setIsModalOpen,
  allCustomerLevels,
  promoId,
  promoLevels,
  setPromoLevels,
  selectedLevels,
  setSelectedLevels,
}) => {
  // === Customer Level Selected Data ===
  const [searchQuery, setSearchQuery] = useState('');
  const [isSelectionReset, setIsSelectionReset] = useState(false);
  const [selectedCustomerLevelsToPost, setSelectedCustomerLevelsToPost] =
    useState([]);
  const [isSelectAllCustomerLevels, setIsSelectAllCustomerLevels] =
    useState(false);

  // Handle default value for selected customer levels top post and selected levels
  useEffect(() => {
    if (isModalOpen || isSelectAllCustomerLevels) {
      setSelectedCustomerLevelsToPost(promoLevels);
    }
  }, [isModalOpen, isSelectAllCustomerLevels]);

  // Handle Select all customer Levels
  const handleSelectAllCustomerLevels = () => {
    const selectedAllCustomerLevels = allCustomerLevels.map((level) => ({
      promoId: promoId,
      customerLevelId: level.id,
    }));
    setPromoLevels(selectedAllCustomerLevels);
    setIsSelectAllCustomerLevels(!isSelectAllCustomerLevels);
  };

  // Handle  search filter to filter customer level
  const customerLevelSearchFilter = (data) => {
    return data?.filter((item) =>
      item?.customerLevelName?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <>
      <div
        className={`fixed inset-0 top-0 z-50 right-0 bg-clinder/60 flex justify-end ${
          !isModalOpen && 'hidden'
        } `}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />

      <div
        className={`fixed top-0 right-0 z-50 px-6 md:px-9 h-screen  bg-transparent rounded-none w-full  duration-300 transition-all overflow-y-auto flex items-center justify-center ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      >
        <div
          className={`z-50 px-6  h-[88%] rounded-lg bg-white w-full md:w-[576px] duration-300 transition-all overflow-y-auto flex flex-col ${
            isModalOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="sticky top-0 z-30 pt-6 bg-white">
            <div className="flex items-center justify-between gap-6 md:flex-row md:justify-between">
              <div className="flex items-center gap-4">
                <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                  Choose & Select Level
                </h2>
              </div>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
              Please choode and select level to be applied for the promo by
              checking the roles listed below.
            </p>
            <div className="flex items-center w-full gap-4 px-4 py-2 mt-6 rounded-lg bg-neutral-gray">
              <img src={search} alt="search" />
              <input
                type="text"
                placeholder={'Search Level'}
                value={searchQuery}
                className="w-full px-2 py-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* Customer levell Selections */}
          <div className="flex-1 mt-3 overflow-y-auto rounded-lg bg-white shadow-[inset_0px_4px_20px_0px_rgba(0,0,0,0.05)] p-3">
            {customerLevelSearchFilter(allCustomerLevels)?.map(
              (customerLevel, customerLevelIndex) => (
                <CustomerLevelSelectionCheckbox
                  key={customerLevelIndex}
                  customerLevelIndex={customerLevelIndex}
                  customerLevelName={customerLevel?.customerLevelName}
                  customerLevelId={customerLevel?.id}
                  promoId={promoId}
                  promoLevels={promoLevels}
                  setPromoLevels={setPromoLevels}
                  isCustomerLevelSelectionModalOpen={isModalOpen}
                  setSelectedCustomerLevelsToPost={
                    setSelectedCustomerLevelsToPost
                  }
                  isSelectAllCustomerLevels={isSelectAllCustomerLevels}
                  allCustomerLevels={allCustomerLevels}
                  selectedLevels={selectedLevels}
                />
              )
            )}
          </div>
          <div className="sticky bottom-0 flex items-center justify-center py-6 bg-white">
            <button
              type="button"
              className="w-full py-4 font-bold px-9 text-aurora-blue font-quicksand"
              onClick={handleSelectAllCustomerLevels}
            >
              Select All
            </button>
            <button
              type="button"
              className="w-full py-4 px-9 button-primary-lg"
              onClick={() => {
                setPromoLevels(selectedCustomerLevelsToPost);
                setIsModalOpen(false);
              }}
            >
              <img src={addSquare} alt="plus icon" />
              <span className="font-bold text-white font-quicksand">
                Add {selectedCustomerLevelsToPost?.length} Level
                {selectedCustomerLevelsToPost?.length > 1 && 's'}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerLevelSelectionModal;
