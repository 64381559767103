import React, { useState } from 'react';

import AppPriceDropdown from './AppPriceDropdown';
import InfoBox from '../../InfoBox';

const AppTypeInput = ({
  appTypePriceIndex,
  appTypeImage,
  appTypeName,
  defaultDiscountType,
  discountValue,
  appTypePriceData,
  setAppTypePriceData,
  onHandleDiscountTypeChange,
  onHandleDiscountValueChange,
  onHandleDecrementDiscountValue,
  onHandleIncrementDiscountValue,
}) => {
  const [discountType, setDiscountType] = useState(defaultDiscountType);

  return (
    <div className="p-3 mt-4 rounded-lg border-[1px] border-gray-x/40">
      <div>
        <div>
          <label
            htmlFor="app-type"
            className="block text-xs font-semibold xs:text-sm font-quicksand text-clinder"
          >
            App Type
          </label>
          <div className="w-full px-4 py-3 mt-2 text-sm font-semibold capitalize border rounded-lg border-gray-x/40 font-quicksand">
            <img src={appTypeImage} alt={appTypeName} />
          </div>
        </div>
      </div>
      <div className="flex p-2 mt-4 rounded-lg bg-neutral-gray gap-[10px]">
        <button
          type="button"
          className={`transition duration-300 ${
            discountType === 'Percentage'
              ? 'bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] font-bold text-clinder'
              : 'font-semibold text-manatee hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:font-bold hover:text-clinder'
          } py-[6px] w-full rounded font-quicksand  text-xs`}
          onClick={() => {
            onHandleDiscountTypeChange(
              appTypePriceIndex,
              'Percentage',
              appTypePriceData,
              setAppTypePriceData
            );
            setDiscountType('Percentage');
          }}
        >
          Percentage
        </button>
        <button
          type="button"
          className={`duration-300 transition ${
            discountType === 'Percentage'
              ? 'font-semibold text-manatee hover:font-bold hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:text-clinder'
              : 'font-bold bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-clinder'
          } py-[6px] w-full rounded font-quicksand text-xs`}
          onClick={() => {
            onHandleDiscountTypeChange(
              appTypePriceIndex,
              'Constant',
              appTypePriceData,
              setAppTypePriceData
            );
            setDiscountType('Constant');
          }}
        >
          Constant
        </button>
      </div>
      {/* percentage value */}
      {discountType === 'Percentage' ? (
        <div className="mt-4">
          <div className="flex flex-col gap-4 mt-2 sm:flex-row lg:flex-1">
            <div className="flex-1">
              <label className="flex flex-none w-[212px] gap-1">
                <span className="text-sm font-semibold text-clinder font-quicksand">
                  Discount Percentage Value
                </span>
                <InfoBox
                  title={'.............................'}
                  content={'............................................'}
                  customLeftPosition={
                    '-left-32 xs:-left-[100px] sm:left-5 md:left-4 md:-left-20 lg:left-5'
                  }
                  customTopPosition={'top-4 sm:-top-3 md:top-3 lg:-top-3'}
                />
              </label>
              <div className="flex items-center p-1 border-[1px] border-gray-x/40 rounded-lg gap-4 mt-2 h-[52px]">
                <div className="flex flex-none gap-2 p-1 rounded-lg bg-neutral-gray ">
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base leading-3 flex-1"
                    onClick={() =>
                      onHandleDecrementDiscountValue(
                        appTypePriceIndex,
                        discountType,
                        discountValue,
                        appTypePriceData,
                        setAppTypePriceData
                      )
                    }
                  >
                    -
                  </button>
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base flex-1"
                    onClick={() =>
                      onHandleIncrementDiscountValue(
                        appTypePriceIndex,
                        discountType,
                        discountValue,
                        appTypePriceData,
                        setAppTypePriceData
                      )
                    }
                  >
                    +
                  </button>
                </div>
                <div className="flex items-center justify-between flex-1 gap-2">
                  <input
                    type="number"
                    className="flex-1 text-sm font-semibold outline-none font-quicksand text-clinder"
                    value={discountValue}
                    onChange={(e) =>
                      onHandleDiscountValueChange(
                        appTypePriceIndex,
                        Number(e.target.value),
                        appTypePriceData,
                        setAppTypePriceData
                      )
                    }
                  />
                  <span className="flex-none px-3 py-1 text-base text-center rounded-lg font-quicksand bg-neutral-gray">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Constant Value
        <div className="mt-4">
          <div className="flex flex-col gap-4 mt-2 sm:flex-row md:flex-col lg:flex-row">
            <div className="flex-1">
              <label className="flex flex-none w-48 gap-1">
                <span className="text-sm font-semibold text-clinder font-quicksand">
                  Discount Constant Value
                </span>
                <InfoBox
                  title={'............'}
                  content={'..................................'}
                  customLeftPosition={
                    '-left-32 xs:-left-[100px] sm:left-5 md:left-4 md:-left-20 lg:left-5'
                  }
                  customTopPosition={'top-4 sm:-top-3 md:top-3 lg:-top-3'}
                />
              </label>
              <div className="flex items-center p-1 border-[1px] border-gray-x/40 rounded-lg gap-4 mt-2 h-[52px]">
                <div className="flex p-1 rounded-lg bg-neutral-gray">
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base flex-1"
                    onClick={() =>
                      onHandleDecrementDiscountValue(
                        appTypePriceIndex,
                        discountType,
                        discountValue,
                        appTypePriceData,
                        setAppTypePriceData
                      )
                    }
                  >
                    -
                  </button>
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base flex-1"
                    onClick={() =>
                      onHandleIncrementDiscountValue(
                        appTypePriceIndex,
                        discountType,
                        discountValue,
                        appTypePriceData,
                        setAppTypePriceData
                      )
                    }
                  >
                    +
                  </button>
                </div>
                <input
                  type="number"
                  className="w-full text-sm font-semibold outline-none font-quicksand text-clinder"
                  value={discountValue}
                  onChange={(e) =>
                    onHandleDiscountValueChange(
                      appTypePriceIndex,
                      Number(e.target.value),
                      appTypePriceData,
                      setAppTypePriceData
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppTypeInput;
