import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const CorlorOptionDropdown = ({
  label,
  background,
  radius,
  width,
  height,
  iconSize,
  optionsWidth,
  optionHeight,
  padding,
  displayedValue,
  backgroundColor,
  fontColor,
  setBackgroundColor,
  setFontColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(label);
  const colors = [
    {
      background: '#FEF5FF',
      font: 'purpletty',
    },
    {
      background: '#F6FEF9',
      font: 'greentty',
    },
    {
      background: '#FEFBF6',
      font: 'orangetty',
    },
    {
      background: '#F6FEF9',
      font: 'redtty',
    },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative ${width}`}>
      <div
        className={`flex items-center justify-between rounded-lg cursor-pointer font-quicksand ${padding} ${height} ${background}`}
        onClick={toggleDropdown}
      >
        <div
          className={`inline px-4 rounded py-2 ${
            displayedValue !== '' && `bg-[${backgroundColor}]`
          }`}
        >
          <span
            className={`text-sm font-semibold  font-quicksand ${
              displayedValue === ''
                ? 'text-gray-x'
                : `text-${fontColor} capitalize`
            } `}
          >
            {displayedValue === '' ? label : displayedValue}
          </span>
        </div>
        <span
          className={`${radius} ${iconSize} flex items-center justify-center bg-white text-clinder shadow-soft flex-none`}
        >
          {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </span>
      </div>
      {isOpen && (
        <div
          className={`absolute right-0 z-10 mt-2 pb-4 rounded-lg  overflow-y-auto bg-neutral-gray ${optionsWidth} ${optionHeight}`}
        >
          {colors.map((color, index) => (
            <div
              key={index}
              className="px-4 py-2 mt-4 text-sm font-medium cursor-pointer font-quicksand"
              onClick={() => {
                setBackgroundColor(color.background);
                setFontColor(color.font);
                setSelectedValue(displayedValue);
                setIsOpen(false);
              }}
            >
              <div
                className={`inline px-4 rounded py-[10px] bg-[${color.background}]`}
              >
                <span
                  className={`capitalize leading-5 text-sm font-semibold font-quicksand  text-${color.font}`}
                >
                  {displayedValue}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CorlorOptionDropdown.defaultProps = {
  background: 'bg-neutral-gray',
  radius: 'rounded-lg',
  height: 'h-14',
  width: 'w-full',
  iconSize: 'w-8 h-8',
  showResultOption: true,
  label: null,
  optionsWidth: 'w-full',
  optionHeight: 'h-auto',
  padding: 'px-4',
};

export default CorlorOptionDropdown;
