import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import axiosInstance from '../../../utils/api/apiConfig';

const DetailBaseProductModal = ({
  isModalOpen,
  setIsModalOpen,
  baseProductId,
}) => {
  const [products, setProducts] = useState([]);
  const [baseProduct, setBaseProduct] = useState({});
  const [isShowAllProduct, setIsShowAllProduct] = useState(false);
  const [isLoadingGetBaseProductById, setIsLoadingGetBaseProductById] =
    useState(false);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const userId = localStorage.getItem('userId');

  // useEffect(() => {
  //   if (Object.keys(baseProduct).length > 0 && baseProduct.productList) {
  //     handleDisplayedProductLimit();
  //   }
  // }, [isModalOpen]);

  // Handle Displayed Product Limit
  useEffect(() => {
    if (baseProductId) {
      getBaseProductById(baseProductId);
    }
  }, [baseProductId]);

  // Handle Get base product by Id
  const getBaseProductById = async (id) => {
    setIsLoadingGetBaseProductById(true);
    try {
      const response = await axiosInstance.get(`/base-products/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setBaseProduct(response?.data?.data);
      setProducts(response?.data?.data?.productList);
      setIsLoadingGetBaseProductById(false);
    } catch (error) {
      // console.log(error);
      setIsLoadingGetBaseProductById(false);
    }
  };

  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          setIsModalOpen(false);
        }}
      />

      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold capitalize md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              {baseProduct?.baseProductName}
            </h2>
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              <AiOutlineClose size={22} />
            </button>
          </div>
          <div className="flex items-center justify-between mt-6">
            <h4 className="text-sm font-quicksand font-semibold text-manatee leading-[150%]">
              Current Stock
            </h4>
            <div className="flex items-center text-sm font-semibold font-quicksand">
              <span className="text-clinder">
                {baseProduct?.stockOnTheInventory}{' '}
                {baseProduct?.unitSystem === 'kilogram'
                  ? 'Kg'
                  : baseProduct?.unitSystem === 'gram'
                  ? 'g'
                  : 'Pcs'}
              </span>
              {baseProduct?.stockOnTheInventory <
                baseProduct?.requiredStock && (
                <span className="px-2 py-1 ml-[4px] text-sm font-semibold rounded font-quicksand text-golden-sun">
                  Need Restock
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mt-9">
          <h3 className="text-sm font-quicksand font-semibold text-clinder leading-[150%]">
            Used in {baseProduct?.totalMenu} Product
          </h3>
          {/* {products.length > 6 && (
            <button
              type="button"
              className="p-4 text-xs font-bold font-quicksand text-aurora-blue"
              onClick={() => {
                setProducts(baseProduct?.products);
              }}
            >
              See All
            </button>
          )} */}
        </div>
        <div className="flex flex-wrap gap-6 mt-9">
          {products?.map((product, index) => (
            <figure key={index} className="w-[155px] h-[198px]">
              <img
                src={product?.productImage}
                alt={product?.productName}
                className="w-full h-[100px] rounded object-cover"
              />
              <h3 className="font-quicksand capitalize text-sm font-semibold leading-[150%]">
                {product?.productName}
              </h3>
              <div className="h-[75px] overflow-hidden">
                <p className="text-manatee leading-[150%] text-xs font-medium font-quicksand line-clamp-3">
                  {product?.productDescription}
                </p>
              </div>
            </figure>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailBaseProductModal;
