import React, { useRef, useState } from 'react';

import SingleIndicatorDropdown from '../../SingleIndicatorDropdown';

import arrowRightWhite from '../../../assets/icons/arrow-right-white.svg';
import close from '../../../assets/icons/close.svg';
import check from '../../../assets/icons/checklist.svg';
import arrowLeftBlue from '../../../assets/icons/arrowLeftBlue.svg';
import calenderBlack from '../../../assets/icons/calendarBlack.svg';
import voucherTemplateFood from '../../../assets/images/voucherTemplateFood.png';
import voucherTemplateFood2 from '../../../assets/images/voucherTemplateFood2.png';
import bettyLogo from '../../../assets/icons/bettyLogo.svg';

const NewVoucherModal = ({ isModalOpen, setIsModalOpen }) => {
  const modalContainerRef = useRef(null);
  const [activeForm, setActiveForm] = useState('general');
  const [voucherName, setVoucherName] = useState('');
  const [voucherCondition, setVoucherCondition] = useState('percentage');
  const [templateColor, setTemplateColor] = useState('bg-orangetty');
  const [voucherTemplate, setVoucherTemplate] = useState('template-1');

  // fake data
  const statusOption = ['option 1', 'option 2'];

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-start justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setActiveForm('general');
          setIsModalOpen(false);
        }}
      />
      <div
        ref={modalContainerRef}
        className={`bg-white w-full md:w-[600px] px-6 md:px-9 pb-6  md:pb-9 h-screen overflow-y-auto flex flex-col gap-6 fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className="sticky top-0 z-50 pt-6 bg-white md:pt-9 md:mx-0">
          <div>
            <div className="flex flex-row-reverse gap-6 md:flex-row justify-end items-center md:justify-between border-b-[1px] border-gray-x/40 md:border-0 -mx-6 px-6 pb-6 md:pb-0">
              <h2 className="text-xl font-semibold md:text-2xl md:font-bold font-quicksand text-aurora-blue">
                Add New Voucher
              </h2>
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsModalOpen(false);
                  setActiveForm('general');
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="text-sm font-medium text-manatee font-satoshi w-10/12 mt-2 leading-[200%] hidden md:block">
              In this window you can set the new voucher and also manage the
              voucher to your restaurant.
            </p>
          </div>
          <div className="flex mt-0 md:mt-4 items-center justify-center md:justify- gap-12 md:gap-8 p-3 border-b-[1px] bg-neutral-gray md:border-b-[2px] border-[#0170a0] -mx-6 md:mx-0">
            <div>
              <div
                className={`hidden md:inline mx-auto text-xs font-semibold font-quicksand py-[5px] rounded-full text-white px-[10px] ${
                  activeForm === 'general' ? 'bg-aurora-blue' : 'bg-lime-green'
                }`}
              >
                1
              </div>
              <span
                className={`text-sm ml-[10px] font-quicksand font-bold ${
                  activeForm === 'general'
                    ? 'text-aurora-blue'
                    : 'text-lime-green'
                }`}
              >
                General
              </span>
            </div>
            <div
              className={`hidden md:block w-3 h-[2px] ${
                activeForm !== 'general' ? 'bg-lime-green' : 'bg-manatee'
              }`}
            />
            <div>
              <div
                className={`hidden md:inline pt-[3px] pb-[5px] mx-auto text-sm font-semibold rounded-full px-[9px] ${
                  activeForm === 'general'
                    ? 'bg-gray-x text-manatee'
                    : activeForm === 'condition'
                    ? 'bg-aurora-blue text-white'
                    : 'bg-lime-green text-white'
                } font-quicksand`}
              >
                2
              </div>
              <span
                className={`text-sm ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'condition'
                    ? 'text-aurora-blue'
                    : activeForm === 'template'
                    ? 'text-lime-green'
                    : 'text-manatee'
                }`}
              >
                Condition
              </span>
            </div>
            <div className="hidden md:block w-3 bg-manatee h-[2px]" />
            <div>
              <span
                className={`hidden md:inline py-1 px-[9px] mx-auto text-xs font-semibold rounded-full ${
                  activeForm === 'template'
                    ? 'bg-aurora-blue text-white'
                    : 'bg-gray-x text-manatee'
                } text-manatee font-quicksand`}
              >
                3
              </span>
              <span
                className={`text-sm ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'template'
                    ? 'text-aurora-blue'
                    : 'text-manatee'
                }`}
              >
                Template
              </span>
            </div>
          </div>
        </header>

        <form className="flex-1">
          {/* General Form  */}
          {activeForm === 'general' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div>
                  <label
                    htmlFor="voucher-nama"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Voucher Name
                  </label>
                  <input
                    maxLength={'16'}
                    type="text"
                    id="voucher-nama"
                    value={voucherName}
                    placeholder="Create Voucher Name"
                    required
                    className="w-full px-4 mt-2 capitalize h-14 focusable-input"
                    onChange={(e) => setVoucherName(e.target.value)}
                  />
                  <span className="block text-xs text-manatee font-satoshi font-medium leading-[200%]">
                    {voucherName.length}/16
                  </span>
                </div>
                <div className="flex gap-4 mt-4">
                  <div className="w-full">
                    <label
                      htmlFor="start-voucher-date"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Start Voucher Date
                    </label>
                    <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                      <input
                        type="text"
                        id="start-voucher-date"
                        placeholder="Pick Voucher Date"
                        required
                        className="w-full h-full text-sm font-bold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none"
                      />
                      <figure className="p-2 bg-white rounded-lg">
                        <img
                          src={calenderBlack}
                          alt="calender"
                          className="w-4 h-4"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="expired-voucher-date"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Expired Voucher Date
                    </label>
                    <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                      <input
                        type="text"
                        id="expired-voucher-date"
                        placeholder="Pick Voucher Date"
                        required
                        className="w-full h-full text-sm font-bold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none"
                      />
                      <figure className="p-2 bg-white rounded-lg">
                        <img
                          src={calenderBlack}
                          alt="calender"
                          className="w-4 h-4"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="voucher-kuota"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Voucher Kuota
                  </label>
                  <input
                    type="text"
                    id="voucher-kuota"
                    placeholder="Enter Voucher Kuota"
                    required
                    className="w-full px-4 mt-2 h-14 focusable-input"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="voucher-type"
                    className="block mb-2 text-sm font-semibold font-quicksand"
                  >
                    Voucher Roles
                  </label>
                  <SingleIndicatorDropdown
                    label={'Pick Voucher Target'}
                    options={statusOption}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full mb-10 mt-9 xl:mb-0 button-primary-lg"
                onClick={() => {
                  setActiveForm('condition');
                  modalContainerRef.current.scrollTop = 0;
                }}
              >
                <span className="text-base font-bold text-white font-quicksand">
                  Next
                </span>
                <img src={arrowRightWhite} alt="arrow right white" />
              </button>
            </div>
          )}

          {/* Condition Form */}
          {activeForm === 'condition' && (
            <div className="flex flex-col justify-between h-full">
              <div className="pb-[100px]">
                <div className="p-2 rounded-lg bg-neutral-gray flex gap-[10px]">
                  <button
                    type="button"
                    className={`w-full py-[6px] px-3 text-xs font-quicksand transition duration-300 ${
                      voucherCondition === 'percentage'
                        ? 'bg-white rounded text-clinder font-bold shadow-subtle-glow'
                        : 'text-manatee font-semibold hover:bg-white hover:rounded hover:text-clinder hover:font-bold hover:shadow-subtle-glow'
                    }`}
                    onClick={() => setVoucherCondition('percentage')}
                  >
                    Percentage
                  </button>
                  <button
                    type="button"
                    className={`w-full py-[6px] px-3 text-xs font-quicksand transition duration-300 ${
                      voucherCondition === 'constant'
                        ? 'bg-white rounded text-clinder font-bold shadow-subtle-glow'
                        : 'text-manatee font-semibold hover:bg-white hover:rounded hover:text-clinder hover:font-bold hover:shadow-subtle-glow'
                    }`}
                    onClick={() => setVoucherCondition('constant')}
                  >
                    Constant
                  </button>
                </div>
                {voucherCondition === 'percentage' ? (
                  <div className="mt-4 h-[85px]">
                    <label
                      htmlFor="percentage-value"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Percentage Value
                    </label>
                    <div className="relative mt-2">
                      <input
                        type="text"
                        id="percentage-value"
                        placeholder="Enter Percentage Value"
                        required
                        className="absolute w-full capitalize h-14 focusable-input pr-[69px] pl-4"
                      />
                      <span className="absolute px-3 py-1 text-base font-semibold bg-white rounded-lg font-quicksand right-4 top-3">
                        %
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 h-[85px]">
                    <label
                      htmlFor="constant-value"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Constant Value
                    </label>
                    <div className="relative mt-2">
                      <span className="absolute z-10 px-3 py-1 text-base font-semibold bg-white rounded-lg font-quicksand left-4 top-3">
                        IDR
                      </span>
                      <input
                        type="text"
                        id="constant-value"
                        placeholder="Enter Constant Value"
                        required
                        className="absolute w-full capitalize h-14 bg-neutral-gray focusable-input pl-[83px] pr-4"
                      />
                    </div>
                  </div>
                )}
                <div className="mt-4 h-[85px]">
                  <label
                    htmlFor="minimum-transaction"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Minimum Transaction
                  </label>
                  <div className="relative mt-2">
                    <span className="absolute z-10 px-3 py-1 text-base font-semibold bg-white rounded-lg font-quicksand left-4 top-3">
                      IDR
                    </span>
                    <input
                      type="text"
                      id="minimum-transaction"
                      placeholder="Enter Minimum Transaction"
                      required
                      className="absolute w-full capitalize h-14 bg-neutral-gray focusable-input pl-[83px] pr-4"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-[10px] mt-9 mb-10 xl:mb-0">
                <button
                  type="button"
                  className="w-1/2 button-secondary-lg"
                  onClick={() => {
                    setActiveForm('general');
                    modalContainerRef.current.scrollTop = 0;
                  }}
                >
                  <img
                    src={arrowLeftBlue}
                    alt="arrow left"
                    className="w-4 h-4"
                  />
                  <span className="text-base font-bold text-aurora-blue font-quicksand">
                    Back
                  </span>
                </button>
                <button
                  type="button"
                  className="w-1/2 button-primary-lg"
                  onClick={() => {
                    setActiveForm('template');
                    modalContainerRef.current.scrollTop = 0;
                  }}
                >
                  <span className="text-base font-bold text-white font-quicksand">
                    Next
                  </span>
                  <img src={arrowRightWhite} alt="arrow right white" />
                </button>
              </div>
            </div>
          )}

          {/* Template Section */}
          {activeForm === 'template' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div className="relative z-10">
                  <h3 className="text-sm font-semibold text-clinder font-quicksand">
                    Color
                  </h3>
                  <div className="flex gap-3 mt-2">
                    <button
                      type="button"
                      className="w-6 h-6 rounded-full bg-orangetty"
                      onClick={() => setTemplateColor('bg-orangetty')}
                    >
                      <img
                        src={check}
                        alt="check"
                        className={`p-[5px] ${
                          templateColor === 'bg-orangetty' ? 'block' : 'hidden'
                        }`}
                      />{' '}
                    </button>
                    <button
                      type="button"
                      className="w-6 h-6 rounded-full bg-redtty"
                      onClick={() => setTemplateColor('bg-redtty')}
                    >
                      <img
                        src={check}
                        alt="check"
                        className={`p-[5px] ${
                          templateColor === 'bg-redtty' ? 'block' : 'hidden'
                        }`}
                      />{' '}
                    </button>
                    <button
                      type="button"
                      className="w-6 h-6 rounded-full bg-bluetty"
                      onClick={() => setTemplateColor('bg-bluetty')}
                    >
                      <img
                        src={check}
                        alt="check"
                        className={`p-[5px] ${
                          templateColor === 'bg-bluetty' ? 'block' : 'hidden'
                        }`}
                      />{' '}
                    </button>
                    <button
                      type="button"
                      className="w-6 h-6 rounded-full bg-greentty"
                      onClick={() => setTemplateColor('bg-greentty')}
                    >
                      <img
                        src={check}
                        alt="check"
                        className={`p-[5px] ${
                          templateColor === 'bg-greentty' ? 'block' : 'hidden'
                        }`}
                      />
                    </button>
                    <button
                      type="button"
                      className="w-6 h-6 rounded-full bg-purpletty"
                      onClick={() => setTemplateColor('bg-[#640099]')}
                    >
                      <img
                        src={check}
                        alt="check"
                        className={`p-[5px] ${
                          templateColor === 'bg-[#640099]' ? 'block' : 'hidden'
                        }`}
                      />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <div
                    className={`h-20 md:h-[124px] w-full flex ${templateColor}`}
                  >
                    <div className="py-1">
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                    </div>
                    <div className="relative w-full h-full px-[10px] py-2">
                      <div className="absolute right-0 -top-11 rounded-[262px] bg-white opacity-40 w-[262px] h-[103px] blur-[50px]" />
                      <div className="flex w-full h-full overflow-hidden rounded bg-white/20">
                        <div className="flex justify-between w-full px-2 py-1">
                          <div className="flex flex-col justify-between">
                            <p className="font-sans text-[10px] md:text-base font-bold text-white">
                              Voucher Grand Opening
                            </p>
                            <p className="text-white text-[18px] md:text-[28px] font-bold font-sans">
                              {voucherCondition === 'percentage'
                                ? '50% OFF'
                                : 'IDR 100,000'}
                            </p>
                            <p className="text-white/60 text-[5px] md:text-[8px] font-medium">
                              Valid until Januari 18th 2022 | Min. Transaction
                              IDR 200,000{' '}
                            </p>
                          </div>
                          <figure className="mt-0 md:mt-1 overflow-hidden bg-white rounded-lg w-12 md:w-[83px] h-full md:h-[90px] flex-none">
                            <img
                              src={
                                'https://cdn.discordapp.com/attachments/1093818778107793489/1132144397648138372/Frame_3054.png'
                              }
                              alt="QR code"
                              className="w-full h-[45px] md:h-[76px]"
                            />
                            <figcaption className="text-purpletty text-[5px] md:text-[8.5px] font-bold font-sans capitalize text-center">
                              {' '}
                              resto pak min
                            </figcaption>
                          </figure>
                        </div>
                        <div className="w-[130px] md:w-[200px] relative h-full bg-[#4B4B4B]/20  border-dashed border-l-[1px] border-white/30">
                          <img
                            src={voucherTemplateFood}
                            alt="voucher template food"
                            className="absolute right-0 z-10 w-[30px] md:w-[45px]"
                          />
                          <img
                            src={voucherTemplateFood2}
                            alt="voucher template food"
                            className="absolute bottom-0 right-0 z-20 w-[60px] md:w-[118px]"
                          />
                          <div className="absolute bottom-1 right-1 z-30 bg-white rounded-[90px] px-2 py-[2px] flex text-[8px] items-center">
                            <span className="font-sans font-bold text-[5px] md:text-[8px]">
                              Powered By
                            </span>{' '}
                            <img
                              src={bettyLogo}
                              alt="betty"
                              className="ml-1 h-[8.6px] mr-[1.5px] w-[6.2px]"
                            />{' '}
                            <span className="font-semibold font-quicksand text-[5px] md:text-[9.8px] text-aurora-blue">
                              betty
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="py-1">
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`w-full px-4 py-2 md:py-3 bg-ivory-cream mt-4 border-[1px] rounded-lg ${
                      voucherTemplate === 'template-1'
                        ? 'border-lime-green'
                        : 'border-grad-bluetty/30'
                    }`}
                    onClick={() => setVoucherTemplate('template-1')}
                  >
                    <span
                      className={`text-sm font-quicksand font-bold ${
                        voucherTemplate === 'template-1'
                          ? 'text-lime-dark'
                          : 'text-aurora-blue'
                      }`}
                    >
                      {voucherTemplate === 'template-1'
                        ? 'Selected'
                        : 'Select this template'}
                    </span>
                  </button>
                </div>
                <div className="mt-6">
                  <div
                    className={`h-20 md:h-[124px] w-full flex ${templateColor} `}
                  >
                    <div className="py-1">
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-r-full shadow-[inset_-1px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                    </div>
                    <div className="relative w-full h-full px-[10px] py-2">
                      <div className="absolute right-0 -top-11 rounded-[262px] bg-white opacity-40 w-[262px] h-[103px] blur-[50px]" />
                      <div className="flex w-full h-full overflow-hidden ">
                        <div className="flex justify-between w-full pl-2 rounded-l bg-white/20">
                          <div className="flex flex-col justify-between py-1">
                            <p className="text-white text-[10px] md:text-base font-bold font-sans">
                              Voucher Grand Opening
                            </p>
                            <p className="mt-[9px] text-white text-[18px] md:text-[28px] font-bold font-sans">
                              {voucherCondition === 'percentage'
                                ? '50% OFF'
                                : 'IDR 100,000'}
                            </p>
                            <p className="text-white/60 text-[5px] md:text-[8px] font-medium">
                              Valid until Januari 18th 2022 | Min. Transaction
                              IDR 200,000{' '}
                            </p>
                          </div>
                          <div className="w-[80px] md:w-[140px] relative h-full">
                            <img
                              src={voucherTemplateFood}
                              alt="voucher template food"
                              className="absolute right-0 z-10 w-[30px] md:w-[45px]"
                            />
                            <img
                              src={voucherTemplateFood2}
                              alt="voucher template food"
                              className="absolute bottom-0 right-0 z-20 w-[60px] md:w-[118px]"
                            />
                            <div className="absolute top-1 right-1 z-30 bg-white rounded-[90px] px-2 py-[2px] flex text-[8px] items-center">
                              <span className="font-sans font-bold text-[5px] md:text-[8px]">
                                Powered By
                              </span>{' '}
                              <img
                                src={bettyLogo}
                                alt="betty"
                                className="ml-1 h-[8.6px] mr-[1.5px] w-[6.2px]"
                              />{' '}
                              <span className="font-semibold font-quicksand text-[6px] md:text-[9.8px] text-aurora-blue">
                                betty
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="pt-1 md:pt-[9px] px-1 md:px-3 rounded-r bg-[#4B4B4B]/20">
                          <figure className="overflow-hidden bg-white rounded-lg w-[48px] md:w-[83px]">
                            <img
                              src={
                                'https://cdn.discordapp.com/attachments/1093818778107793489/1132144397648138372/Frame_3054.png'
                              }
                              alt="QR code"
                              className="w-full h-[45px] md:h-[76px]"
                            />
                            <figcaption className="text-purpletty text-[5px] md:text-[8.5px] font-bold font-sans capitalize text-center mt-[2px] mb-[2px]">
                              {' '}
                              resto pak min
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="py-1">
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                      <div className="w-[5px] h-[10px] md:w-2 md:h-4 mt-[2.5px] md:mt-1 bg-white rounded-l-full shadow-[inset_0px_4px_0.5px_0.1px_rgba(0,0,0,0.1)]" />
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`w-full px-4 py-2 md:py-3 bg-ivory-cream mt-4 border-[1px] rounded-lg ${
                      voucherTemplate === 'template-2'
                        ? 'border-lime-green'
                        : 'border-grad-bluetty/30'
                    }`}
                    onClick={() => setVoucherTemplate('template-2')}
                  >
                    <span
                      className={`text-sm font-quicksand font-bold ${
                        voucherTemplate === 'template-2'
                          ? 'text-lime-dark'
                          : 'text-aurora-blue'
                      }`}
                    >
                      {voucherTemplate === 'template-2'
                        ? 'Selected'
                        : 'Select this template'}
                    </span>
                  </button>
                </div>
              </div>
              <div className="flex gap-[10px] mt-9 mb-10 xl:mb-0">
                <button
                  type="button"
                  className="w-2/5 md:w-1/2 button-secondary-lg"
                  onClick={() => {
                    setActiveForm('condition');
                    modalContainerRef.current.scrollTop = 0;
                  }}
                >
                  <img
                    src={arrowLeftBlue}
                    alt="arrow left"
                    className="w-4 h-4"
                  />
                  <span className="text-base font-bold text-aurora-blue font-quicksand">
                    Back
                  </span>
                </button>
                <button
                  type="button"
                  className="w-3/5 gap-3 md:w-1/2 button-primary-lg"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setIsModalOpen(false);
                    setActiveForm('general');
                  }}
                >
                  <span className="text-base font-bold text-white font-quicksand">
                    Create Voucher
                  </span>
                  <img src={arrowRightWhite} alt="arrow right white" />
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default NewVoucherModal;
