import React, { useState } from 'react';

import searchBlue from '../../../assets/icons/searchBlue.svg';
import plus from '../../../assets/icons/plus.svg';
import dots from '../../../assets/icons/dots.svg';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';
import DataFilterEmptyInfo from '../../DataFilterEmptyInfo';
import DataApiEmptyInfo from '../../DataApiEmptyInfo';

const Notes = ({
  displayedNotes,
  allNotes,
  setIsAddNotesModal,
  onDeleteNote,
  noteId,
  setNoteId,
}) => {
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  // handle format date to Indonesian formate date
  function formatDateToIndonesian(tanggalAwal) {
    const date = new Date(tanggalAwal);

    const namaBulan = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ];

    const tanggal = date.getDate();
    const bulan = date.getMonth();
    const tahun = date.getFullYear();

    const tanggalFormat = `${tanggal} ${namaBulan[bulan]} ${tahun}`;
    return tanggalFormat;
  }

  return (
    <>
      <div className="flex gap-4 px-6 py-2 border-b-[1px] border-gray-x md:hidden sticky top-[164px] bg-white z-20">
        <img src={searchBlue} alt="search" />
        <input
          type="text"
          placeholder="Find your notes here"
          className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
        />
        <button
          type="button"
          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
          onClick={() => setIsAddNotesModal(true)}
        >
          <img src={plus} alt="add" className="mx-auto" />
        </button>
      </div>
      <div className="pt-6">
        {/* If notes is not empty */}
        {displayedNotes?.length > 0 && (
          <div className="flex flex-wrap gap-4 px-6 pb-4 xl:gap-5 2xl:gap-3 md:px-0">
            {displayedNotes.map((note, index) => (
              <div
                key={index}
                className="bg-white h-[130px] border-[0.5px] p-[10px] relative border-gray-x w-[236px] rounded-lg flex gap-[10px] justify-between"
              >
                <div className="flex flex-col justify-between flex-1">
                  <div>
                    {note?.Product?.name && (
                      <h5 className="mb-1 text-xs font-medium capitalize font-quicksand text-aurora-blue">
                        {note?.Product?.name}
                      </h5>
                    )}
                    <p className="mt-2 text-sm font-semibold normal-case font-quicksand">
                      {note?.additionalNotes}
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <p className="text-xs font-medium font-satoshi text-manatee">
                      {formatDateToIndonesian(note?.createdAt)}
                    </p>
                    {note?.productCategory?.name && (
                      <div className="w-20 px-2 py-1 overflow-hidden rounded bg-tender-rose">
                        <p className="text-xs text-center truncate text-grad-purpletty font-quicksand">
                          {note?.productCategory?.name}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  className="bg-black/20 blur-5 py-[6px] px-1 h-[13px] absolute top-[10px] right-[10px] rounded-sm"
                  onClick={() => {
                    setIsDeleteConfirmationModalOpen(true);
                    setNoteId(note?.id);
                  }}
                >
                  <img src={dots} alt="dots" />
                </button>
              </div>
            ))}
          </div>
        )}

        {/* If no notes matched with the search filter and filtering by date criteria */}
        {allNotes?.length !== 0 && displayedNotes?.length === 0 && (
          <DataFilterEmptyInfo
            emptyInfoHeading={'Sorry, No Results Found'}
            emptyInfoDescription={
              "Well, we've searched high and low, but it seems like what your're looking for isn't our database (yet!)"
            }
          />
        )}

        {/* If notes data on database is empty */}
        {allNotes?.length === 0 && (
          <DataApiEmptyInfo
            emptyInfoHeading={'Oops, Nothing Here Yet!'}
            firstEmptyInfoDescription={
              'Looks like this space is feeling a bit lonely right now. Why not kick things off by adding some info?'
            }
            secondEmptyInfoDescription={
              "Just hit that 'Add Notes' button, and let the good times (and data) roll!"
            }
          />
        )}

        {/* delete note modal */}
        <DeleteConfirmationModal
          isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
          setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
          shortDeleteConfirmatonMessage={'Delete this additional notes?'}
          longDeleteConfirmatonMessage={
            'Are you sure you want to delete this additional note?'
          }
          longDeleteButtonLabel={'Yes, Delete Additional Note'}
          onDelete={onDeleteNote}
          id={noteId}
        />
      </div>
    </>
  );
};

export default Notes;
