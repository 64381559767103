import React from 'react';

import close from '../../../assets/icons/close.svg';

const ScreenCashierTemplateModal = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-center justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => setIsModalOpen(false)}
      />
      <div
        className={`p-9 bg-white w-[600px] overflow-y-auto fixed top-0 right-0 z-50 transition duration-300 min-h-screen ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header>
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold text-aurora-blue">
              ScreenCashierTemplateModal
            </h2>
            <button onClick={() => setIsModalOpen(false)}>
              <img src={close} alt="close" />
            </button>
          </div>
        </header>
        <main className="flex-1">
          <p className="mx-auto text-2xl font-bold text-center mt-60 font-quicksand">
            Coming Soon
          </p>
        </main>
      </div>
    </>
  );
};

export default ScreenCashierTemplateModal;
