import React from 'react';

import trashRed from '../../../assets/icons/trashRed.svg';

const NormalPriceForm = ({
  menuName,
  normalPrice,
  priceWithTaxNormalPrice,
  menuIndex,
  onHandleNormalPriceChange,
  onHandleRemoveMenu,
}) => {
  return (
    <div className="p-3 mt-4 border rounded-lg border-gray-x/40">
      <div className="flex items-center justify-between">
        <h4 className="text-base font-semibold capitalize text-clinder font-quicksand">
          {menuName}
        </h4>
        <button
          type="button"
          className="p-2 rounded bg-coral"
          onClick={() => onHandleRemoveMenu(menuIndex)}
        >
          <img src={trashRed} alt="delete" className="w-3 h-3" />
        </button>
      </div>
      <div className="mt-4">
        <label
          htmlFor="price-normalprice"
          className="text-sm font-semibold text-clinder font-quicksand"
        >
          Price
        </label>
        <input
          id="price-normalprice"
          type="number"
          placeholder="12.000"
          name="price"
          value={normalPrice}
          required
          className="w-full px-4 py-3 mt-2 focusable-input"
          onChange={(e) => onHandleNormalPriceChange(menuIndex, e)}
        />
      </div>
      <div className="mt-4">
        <label
          htmlFor="price-withtax"
          className="text-sm font-semibold text-clinder font-quicksand"
        >
          Price with Tax
        </label>
        <input
          id="price-withtax"
          type="number"
          required
          disabled
          placeholder={priceWithTaxNormalPrice}
          defaultValue={priceWithTaxNormalPrice}
          className="w-full px-4 py-3 mt-2 focusable-input"
        />
      </div>
      {/* <div className="mt-4">
        <label
          htmlFor="profit-normalprice"
          className="text-sm font-semibold text-clinder font-quicksand"
        >
          Profit
        </label>
        <input
          id="profit-normalprice"
          type="number"
          disabled
          required
          placeholder="3.200"
          value={'3.200'}
          className="w-full px-4 py-3 mt-2 focusable-input"
          onChange={(e) => setProfitNormalPrice(e.target.value)}
        />
      </div> */}
    </div>
  );
};

export default NormalPriceForm;
