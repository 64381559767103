import React, { useEffect, useState } from 'react';

import DaysOptionDropdown from './DaysOptionDropdown';

import close from '../../../assets/icons/close.svg';
import search from '../../../assets/icons/search.svg';
import plus from '../../../assets/icons/plus.svg';
import axiosInstance from '../../../utils/api/apiConfig';
import SelectionFovoriteMenuButton from './SelectionFovoriteMenuButton';

const NewFavoriteMenuModal = ({ isModalOpen, setIsModalOpen }) => {
  // === Favorite Menus Data ===
  const [favoriteMenus, setFavoriteMenus] = useState([]);
  const [searchMenu, setSearchMenu] = useState('');
  const [choosedDay, setChoosedDay] = useState('');
  const [choosedDayId, setChoosedDayId] = useState(null);
  const days = [
    {
      id: 1,
      value: 'monday',
    },
    {
      id: 2,
      value: 'tuesday',
    },
    {
      id: 3,
      value: 'wednesday',
    },
    {
      id: 4,
      value: 'thursday',
    },
    {
      id: 5,
      value: 'friday',
    },
    {
      id: 6,
      value: 'saturday',
    },
    {
      id: 7,
      value: 'sunday',
    },
  ];

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const creator = localStorage.getItem('userFullName');

  // fake data
  const menu = [
    {
      id: '1',
      menuName: 'Nasi Liwet',
      kategori: 'Rice Food',
      status: true,
    },
    {
      id: '2',
      menuName: 'Nasi Goreng',
      kategori: 'Rice Food',
      status: false,
    },
    {
      id: '3',
      menuName: 'nasi langgi',
      kategori: 'rice food',
      status: true,
    },
    {
      id: '4',
      menuName: 'nasikuning',
      kategori: 'rice food',
      status: false,
    },
    {
      id: '5',
      menuName: 'nasi opor',
      kategori: 'rice food',
      status: true,
    },
    {
      id: '6',
      menuName: 'nasi botok',
      kategori: 'rice food',
      status: false,
    },
    {
      id: '7',
      menuName: 'nasi wader',
      kategori: 'rice food',
      status: true,
    },
    {
      id: '8',
      menuName: 'nasi godhog',
      kategori: 'rice food',
      status: false,
    },
    {
      id: '9',
      menuName: 'nasi jotos',
      kategori: 'rice food',
      status: false,
    },
    {
      id: '10',
      menuName: 'nasi gandhul',
      kategori: 'rice food',
      status: true,
    },
  ];

  // handle search menu
  const searchFilter = (data) => {
    return data.filter((menu) =>
      menu.name.toLowerCase().includes(searchMenu.toLocaleLowerCase())
    );
  };

  // Handle Get Favorite Menu by Day
  useEffect(() => {
    getFavoriteMenuByDay();
  }, [choosedDay]);
  const getFavoriteMenuByDay = async () => {
    try {
      const response = await axiosInstance.get(`/favorites/list`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setFavoriteMenus(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
          setChoosedDayId(null);
          setChoosedDay('');
        }}
      />
      <div
        className={`bg-white w-full md:w-[600px]  overflow-y-auto h-screen fixed top-0 overflow-x-hidden right-0 z-50 transition duration-300 ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className="bg-white w-full md:w-[600px] sticky top-0 pt-6 lg:pt-9 px-0 md:px-6 lg:px-9 z-20">
          <div className="border-b-[1px] border-gray-x/40 pb-6">
            <div className="flex flex-row-reverse justify-end w-full gap-6 px-6 md:px-0 md:flex-row md:justify-between">
              <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                Add New Favorite Menu
              </h2>
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsModalOpen(false);
                  setChoosedDayId(null);
                  setChoosedDay('');
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="text-sm font-medium text-manatee font-satoshi w-[400px] leading-7 hidden md:block">
              In this window, you can change and add favorite menus to your
              restaurant every day.
            </p>
          </div>
        </header>
        <main className="p-6 lg:p-9">
          <div>
            <p className="mb-2 text-sm font-semibold font-quicksand">Day</p>
            <DaysOptionDropdown
              label={'Choose the day'}
              options={days}
              selectedValue={choosedDay}
              setSelectedId={setChoosedDayId}
              setSelectedValue={setChoosedDay}
            />
          </div>
          <div
            className={`border-[1px] border-gray-x/40 rounded p-3 mt-[15px] ${
              choosedDay === '' ? 'hidden' : 'block'
            }`}
          >
            <div className="flex items-center justify-between">
              <label
                htmlFor="search-menu"
                className="text-sm font-semibold font-quicksand"
              >
                Search Menu
              </label>
              <p className="block text-sm font-semibold md:hidden text-manatee font-quicksand">
                {menu.filter((x) => x.status === true).length} Menu Selected
              </p>
            </div>
            <div className="relative mt-2">
              <img
                src={search}
                alt="search"
                className="absolute top-[10px] left-4"
              />
              <input
                type="text"
                placeholder="Choose Favorite Menu"
                className={`w-full py-2 pr-4 focusable-input-unrequired pl-11 ${
                  searchMenu === '' ? 'bg-neutral-gray' : 'bg-dew'
                }`}
                onChange={(e) => setSearchMenu(e.target.value)}
              />
            </div>
            {/* table of favorite menu */}
            <div className="p-3 mt-3 border-0 md:border-b-[1px]  md:border-gray-x/40">
              <table className="w-full mt-3 table-auto border-coolapse">
                <thead className="bg-neutral-gray">
                  <tr>
                    <th
                      id="manu-name"
                      className="w-1/3 py-2 pl-2 text-sm font-medium text-center md:text-start font-quicksand text-manatee"
                    >
                      Menu Name
                    </th>
                    <th
                      id="category"
                      className="hidden w-1/3 text-sm font-medium text-start text-manatee font-quicksand md:table-cell"
                    >
                      Category
                    </th>
                    <th
                      id="status"
                      className="w-1/3 pr-2 text-sm font-medium text-center md:text-start text-manatee font-quicksand"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {searchFilter(favoriteMenus).map((menu, index) => (
                    <tr key={index} className="px-8">
                      <td
                        aria-labelledby="menu-name"
                        className="py-3 pl-2 text-sm font-semibold leading-5 text-center capitalize font-quicksand text-clinder md:text-start"
                      >
                        {menu?.name}
                      </td>
                      <td
                        aria-labelledby="kategori"
                        className="hidden py-3 text-sm font-semibold leading-5 text-center capitalize font-quicksand text-clinder md:table-cell md:text-start"
                      >
                        {menu?.productCategoryName}
                      </td>
                      <td
                        aria-labelledby="status"
                        className="text-center md:text-start"
                      >
                        <SelectionFovoriteMenuButton
                          choosedDayId={choosedDayId}
                          choosedDay={choosedDay}
                          menuDay={menu.selectedDay}
                          productId={menu?.productId}
                          onGetFavoriteMenuByDay={getFavoriteMenuByDay}
                          isFavorites={menu.isFavorites}
                          favoritesProductId={
                            menu?.isFavorites
                              ? menu?.favoritesProductId[0][choosedDay]
                              : null
                          }
                          index={index}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="hidden mt-3 md:block">
              <p className="text-sm font-semibold text-manatee font-quicksand">
                {menu.filter((x) => x.status === true).length} Menu Selected
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default NewFavoriteMenuModal;
