import React, { useState } from "react";

import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";

import preferencesBanner from "../../assets/images/preferencesBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";
import UnderConstructionBanner from "../../components/banner/UnderConstructionBanner";

const RestaurantPreferences = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [activeTopNav, setActiveTopNav] = useState("preferences");
    const topNavs = [
        {
            nav: "Employee",
            value: "employee",
            route: "admin-restaurant-employee",
        },
        {
            nav: "Preferences",
            value: "preferences",
            route: "admin-restaurant-preferences",
        },
        {
            nav: "Screen Cashier",
            value: "screen-cashier",
            route: "admin-restaurant-screen-cashier",
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] sticky top-0 z-50 bg-white h-[86px] 2xl:w-[200px] flex-none">
                <SideBar isRestaurant={true} />
            </aside>

            <div className="flex flex-col w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Restaurant"}
                    menuDescription={
                        "You can manage and organize your restaurant and other things here"
                    }
                    topNavs={topNavs}
                />
                <main>
                    <Banner
                        title="Preferences"
                        description="On this menu you will be able to create, edit, and also delete preferences about restaurant. Also you can manage it easily."
                        showOpenModalButton={false}
                        searchPlaceholder={"Find your base product here"}
                        image={preferencesBanner}
                        showPrintButton={false}
                        shortPrintButtonLabel={"Print List"}
                        longPrintButtonLabel={"Print List of Base Products"}
                        isDisable={true} // disabled due to unready feature
                    />

                    {/* Navbar for Mobile & Tablet */}
                    <TopNavbar
                        topNavs={topNavs}
                        activeTopNav={activeTopNav}
                        setActiveTopNav={setActiveTopNav}
                    />

                    {/* Fake Content */}
                    {/* <p className="text-2xl font-bold text-center mt-36 font-quicksand 2xl:text-3xl">
                        Coming Soon
                    </p> */}

                    <UnderConstructionBanner />
                </main>
            </div>
        </div>
    );
};

export default RestaurantPreferences;
