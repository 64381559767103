import React from "react";

import DoubleIndicatorDropdown from "../../../components/DoubleIndicatorDropdown";
import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import edit from "../../../assets/icons/edit.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import SingleIndicatorDropdown from "../../SingleIndicatorDropdown";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const TabletDesktopEmployeeTable = ({
    employeeData,
    employeeNameOptions,
    roleOptions,
    createdByOptions,
    statusOptions,
}) => {
    return (
        <div className="hidden mt-6 mb-3 md:block">
            <table className="w-full table-auto border-collaplse">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th id="employee-name" className="w-[29.11%] lg:w-[22.75%] xl:w-1/5">
                            <DoubleIndicatorDropdown options={employeeNameOptions} />
                        </th>
                        <th id="role" className="w-[29.11%] lg:w-[22.75%] xl:w-1/5">
                            <DoubleIndicatorDropdown options={roleOptions} />
                        </th>
                        <th
                            id="created-by"
                            className="w-[29.11%] lg:w-[22.75%] xl:w-1/5 hidden lg:table-cell"
                        >
                            <DoubleIndicatorDropdown options={createdByOptions} />
                        </th>
                        <th id="status" className="w-[29.11%] lg:w-[22.75%] xl:w-1/5">
                            <DoubleIndicatorDropdown options={statusOptions} />
                        </th>
                        <th
                            id="action"
                            className="text-sm font-medium font-quicksand text-manatee text-start w-[12.66%] lg:w-[9.01%] xl:w-1/5"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {employeeData.map((employee, index) => {
                        let roleTextColor = "";
                        let roleBackground = "";

                        if (employee.role === "food quality") {
                            roleTextColor = "text-[#9100ff]";
                            roleBackground = "bg-[#FEF5FF]";
                        } else if (employee.role === "food tester") {
                            roleTextColor = "text-[#27c984]";
                            roleBackground = "bg-[#F6FEF9]";
                        } else if (employee.role === "cashier") {
                            roleTextColor = "text-[#ef9f48]";
                            roleBackground = "bg-[#FEFBF6]";
                        } else {
                            roleTextColor = "text-[#3fa0ef]";
                            roleBackground = "bg-[#F6FCFE]";
                        }
                        return (
                            <tr key={index}>
                                <td
                                    aria-labelledby="employee-name"
                                    className="px-[10px] w-[21.23%]"
                                >
                                    <div className="flex items-center gap-3 py-[10px]">
                                        <figure className="overflow-hidden rounded-full w-9 h-9">
                                            <img
                                                src={employee.profilePicture}
                                                alt={employee.name}
                                            />
                                        </figure>
                                        <div>
                                            <h3 className="text-sm font-semibold text-clinder font-quicksand leading-[150%] capitalize">
                                                {employee.name}
                                            </h3>
                                            <h4 className="text-xs font-normal text-manatee font-satoshi leading-[200%]">
                                                {employee.noHP}
                                            </h4>
                                        </div>
                                    </div>
                                </td>
                                <td aria-labelledby="role" className="px-[10px]w-[15.10%]">
                                    <p
                                        className={`py-2 text-sm font-semibold text-center capitalize ${roleBackground} ${roleTextColor} rounded font-quicksand`}
                                    >
                                        {employee.role}
                                    </p>
                                </td>
                                <td
                                    aria-labelledby="created-by"
                                    className="px-[10px] w-[21.23%] hidden lg:table-cell"
                                >
                                    <div className="flex items-center gap-3 py-[10px] ">
                                        <figure className="w-10 h-10 overflow-hidden rounded-full lg:table-cell">
                                            <img
                                                src={employee.createdBy.profilePicture}
                                                alt={employee.createdBy.name}
                                            />
                                        </figure>
                                        <div>
                                            <h3 className="text-sm font-semibold capitalize text-clinder font-quicksand leading-[150%]">
                                                {employee.createdBy.name}
                                            </h3>
                                            <h4 className="text-xs font-normal text-manatee font-satoshi leading-[200%]">
                                                {employee.createdBy.role}
                                            </h4>
                                        </div>
                                    </div>
                                </td>
                                <td aria-labelledby="status" className="px-[10px] w-[21.23%]">
                                    <div
                                        className={`px-3 py-[10px] flex justify-between items-center gap-[10px] rounded ${
                                            employee.status === "Active"
                                                ? "bg-ivory-cream"
                                                : "bg-coral"
                                        }`}
                                    >
                                        <span
                                            className={`text-sm font-semibold font-quicksand text-lime-green capitalize ${
                                                employee.status === "Active"
                                                    ? "text-lime-green"
                                                    : "text-scarlet-red"
                                            }`}
                                        >
                                            {employee.status}
                                        </span>
                                        <SingleIndicatorDropdown
                                            options={["Active", "Inactive"]}
                                            showResultOption={false}
                                            height={"h-auto"}
                                            width={"w-auto"}
                                            iconSize={"w-5 h-5"}
                                            radius={"rounded"}
                                            optionsWidth={"w-[100px]"}
                                            padding={"px-0"}
                                        />
                                    </div>
                                </td>
                                <td aria-labelledby="action" className="p-[10px] w-[21.23%]">
                                    <div className={`flex items-center justify-center gap-1 px-2`}>
                                        <button
                                            type="button"
                                            className="block px-3 mx-auto xl:hidden"
                                        >
                                            <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                            <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                                            <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                        </button>
                                        <button
                                            type="button"
                                            className="items-center justify-center flex-1 hidden py-1 duration-300 transform rounded xl:flex bg-neutral-gray hover:bg-gray-200"
                                        >
                                            <img
                                                src={shieldSearch}
                                                alt="shield search"
                                                className="w-3 h-3"
                                            />
                                            <span className="ml-2 text-sm font-semibold font-quicksand text-clinder">
                                                Detail
                                            </span>
                                        </button>
                                        <button
                                            type="button"
                                            className="px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200 hidden xl:block"
                                        >
                                            <img src={edit} alt="edit" className="w-3 h-3" />
                                        </button>
                                        <button
                                            type="button"
                                            className="px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200 hidden xl:block"
                                        >
                                            <img
                                                src={trashRed}
                                                alt="trash red"
                                                className="w-3 h-3"
                                            />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })} */}
                </tbody>
            </table>

            <UnderConstructionBanner />
        </div>
    );
};

export default TabletDesktopEmployeeTable;
