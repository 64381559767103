import React from 'react';

const MobileTabletTopMenu = ({ topCustomer }) => {
  return (
    <div className="block w-full overflow-hidden xl:hidden bg-neutral-gray border-b-[1px] md:border-none border-gray-x">
      <div className="flex justify-between">
        <div className="mt-6 ml-2 xs:ml-6">
          <p className="text-2xl font-quicksand">
            <span className="font-medium text-clinder">Top Menu</span> <br />
            <span className="font-semibold text-orange">This Week</span>
          </p>
          <p className="mt-6 text-xs font-medium font-satoshi text-manatee">
            10 - 12 Agustus 2023
          </p>
        </div>
        <figure>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="229"
            height="206"
            viewBox="0 0 229 206"
            fill="none"
          >
            <path
              d="M29.6056 56.46L11.5004 19.0025L1.00036 -2.9998L26.6377 -23.2229L266.29 -23.223L266.29 205.486L264.798 192.875C263.306 180.265 260.322 155.044 253.379 156.54C246.436 158.037 235.534 186.251 229.371 182.489C223.207 178.726 221.781 142.986 217.676 125.326C213.572 107.667 206.788 108.087 198.112 121.286C189.435 134.486 178.864 160.465 171.063 167.759C163.261 175.054 158.227 163.663 153.109 152.842C147.991 142.021 142.789 131.77 136.271 130.4C129.753 129.03 121.919 136.54 117.115 123.6C112.311 110.661 110.536 77.2722 105.713 64.4626C100.89 51.6531 93.0176 59.4228 86.4977 58.0647C79.9777 56.7066 74.8102 46.2207 68.0267 46.6409C61.2433 47.0611 52.8441 58.3872 45.6766 61.3996C38.5091 64.412 32.5734 59.1107 29.6056 56.46Z"
              fill="url(#paint0_linear_4802_49679)"
              stroke="#F17300"
            />
            <defs>
              <linearGradient
                id="paint0_linear_4802_49679"
                x1="129.549"
                y1="515.983"
                x2="129.549"
                y2="26.4832"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F17300" />
                <stop offset="1" stopColor="#F17300" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </figure>
      </div>
      <div className="flex flex-wrap justify-between gap-x-1 sm:gap-x-2  gap-y-2 w-[95%] sm:w-[80%] mx-auto pb-6 -mt-6">
        {topCustomer.map((menu, index) => (
          <button
            key={index}
            type="button"
            className="relative block w-[48%] sm:w-[45%] h-10 cursor-pointer group"
            onClick={() => console.log('klik')}
          >
            <div className="z-10 group-hover:bg-white group-hover:shadow-md group-hover:p-[10px] group-hover:absolute -top-1/4 group-hover:rounded-lg transform duration-300 w-full">
              <span className="hidden group-hover:block text-sm font-bold text-white font-quicksand absolute px-2 bg-orange shadow-[2px_2px_0px_0px_#464646] transform rotate-[8deg] right-0 -top-2 transition duration-300">
                {index + 1}
              </span>
              <div className="flex text-xs font-semibold font-quicksand text-manatee group-hover:text-sm group-hover:font-bold group-hover:text-black group-hover:font-quicksand text-start">
                <span className="mr-1 group-hover:hidden">{index + 1}.</span>
                <span className="capitalize">{menu.menuName}</span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileTabletTopMenu;
