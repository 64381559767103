import React, { useEffect, useState } from "react";

import axiosInstance from "../../../utils/api/apiConfig";
import { HiOutlineChevronLeft } from "react-icons/hi";

import SingleIndicatorDropdown from "../../SingleIndicatorDropdown";

import close from "../../../assets/icons/close.svg";
import lock from "../../../assets/icons/lock.svg";
import lockColorful from "../../../assets/icons/lockColorful.svg";
import chart from "../../../assets/icons/chart.svg";
import chartColorful from "../../../assets/icons/chartColorful.svg";
import personalCard from "../../../assets/icons/personalcard.svg";
import personalCardColorful from "../../../assets/icons/personalcardColorful.svg";
import moneySend from "../../../assets/icons/money-send.svg";
import moneySendColorful from "../../../assets/icons/moneySendColorful.svg";
import coffee from "../../../assets/icons/coffee.svg";
import reserveGoldenRod from "../../../assets/icons/reserveGoldenRod.svg";
import wallet from "../../../assets/icons/wallet.svg";
import timer from "../../../assets/icons/timer.svg";
import printerBlue from "../../../assets/icons/printerBlue.svg";
import documentDownload from "../../../assets/icons/document-download.svg";
import CustomerAnalyticSection from "./CustomerAnalyticSection";
import CustomerTransactionSection from "./CustomerTransactionSection";
import CustomerDetailTransactionSection from "./CustomerDetailTransactionSection";
import formatDefaultDate from "../../../utils/dateFormate";

const DetailCustomerModal = ({ isModalOpen, setIsModalOpen, customerCredentialId }) => {
    const [activeSideNav, setActiveSideNav] = useState("Credential");

    const [selectedTransactionId, setSelectedTransactionId] = useState(0);
    const [detailCustomerTransaction, setDetailCustomerTransaction] = useState(null);

    // === Credential Data State ===
    const [customerLevel, setCustomerLevel] = useState("");
    const [generatedCustomerId, setGeneratedCustomerId] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [shortenEmail, setShortenEmail] = useState("");

    // === Personal Data State ===
    const [fullName, setFullName] = useState("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [birthMonthTextValue, setBirthMonthTextValue] = useState("");
    const [birthMonthNumberValue, setBirthMonthNumberValue] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [fullBirthDate, setFullBirthDate] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    // Tab Description Handler
    let tabDescription = "";
    if (activeSideNav === "Credential") {
        tabDescription =
            "In this window you can view the credential data from your customer if they are need it.";
    } else if (activeSideNav === "Personal") {
        tabDescription =
            "In this window you can view the personal data from your customer if they are need it.";
    } else if (activeSideNav === "Analytics Data") {
        tabDescription =
            "In this window you can see the analytics data from your customer if you need it.";
    } else {
        tabDescription =
            "In this window you can see the transactions data from your customer if you need it.";
    }

    // === Credential Data method ===

    // Handle Get customer credential by Id
    useEffect(() => {
        getCustomerCredentialById(customerCredentialId);
    }, [customerCredentialId]);

    const getCustomerCredentialById = async (id) => {
        // setIsLoadingGetCategoryById(true);
        try {
            const response = await axiosInstance.get(`/customer-credentials/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setCustomerLevel(response?.data?.data?.CustomerLevel?.customerLevelName);
            setGeneratedCustomerId(response?.data?.data?.CustomerCredential?.customerId);
            setUsername(response?.data?.data?.CustomerCredential?.username);
            setEmail(response?.data?.data?.CustomerCredential?.email);
            setShortenEmail(response?.data?.data?.CustomerCredential?.email.split("@")[0]);
            setFullName(response?.data?.data?.CustomerCredential?.Customer?.fullName);
            setGender(response?.data?.data?.CustomerCredential?.Customer?.gender);
            setFullBirthDate(response?.data?.data?.CustomerCredential?.Customer?.birthDate);
            setPhoneNumber(response?.data?.data?.CustomerCredential?.Customer?.phone);
            setAddress(response?.data?.data?.CustomerCredential?.Customer?.address);
        } catch (error) {
            // console.log(error);
            //   setIsLoadingGetCategoryById(false);
        }
    };

    // === Personal Data method ===
    // Handle Date Value
    const getYears = (yearsBack) => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= currentYear - yearsBack; i--) {
            years.push(i.toString());
        }
        return years;
    };

    const monthOptionsTextValueTextValue = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    //Handle Split Full birth date input
    useEffect(() => {
        const birthDateParts = fullBirthDate.split("-");
        setBirthDate(birthDateParts[2]);
        setBirthMonthNumberValue(parseInt(birthDateParts[1]));
        setBirthMonthTextValue(monthOptionsTextValueTextValue[parseInt(birthDateParts[1] - 1)]);
        setBirthYear(birthDateParts[0]);
    }, [fullBirthDate]);

    return (
        <>
            {isModalOpen && (
                <div
                    className="fixed inset-0 z-50 flex justify-end bg-clinder/60"
                    onClick={() => setIsModalOpen(false)}
                >
                    <div
                        className="h-screen overflow-y-auto bg-white w-[768px] lg:w-[800px] flex flex-col md:flex-row"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Side Nav */}
                        <aside className="hidden md:block pt-6 lg:pt-9 border-r-[1px] sticky top-0 border-gray-x/40 w-[26%]">
                            <ul>
                                <li>
                                    <button
                                        className={`py-4 flex pl-6 md:pl-9 gap-3 w-full ${
                                            activeSideNav === "Credential" && "bg-dew"
                                        }`}
                                        onClick={() => setActiveSideNav("Credential")}
                                    >
                                        <img
                                            src={
                                                activeSideNav === "Credential" ? lockColorful : lock
                                            }
                                            alt="lock"
                                        />
                                        <span
                                            className={`font-quicksand text-base ${
                                                activeSideNav === "Credential"
                                                    ? "font-bold text-aurora-blue"
                                                    : "text-manatee font-semibold"
                                            }`}
                                        >
                                            Credential
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`py-4 mt-2 flex pl-6 md:pl-9 gap-3 w-full ${
                                            activeSideNav === "Personal" && "bg-dew"
                                        }`}
                                        onClick={() => setActiveSideNav("Personal")}
                                    >
                                        <img
                                            src={
                                                activeSideNav === "Personal"
                                                    ? personalCardColorful
                                                    : personalCard
                                            }
                                            alt="personal card"
                                        />
                                        <span
                                            className={`font-quicksand text-base ${
                                                activeSideNav === "Personal"
                                                    ? "font-bold text-aurora-blue"
                                                    : "text-manatee font-semibold"
                                            }`}
                                        >
                                            Personal
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`py-4 mt-2 flex pl-6 md:pl-9 gap-3 w-full ${
                                            activeSideNav === "Analytics Data" && "bg-dew"
                                        }`}
                                        onClick={() => setActiveSideNav("Analytics Data")}
                                    >
                                        <img
                                            src={
                                                activeSideNav === "Analytics Data"
                                                    ? chartColorful
                                                    : chart
                                            }
                                            alt="chart"
                                        />
                                        <span
                                            className={`font-quicksand text-base ${
                                                activeSideNav === "Analytics Data"
                                                    ? "font-bold text-aurora-blue"
                                                    : "text-manatee font-semibold"
                                            }`}
                                        >
                                            Analytics
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button
                                        className={`py-4 mt-2 flex pl-6 md:pl-9 gap-3 w-full ${
                                            (activeSideNav === "Transactions" ||
                                                activeSideNav === "detail-transaction") &&
                                            "bg-dew"
                                        }`}
                                        onClick={() => setActiveSideNav("Transactions")}
                                    >
                                        <img
                                            src={
                                                activeSideNav === "Transactions" ||
                                                activeSideNav === "detail-transaction"
                                                    ? moneySendColorful
                                                    : moneySend
                                            }
                                            alt="money send"
                                        />
                                        <span
                                            className={`font-quicksand text-base ${
                                                activeSideNav === "Transactions" ||
                                                activeSideNav === "detail-transaction"
                                                    ? "font-bold text-aurora-blue"
                                                    : "text-manatee font-semibold"
                                            }`}
                                        >
                                            Transaction
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </aside>

                        {/* Main Content */}
                        <div className="w-full md:w-[74%] px-6 pt-0 md:pt-6 lg:px-9 lg:pt-9 relative flex flex-col">
                            {/* Detail Transaction Header (hidden on mobile) */}
                            <header className="fixed top-0 pb-4 border-b-[1px] border-gray-x/40 justify-between md:w-[515px] bg-white pt-6 lg:pt-9 hidden md:flex">
                                <div className="flex items-center gap-3 -mt-6">
                                    <button
                                        type="button"
                                        onClick={() => setActiveSideNav("Transactions")}
                                    >
                                        <HiOutlineChevronLeft className="text-2xl text-manatee" />
                                    </button>
                                    <h2 className="text-2xl font-bold font-quicksand text-clinder">
                                        Details
                                    </h2>
                                </div>
                                <div>
                                    <p className="text-manatee text-sm font-satoshi leading-[200%] font-medium">
                                        {formatDefaultDate(detailCustomerTransaction?.createdAt)}
                                    </p>
                                    <p className="text-end font-satoshi text-sm leading-[200%] font-medium text-manatee mt-1">
                                        Pesanan {detailCustomerTransaction?.invoiceNumber}
                                    </p>
                                </div>
                            </header>

                            {/* Credential, personal, and analitics,  transaction and details transaction (on mobile) Header */}
                            <header
                                className={`pb-6 border-b-[1px] border-gray-x/40 fixed top-0 bg-white w-full md:w-[515px] lg:w-[520px] pt-6 lg:pt-9 z-50 px-6 md:px-0 -mx-6 md:mx-0 ${
                                    activeSideNav === "detail-transaction" && "block md:hidden"
                                }`}
                            >
                                <div className="flex flex-row-reverse items-center justify-end gap-6 md:justify-between md:flex-row">
                                    <h2 className="block text-xl font-semibold md:hidden font-quicksand text-aurora-blue">
                                        Edit Customer
                                    </h2>
                                    <h2 className="hidden text-2xl font-bold md:block font-quicksand">
                                        <span className="text-aurora-blue">{activeSideNav}</span>{" "}
                                        {"  "}
                                        <span className="capitalize text-manatee">{fullName}</span>
                                    </h2>
                                    <button onClick={() => setIsModalOpen(false)}>
                                        <img src={close} alt="close" />
                                    </button>
                                </div>
                                <p className="font-satoshi text-sm font-medium leading-[200%] text-manatee mt-2 w-96 hidden md:block">
                                    {tabDescription}
                                </p>
                            </header>

                            {/* Navigation Menu for Mobile */}
                            <div className="fixed top-[77px] border-b-[1px] border-gray-x/40 w-full px-6 -mx-6 bg-white z-50 block md:hidden">
                                <ul className="flex py-4 overflow-x-scroll">
                                    <li>
                                        <button
                                            type="button"
                                            className={`py-3 rounded-lg flex items-center justify-center px-6 gap-3 w-full ${
                                                activeSideNav === "Credential" && "bg-dew"
                                            }`}
                                            onClick={() => setActiveSideNav("Credential")}
                                        >
                                            <img
                                                src={
                                                    activeSideNav === "Credential"
                                                        ? lockColorful
                                                        : lock
                                                }
                                                alt="lock"
                                            />
                                            <span
                                                className={`font-quicksand text-base ${
                                                    activeSideNav === "Credential"
                                                        ? "font-bold text-aurora-blue"
                                                        : "text-manatee font-semibold"
                                                }`}
                                            >
                                                Credential
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className={`py-3 rounded-lg items-center justify-center flex px-6 gap-3 w-full ${
                                                activeSideNav === "Personal" && "bg-dew"
                                            }`}
                                            onClick={() => setActiveSideNav("Personal")}
                                        >
                                            <img
                                                src={
                                                    activeSideNav === "Personal"
                                                        ? personalCardColorful
                                                        : personalCard
                                                }
                                                alt="personal card"
                                            />
                                            <span
                                                className={`font-quicksand text-base ${
                                                    activeSideNav === "Personal"
                                                        ? "font-bold text-aurora-blue"
                                                        : "text-manatee font-semibold"
                                                }`}
                                            >
                                                Personal
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className={`py-3 rounded-lg items-center justify-center flex px-6 gap-3 w-full ${
                                                activeSideNav === "Analytics Data" && "bg-dew"
                                            }`}
                                            onClick={() => setActiveSideNav("Analytics Data")}
                                        >
                                            <img
                                                src={
                                                    activeSideNav === "Analytics Data"
                                                        ? chartColorful
                                                        : chart
                                                }
                                                alt="chart"
                                            />
                                            <span
                                                className={`font-quicksand text-base ${
                                                    activeSideNav === "Analytics Data"
                                                        ? "font-bold text-aurora-blue"
                                                        : "text-manatee font-semibold"
                                                }`}
                                            >
                                                Analytics
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            className={`py-3 rounded-lg items-center justify-center flex px-6 gap-3 w-full ${
                                                (activeSideNav === "Transactions" ||
                                                    activeSideNav === "detail-transaction") &&
                                                "bg-dew"
                                            }`}
                                            onClick={() => setActiveSideNav("Transactions")}
                                        >
                                            <img
                                                src={
                                                    activeSideNav === "Transactions" ||
                                                    activeSideNav === "detail-transaction"
                                                        ? moneySendColorful
                                                        : moneySend
                                                }
                                                alt="money send"
                                            />
                                            <span
                                                className={`font-quicksand text-base ${
                                                    activeSideNav === "Transactions" ||
                                                    activeSideNav === "detail-transaction"
                                                        ? "font-bold text-aurora-blue"
                                                        : "text-manatee font-semibold"
                                                }`}
                                            >
                                                Transaction
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <form className="flex-1">
                                {/* Credential Section */}
                                {activeSideNav === "Credential" && (
                                    <div className="flex flex-col h-full gap-5 pb-20 md:pb-6 lg:pb-9">
                                        <div className="mt-[175px] md:mt-36">
                                            <div className="mt-4">
                                                <label
                                                    htmlFor="customer-id"
                                                    className="text-sm font-semibold font-quicksand text-clinder"
                                                >
                                                    Customer Level
                                                </label>
                                                <div className="w-full p-4 mt-1 text-sm font-semibold capitalize rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                    {customerLevel}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="customer-id"
                                                className="text-sm font-semibold font-quicksand text-clinder"
                                            >
                                                Customer ID
                                            </label>
                                            <div className="w-full p-4 mt-1 text-sm font-semibold rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                {generatedCustomerId}
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="username"
                                                className="text-sm font-semibold font-quicksand text-clinder"
                                            >
                                                Username
                                            </label>
                                            <div className="w-full p-4 mt-1 text-sm font-semibold rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                {username}
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="text-sm font-semibold font-quicksand text-clinder"
                                            >
                                                Email
                                            </label>
                                            <div className="flex items-center justify-between gap-4 p-4 mt-1 rounded-lg bg-dew">
                                                <p className="text-sm font-semibold text-clinder font-quicksand">
                                                    {shortenEmail}
                                                </p>
                                                <p className="px-3 py-1 text-xs font-semibold bg-white rounded shadow-soft">
                                                    <span className="font-quicksand text-aurora-blue">
                                                        @gmail.com
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Personal Data Form */}
                                {activeSideNav === "Personal" && (
                                    <div className="flex flex-col justify-between h-full pb-20 md:pb-9 lg:pb-9">
                                        <div>
                                            <div className="mt-[175px] md:mt-36">
                                                <label
                                                    htmlFor="full-name"
                                                    className="text-sm font-semibold font-quicksand text-clinder"
                                                >
                                                    Full Name
                                                </label>
                                                <div className="w-full p-4 mt-1 text-sm font-semibold capitalize rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                    {fullName}
                                                </div>
                                                <div className="mt-4">
                                                    <label
                                                        htmlFor="customer-gender"
                                                        className="text-sm font-semibold font-quicksand text-clinder"
                                                    >
                                                        Customer Gender{" "}
                                                        <span className="text-manatee">
                                                            (optional)
                                                        </span>
                                                    </label>
                                                    <div className="mt-2">
                                                        <button
                                                            disabled
                                                            type="button"
                                                            className={`w-auto flex gap-4 px-4 py-3 transition-all duration-500 text-start rounded-lg group bg-aurora-blue`}
                                                        >
                                                            <div className="flex w-6 h-6 p-1 rounded-full bg-white/20 shadow-gentle">
                                                                <div
                                                                    className={`bg-white  rounded-full w-4 h-4`}
                                                                />
                                                            </div>
                                                            <span
                                                                className={`font-quicksand text-base font-bold text-white capitalize`}
                                                            >
                                                                {gender === "" ? (
                                                                    <span className="italic font-medium text-manatee">
                                                                        no gender filled in
                                                                    </span>
                                                                ) : (
                                                                    gender
                                                                )}
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <label
                                                        htmlFor="birth-date"
                                                        className="text-sm font-semibold font-quicksand"
                                                    >
                                                        Birth Date
                                                    </label>
                                                    <div className="flex flex-col gap-3 mt-2 sm:flex-row">
                                                        <div className="flex w-full gap-3 sm:w-2/3">
                                                            <div className="w-full p-4 mt-1 text-sm font-semibold capitalize rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                                {birthDate}
                                                            </div>
                                                            <div className="w-full p-4 mt-1 text-sm font-semibold capitalize rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                                {birthMonthTextValue}
                                                            </div>
                                                        </div>
                                                        <div className="w-full sm:w-1/3">
                                                            <div className="w-full p-4 mt-1 text-sm font-semibold capitalize rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                                {birthYear}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4 h-[100px]">
                                                    <label
                                                        htmlFor="phone-number"
                                                        className="text-sm font-semibold font-quicksand text-clinder"
                                                    >
                                                        Phone Number
                                                    </label>
                                                    <div className="flex items-center gap-4 px-4 py-3 mt-1 rounded-lg bg-dew">
                                                        <p className="px-3 py-2 text-xs font-semibold bg-white rounded left-4 shadow-soft">
                                                            <span className="font-quicksand text-aurora-blue">
                                                                +62
                                                            </span>
                                                        </p>
                                                        <p className="text-sm font-semibold text-clinder font-quicksand">
                                                            {phoneNumber}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="address"
                                                        className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
                                                    >
                                                        Address{" "}
                                                        <span className="text-manatee">
                                                            (optional)
                                                        </span>
                                                    </label>
                                                    <div className="w-full p-4 mt-1 text-sm font-semibold rounded-lg border-neutral-gray font-quicksand text-clinder bg-dew">
                                                        {address === "" ? (
                                                            <span className="italic font-medium text-manatee">
                                                                No address filled in
                                                            </span>
                                                        ) : (
                                                            address
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Analytics Section */}
                                {activeSideNav === "Analytics Data" && (
                                    <CustomerAnalyticSection
                                        customerCredentialId={customerCredentialId}
                                    />
                                )}

                                {/* Transactions Section */}
                                {activeSideNav === "Transactions" && (
                                    <CustomerTransactionSection
                                        customerCredentialId={customerCredentialId}
                                        setActiveSideNav={setActiveSideNav}
                                        setSelectedTransactionId={setSelectedTransactionId}
                                    />
                                )}

                                {/* Detail Transaction */}
                                {activeSideNav === "detail-transaction" && (
                                    <CustomerDetailTransactionSection
                                        setActiveSideNav={setActiveSideNav}
                                        customerCredentialId={customerCredentialId}
                                        selectedTransactionId={selectedTransactionId}
                                        setSelectedTransactionId={setSelectedTransactionId}
                                        setDetailCustomerTransaction={setDetailCustomerTransaction}
                                    />
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DetailCustomerModal;
