import React from 'react';

import searchBlue from '../../../assets/icons/searchBlue.svg';
import shieldSearch from '../../../assets/icons/shieldSearch.svg';

import formatNumberWithCommas from '../../../utils/formatNumber';

const MobileTransactionTable = ({
	transactionData,
	setTransactionId,
	setDetailTransactionModalOpen
}) => {
	return (
		<div className='block md:hidden'>
			<div className='flex gap-4 px-6 py-2 sticky bg-white border-b-[1px] border-gray-x z-30'>
				<img src={searchBlue} alt='search' />
				<input
					type='text'
					placeholder='Search Order ID'
					className='flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium'
				/>
			</div>
			{transactionData.map((transaction, index) => {
				return (
					<div key={index} className='w-full'>
						<div className='flex items-center justify-between px-6 mt-4'>
							<p className='text-sm font-semibold leading-[150%] font-quicksand text-clinder'>
								Order ID
							</p>
							<div className='flex gap-[10px] items-center'>
								<p className={`px-4 py-1 bg-neutral-gray`}>
									<span className='text-sm font-semibold leading-[150%] capitalize font-quicksand flex-initial text-end'>
										#{transaction.orderId}
									</span>
								</p>
							</div>
						</div>
						<div className='flex items-center justify-between px-6 mt-4'>
							<p className='text-sm font-semibold leading-[150%] font-quicksand text-clinder'>
								Customer
							</p>
							<div className='flex gap-[10px] items-center'>
								<p className={`px-4 py-1`}>
									<span className='text-sm font-semibold leading-[150%] capitalize font-quicksand flex-initial text-end'>
										{transaction.customer}
									</span>
								</p>
							</div>
						</div>
						<div className='flex items-center justify-between px-6 mt-4'>
							<p className='text-sm font-semibold leading-[150%] font-quicksand text-clinder'>
								Items
							</p>
							<div className='flex gap-[10px] items-center'>
								<p className={`px-4 py-1`}>
									<span className='text-sm font-semibold leading-[150%] capitalize font-quicksand flex-initial text-end'>
										{transaction.item} Item
									</span>
								</p>
							</div>
						</div>
						<div className='flex items-center justify-between px-6 mt-4'>
							<p className='text-sm font-semibold leading-[150%] font-quicksand text-clinder'>
								Date
							</p>
							<div className='flex gap-[10px] items-center'>
								<p className={`px-4 py-1`}>
									<span className='text-sm font-semibold leading-[150%] capitalize font-quicksand flex-initial text-end'>
										{transaction.date}
									</span>
								</p>
							</div>
						</div>
						<div className='flex items-center justify-between px-6 mt-4 mb-4'>
							<p className='text-sm font-semibold leading-[150%] font-quicksand text-clinder'>
								Total Transaction
							</p>
							<div className='flex gap-[10px] items-center'>
								<p className={`px-4 py-1`}>
									<span className='text-sm font-semibold leading-[150%] capitalize font-quicksand flex-initial text-end text-[#f09448]'>
										IDR{' '}
										{formatNumberWithCommas(transaction.total.toLocaleString())}
									</span>
								</p>
							</div>
						</div>
						<div
							className={`flex items-center justify-between w-full py-4 px-6 bg-neutral-gray border-y-[1px]`}>
							<p className='text-sm font-semibold leading-[150%] font-quicksand text-clinder capitalize'>
								{''}
							</p>
							<button
								onClick={() => {
									setTransactionId(transaction?.orderId);
									setDetailTransactionModalOpen(true);
								}}
								type='button'
								className='items-center justify-center flex-1 flex py-1 duration-300 transform rounded bg-neutral-gray hover:bg-gray-200'>
								<img
									src={shieldSearch}
									alt='shield search'
									className='w-3 h-3'
								/>
								<span className='ml-2 text-sm font-semibold font-quicksand text-clinder'>
									Detail
								</span>
							</button>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default MobileTransactionTable;
