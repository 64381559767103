import React from "react";

import DoubleIndicatorDropdown from "../../DoubleIndicatorDropdown";
import LevelOptionDropdown from "./LevelOptionDropdown";
import AppPriceDropdown from "./AppPriceDropdown";

import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import edit from "../../../assets/icons/edit.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const TabletDesktopPriceTable = ({
    productData,
    menuNameOptions,
    baseProductOptions,
    priceWithTaxOptions,
    priceNoTaxOptions,
    selectedCustomerLevelName,
    setSelectedCustomerLevelName,
    selectedCustomerLevelFontColor,
    setSelectedCustomerLevelFontColor,
}) => {
    return (
        <div className="hidden w-full mt-6 md:block">
            <table className="w-full table-auto border-coolapse">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th
                            id="menu-name"
                            className="w-[15.82%] lg:w-[27.93%] xl:w-[21.25%] 2xl:w-[20.87%] 3xl:w-[14.29%]"
                        >
                            <DoubleIndicatorDropdown options={menuNameOptions} />
                        </th>
                        <th
                            id="base-product"
                            className="w-[16.97%] lg:w-[16.13%] xl:w-[12.82%] 2xl:w-[13.12%] 3xl:w-[14.29%]"
                        >
                            <DoubleIndicatorDropdown options={baseProductOptions} />
                        </th>
                        <th
                            id="price"
                            className="p-[10px] text-sm font-medium text-manatee xl:flex items-center gap-2 w-[16.97%] lg:w-[16.13%] xl:w-full"
                        >
                            <span className="hidden w-16 font-quicksand xl:inline">IDR Price</span>
                            <LevelOptionDropdown
                                selectedCustomerLevelName={selectedCustomerLevelName}
                                setSelectedCustomerLevelName={setSelectedCustomerLevelName}
                                selectedCustomerLevelFontColor={selectedCustomerLevelFontColor}
                                setSelectedCustomerLevelFontColor={
                                    setSelectedCustomerLevelFontColor
                                }
                            />
                        </th>
                        <th
                            id="price-with-tax"
                            className="w-[16.97%] lg:w-[16.13%] xl:w-[12.82%] 2xl:w-[13.12%] 3xl:w-[14.29%]"
                        >
                            <DoubleIndicatorDropdown options={priceWithTaxOptions} />
                        </th>
                        <th
                            id="price-no-tax"
                            className="hidden xl:table-cell xl:w-[15.44%] 2xl:w-[15.1%] 3xl:w-[14.29%]"
                        >
                            <DoubleIndicatorDropdown options={priceNoTaxOptions} />
                        </th>
                        <th
                            id="app-price"
                            className="w-[20.57%] lg:w-[12.39%] xl:w-[10.28%] 2xl:w-[15.1%] 3xl:w-[14.29%]"
                        >
                            <div className="ml-[10px] px-2 py-1 rounded-[4px] bg-white border-[0.5px] border-gray-x/40">
                                <AppPriceDropdown />
                            </div>
                        </th>
                        <th
                            id="action"
                            className="w-[12.66%] lg:w-[9%] xl:w-[12.82%] 2xl:w-[13.12%] 3xl:w-[14.29%]"
                        >
                            <p className="p-[10px] text-sm font-medium hover:bg-gray-50 text-manatee font-quicksand">
                                Action
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {productData.map((product, index) => (
            <tr key={index}>
              <td
                aria-labelledby="menu-name"
                className="text-sm font-medium capitalize leading-5 font-quicksand text-clinder px-[10px] py-3"
              >
                {product.menuName}
              </td>
              <td
                area-aria-labelledby="base-product"
                className="text-sm font-medium leading-5 font-quicksand text-clinder px-[10px] py-3"
              >
                {product.baseProduct} Items
              </td>
              <td
                aria-labelledby="price-with-tax"
                className="text-sm font-medium leading-5 font-quicksand text-clinder px-[10px] py-3"
              >
                {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </td>
              <td
                aria-labelledby="price-with-tax"
                className="text-sm font-medium leading-5 font-quicksand text-clinder px-[10px] py-3"
              >
                {product.priceWithTax
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </td>
              <td
                aria-labelledby="price-no-tax"
                className="text-sm font-medium leading-5 font-quicksand text-clinder px-[10px] py-3 hidden xl:table-cell"
              >
                {product.priceNoTax
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </td>
              <td
                aria-labelledby="app-price"
                className="text-sm font-medium leading-5 font-quicksand text-clinder px-[10px] py-3"
              >
                {product.appPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </td>
              <td
                className="flex items-center justify-center py-3 gap-x-1"
                aria-labelledby="action"
              >
                <button type="button" className="block px-3 mx-auto xl:hidden">
                  <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                  <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                  <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                </button>
                <button
                  type="button"
                  className="items-center flex-none hidden px-3 py-2 duration-300 transform rounded-md xl:flex bg-neutral-gray hover:bg-gray-200"
                >
                  <img
                    src={shieldSearch}
                    alt="shield search"
                    className="w-3 h-3"
                  />
                </button>
                <button
                  type="button"
                  className="flex-none hidden px-3 py-2 duration-300 transform rounded-md xl:inline-block bg-neutral-gray hover:bg-gray-200"
                >
                  <img src={trashRed} alt="trash red" className="w-3 h-3" />
                </button>
              </td>
            </tr>
          ))} */}
                </tbody>
            </table>

            <UnderConstructionBanner />
        </div>
    );
};

export default TabletDesktopPriceTable;
