import React, { useEffect, useState } from 'react';

import axiosInstance from '../../utils/api/apiConfig';

import Pagination from '../../components/Pagination';
import NewPriceModal from '../../components/stock/price/NewPriceModal';
import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
import TopNavbar from '../../components/TopNavbar';
import TabletDesktopPriceTable from '../../components/stock/price/TabletDesktopPriceTable';
import MobilePriceTable from '../../components/stock/price/MobilePriceTable';

import stockPriceBanner from '../../assets/images/stockPriceBanner.png';
import NeedLoginAlert from '../../components/alert/NeedLoginAlert';

const StockPrice = () => {
  // === Stock-price State ===
  const [searchQuery, setSearchQuery] = useState('');
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [allCustomerLevels, setAllCustomerLevels] = useState([]);
  const [activeTopNav, setActiveTopNav] = useState('price');
  const topNavs = [
    { nav: 'Category', value: 'category', route: 'admin-stock-category' },
    {
      nav: 'Base Product',
      value: 'base-product',
      route: 'admin-stock-base-product',
    },
    { nav: 'Menu', value: 'menu', route: 'admin-stock-menu' },
    { nav: 'Price', value: 'price', route: 'admin-stock-price' },
    {
      nav: 'Favorite Menu',
      value: 'favorite-menu',
      route: 'admin-stock-favorite-menu',
    },
    {
      nav: 'Additional Notes',
      value: 'additional-notes',
      route: 'admin-stock-additional-notes',
    },
  ];
  const [menus, setMenus] = useState([]);
  const [selectedCustomerLevelName, setSelectedCustomerLevelName] =
    useState('warga');
  const [selectedCustomerLevelFontColor, setSelectedCustomerLevelFontColor] =
    useState('orangetty');
  const [isCustomerLevelUpdate, setIsCustomerLevelUpdate] = useState(false);

  // fake data
  const menuNameOptions = [
    {
      label: 'Menu Name',
      value: 'Menu Name',
      disabled: true,
    },
    { label: 'Sort By Name A-Z', value: 'ascending' },
    { label: 'Sort By Name Z-A', value: 'descending' },
  ];
  const baseProductOptions = [
    {
      label: 'Base Product',
      value: 'Base Product',
      disabled: true,
    },
    { label: 'Less than 50', value: '<50' },
    { label: '50 - 75', value: '50-75' },
    { label: 'More than 100', value: '>100' },
  ];
  const priceWithTaxOptions = [
    {
      label: 'IDR Price with Tax',
      value: 'IDR Price with Tax',
      disabled: true,
    },
    { label: 'Less than 20.000', value: '<20.000' },
    { label: '21.000 - 50.000', value: '21.000-50.000' },
    { label: 'More than 50.000', value: '>50.000' },
  ];
  const priceNoTaxOptions = [
    {
      label: 'IDR Price (No Tax)',
      value: 'IDR Price (No Tax)',
      disabled: true,
    },
    { label: 'Less than 20.000', value: '<20.000' },
    { label: '21.000 - 50.000', value: '21.000-50.000' },
    { label: 'More than 50.000', value: '>50.000' },
  ];
  const productFakeData = [
    {
      menuName: 'cheese pizza',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'chiken sandwich',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'cajun fries',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'butter burger',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'white castle sliders',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'smoke shack burger',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'crispy chicke',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'french fries',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'panera mac and cheese',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'taquito with cheese',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
    {
      menuName: 'butter burger',
      baseProduct: 23,
      price: 16000,
      priceWithTax: 18200,
      priceNoTax: 12000,
      appPrice: 17800,
    },
  ];

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  // Get all menus
  useEffect(() => {
    getAllMenus();
  }, []);
  const getAllMenus = async () => {
    // setIsLoadingGetAllMenus(true);
    try {
      const response = await axiosInstance.get(`/products`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setMenus(response.data.data);
      // setIsLoadingGetAllMenus(false);
    } catch (error) {
      // console.log(error);
      // setIsLoadingGetAllMenus(false);
    }
  };

  // Handle Get All customer levels
  useEffect(() => {
    const getAllCustomerLevels = async () => {
      // setIsLoadingGetAllBaseProducts(true);
      try {
        const response = await axiosInstance.get(`/customer-levels`, {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setAllCustomerLevels(response.data.data);
        // setIsLoadingGetAllBaseProducts(false);
      } catch (error) {
        // console.log(error);
        // setIsLoadingGetAllBaseProducts(false);
      }
    };

    getAllCustomerLevels();
  }, [isCustomerLevelUpdate]);

  // Handle Search Filter
  const searchFilter = (data) => {
    return data.filter((item) =>
      item.menuName.toLowerCase().includes(searchQuery.toLocaleLowerCase())
    );
  };

  if (!token || !tenantId) return <NeedLoginAlert />;

  return (
    <div className="flex flex-col min-h-screen md:flex-row">
      <aside className="md:w-[88px] 2xl:w-[200px] flex-none  bg-white sticky top-0 z-50 w-full h-[86px]">
        <SideBar isStock={true} />
      </aside>

      <div className="flex flex-col flex-1 w-full ml-0 mr-0 md:w-auto md:ml-6 md:mr-4">
        <Header
          activeTopNav={activeTopNav}
          setActiveTopNav={setActiveTopNav}
          activeSideNav={'Product'}
          menuDescription={
            'You can manage and organize your product stock here'
          }
          topNavs={topNavs}
        />
        <main className="flex flex-col justify-between flex-1">
          <div>
            <Banner
              title="Price"
              description="On this menu you will be able to create, edit, and also delete the Price of the menu. Also you can manage it easily."
              onOpenModal={() => setIsPriceModalOpen(true)}
              openModalButtonLabel="Update New Price"
              searchPlaceholder={'Find your menu here'}
              image={stockPriceBanner}
              showFilterButton={false}
              shortPrintButtonLabel={'Print List'}
              longPrintButtonLabel={'Print List of Product Prices'}
              setSearchQuery={setSearchQuery}
              isDisable={true} // disabled due to unready feature
            />

            {/* Navbar for Mobile & Tablet */}
            <TopNavbar
              topNavs={topNavs}
              activeTopNav={activeTopNav}
              setActiveTopNav={setActiveTopNav}
            />

            {/* Price Table For Mobile */}
            <MobilePriceTable
              priceData={productFakeData}
              setIsNewPriceModalOpen={setIsPriceModalOpen}
            />

            {/* Price Tabel For Tablet & Desktop */}
            <TabletDesktopPriceTable
              productData={searchFilter(productFakeData)}
              menuNameOptions={menuNameOptions}
              baseProductOptions={baseProductOptions}
              priceWithTaxOptions={priceWithTaxOptions}
              priceNoTaxOptions={priceNoTaxOptions}
              selectedCustomerLevelName={selectedCustomerLevelName}
              setSelectedCustomerLevelName={setSelectedCustomerLevelName}
              selectedCustomerLevelFontColor={selectedCustomerLevelFontColor}
              setSelectedCustomerLevelFontColor={
                setSelectedCustomerLevelFontColor
              }
            />
          </div>

          {/* pagination */}
          <div className="static bottom-0 flex justify-end py-6 bg-white md:sticky">
            <Pagination totalPages={40} />
          </div>

          {/* Price modal */}
          <NewPriceModal
            isPriceModalOpen={isPriceModalOpen}
            setIsPriceModalOpen={setIsPriceModalOpen}
            menus={menus}
            allCustomerLevels={allCustomerLevels}
            isCustomerLevelUpdate={isCustomerLevelUpdate}
            setIsCustomerLevelUpdate={setIsCustomerLevelUpdate}
          />
        </main>
      </div>
    </div>
  );
};

export default StockPrice;
