import React, { useEffect, useState } from 'react';

const MenuSelectionCheckbox = ({
  menuIndex,
  menuName,
  menuId,
  price,
  priceWithTax,
  setSelectedMenusToPost,
  selectedMenus,
  isChoosingMenuModalOpen,
}) => {
  const [isMenuSelected, setIsMenuSelected] = useState(false);
  // // console.log(menuIndex, isMenuSelected, menuName);

  // Handle  the default customer level selection based on the level that is selected in the promo levels
  useEffect(() => {
    if (isChoosingMenuModalOpen) {
      if (selectedMenus.some((item) => item.menuId === menuId)) {
        setIsMenuSelected(true);
      } else {
        setIsMenuSelected(false);
      }
    }
  }, [isChoosingMenuModalOpen]);

  const handleCustomerLevelSelection = ({ menuId, isMenuSelected }) => {
    if (isMenuSelected) {
      setSelectedMenusToPost((prevSelected) => [
        ...prevSelected,
        {
          menuId: menuId,
          menuName: menuName,
          price: price,
          priceWithTax: priceWithTax,
        },
      ]);
    } else {
      setSelectedMenusToPost((prevSelected) =>
        prevSelected.filter((item) => item.menuId !== menuId)
      );
    }
  };

  const toggleSelection = () => {
    setIsMenuSelected(!isMenuSelected);
    handleCustomerLevelSelection({
      menuId,
      isMenuSelected: !isMenuSelected,
    });
  };

  return (
    <div
      className={`flex items-center justify-between w-full gap-3 p-3 rounded cursor-pointer ${
        menuIndex !== 0 && 'mt-1'
      } ${isMenuSelected && 'border border-blue-500'}`}
      onClick={toggleSelection}
    >
      <label
        htmlFor={`menu-${menuIndex}`}
        className="text-sm font-semibold capitalize text-clinder font-quicksand"
      >
        {menuName}
      </label>
      <input
        id={`menu-${menuIndex}`}
        type="checkbox"
        required
        checked={isMenuSelected}
        className="w-4 h-4 mt-1 hover:cursor-pointer"
        onClick={(e) => e.stopPropagation()}
        onChange={toggleSelection}
      />
    </div>
  );
};

export default MenuSelectionCheckbox;
