import React, { useEffect, useState } from 'react';

import mediumCashierPage from '../assets/images/medium-cashier-page.svg';
import largeCashierPage from '../assets/images/large-cashier-page.svg';

const AuthRightSection = ({ cropImage }) => {
  const [cashierPageImage, setCashierPageImage] = useState('');

  // handle Screen Height Change
  useEffect(() => {
    const handleResizeScreen = () => {
      const isMediumScreen =
        window.innerWidth >= 1200 && window.innerWidth <= 1439;
      setCashierPageImage(
        isMediumScreen ? mediumCashierPage : largeCashierPage
      );
    };
    handleResizeScreen();
    window.addEventListener('resize', handleResizeScreen);
    return () => {
      window.removeEventListener('resize', handleResizeScreen);
    };
  }, []);

  return (
    <div
      className={`hidden w-3/5 pb-2 overflow-x-hidden xl:block bg-dew side-login-shadow ${
        cropImage && 'lg:overflow-y-hidden h-screen'
      }`}
    >
      <div className="mt-16 ml-0 lg:ml-10 xl:ml-[72px]  xl:mx-auto">
        <p className="w-[73%] 4xl:w-[65%] text-xl font-semibold leading-8 xl:leading-10 xl:text-2xl font-quicksand">
          Betty has become a restaurant point of sale application that is very
          easy to use, by anyone
        </p>
      </div>
      <figure className="mt-10 2xl:mt-20">
        <img
          src={cashierPageImage}
          alt="chasier page"
          className="ml-[72px] w-full h-full"
        />
      </figure>
    </div>
  );
};

AuthRightSection.defaultProps = {
  cropImage: false,
};

export default AuthRightSection;
