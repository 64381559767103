import React, { useEffect, useState } from "react";

import axiosInstance from "../../utils/api/apiConfig";

import Pagination from "../../components/Pagination";
import NewPromoModal from "../../components/customer/promo/NewPromoModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import TabletDesktopPromoTable from "../../components/customer/promo/TabletDesktopPromoTable";
import MobilePromoTable from "../../components/customer/promo/MobilePromoTable";
import DetailPromoModal from "../../components/customer/promo/DetailPromoModal";

import promoBanner from "../../assets/images/promoBanner.png";
import PromoFilterModal from "../../components/customer/promo/PromoFilterModal";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const CustomerPromo = () => {
    //  === Promo State ===
    const [promos, setPromos] = useState([]);
    const [displayedPromos, setDisplayedPromos] = useState([]);
    const [promoId, setPromoId] = useState(null);
    const [customerLevels, setCustomerLevels] = useState([]);

    // === Filtering state ===
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
    const [selectedCustomerLevelFilter, setSelectedCustomerLevelFilter] = useState(null);
    const [optionFilteredPromos, setOptionFilteredPromos] = useState([]);
    const [isRefreshActive, setIsRefreshActive] = useState(false);
    const [isOptionFilterActive, setIsOptionFilterActive] = useState(false);
    const [isOptionFilterToggleActive, setIsOptionFilterToggleActive] = useState(false);

    // === Pagination State ===
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // === Component State ===
    const [isNewPromoModalOpen, setIsNewPromoModalOpen] = useState(false);
    const [isDetailPromoModalOpen, setIsDetailPromoModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("promo");
    const [isPromoFilterModalOpen, setIsPromoFilterModalOpen] = useState(false);
    const topNavs = [
        {
            nav: "Customer",
            value: "customer",
            route: "admin-customer",
        },
        { nav: "Promo", value: "promo", route: "admin-customer-promo" },
        // { nav: 'Voucher', value: 'voucher', route: 'admin-customer-voucher' },
    ];

    // Fake Data
    const promoNameOptions = [
        { label: "Promo Name", value: "promo-name", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const roleOptions = [
        { label: "Level", value: "role-name", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const dateAddedOptions = [
        { label: "Date Added", value: "date-added", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const dateExpireddOptions = [
        { label: "Date Expired", value: "date-expired", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const kuotaOptions = [
        { label: "Kuota", value: "kuota", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];
    const statusOptions = [
        { label: "Status", value: "status", disable: true },
        { label: "option 1", value: "option-1" },
        { label: "option 2", value: "option-2" },
        { label: "option 3", value: "option-3" },
        { label: "option 4", value: "option-4" },
    ];

    const promoData = [
        {
            promoName: "Promo Buy 1 Get 1",
            role: "All Roles",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo gopay seru",
            role: "All Roles",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo bank jago",
            role: "juragan",
            countOfPromo: 2,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Inactive",
        },
        {
            promoName: "promo hemat ankos 1",
            role: "sultan",
            countOfPromo: 2,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo hemat angkos 2",
            role: "juragan",
            countOfPromo: 1,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo sultan bgt 1",
            role: "juragan",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo sultan bgt 2",
            role: "juragan",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo sultan bgt 3",
            role: "konglomerat",
            countOfPromo: 2,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo valentine day",
            role: "konglomerat",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "promo saturday night",
            role: "konglomerat",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
        {
            promoName: "Promo Buy 1 Get 1",
            role: "All Roles",
            countOfPromo: 0,
            dateAdded: "2020-04-05",
            dateExpired: "2020-04-07",
            kuota: 192,
            status: "Active",
        },
    ];

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("userId");

    // Handle Display Menus based on filtering and pagination
    useEffect(() => {
        const handleDisplayPromos = () => {
            if (
                searchQuery !== "" &&
                selectedStatusFilter === null &&
                selectedCustomerLevelFilter === null
            ) {
                const searchedPromos = promos?.filter((promo) =>
                    promo?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
                );
                setDisplayedPromos(searchedPromos.slice(startIndex, endIndex));
                setTotalPages(Math.ceil(searchedPromos?.length / itemsPerPage));
                setTotalData(searchedPromos?.length);
            } else if (selectedStatusFilter !== null || selectedCustomerLevelFilter !== null) {
                if (searchQuery !== "") {
                    const optionaFilteredPromosSearch = optionFilteredPromos?.filter((promo) =>
                        promo?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
                    );
                    setDisplayedPromos(optionaFilteredPromosSearch.slice(startIndex, endIndex));
                    setTotalPages(Math.ceil(optionaFilteredPromosSearch?.length / itemsPerPage));
                    setTotalData(optionaFilteredPromosSearch?.length);
                } else {
                    setDisplayedPromos(optionFilteredPromos?.slice(startIndex, endIndex));
                    setTotalPages(Math.ceil(optionFilteredPromos?.length / itemsPerPage));
                    setTotalData(optionFilteredPromos?.length);
                }
            } else {
                setDisplayedPromos(promos.slice(startIndex, endIndex));
                setTotalPages(Math.ceil(promos?.length / itemsPerPage));
                setTotalData(promos?.length);
            }
        };

        handleDisplayPromos();
    }, [promos, currentPage, searchQuery, isOptionFilterToggleActive]);

    // Handel Active refresh (when search query and  option filter is active)
    useEffect(() => {
        const handleActiveRefresh = () => {
            if (searchQuery !== "" || isOptionFilterActive) {
                setIsRefreshActive(true);
            } else {
                setIsRefreshActive(false);
            }
        };

        handleActiveRefresh();
    }, [searchQuery, isOptionFilterToggleActive]);

    // Handle delete filter
    const handleDeleteFilter = () => {
        setSearchQuery("");
        setSelectedStatusFilter(null);
        setSelectedCustomerLevelFilter(null);
        setIsOptionFilterActive(false);
        setIsOptionFilterToggleActive(!isOptionFilterToggleActive);
    };

    // ==== Handle Get All customer ====
    useEffect(() => {
        getAllPromos();
        getAllCustomerLevels();
    }, []);

    const getAllPromos = async () => {
        // setIsLoadingGetAllPromos(true);
        try {
            const response = await axiosInstance.get(`/promos`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setPromos(response.data.data);
            // setIsLoadingGetAllPromos(false);
        } catch (error) {
            // console.log(error);
            // setIsLoadingGetAllPromos(false);
        }
    };

    // Handle Customer Levels
    const getAllCustomerLevels = async () => {
        // setIsLoadingGetAllBaseProducts(true);
        try {
            const response = await axiosInstance.get(`/customer-levels`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setCustomerLevels(response.data.data);
            // setIsLoadingGetAllBaseProducts(false);
        } catch (error) {
            // console.log(error);
            // setIsLoadingGetAllBaseProducts(false);
        }
    };

    // Handle Delete customer
    const deletePromos = async (id) => {
        // setIsLoadingDeletePromos(true);
        try {
            await axiosInstance.delete(`/promos/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllPromos();
            // setIsLoadingDeletePromos(false);
        } catch (error) {
            // console.log(error);
            // setIsLoadingDeletePromos(false);
        }
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] sticky top-0 z-50 bg-white h-[86px] 2xl:w-[200px] flex-none">
                <SideBar isCustomer={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Customer"}
                    menuDescription={
                        "You can manage and organize your customer and other things here"
                    }
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title="Promo"
                            description="On this menu you will be able to create, edit, and also delete the promo. Also you can manage it easily."
                            onOpenModal={() => setIsNewPromoModalOpen(true)}
                            onOpenFilterModal={() => setIsPromoFilterModalOpen(true)}
                            openModalButtonLabel="Add New Promo"
                            searchPlaceholder={"Search Promo"}
                            image={promoBanner}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Promo"}
                            isRefreshActive={isRefreshActive}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                            onHandleDeleteFilter={handleDeleteFilter}
                            isDisable={true} // disabled due to unready feature
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={topNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Promo Table for Mobile */}
                        <MobilePromoTable
                            promoData={promoData}
                            onOpenModal={setIsNewPromoModalOpen}
                        />

                        {/* Promo Table for Tablet & Desktop */}
                        <TabletDesktopPromoTable
                            promoData={displayedPromos}
                            allPromos={promos}
                            promoNameOptions={promoNameOptions}
                            roleOptions={roleOptions}
                            dateAddedOptions={dateAddedOptions}
                            dateExpireddOptions={dateExpireddOptions}
                            kuotaOptions={kuotaOptions}
                            statusOptions={statusOptions}
                            onDeletePromo={deletePromos}
                            onGetAllPromos={getAllPromos}
                            promoId={promoId}
                            setPromoId={setPromoId}
                            setIsDetailPromoModalOPen={setIsDetailPromoModalOpen}
                        />
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between bg-neutral-gray">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing{" "}
                                <span className="text-clinder">{displayedPromos?.length}</span> of{" "}
                                <span className="text-clinder">{totalData}</span> Promo
                                {totalData > 1 && "s"}
                            </p>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isPageNumberColorGray={false}
                            />
                        </div>
                    </div>
                </main>
            </div>

            {/* Add New Promo Modal */}
            <NewPromoModal
                isModalOpen={isNewPromoModalOpen}
                setIsModalOpen={setIsNewPromoModalOpen}
                onGetAllPromos={getAllPromos}
            />

            {/* Detail Promo Modal */}
            <DetailPromoModal
                isModalOpen={isDetailPromoModalOpen}
                setIsModalOpen={setIsDetailPromoModalOpen}
                promoId={promoId}
                onGetAllPromos={getAllPromos}
            />

            {/* Filter Promo Modal */}
            <PromoFilterModal
                isModalOpen={isPromoFilterModalOpen}
                setIsModalOpen={setIsPromoFilterModalOpen}
                setIsOptionFilterActive={setIsOptionFilterActive}
                isOptionFilterToggleActive={isOptionFilterToggleActive}
                setIsOptionFilterToggleActive={setIsOptionFilterToggleActive}
                customerLevels={customerLevels}
                selectedStatusFilter={selectedStatusFilter}
                setSelectedStatusFilter={setSelectedStatusFilter}
                selectedCustomerLevelFilter={selectedCustomerLevelFilter}
                setSelectedCusomerLevelFilter={setSelectedCustomerLevelFilter}
                promos={promos}
                optionFilteredPromos={optionFilteredPromos}
                setOptionFilteredPromo={setOptionFilteredPromos}
            />
        </div>
    );
};

export default CustomerPromo;
