import React from 'react';

import SelectOptionDropdown from '../../../components/DoubleIndicatorDropdown';
import shieldSearch from '../../../assets/icons/shieldSearch.svg';
import formatNumberWithCommas from '../../../utils/formatNumber';

const TabletDesktopTransactionTable = ({
	transactionData,
	transactionOrderIdOptions,
	transactionCustomerOptions,
	transactionItemOptions,
	transactionDateOptions,
	transactionTotalOptions,
	setTransactionId,
	setDetailTransactionModalOpen,
	setCustomerName
}) => {
	return (
		<div className='hidden w-full mt-6 mb-3 md:block'>
			<table className='w-full border-collapse table-auto'>
				<thead className='bg-neutral-gray'>
					<tr>
						<th id='order-id' className=''>
							<SelectOptionDropdown options={transactionOrderIdOptions} />
						</th>
						<th id='customer' className=''>
							<SelectOptionDropdown options={transactionCustomerOptions} />
						</th>
						<th id='items' className=''>
							<SelectOptionDropdown options={transactionItemOptions} />
						</th>
						<th id='date' className=''>
							<SelectOptionDropdown options={transactionDateOptions} />
						</th>
						<th id='total-transaction' className=''>
							<SelectOptionDropdown options={transactionTotalOptions} />
						</th>
						<th
							id='action'
							className='text-sm font-medium font-quicksand text-manatee text-start'>
							Action
						</th>
					</tr>
				</thead>
				<tbody className='mt-[14px]'>
					{transactionData.map((transaction, index) => (
						<tr key={index}>
							<td aria-labelledby='order-id' className='p-[10px]'>
								<div
									className={`flex gap-3 items-center ${
										index === 0 ? 'mt-[14px]' : 'mt-2'
									}`}>
									<h3 className='text-sm font-semibold text-clinder font-quicksand leading-[150%]'>
										#{transaction.orderId}
									</h3>
								</div>
							</td>
							<td aria-labelledby='cutomer' className='p-[10px]'>
								<div
									className={`flex gap-3 items-center ${
										index === 0 ? 'mt-[14px]' : 'mt-2'
									}`}>
									<h3 className='text-sm font-semibold text-clinder font-quicksand leading-[150%]'>
										{transaction.customer}
									</h3>
								</div>
							</td>
							<td aria-labelledby='items' className='p-[10px]'>
								<div
									className={`flex gap-3 items-center ${
										index === 0 ? 'mt-[14px]' : 'mt-2'
									}`}>
									<h3 className='text-sm font-semibold text-clinder font-quicksand leading-[150%]'>
										{transaction.item} Item
									</h3>
								</div>
							</td>
							<td aria-labelledby='date' className='p-[10px]'>
								<div
									className={`flex gap-3 items-center ${
										index === 0 ? 'mt-[14px]' : 'mt-2'
									}`}>
									<h3 className='text-sm font-semibold text-clinder font-quicksand leading-[150%]'>
										{transaction.date}
									</h3>
								</div>
							</td>
							<td aria-labelledby='total-transaction' className='p-[10px]'>
								<div
									className={`flex gap-3 items-center ${
										index === 0 ? 'mt-[14px]' : 'mt-2'
									}`}>
									<h3 className='text-sm font-semibold text-[#f09448] font-quicksand leading-[150%]'>
										IDR{' '}
										{formatNumberWithCommas(transaction.total.toLocaleString())}
									</h3>
								</div>
							</td>
							<td aria-labelledby='action' className='p-[10px]'>
								<div
									className={`flex items-center justify-center gap-1 mt-2 ${
										index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
									}`}>
									<button
										onClick={() => {
											setTransactionId(transaction.orderId);
											setCustomerName(transaction.customer);
											setDetailTransactionModalOpen(true);
										}}
										type='button'
										className='items-center justify-center flex-1 flex py-1 duration-300 transform rounded bg-neutral-gray hover:bg-gray-200'>
										<img
											src={shieldSearch}
											alt='shield search'
											className='w-3 h-3'
										/>
										<span className='ml-2 text-sm font-semibold font-quicksand text-clinder hidden lg:flex'>
											Detail
										</span>
									</button>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TabletDesktopTransactionTable;
