import React, { useEffect, useState } from 'react';
import tickSuccess from '../assets/icons/tickSuccess.gif';

const SmallSuccessNotification = ({
  isModalOpen,
  setIsModalOpen,
  notificationMessage,
  notificationCountDown,
}) => {
  const [countDown, setCountDown] = useState(0);
  useEffect(() => {
    if (isModalOpen) {
      setCountDown(notificationCountDown);
    }
  }, [isModalOpen]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown((notificationCountDown) => notificationCountDown - 1);
    }, 1000);

    if (countDown === 0) {
      setIsModalOpen(false);

      // console.log({ isModalOpen });
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [countDown]);

  return (
    <>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-clinder/60"
          onClick={() => setIsModalOpen(false)}
        >
          <div className="bg-white w-[342px] md:w-[579px] py-10 rounded-lg">
            <img
              src={tickSuccess}
              alt="success"
              className="w-[187px] h-[187px] mx-auto"
            />
            <p className="text-xl font-semibold text-center font-quicksand text-clinder">
              {notificationMessage}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SmallSuccessNotification;
