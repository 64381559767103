import React, { useEffect, useState } from "react";

import CategoryOptionsDropdown from "../menu/CategoryOptionsDropdown";
import ProductOptionsDropdown from "./ProductOptionsDropdown";

import close from "../../../assets/icons/close.svg";
import plus from "../../../assets/icons/plus.svg";
import axiosInstance from "../../../utils/api/apiConfig";

const AddNotesModal = ({ isModalOpen, setIsModalOpen, onGetAllNotes }) => {
    // === Additional Notes Data ===
    const [additionalNotes, setAdditionalNotes] = useState("");
    const [productId, setProductId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [productCategoryName, setProductCategoryName] = useState("");
    const [productCategoryId, setProductCategoryId] = useState(null);
    const [productName, setProductName] = useState("");
    const [products, setProducts] = useState([]);
    const [notesTarget, setNotesTarget] = useState("constant");

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const handleSubmitNotesData = async (e) => {
        e.preventDefault();

        const bodyRequest = {
            additionalNotes,
            productCategoryId,
            productId,
        };

        try {
            const response = await axiosInstance.post(`/product-notes`, bodyRequest, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setAdditionalNotes("");
            setProductCategoryId(null);
            setProductCategoryName("");
            setProductId(null);
            setProductName("");
            setNotesTarget("category");
            onGetAllNotes();
            setIsModalOpen(false);
            window.scrollTo(0, 0);
        } catch (error) {
            // console.log(error);
        }
    };

    // Handle Get All Categories
    useEffect(() => {
        const getAllCategories = async () => {
            // setIsLoadingGetCategories(true);
            try {
                const response = await axiosInstance.get(`/product-categories`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-TENANT-ID": tenantId,
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCategories(response.data.data.results);
            } catch (error) {
                // console.log(error);
                // setIsLoadingGetCategories(false);
            }
        };

        getAllCategories();
    }, []);

    // Handle get all products
    useEffect(() => {
        getAllProducts();
    }, []);
    const getAllProducts = async () => {
        // setIsLoadingGetAllMenus(true);
        try {
            const response = await axiosInstance.get(`/products`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setProducts(response.data.data);
            // setIsLoadingGetAllMenus(false);
        } catch (error) {
            // console.log(error);
            // setIsLoadingGetAllMenus(false);
        }
    };

    return (
        <>
            <div
                className={`fixed inset-0 z-50 flex justify-end bg-black/60 ${
                    !isModalOpen && "hidden"
                }`}
                onClick={() => {
                    window.scrollTo(0, 0);
                    setIsModalOpen(false);
                }}
            />
            <div
                className={`bg-white w-full md:w-[600px] overflow-x-hidden h-screen overflow-y-auto fixed top-0 right-0 z-50 duration-300 transition ${
                    isModalOpen ? "translate-x-0" : "translate-x-full"
                }`}
                onClick={(e) => e.stopPropagation()}
            >
                {/* Modal Header */}
                <header className="bg-white w-full md:w-[600px] sticky top-0 pt-6 lg:pt-9 px-0 md:px-6 lg:px-9 z-20">
                    <div className="pb-6 border-b-[1px] border-gray-x/40">
                        <div className="flex flex-row-reverse justify-end w-full gap-6 px-6 md:px-0 md:flex-row md:justify-between">
                            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                                Add New Additional Notes
                            </h2>
                            <button
                                type="button"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    setIsModalOpen(false);
                                }}
                            >
                                <img src={close} alt="close" />
                            </button>
                        </div>
                        <p className="w-5/6 mt-2 text-sm font-medium font-satoshi text-manatee leading-[200%] hidden md:block">
                            in this window, you can change and add additional notes to your menu. So
                            it can make easier to use.
                        </p>
                    </div>
                </header>

                {/* Modal Form */}
                <form className="flex flex-col justify-between h-auto min-h-full p-6 lg:p-9">
                    <div>
                        <div className="flex p-2 mt-4 rounded-lg bg-neutral-gray gap-[10px]">
                            {/* <button
                                disabled
                                type="button"
                                className={`transition duration-300 ${
                                    notesTarget === "category"
                                        ? "bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] font-bold text-clinder"
                                        : "font-semibold text-manatee hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:font-bold hover:text-clinder"
                                } py-[6px] w-full rounded font-quicksand  text-xs`}
                                onClick={() => setNotesTarget("category")}
                            >
                                Notes for Category 
                            </button> */}
                            <button
                                type="button"
                                className={`duration-300 transition ${
                                    notesTarget === "category"
                                        ? "font-semibold text-manatee hover:font-bold hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:text-clinder"
                                        : "font-bold bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-clinder"
                                } py-[6px] w-full rounded font-quicksand text-xs`}
                                onClick={() => setNotesTarget("constant")}
                            >
                                Notes for Menu
                            </button>
                        </div>
                        {notesTarget === "category" ? (
                            // If notes target is Category
                            <div className="mt-6">
                                <label
                                    htmlFor="note-for-category"
                                    className="block mb-2 text-sm font-semibold text-clinder font-quicksand"
                                >
                                    <span>Choose Category</span>{" "}
                                </label>
                                <CategoryOptionsDropdown
                                    options={categories}
                                    label={"Choose category you want to add notes in it"}
                                    setSelectedId={setProductCategoryId}
                                    selectedValue={productCategoryName}
                                    setSelectedValue={setProductCategoryName}
                                    optionHeight={"h-[155px]"}
                                />
                            </div>
                        ) : (
                            // If notes target is Menu
                            <>
                                <div className="mt-6">
                                    <label
                                        htmlFor="note-for-category"
                                        className="block mb-2 text-sm font-semibold text-clinder font-quicksand"
                                    >
                                        <span>Choose Menu</span>{" "}
                                    </label>
                                </div>
                                <ProductOptionsDropdown
                                    options={products}
                                    label={"Choose menu you want to add notes in it"}
                                    setSelectedId={setProductId}
                                    selectedValue={productName}
                                    setSelectedValue={setProductName}
                                    optionHeight={"h-[155px]"}
                                />
                            </>
                        )}

                        <div className="mt-3">
                            <label
                                htmlFor="additional-note"
                                className="block text-sm font-semibold text-clinder font-quicksand"
                            >
                                Additional Note
                            </label>
                            <textarea
                                name="additional-note"
                                value={additionalNotes}
                                id="additional-note"
                                required
                                placeholder="Write your note here"
                                className="w-full px-4 py-3 mt-2 resize-none h-52 focusable-input"
                                onChange={(e) => setAdditionalNotes(e.target.value)}
                            />
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="flex w-full mb-10 text-base text-white font-quicksand button-primary-lg mt-9 xl:mb-0"
                        onClick={handleSubmitNotesData}
                    >
                        <img src={plus} alt="plus" />
                        <span>Add New Additional Notes</span>
                    </button>
                </form>
            </div>
        </>
    );
};

export default AddNotesModal;
