import React from 'react';

import loading from '../assets/icons/loading.gif';

const Loading = () => {
  return (
    <div className="flex items-center justify-center flex-1 mt-3 bg-white opacity-60">
      <img src={loading} className="h-[200px] w-[200px]" alt="loading" />
    </div>
  );
};

export default Loading;
