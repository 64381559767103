import React, { useEffect, useState } from 'react';

import MenuSelectionCheckbox from './MenuSelectionCheckbox';

import close from '../../../assets/icons/close.svg';
import search from '../../../assets/icons/search.svg';
import addSquare from '../../../assets/icons/addSquare.svg';

const MenuSelectionModal = ({
  isModalOpen,
  setIsModalOpen,
  allMenus,
  selectedMenusToGet,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMenusToPost, setSelectedMenusToPost] = useState([]);

  //   Handle default value for selected menus
  // useEffect(() => {
  //   // if (isModalOpen) {
  //   setSelectedMenusToPost(selectedMenusToGet);
  //   // }
  // }, [isModalOpen]);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  // Handle Submit Selected Base Product to the Menu
  const handleSubmitSelectMenusToPackageMenu = () => {
    // console.log('selected ToPost', selectedMenusToPost);
    const bodyRequest = selectedMenusToPost;

    // console.log({ bodyRequest });

    // try {
    //   const response = await axiosInstance.post(
    //     `/product-details`,
    //     bodyRequest,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'X-TENANT-ID': tenantId,
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   setIsBaseProductAddedToRecipeUpdated(!isBaseProductAddedToRecipeUpdated);
    setSelectedMenusToPost([]);
    //   setIsSelectionReset(!isSelectionReset);
    //   // console.log(response);
    //   setIsModalOpen(false);
    // } catch (error) {
    //   // console.log(error);
    // }
  };

  // Handle  search filter to filter menu
  const menuSearchFilter = (menus) => {
    return menus?.filter((item) =>
      item?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <>
      <div
        className={`fixed inset-0 top-0 z-50 right-0 bg-clinder/60 flex justify-end ${
          !isModalOpen && 'hidden'
        } `}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />

      <div
        className={`fixed top-0 right-0 z-50 px-6 md:px-9 h-screen  bg-transparent rounded-none w-full  duration-300 transition-all overflow-y-auto flex items-center justify-center ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      >
        <div
          className={`z-50 px-6  h-[88%] rounded-lg bg-white w-full md:w-[576px] duration-300 transition-all overflow-y-auto flex flex-col ${
            isModalOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="sticky top-0 z-30 pt-6 bg-white">
            <div className="flex items-center justify-between gap-6 md:flex-row md:justify-between">
              <div className="flex items-center gap-4">
                <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                  Choose & Select Menu
                </h2>
              </div>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-quicksand text-manatee hidden md:block">
              Please choose and select menu that you want to edit or add status
              price and app price!
            </p>
            <div className="flex items-center w-full gap-4 px-4 py-2 mt-6 rounded-lg bg-neutral-gray">
              <img src={search} alt="search" />
              <input
                type="text"
                placeholder={'Search Menu'}
                value={searchQuery}
                className="w-full px-2 py-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand plselectedLevelsaceholder:text-sm placeholder:font-medium"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* Customer levell Selections */}
          <div className="flex-1 mt-3 overflow-y-auto rounded-lg bg-white shadow-[inset_0px_4px_20px_0px_rgba(0,0,0,0.05)] p-3">
            {menuSearchFilter(allMenus)?.map((menu, menuIndex) => (
              // {allMenus?.map((menu, menuIndex) => (
              <MenuSelectionCheckbox
                key={menuIndex}
                menuIndex={menuIndex}
                menuName={menu?.name}
                menuId={menu?.id}
                isChoosingMenuModalOpen={isModalOpen}
                selectedMenusToPost={selectedMenusToPost}
                setSelectedMenusToPost={setSelectedMenusToPost}
                selectedMenusToGet={selectedMenusToGet}
              />
            ))}
          </div>
          <div className="sticky bottom-0 py-6 bg-white">
            <button
              type="button"
              className="w-full py-4 px-9 button-primary-lg"
              onClick={handleSubmitSelectMenusToPackageMenu}
            >
              <img src={addSquare} alt="plus icon" />
              <span className="font-bold text-white font-quicksand">
                Add {selectedMenusToPost?.length} Menu
                {selectedMenusToPost?.length > 1 && 's'}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuSelectionModal;
