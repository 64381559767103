import React, { useState } from 'react';

import axiosInstance from '../../../utils/api/apiConfig';

import BaseProductSelectionCheckbox from './BaseProductSelectionCheckbox';

import close from '../../../assets/icons/close.svg';
import search from '../../../assets/icons/search.svg';
import addSquare from '../../../assets/icons/addSquare.svg';

const BaseProductSelectionModal = ({
  isModalOpen,
  setIsModalOpen,
  productId,
  allBaseProducts,
  isBaseProductAddedToRecipeUpdated,
  setIsBaseProductAddedToRecipeUpdated,
}) => {
  // === Base Product Selected Data ===
  const [selectedBaseProductsToPost, setSelectedBaseProductsToPost] = useState(
    []
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [isSelectionReset, setIsSelectionReset] = useState(false);
  // // console.log('selectedBaseProductsToPost', selectedBaseProductsToPost);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  // Handle Submit Selected Base Product to the Menu
  const handleSubmitSelectBaseProductToMenu = async () => {
    const bodyRequest = selectedBaseProductsToPost;

    try {
      const response = await axiosInstance.post(
        `/product-details`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsBaseProductAddedToRecipeUpdated(!isBaseProductAddedToRecipeUpdated);
      setSelectedBaseProductsToPost([]);
      setIsSelectionReset(!isSelectionReset);
      setIsModalOpen(false);
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle search filter to filter base product
  const recipeSearchFilter = (data) => {
    return data.filter((item) =>
      item.baseProductName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <>
      <div
        className={`fixed inset-0 top-0 z-50 right-0 bg-clinder/60 flex justify-end ${
          !isModalOpen && 'hidden'
        } `}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />

      <div
        className={`fixed top-0 right-0 z-50 px-6 md:px-9 h-screen  bg-transparent rounded-none w-full md:w-[650px] lg:w-[800px] duration-300 transition-all overflow-y-auto flex items-center justify-center ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      >
        <div
          className={`z-50 px-6  h-[88%] rounded-lg bg-white w-full md:w-[576px] duration-300 transition-all overflow-y-auto flex flex-col ${
            isModalOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="sticky top-0 z-30 pt-6 bg-white">
            <div className="flex items-center justify-between gap-6 md:flex-row md:justify-between">
              <div className="flex items-center gap-4">
                <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                  Choose & Select Base Product
                </h2>
              </div>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
              Please select and add base product to create recipe of the product
              by checking the base product listed below.
            </p>
            <div className="flex items-center w-full gap-4 px-4 py-2 mt-6 rounded-lg bg-neutral-gray">
              <img src={search} alt="search" />
              <input
                type="text"
                placeholder={'Search Base Product'}
                className="w-full px-2 py-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          {/* Base Product Selections */}
          <div className="flex-1 mt-3 overflow-y-auto rounded-lg bg-white shadow-[inset_0px_4px_20px_0px_rgba(0,0,0,0.05)] p-3">
            {recipeSearchFilter(allBaseProducts).map(
              (baseProduct, baseProductIndex) => (
                <BaseProductSelectionCheckbox
                  key={baseProductIndex}
                  productId={productId}
                  baseProductName={baseProduct.baseProductName}
                  baseProductId={baseProduct.baseProductId}
                  baseProductUnitSystem={baseProduct.unitSystem}
                  setSelectedBaseProductsToPost={setSelectedBaseProductsToPost}
                  isSelectionReset={isSelectionReset}
                />
              )
            )}
          </div>
          <div className="sticky bottom-0 py-6 bg-white">
            <button
              type="button"
              className="w-full py-4 px-9 button-primary-lg"
              onClick={() => {
                handleSubmitSelectBaseProductToMenu();
              }}
            >
              <img src={addSquare} alt="plus icon" />
              <span className="font-bold text-white font-quicksand">
                Add {selectedBaseProductsToPost.length} Item
                {selectedBaseProductsToPost.length > 1 && 's'} to Recipe
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseProductSelectionModal;
