import React, { useEffect, useState } from "react";

import InfoBox from "../../InfoBox";
import SingleIndicatorDropdownById from "./SingleIndicatorDropdownById";
import NewAreaOfCategoryModal from "./NewAreaOfCategoryModal";

import plus from "../../../assets/icons/plus.svg";
import close from "../../../assets/icons/close.svg";
import plusGradient from "../../../assets/icons/plusGradient.svg";
import axiosInstance from "../../../utils/api/apiConfig";

const EditCategoryModal = ({
    isModalOpen,
    setIsModalOpen,
    areasOfCategory,
    onGetAllCategories,
    isAreaOfCategoryUpdate,
    setIsAreaOfCategoryUpdate,
    categoryId,
}) => {
    // === Category Data ===
    const [categoryName, setCategoryName] = useState("");
    const [areaOfCategoryId, setAreaOfCategoryId] = useState(0);
    const [areaOfCategoryName, setAreaOfCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [showOnApp, setShowOnApp] = useState(false);

    // === State Condition ===
    const [isNewAreaOfCategoryModalOpen, setIsNewAreaOfCategoryModalOpen] = useState(false);

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("userId");

    // Handle Submit Category Data
    const handleEditCategoryData = async (e) => {
        e.preventDefault();

        const bodyRequest = {
            name: categoryName,
            areaOfCategoryId,
            categoryDescription,
            userId,
            showOnApp,
        };

        try {
            const response = await axiosInstance.put(
                `/product-categories/${categoryId}`,
                bodyRequest,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-TENANT-ID": tenantId,
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            onGetAllCategories();
            setIsModalOpen(false);
            window.scrollTo(0, 0);
            // console.log(response.data.data);
        } catch (error) {
            // console.log(error);
        }
    };

    // Handle Get Category by Id
    useEffect(() => {
        getCategoryById(categoryId);
    }, [categoryId]);
    const getCategoryById = async (id) => {
        // setIsLoadingGetCategoryById(true);
        try {
            const response = await axiosInstance.get(`/product-categories/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            const { name, description, areaOfCategoryName, showOnApp } = response.data.data;
            setCategoryName(name);
            setCategoryDescription(description);
            setAreaOfCategoryName(areaOfCategoryName);
            setShowOnApp(showOnApp);
        } catch (error) {
            // console.log(error);
            //   setIsLoadingGetCategoryById(false);
        }
    };

    return (
        <>
            <div
                className={`fixed inset-0 top-0 z-50 right-0 bg-clinder/60 flex justify-end ${
                    !isModalOpen && "hidden"
                } `}
                onClick={() => {
                    window.scrollTo(0, 0);
                    setIsModalOpen(false);
                }}
            />
            <div
                className={`fixed top-0 right-0 z-50 px-6 pb-6 md:px-9 md:pb-9 bg-white rounded-none w-full md:w-[600px] duration-300 transition-all overflow-y-auto flex flex-col h-screen ${
                    isModalOpen ? "translate-x-0" : "translate-x-full"
                }`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="px-6 pt-6 md:px-0 md:pt-9 pb-6 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
                    <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
                        <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                            Edit Category
                        </h2>
                        <button
                            onClick={() => {
                                setIsModalOpen(false);
                                window.scrollTo(0, 0);
                            }}
                        >
                            <img src={close} alt="close" />
                        </button>
                    </div>
                    <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
                        In this window you can manage to add categories to the menus that you will
                        create later.
                    </p>
                </div>
                <form className="flex flex-col justify-between flex-1 pt-6">
                    <div>
                        <div className="mb-6">
                            <label
                                htmlFor="category-nama"
                                className="text-sm font-semibold font-quicksand text-clinder"
                            >
                                Category Name
                            </label>{" "}
                            <br />
                            <input
                                type="text"
                                id="category-nama"
                                required={true}
                                value={categoryName}
                                placeholder="Category name"
                                className="w-full px-4 py-3 mt-2 focusable-input"
                                onChange={(e) => setCategoryName(e.target.value)}
                            />
                        </div>
                        <div className="mb-6">
                            <div className="flex items-center justify-between mb-2">
                                <div className="flex">
                                    <label
                                        htmlFor="area-of-category"
                                        className="text-sm font-semibold font-quicksand text-clinder mr-[10px]"
                                    >
                                        Area of Category
                                    </label>{" "}
                                    <InfoBox
                                        title={"Area of Content"}
                                        content={
                                            "area of category is the area where the category will be placed. If you have more than One kitchen, you can use them."
                                        }
                                        customLeftPosition={"-left-32 xs:-left-24 sm:left-5"}
                                        customTopPosition={"top-4 sm:-top-3"}
                                    />
                                </div>
                                <div className="inline text-end">
                                    <button
                                        type="button"
                                        className="flex px-6 py-3 border-[1px] border-blue-400 hover:bg-forested-mind rounded-lg cursor-pointer gap-3"
                                        onClick={() => setIsNewAreaOfCategoryModalOpen(true)}
                                    >
                                        <img
                                            src={plusGradient}
                                            alt="plus"
                                            className="hidden md:block"
                                        />
                                        <span className="text-xs font-bold font-quicksand text-aurora-blue">
                                            Add New Area
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <SingleIndicatorDropdownById
                                label={"Area of Category"}
                                options={areasOfCategory}
                                selected={areaOfCategoryId}
                                setSelectedId={setAreaOfCategoryId}
                                selectedValue={areaOfCategoryName}
                                setSelectedValue={setAreaOfCategoryName}
                                optionHeight={"h-[170px]"}
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                htmlFor="category-description"
                                className="text-sm font-semibold font-quicksand text-clinder"
                            >
                                Category Description (optional)
                            </label>{" "}
                            <br />
                            <textarea
                                id="category-description"
                                required={true}
                                value={categoryDescription}
                                rows="10"
                                placeholder="Describe your new category"
                                className="w-full px-4 py-3 mt-2 focusable-input"
                                onChange={(e) => setCategoryDescription(e.target.value)}
                            />
                        </div>
                        <div
                            className="flex items-center p-3 rounded-[4px] bg-dew border-[0.5px] border-gray-x cursor-pointer"
                            onClick={() => setShowOnApp(!showOnApp)}
                        >
                            <input
                                type="checkbox"
                                checked={showOnApp}
                                value={true}
                                id="checklist"
                                className="w-6 h-6"
                                onChange={(e) => setShowOnApp(e.target.checked)}
                            />
                            <p className="ml-2 text-sm font-semibold text-clinder font-quicksand">
                                Show this Category on the Betty Cashier App
                            </p>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full py-4 mb-10 xl:mb-0 mt-9 px-9 button-primary-lg"
                        onClick={handleEditCategoryData}
                    >
                        <img src={plus} alt="plus icon" />
                        <span className="ml-3 font-bold text-white font-quicksand">
                            {" "}
                            Add New Category{" "}
                        </span>
                    </button>
                </form>
            </div>

            {/* Add Area of Category Section */}
            <NewAreaOfCategoryModal
                isModalOpen={isNewAreaOfCategoryModalOpen}
                setIsModalOpen={setIsNewAreaOfCategoryModalOpen}
                setIsNewCategoryModalOpen={setIsModalOpen}
                isAreaOfCategoryUpdate={isAreaOfCategoryUpdate}
                setIsAreaOfCategoryUpdate={setIsAreaOfCategoryUpdate}
            />
        </>
    );
};

export default EditCategoryModal;
