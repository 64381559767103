import React, { useState } from 'react';

import close from '../../assets/icons/close.svg';
import axiosInstance from '../../utils/api/apiConfig';

const NewTableDesign = ({
	isModalOpen,
	setIsModalOpen,
	onGetAllTableSections
}) => {
	const [sectionName, setSectionName] = useState('');
	const [tableAmount, setTableAmount] = useState('');

	// === Data on Local Storage ===
	const token = localStorage.getItem('token');
	const tenantId = localStorage.getItem('tenantId');

	// Handle generate table amount
	const generateTableAmount = (tableAmount, id) => {
		let tableData = [];

		for (var i = 0; i < tableAmount; i++) {
			tableData.push({ placeId: id, number: i + 1, tableStatus: 'available' });
		}

		return tableData;
	};

	// Handle Submit base product Data
	const handleSubmitBaseProductData = async e => {
		e.preventDefault();

		const formData = new FormData();
		const defaultImage = new File([''], '../../assets/icons/plus.svg', {
			type: 'image/png'
		});

		formData.append('placeName', sectionName);
		formData.append('file', defaultImage);
		formData.append('reservationPrice', 0);
		formData.append('description', '');
		// console.log(formData);

		try {
			const response = await axiosInstance.post(`/table-positions`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-TENANT-ID': tenantId,
					Authorization: `Bearer ${token}`
				}
			});

			// console.log(response.data.data);

			const datatable = await axiosInstance.post(
				`/table-data`,
				generateTableAmount(tableAmount, response.data.data.id),
				{
					headers: {
						'Content-Type': 'application/json',
						'X-TENANT-ID': tenantId,
						Authorization: `Bearer ${token}`
					}
				}
			);
			onGetAllTableSections();
		} catch (error) {
			// console.log(error);
		} finally {
			setIsModalOpen(false);
			window.scrollTo(0, 0);
		}
	};

	return (
		<>
			<div
				className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
					!isModalOpen && 'hidden'
				}`}
				onClick={() => {
					window.scrollTo(0, 0);
					setIsModalOpen(false);
				}}
			/>
			<div
				className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
					isModalOpen ? 'translate-x-0' : 'translate-x-full'
				}`}
				onClick={e => e.stopPropagation()}>
				<div className='px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30'>
					<div className='flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between'>
						<h2 className='text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand'>
							Add New Table Design
						</h2>
						<button
							onClick={() => {
								window.scrollTo(0, 0);
								setIsModalOpen(false);
							}}>
							<img src={close} alt='close' />
						</button>
					</div>
					<p className='w-[90%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block'>
						In this window you can add amount of table in every section. You
						name the section with floor or room.
					</p>
				</div>
				<form className='flex flex-col justify-between flex-1 pt-6'>
					<div>
						<div className='mb-6'>
							<label
								htmlFor='section-name'
								className='text-sm font-semibold font-quicksand text-clinder'>
								Section Name
							</label>{' '}
							<br />
							<input
								value={sectionName}
								type='text'
								id='section-name'
								required
								placeholder='ex: "1st floor" or "Smoking Room"'
								className='w-full px-4 py-3 mt-2 focusable-input'
								onChange={e => setSectionName(e.target.value)}
							/>
						</div>
						<div className='mb-6'>
							<label
								htmlFor='table-amount'
								className='text-sm font-semibold font-quicksand text-clinder'>
								Amount of table in{' '}
								<span className='capitalize text-aurora-blue'>
									{sectionName === '' ? 'Section Name' : sectionName}
								</span>
							</label>{' '}
							<br />
							<input
								value={tableAmount}
								type='number'
								id='table-amount'
								required
								placeholder='Input how munch table do you want'
								className='w-full px-4 py-3 mt-2 focusable-input'
								onChange={e => setTableAmount(e.target.value)}
							/>
						</div>
					</div>
					<button
						type='submit'
						className='w-full mb-10 mt-9 button-primary-lg xl:mb-0'
						onClick={handleSubmitBaseProductData}>
						<span className='font-bold text-white font-quicksand'>
							{' '}
							Add Table
						</span>
					</button>
				</form>
			</div>
		</>
	);
};

export default NewTableDesign;
