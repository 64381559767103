import React from 'react';

import trashRed from '../assets/icons/trashRed.svg';
import { AiOutlineClose } from 'react-icons/ai';

const DeleteWithPasswordConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  shortDeleteConfirmatonMessage,
  longDeleteConfirmatonMessage,
  longDeleteButtonLabel,
  setIsConfirmationPasswordModalOpen,
}) => {
  return (
    <>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-clinder/60"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="bg-white w-[342px] md:w-[579px] rounded-lg p-8"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="items-center justify-between pb-6 border-b-[1px] border-gray-x hidden md:flex">
              <h2 className="text-xl font-bold font-quicksand text-black-coal">
                Action Confirmation
              </h2>
              <button
                className="text-xl font-medium text-manatee font-quicksand"
                onClick={() => setIsModalOpen(false)}
              >
                <AiOutlineClose size={25} />
              </button>
            </div>
            <p className="block w-full text-base font-bold text-center md:hidden font-quicksand text-clinder">
              {shortDeleteConfirmatonMessage}
            </p>
            <p className="hidden md:block text-xl font-bold text-center mt-8 font-quicksand w-[366px] mx-auto text-clinder">
              {longDeleteConfirmatonMessage}
            </p>
            <div className="flex items-center justify-center mt-8 gap-4 md:gap-[10px]">
              <button
                className="flex items-center justify-center flex-1 w-4/5 gap-4 px-3 py-4 rounded md:flex-none bg-coral"
                onClick={() => {
                  setIsConfirmationPasswordModalOpen(true);
                }}
              >
                <img
                  src={trashRed}
                  className="hidden md:block"
                  alt="trash red"
                />{' '}
                <span className="inline text-base font-semibold font-quicksand text-red-dark md:hidden">
                  Delete
                </span>
                <span className="hidden text-base font-semibold font-quicksand text-red-dark md:inline">
                  {longDeleteButtonLabel}
                </span>
              </button>
              <button
                className="flex-1 p-4 text-base font-semibold rounded font-quicksand bg-neutral-gray text-black-coal"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteWithPasswordConfirmationModal;
