import React, { useEffect } from "react";
import { useState } from "react";

import axiosInstance from "../../utils/api/apiConfig";

import NewFavoriteMenuModal from "../../components/stock/favorite_menu/NewFavoriteMenuModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import FavoriteMenusList from "../../components/stock/favorite_menu/FavoriteMenusList";

import favoriteMenuBanner from "../../assets/images/favoriteMenuBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const FavoriteMenu = () => {
    // === Favorite Menus Data ===
    const [favoriteMenus, setFavoriteMenus] = useState([]);
    const [favoriteId, setFavoriteId] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedDay, setSelectedDay] = useState("monday");
    const [selectedDayId, setSelectedDayId] = useState(1);
    const days = [
        {
            id: 1,
            value: "monday",
        },
        {
            id: 2,
            value: "tuesday",
        },
        {
            id: 3,
            value: "wednesday",
        },
        {
            id: 4,
            value: "thursday",
        },
        {
            id: 5,
            value: "friday",
        },
        {
            id: 6,
            value: "saturday",
        },
        {
            id: 7,
            value: "sunday",
        },
    ];
    const [isRefreshActive, setIsRefreshActive] = useState(false);

    // === Component State ===
    const [activeTopNav, setActiveTopNav] = useState("favorite-menu");
    const topNavs = [
        { nav: "Category", value: "category", route: "admin-stock-category" },
        {
            nav: "Base Product",
            value: "base-product",
            route: "admin-stock-base-product",
        },
        { nav: "Menu", value: "menu", route: "admin-stock-menu" },
        { nav: "Price", value: "price", route: "admin-stock-price" },
        {
            nav: "Favorite Menu",
            value: "favorite-menu",
            route: "admin-stock-favorite-menu",
        },
        {
            nav: "Additional Notes",
            value: "additional-notes",
            route: "admin-stock-additional-notes",
        },
    ];

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const [today, setToday] = useState("monday");
    const [isDeleteFavoriteMenuModalOpen, setIsDeleteFavoriteMenuModalOpen] = useState(false);
    const [isNewFavoriteMenuModalOpen, setIsNewFavoriteMenuModalOpen] = useState(false);

    // Handle Get Favorite Menu By Day
    useEffect(() => {
        getFavoriteMenuByDay(selectedDayId);
    }, [selectedDayId, isNewFavoriteMenuModalOpen]);
    const getFavoriteMenuByDay = async (selectedDayId) => {
        try {
            const response = await axiosInstance.get(`/favorites/day/${selectedDayId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setFavoriteMenus(response.data.data);
        } catch (error) {
            // console.log(error);
        }
    };

    // Handle Delete Favorite Menu
    const handleDeleteFavoriteMenu = async (favoriteId) => {
        try {
            const response = await axiosInstance.delete(`/favorites/${favoriteId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getFavoriteMenuByDay(selectedDayId);
        } catch (error) {
            // console.log(error);
        }
    };

    // Handle Search Filter
    const searchFilter = (data) => {
        return data?.filter((item) =>
            item?.product?.name?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );
    };

    // Handle Active Refresh
    useEffect(() => {
        searchQuery !== "" ? setIsRefreshActive(true) : setIsRefreshActive(false);
    }, [searchQuery]);

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="block md:flex">
            <aside className="md:w-[88px] 2xl:w-[200px] flex-none bg-white sticky top-0 z-50 w-full h-[86px]">
                <SideBar isStock={true} />
            </aside>

            <div className="flex-1 w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Product"}
                    menuDescription={"You can manage and organize your product stock here"}
                    topNavs={topNavs}
                />
                <main>
                    <Banner
                        title="Favorite Menu"
                        description="On this menu you will be able to create, edit, and also delete the favorite daily menu. Also you can manage it easily."
                        onOpenModal={() => setIsNewFavoriteMenuModalOpen(true)}
                        openModalButtonLabel="Add New Favorite Menu"
                        searchPlaceholder={"Find your menu here"}
                        image={favoriteMenuBanner}
                        shortPrintButtonLabel={"Print List"}
                        longPrintButtonLabel={"Print List of Favorite Menu"}
                        showFilterButton={false}
                        higher={true}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        // isRefreshActive={isRefreshActive}
                        onHandleDeleteFilter={() => setSearchQuery("")}
                        isDisable={true} // disabled due to unready feature
                    />

                    {/* Navbar for Mobile & Tablet */}
                    <TopNavbar
                        topNavs={topNavs}
                        activeTopNav={activeTopNav}
                        setActiveTopNav={setActiveTopNav}
                    />

                    {/* Favorite Menus List  */}
                    <FavoriteMenusList
                        today={selectedDay}
                        setToday={setSelectedDay}
                        setIsNewFavoriteMenuModalOpen={setIsNewFavoriteMenuModalOpen}
                        favoriteMenus={searchFilter(favoriteMenus)}
                        setIsDeleteFavoriteMenuModalOpen={setIsDeleteFavoriteMenuModalOpen}
                        setSelectedDayId={setSelectedDayId}
                        setFavoriteId={setFavoriteId}
                        days={days}
                        searchQuery={searchQuery}
                    />
                    {/* delete favorite menu modal */}
                    <DeleteConfirmationModal
                        isDeleteConfirmationModalOpen={isDeleteFavoriteMenuModalOpen}
                        setIsDeleteConfirmationModalOpen={setIsDeleteFavoriteMenuModalOpen}
                        shortDeleteConfirmatonMessage={"Delete this menu from favorites?"}
                        longDeleteConfirmatonMessage={
                            "Are you sure you wanto delete this menu from favorites?"
                        }
                        longDeleteButtonLabel={"Yes, Delete menu from Favorites"}
                        onDelete={handleDeleteFavoriteMenu}
                        id={favoriteId}
                    />

                    {/* new favorite menu modal */}
                    <NewFavoriteMenuModal
                        isModalOpen={isNewFavoriteMenuModalOpen}
                        setIsModalOpen={setIsNewFavoriteMenuModalOpen}
                    />
                </main>
            </div>
        </div>
    );
};

export default FavoriteMenu;
