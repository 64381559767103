import { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/api/apiConfig';

const useGetAllTransaction = (token, tenantId) => {
	const [loading, setLoading] = useState(true);
	const [allTransactionData, setAllTransactionData] = useState([]);

	useEffect(() => {
		const getCustomerName = async customerId => {
			try {
				const response = await axiosInstance.get(
					`/customer-credentials/${customerId}`,
					{
						headers: {
							'Content-Type': 'application/json',
							'X-TENANT-ID': tenantId,
							Authorization: `Bearer ${token}`
						}
					}
				);
				return response.data.data.CustomerCredential?.Customer?.fullName;
			} catch (error) {
				// console.log(error);
			}
		};

		const getAllTransaction = async () => {
			try {
				const response = await axiosInstance.get(`/transactions`, {
					headers: {
						'Content-Type': 'application/json',
						'X-TENANT-ID': tenantId,
						Authorization: `Bearer ${token}`
					}
				});

				const responseTrans = response.data.data;
				const buildTrans = responseTrans.map(async t => {
					const customerName = await getCustomerName(t.customerId);
					return {
						orderId: t.id,
						customerId: t.customerId ? t.customerId : 0,
						customer: t.customerId ? customerName : 'Guest',
						item: t.totalqty,
						date: new Date(t.createdAt).toLocaleDateString('id-ID', {
							day: 'numeric',
							month: 'long',
							year: 'numeric'
						}),
						total: parseFloat(t.subtotal)
					};
				});

				// setAllTransactionData(buildTrans);
				const resolvedTrans = await Promise.all(buildTrans);
				setAllTransactionData(resolvedTrans);
			} catch (error) {
				// console.log(error);
			} finally {
				setLoading(false);
			}
		};

		getAllTransaction();
	}, [token, tenantId]);

	return { loading, allTransactionData };
};

export default useGetAllTransaction;
