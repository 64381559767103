export default function formatDefaultDate(rawDate) {
    const date = new Date(rawDate || Date.now());

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    const formattedDate = date.toLocaleDateString("id-ID", options);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}.${minutes}`;

    return `${formattedDate}, ${formattedTime} WIB`;
}

export function formatDateToHour(rawDate) {
    if (!rawDate) return 0;

    const date = new Date(rawDate);

    const hours = date.getHours().toLocaleString().padStart(2, "0");
    const minutes = date.getMinutes().toLocaleString().padStart(2, "0");

    // expected output : 14:12 WIB
    return `${hours}:${minutes} WIB`;
}
                                                     