"use client";

import { useEffect, useState } from "react";
import axiosInstance from "../../utils/api/apiConfig";

const useGetOrderStatistic = (token, tenantId) => {
    const [loading, setLoading] = useState(true);
    const [orderStatsData, setOrderStatsData] = useState();

    useEffect(() => {
        const getOrderStats = async () => {
            try {
                const response = await axiosInstance.get(`/order/statistic`, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-TENANT-ID": tenantId,
                        Authorization: `Bearer ${token}`,
                    },
                });

                setOrderStatsData(response.data.data);
            } catch (error) {
                // console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getOrderStats();
    }, [token, tenantId]);

    return { loading, orderStatsData };
};

export default useGetOrderStatistic;
