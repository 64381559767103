import React from "react";

import DoubleIndicatorDropdown from "../../../components/DoubleIndicatorDropdown";
import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import edit from "../../../assets/icons/edit.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import SingleIndicatorDropdown from "../../SingleIndicatorDropdown";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const TabletDesktopRoleTable = ({
    roleData,
    roleNameOptions,
    numberOfMembersOptions,
    statusOptions,
}) => {
    return (
        <div className="hidden w-full mt-6 mb-3 md:block">
            <table className="w-full border-collapse table-auto">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th id="role-name" className="w-[29.11%] xl:w-[27.61%] 3xl:w-1/4">
                            <DoubleIndicatorDropdown options={roleNameOptions} />
                        </th>
                        <th id="numberof-members" className="w-[29.11%] xl:w-[27.61%] 3xl:w-1/4">
                            <DoubleIndicatorDropdown options={numberOfMembersOptions} />
                        </th>
                        <th id="status" className="w-[29.11%] xl:w-[17.14%] 3xl:w-1/4">
                            <DoubleIndicatorDropdown options={statusOptions} />
                        </th>
                        <th
                            id="action"
                            className="text-sm font-medium font-quicksand text-manatee text-start w-[12.66%] xl:w-[27.61%] 3xl:w-1/4"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {roleData.map((role, index) => (
                        <tr key={index}>
                            <td aria-labelledby="role-name w-[29.11%] xl:w-[27.61%] 3xl:w-1/4">
                                <div className="mt-2 px-[10px]">
                                    <h2 className="font-bold capitalize text-clinder text-sm font-quicksand leading-[150%]">
                                        {role.name}
                                    </h2>
                                    <p className="mt-1 font-medium text-manatee font-satoshi leading-[200%] text-xs">
                                        {role.descripton}
                                    </p>
                                </div>
                            </td>
                            <td
                                aria-labelledby="numberOf-members"
                                className="w-[29.11%] xl:w-[27.61%] 3xl:w-1/4"
                            >
                                <div className="px-[10px]">
                                    <h2 className="font-semibold font-quicksand text-sm leading-[150%]">
                                        {role.numberOfMembers} People
                                    </h2>
                                    <p className="mt-1 text-xs font-medium text-manatee font-satoshi leading-[200%]">
                                        Employee of {role.company}
                                    </p>
                                </div>
                            </td>
                            <td
                                aria-labelledby="status"
                                className="w-[29.11%] xl:w-[17.14%] 3xl:w-1/4"
                            >
                                <div
                                    className={`px-3 py-[10px] flex justify-between items-center gap-[10px] rounded ${
                                        role.status === "Active" ? "bg-ivory-cream" : "bg-coral"
                                    }`}
                                >
                                    <span
                                        className={`text-sm font-semibold font-quicksand text-lime-green capitalize ${
                                            role.status === "Active"
                                                ? "text-lime-green"
                                                : "text-scarlet-red"
                                        }`}
                                    >
                                        {role.status}
                                    </span>
                                    <SingleIndicatorDropdown
                                        options={["Active", "Inactive"]}
                                        showResultOption={false}
                                        height={"h-auto"}
                                        width={"w-auto"}
                                        iconSize={"w-5 h-5"}
                                        radius={"rounded"}
                                        optionsWidth={"w-[100px]"}
                                        padding={"px-0"}
                                    />
                                </div>
                            </td>
                            <td
                                aria-labelledby="action"
                                className="w-[12.66%] xl:w-[27.61%] 3xl:w-1/4"
                            >
                                <div className={`flex items-center justify-center gap-1 px-2`}>
                                    <button type="button" className="block px-3 mx-auto xl:hidden">
                                        <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                        <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                                        <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                    </button>
                                    <button
                                        type="button"
                                        className="items-center justify-center flex-1 hidden py-1 duration-300 transform rounded xl:flex bg-neutral-gray hover:bg-gray-200"
                                    >
                                        <img
                                            src={shieldSearch}
                                            alt="shield search"
                                            className="w-3 h-3"
                                        />
                                        <span className="ml-2 text-sm font-semibold font-quicksand text-clinder">
                                            Detail
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className="px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200 hidden xl:block"
                                    >
                                        <img src={edit} alt="edit" className="w-3 h-3" />
                                    </button>
                                    <button
                                        type="button"
                                        className="px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200 hidden xl:block"
                                    >
                                        <img src={trashRed} alt="trash red" className="w-3 h-3" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))} */}
                </tbody>
            </table>

            <UnderConstructionBanner />
        </div>
    );
};

export default TabletDesktopRoleTable;
