import React from 'react';

const FilterOptionButton = ({
  filterOPtions,
  filterLabel,
  selectedFilter,
  setSelectedFilter,
}) => {
  return (
    <div className="mb-6">
      <p className="text-sm font-semibold font-quicksand text-clinder">
        {filterLabel}
      </p>{' '}
      <div className="flex flex-wrap gap-4 mt-4">
        {filterOPtions.map((option, index) => (
          <button
            key={index}
            className={`text-sm border rounded-3xl py-[10px] px-5 font-quicksand ${
              selectedFilter === option.value
                ? 'border-tiffany-blue bg-forested-mind'
                : 'border-manatee bg-white'
            }`}
            onClick={() => setSelectedFilter(option.value)}
          >
            <span
              className={`${
                selectedFilter === option.value
                  ? 'text-aurora-blue font-semibold'
                  : 'text-clinder font-medium'
              }`}
            >
              {option.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterOptionButton;
