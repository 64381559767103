import React, { useEffect, useState } from 'react';

const CustomerLevelSelectionCheckbox = ({
  customerLevelIndex,
  customerLevelName,
  customerLevelId,
  setSelectedCustomerLevelsToPost,
  isSelectAllCustomerLevels,
  promoId,
  promoLevels,
  isCustomerLevelSelectionModalOpen,
}) => {
  const [isCustomerLevelSelected, setIsCustomerLevelSelected] = useState(false);

  // Handle  the default customer level selection based on the level that is selected in the promo levels
  useEffect(() => {
    if (isCustomerLevelSelectionModalOpen || isSelectAllCustomerLevels) {
      if (
        promoLevels.some((item) => item.customerLevelId === customerLevelId)
      ) {
        setIsCustomerLevelSelected(true);
      } else {
        setIsCustomerLevelSelected(false);
      }
    }
  }, [isCustomerLevelSelectionModalOpen, isSelectAllCustomerLevels]);

  const handleCustomerLevelSelection = ({
    customerLevelId,
    isCustomerLevelSelected,
  }) => {
    if (isCustomerLevelSelected) {
      setSelectedCustomerLevelsToPost((prevSelected) => [
        ...prevSelected,
        {
          promoId: promoId,
          customerLevelId: customerLevelId,
        },
      ]);
    } else {
      setSelectedCustomerLevelsToPost((prevSelected) =>
        prevSelected.filter((item) => item.customerLevelId !== customerLevelId)
      );
    }
  };

  const toggleSelection = () => {
    setIsCustomerLevelSelected(!isCustomerLevelSelected);
    handleCustomerLevelSelection({
      customerLevelId,
      isCustomerLevelSelected: !isCustomerLevelSelected,
    });
  };

  return (
    <div
      className={`flex items-center justify-between w-full gap-3 p-3 rounded cursor-pointer ${
        customerLevelIndex !== 0 && 'mt-1'
      } ${isCustomerLevelSelected && 'border border-blue-500'}`}
      onClick={toggleSelection}
    >
      <label
        htmlFor={`base-product-${customerLevelIndex}`}
        className="text-sm font-semibold capitalize text-clinder font-quicksand"
      >
        {customerLevelName}
      </label>
      <input
        id={`base-product-${customerLevelIndex}`}
        type="checkbox"
        required
        checked={isCustomerLevelSelected}
        className="w-4 h-4 mt-1 hover:cursor-pointer"
        onClick={(e) => e.stopPropagation()}
        onChange={toggleSelection}
      />
    </div>
  );
};

export default CustomerLevelSelectionCheckbox;
