import React from "react";

const FilledSuccessButton = ({ typeOfBtn, label, actionHandler, isDisable, isLoading }) => {
    return (
        <button
            disabled={isDisable || isLoading}
            type={typeOfBtn}
            onClick={actionHandler}
            className={`w-auto h-auto px-6 py-3 flex items-center justify-center border-none rounded-lg group transition duration-200  ${
                label === "delete" ? "bg-rose-600" : "bg-sky-700"
            } ${isDisable || isLoading ? "opacity-60" : "hover:opacity-80"}`}
        >
            {isLoading ? (
                <span className="w-full h-full text-sm text-white capitalize animate-pulse">
                    loading...
                </span>
            ) : (
                <span className="w-full h-full text-sm text-white capitalize">{label}</span>
            )}
        </button>
    );
};

export default FilledSuccessButton;
