import React from 'react';

const SectionNavigator = ({
  menuNavs,
  currentNav,
  setCurrentNav,
  modalContainerRef,
}) => {
  return (
    <div className="grid grid-flow-col gap-2 py-6 -mx-6 overflow-x-hidden bg-white md:hidden justify-stretch hover:overflow-x-auto sticky top-[77px] z-30">
      {menuNavs.map((nav, index) => (
        <button
          key={index}
          className={`rounded-lg border-[1px] py-2 px-6 font-quicksand ${
            index === 0 && 'ml-6'
          } ${index === menuNavs.length - 1 && 'mr-6'} ${
            nav.title === currentNav ? 'border-grad-purpletty' : 'border-gray-x'
          }`}
          onClick={() => {
            setCurrentNav(nav.title);
            modalContainerRef.current.scrollTop = 0;
          }}
        >
          <span
            className={`font-bold ${
              nav.title === currentNav ? 'text-aurora-blue' : 'text-gray-x'
            }`}
          >
            {nav.menu}
          </span>
        </button>
      ))}
    </div>
  );
};

export default SectionNavigator;
