import axios from "axios";

// server
// const BASE_URL = 'http://localhost:5000/api/v1';
// const BASE_URL = 'http://192.168.100.100:5000/api/v1';

const BASE_URL = "https://dev.8866883.xyz/api/v1";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response.status === 401) {
            // console.log("UNAUTHORIZED");
        }

        return response;
    },
    function (error) {
        // if (error.response.status === 401) {
        //     return Promise.resolve(error.response);
        // }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default axiosInstance;
