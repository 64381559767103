import { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/api/apiConfig';

const useGetDetailTransaction = (token, tenantId, transactionId) => {
	const [loading, setLoading] = useState(true);
	const [detailTransactionData, setDetailTransactionData] = useState();

	useEffect(() => {
		const getDetailTransaction = async () => {
			try {
				const response = await axiosInstance.get(
					`/transactions/by-customer/transaction/${transactionId}`,
					{
						headers: {
							'Content-Type': 'application/json',
							'X-TENANT-ID': tenantId,
							Authorization: `Bearer ${token}`
						}
					}
				);

				// const callback = response.data.data;

				setDetailTransactionData(response.data.data);
			} catch (error) {
				// console.log(error);
			} finally {
				setLoading(false);
			}
		};

		getDetailTransaction();
	}, [token, tenantId, transactionId]);

	return { loading, detailTransactionData };
};

export default useGetDetailTransaction;
