import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';

import axiosInstance from '../../../utils/api/apiConfig';

import CustomerLevelSelectionModal from './CustomerLevelSelectionModal';
import CategoryOptionsDropdown from '../../stock/menu/CategoryOptionsDropdown';
import ProductOptionsDropdown from '../../stock/additional_notes/ProductOptionsDropdown';
import SingleValueDropdown from '../../SingleValueDropdown';
import NewLevelModal from './NewLevelModal';
import SuccessNotification from '../../SuccessNotification';

import arrowRightWhite from '../../../assets/icons/arrow-right-white.svg';
import close from '../../../assets/icons/close.svg';
import arrowLeftBlue from '../../../assets/icons/arrowLeftBlue.svg';
import calenderBlack from '../../../assets/icons/calendarBlack.svg';
import clock from '../../../assets/icons/clock.svg';
import plusGradient from '../../../assets/icons/plusGradient.svg';

const AddNewPromoModal = ({ isModalOpen, setIsModalOpen, onGetAllPromos }) => {
  // === General  State ===
  const [promoId, setPromoId] = useState(null);
  const [promoName, setPromoName] = useState('');
  const [promoDescription, setPromoDescription] = useState('');
  const [promoStartDate, setPromoStartDate] = useState('');
  const [promoEndDate, setPromoEndDate] = useState('');
  const [promoDatePlaceholder, setPromoDatePlaceholder] = useState('Pick Date');
  const [promoKuota, setPromoKuota] = useState('');
  const [promoLevels, setPromoLevels] = useState([]);
  const [allCustomerLevels, setAllCustomerLevels] = useState([]);
  const [isCustomerLevelUpdate, setIsCustomerLevelUpdate] = useState(false);

  // === Template State ===
  const [discountAmount, setDiscountAmount] = useState('');
  const [menuId, setMenuId] = useState(null);
  const [menuName, setMenuName] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [promoTemplate, setPromoTemplate] = useState('template-1');

  // === Condition State ===
  const [minimumTransaction, setMinimumTransaction] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [timePromoStart, setTimePromoStart] = useState('');
  const [timePromoEnd, setTimePromoEnd] = useState('');
  const [dayPromo, setDayPromo] = useState([]);
  const [isMondaySelected, setIsMondaySelected] = useState(false);
  const [isTuesdaySelected, setIsTuesdaySelected] = useState(false);
  const [isWednesdaySelected, setIsWednesdaySelected] = useState(false);
  const [isThursdaySelected, setIsThursdaySelected] = useState(false);
  const [isFridaySelected, setIsFridaySelected] = useState(false);
  const [isSaturdaySelected, setIsSaturdaySelected] = useState(false);
  const [isSundaySelected, setIsSundaySelected] = useState(false);
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  // === Component State ===
  const modalContainerRef = useRef(null);
  const [activeForm, setActiveForm] = useState('general');
  const [isCustomerLevelSelectionModal, setIsCustomerLevelSelectionModal] =
    useState(false);
  const [isNewLevelModalOpen, setIsNewLevelModalOpen] = useState(false);
  const [promoType, setPromoType] = useState('Percentage');
  useState(false);
  const [countdown, setCountdown] = useState(3);
  const [isShowSuccessNotification, setIsShowSuccessNotification] =
    useState(false);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const userId = localStorage.getItem('userId');

  // fake data
  const statusOption = ['option 1', 'option 2'];

  // Set Promo Date Placeholder Text by Screen
  const isTablet = window.matchMedia('(min-width: 768px)').matches;
  useEffect(() => {
    if (isTablet) {
      setPromoDatePlaceholder('Pick Promo Date');
    } else {
      setPromoDatePlaceholder('Pick Date');
    }
  }, []);

  // handle close modal by timer
  useEffect(() => {
    if (isShowSuccessNotification) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      if (countdown === 0) {
        clearInterval(timer);
        window.scrollTo(0, 0);
        setIsModalOpen(false);
        setActiveForm('general');
        setCountdown(3);
        setIsShowSuccessNotification(false);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown, isShowSuccessNotification]);

  // === General Method ===
  // Handle Get All Customer Levels
  useEffect(() => {
    const getAllCustomerLevels = async () => {
      // setIsLoadingGetAllBaseProducts(true);
      try {
        const response = await axiosInstance.get(`/customer-levels`, {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setAllCustomerLevels(response.data.data);
        // setIsLoadingGetAllBaseProducts(false);
      } catch (error) {
        // console.log(error);
        // setIsLoadingGetAllBaseProducts(false);
      }
    };

    getAllCustomerLevels();
  }, [isCustomerLevelUpdate]);

  // Handle input tag type
  // const handleInputType = (event) => {
  //   event.target.type = event.type === 'focus' ? 'date' : 'text';
  // };

  // === Template Method ===
  // Handle Get All Menu
  useEffect(() => {
    getAllMenus();
  }, []);
  const getAllMenus = async () => {
    // setIsLoadingGetAllMenus(true);
    try {
      const response = await axiosInstance.get(`/products`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setMenus(response.data.data);
      // setIsLoadingGetAllMenus(false);
    } catch (error) {
      // console.log(error);
      // setIsLoadingGetAllMenus(false);
    }
  };

  // Handle Get All Categories
  useEffect(() => {
    const getAllCategories = async () => {
      // setIsLoadingGetCategories(true);
      try {
        const response = await axiosInstance.get(`/product-categories`, {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data.data.results);
      } catch (error) {
        // console.log(error);
        // setIsLoadingGetCategories(false);
      }
    };

    getAllCategories();
  }, []);

  // === Condition Method
  const handleDayPromoSelection = ({ day, isDaySelected }) => {
    if (isDaySelected) {
      setDayPromo((prevSelected) => [...prevSelected, day]);
    } else {
      setDayPromo((prevSelected) =>
        prevSelected.filter((item) => item !== day)
      );
    }
  };

  const toggleSelection = (isDaySelected, setIsDaySelected, day) => {
    setIsDaySelected(!isDaySelected);
    handleDayPromoSelection({ day, isDaySelected: !isDaySelected });
  };

  // Handle Submit Promo Data
  const handlePostNewPromo = async () => {
    // e.preventDefault();

    const bodyRequest = {
      name: promoName,
      promoType,
      promoTemplate: 1,
      promoDescription,
      promoStartDate,
      promoEndDate,
      promoKuota,
      discountAmount,
      productId: menuId,
      productCategoryId: categoryId,
      minimumTransaction,
      paymentMethod,
      timePromoStart:
        timePromoStart === '' ? '00:00:00' : timePromoStart + ':00',
      timePromoEnd: timePromoEnd === '' ? '23:59:59' : timePromoEnd + ':00',
      dayPromo,
      status: true,
    };

    try {
      const response = await axiosInstance.post(`/promos`, bodyRequest, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setPromoId(response.data.data.id);
      setActiveForm('finishing');
      modalContainerRef.current.scrollTop = 0;
      onGetAllPromos();

      setPromoName('');
      setPromoDescription('');
      setPromoStartDate('');
      setPromoEndDate('');
      setPromoKuota('');
      setPromoLevels([]);

      setDiscountAmount('');
      setMenuId(null);
      setMenuName('');
      setCategoryId(null);
      setCategoryName('');

      setMinimumTransaction('');
      setPaymentMethod('');
      setTimePromoStart('');
      setTimePromoEnd('');
      setDayPromo([]);
      setIsMondaySelected(false);
      setIsTuesdaySelected(false);
      setIsWednesdaySelected(false);
      setIsThursdaySelected(false);
      setIsFridaySelected(false);
      setIsSaturdaySelected(false);
      setIsSundaySelected(false);
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle post customer levels to promo levels
  const handlePostPromoLevels = async () => {
    // e.preventDefault();
    if (promoId !== null) {
      try {
        const response = await axiosInstance.post(
          '/promo-levels',
          promoLevels,
          {
            headers: {
              'Content-Type': 'application/json',
              'X-TENANT-ID': tenantId,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data.data);
        onGetAllPromos();
        setIsShowSuccessNotification(true);
        modalContainerRef.current.scrollTop = 0;
      } catch (error) {
        // console.log(error);
      }
    }
  };

  // // console.log('promoName', promoName);
  // // console.log({ promoDescription });
  // // console.log({ promoStartDate });
  // // console.log({ promoEndDate });
  // // console.log({ promoKuota });
  // // console.log({ promoLevels });
  // // console.log({ discountAmount });
  // // console.log({ menuId });
  // // console.log({ categoryId });
  // // console.log(minimumTransaction);
  // // console.log({ paymentMethod });
  // // console.log({ timePromoStart });
  // // console.log({ timePromoEnd });
  // // console.log('day promo', dayPromo);

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex items-start justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          setIsModalOpen(false);
          window.scrollTo(0, 0);
          setActiveForm('general');
          modalContainerRef.current.scrollTop = 0;
        }}
      />
      <div
        ref={modalContainerRef}
        className={`bg-white w-full md:w-[600px] px-6 md:px-9 pb-6 md:pb-9 h-screen overflow-y-auto flex flex-col gap-6 fixed top-0 right-0 z-50 duration-300 ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <header className="sticky top-0 z-30 pt-6 bg-white md:pt-9 md:mx-0">
          <div>
            <div className="flex flex-row-reverse md:flex-row items-center justify-end md:justify-between gap-6 border-b-[1px] border-gray-x/40 md:border-b-0 -mx-6 px-6 pb-6 md:pb-0">
              <h2 className="text-xl font-semibold md:text-2xl md:font-bold font-quicksand text-aurora-blue">
                Add New Promo
              </h2>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  window.scrollTo(0, 0);
                  setActiveForm('general');
                  modalContainerRef.current.scrollTop = 0;
                }}
              >
                <img src={close} alt="close" />
              </button>
            </div>
            <p className="text-sm font-medium text-manatee font-satoshi w-10/12 mt-2 leading-[200%] hidden md:block">
              In this window you can set the new promo and also manage the promo
              to your restaurant.
            </p>
          </div>
          <div className="flex mt-0 md:mt-4 items-center justify-between gap-1 py-3 p-0 md:px-6 px-9 bg-neutral-gray border-b-[1px] md:border-b-[2px] border-[#0170a0] -mx-6 md:mx-0">
            <div>
              <div
                className={`mx-auto text-xs font-semibold font-quicksand hidden md:inline py-[5px] rounded-full  text-white px-[10px] ${
                  activeForm === 'general' ? 'bg-aurora-blue' : 'bg-lime-green'
                }`}
              >
                <span>1</span>
              </div>
              <span
                className={`text-sm ml-0 md:ml-[10px] font-quicksand font-bold ${
                  activeForm === 'general'
                    ? 'text-aurora-blue'
                    : 'text-lime-green'
                }`}
              >
                General
              </span>
            </div>
            <div
              className={`hidden md:block w-3 h-[2px] ${
                activeForm !== 'general' ? 'bg-lime-green' : 'bg-manatee'
              }`}
            />
            <div>
              <div
                className={`hidden md:inline py-1 mx-auto text-sm font-semibold rounded-full ${
                  activeForm === 'general'
                    ? 'bg-gray-x px-[9px] text-manatee'
                    : activeForm === 'template'
                    ? 'bg-aurora-blue px-[9px] text-white'
                    : 'bg-lime-green px-[9px] text-white'
                } font-quicksand`}
              >
                <span>2</span>
              </div>
              <span
                className={`text-sm ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'template'
                    ? 'text-aurora-blue'
                    : activeForm === 'condition' || activeForm === 'finishing'
                    ? 'text-lime-green'
                    : 'text-manatee'
                }`}
              >
                Template
              </span>
            </div>
            <div
              className={`hidden md:block w-3 h-[2px] ${
                activeForm === 'general' || activeForm === 'template'
                  ? 'bg-manatee'
                  : 'bg-lime-green'
              }`}
            />
            <div>
              <span
                className={`hidden md:inline py-1 px-[9px] mx-auto text-xs font-semibold rounded-full ${
                  activeForm === 'condition'
                    ? 'bg-aurora-blue text-white'
                    : activeForm === 'finishing'
                    ? 'bg-lime-green text-white'
                    : 'bg-gray-x text-manatee'
                } text-manatee font-quicksand`}
              >
                3
              </span>
              <span
                className={`text-sm ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'condition'
                    ? 'text-aurora-blue'
                    : activeForm === 'finishing'
                    ? 'text-lime-green'
                    : 'text-manatee'
                }`}
              >
                Condition
              </span>
            </div>
            <div
              className={`hidden md:block w-3 h-[2px] ${
                activeForm === 'finishing' ? 'bg-lime-green' : 'bg-manatee'
              }`}
            />
            <div>
              <span
                className={`hidden md:inline py-1 px-[9px] mx-auto text-xs font-semibold rounded-full ${
                  activeForm === 'finishing'
                    ? 'bg-aurora-blue text-white'
                    : 'bg-gray-x text-manatee'
                } text-manatee font-quicksand`}
              >
                4
              </span>
              <span
                className={`text-sm ml-[10px] font-semibold font-quicksand ${
                  activeForm === 'finishing'
                    ? 'text-aurora-blue'
                    : 'text-manatee'
                }`}
              >
                Finishing
              </span>
            </div>
          </div>
        </header>

        {/* General Data Form  */}
        <div className="flex-1">
          {activeForm === 'general' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div>
                  <label
                    htmlFor="promo-nama"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Promo Name
                  </label>
                  <input
                    type="text"
                    id="promo-nama"
                    value={promoName}
                    placeholder="Create Promo Name"
                    required
                    className="w-full px-4 mt-2 h-14 focusable-input"
                    onChange={(e) => setPromoName(e.target.value)}
                  />
                </div>
                {/* <div className="mt-4">
                  <label
                    htmlFor="promo-type"
                    className="block mb-2 text-sm font-semibold font-quicksand"
                  >
                    Promo Type
                  </label>
                  <SingleIndicatorDropdown
                    label={'Create Promo Type'}
                    options={statusOption}
                  />
                </div> */}
                <div className="mt-4">
                  <label
                    htmlFor="promo-description"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Promo Description{' '}
                    <span className="text-manatee">(optional)</span>
                  </label>
                  <textarea
                    value={promoDescription}
                    type="text"
                    id="promo-description"
                    placeholder="Describe Promo Explanation"
                    required
                    className="block w-full px-4 py-3  resize-none h-[101px] mt-2 focusable-input"
                    onChange={(e) => setPromoDescription(e.target.value)}
                  />
                </div>
                <div className="flex gap-4 mt-4">
                  <div className="w-full">
                    <label
                      htmlFor="start-promo-date"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Start Promo Date
                    </label>
                    <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                      <input
                        value={promoStartDate}
                        type="date"
                        id="start-promo-date"
                        placeholder={promoDatePlaceholder}
                        // onFocus={handleInputType}
                        // onBlur={handleInputType}
                        required
                        className="w-full h-full text-sm font-semibold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none  md:placeholder-Pick\ Promo\ Date"
                        onChange={(e) => setPromoStartDate(e.target.value)}
                      />
                      {/* <figure className="p-2 bg-white rounded-lg">
                        <img src={calenderBlack} alt="calender" />
                      </figure> */}
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="expired-promo-date"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Expired Promo Date
                    </label>
                    <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                      <input
                        value={promoEndDate}
                        type="date"
                        id="expired-promo-date"
                        placeholder={promoDatePlaceholder}
                        required
                        className="w-full h-full text-sm font-semibold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none"
                        onChange={(e) => setPromoEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="promo-kuota"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Promo Kuota
                  </label>
                  <input
                    type="number"
                    id="promo-kuota"
                    value={promoKuota}
                    placeholder="Create Promo Name"
                    required
                    className="w-full px-4 mt-2 capitalize h-14 focusable-input"
                    onChange={(e) => setPromoKuota(e.target.value)}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full mb-10 mt-9 xl:mb-0 button-primary-lg"
                onClick={() => {
                  setActiveForm('template');
                  modalContainerRef.current.scrollTop = 0;
                }}
              >
                <span className="text-base font-bold text-white font-quicksand">
                  Next
                </span>
                <img src={arrowRightWhite} alt="arrow right white" />
              </button>
            </div>
          )}

          {/* Template  Form */}
          {activeForm === 'template' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div>
                  <div className="flex p-2 mt-4 rounded-lg bg-neutral-gray gap-[10px] mb-4">
                    <button
                      type="button"
                      className={`transition duration-300 ${
                        promoType === 'Percentage'
                          ? 'bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] font-bold text-clinder'
                          : 'font-semibold text-manatee hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:font-bold hover:text-clinder'
                      } py-[6px] w-full rounded font-quicksand  text-xs`}
                      onClick={() => {
                        setPromoType('Percentage');
                        setDiscountAmount('');
                      }}
                    >
                      Percentage Discount
                    </button>
                    <button
                      type="button"
                      className={`duration-300 transition ${
                        promoType === 'Percentage'
                          ? 'font-semibold text-manatee hover:font-bold hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:text-clinder'
                          : 'font-bold bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-clinder'
                      } py-[6px] w-full rounded font-quicksand text-xs`}
                      onClick={() => {
                        setPromoType('Constant');
                        setDiscountAmount('');
                      }}
                    >
                      Constant Value Discount
                    </button>
                  </div>
                  {promoType === 'Percentage' ? (
                    <div className="w-full">
                      <label
                        htmlFor="percentage-discount"
                        className="text-sm font-semibold font-quicksand text-clinder"
                      >
                        Percentage Discount
                      </label>
                      <div className="relative mt-2 h-14">
                        <input
                          type="number"
                          value={discountAmount}
                          id="percentage-discount"
                          placeholder="Enter Percentage Discount"
                          required
                          className="w-full py-[15px] focusable-input absolute pl-4 pr-[72px]"
                          onChange={(e) => setDiscountAmount(e.target.value)}
                        />
                        <span className="absolute top-3 right-4 text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none">
                          %
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full">
                      <label
                        htmlFor="constant-discount"
                        className="text-sm font-semibold font-quicksand text-clinder"
                      >
                        Constant Value Discount
                      </label>
                      <div className="relative mt-2 h-14">
                        <input
                          type="number"
                          value={discountAmount}
                          id="constant-discount"
                          placeholder="Enter Constant Value Discount"
                          required
                          className="w-full py-[15px] focusable-input absolute pr-4 pl-[72px]"
                          onChange={(e) => setDiscountAmount(e.target.value)}
                        />
                        <span className="absolute top-3  text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none">
                          IDR
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-4 mt-4 md:flex-row">
                  <div className="w-full">
                    <label
                      htmlFor="menu"
                      className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
                    >
                      Menu{' '}
                      {menuId === null && categoryId !== null && (
                        <span className="text-manatee">(optional)</span>
                      )}
                    </label>
                    <ProductOptionsDropdown
                      options={menus}
                      label={'Pick Menu'}
                      setSelectedId={setMenuId}
                      selectedValue={menuName}
                      setSelectedValue={setMenuName}
                      optionHeight={'h-[155px]'}
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="category"
                      className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
                    >
                      Category{' '}
                      {menuId !== null && categoryId === null && (
                        <span className="text-manatee">(optional)</span>
                      )}
                    </label>
                    <CategoryOptionsDropdown
                      options={categories}
                      label={'Pick Category'}
                      setSelectedId={setCategoryId}
                      selectedValue={categoryName}
                      setSelectedValue={setCategoryName}
                      optionHeight={'h-[155px]'}
                    />
                  </div>
                </div>

                {/* <div className="mt-4 border-t-[1px] border-gray-x/40 pt-4">
                  <h3 className="text-sm font-semibold text-clinder font-quicksand">
                    Pick Promo Template
                  </h3>
                  <div className="flex gap-3 mt-4 mb-3">
                    <div
                      className={`w-full cursor-pointer p-3 rounded-lg ${
                        promoTemplate === 'template-1'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-1')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-orangetty">40%</span> to
                        All Menu (For each menu)
                      </p>
                    </div>
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-2'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-2')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-bluetty">20,000</span>{' '}
                        to All Menu (For each menu)
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 mb-3">
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-3'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-3')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-orangetty">40%</span> to
                        All Menu (For Total Transactions)
                      </p>
                    </div>
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-4'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-4')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-bluetty">20,000</span>{' '}
                        to All Menu (For Total Transactions)
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 mb-3">
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-5'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-5')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-bluetty">20,000</span>{' '}
                        for every menu on{' '}
                        <span className="font-bold text-purpletty">
                          Italian
                        </span>{' '}
                        Category
                      </p>
                    </div>
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-6'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-6')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-orangetty">40%</span> to
                        All Menu (For each menu)
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3 mb-3">
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-7'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-7')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-orangetty">40%</span> to
                        All Menu (For each menu)
                      </p>
                    </div>
                    <div
                      className={`w-full p-3 rounded-lg cursor-pointer ${
                        promoTemplate === 'template-8'
                          ? 'bg-dew border-[0.5px] border-[#1a4e5b]/20'
                          : 'bg-neutral-gray'
                      }`}
                      onClick={() => setPromoTemplate('template-8')}
                    >
                      <p className="text-sm font-medium text-clinder font-quicksand leading-[150%]">
                        Discount{' '}
                        <span className="font-bold text-bluetty">20,000</span>{' '}
                        to All Menu (For each menu)
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="flex gap-[10px] bg-white mt-9 mb-10 xl:mb-0">
                <button
                  type="button"
                  className="w-1/2 button-secondary-lg"
                  onClick={() => {
                    setActiveForm('general');
                    modalContainerRef.current.scrollTop = 0;
                  }}
                >
                  <img
                    src={arrowLeftBlue}
                    alt="arrow left"
                    className="w-4 h-4"
                  />
                  <span className="text-base font-bold text-aurora-blue font-quicksand">
                    Back
                  </span>
                </button>
                <button
                  type="button"
                  className="w-1/2 button-primary-lg"
                  onClick={() => {
                    setActiveForm('condition');
                    modalContainerRef.current.scrollTop = 0;
                  }}
                >
                  <span className="text-base font-bold text-white font-quicksand">
                    Next
                  </span>
                  <img src={arrowRightWhite} alt="arrow right white" />
                </button>
              </div>
            </div>
          )}

          {/* Condition form */}
          {activeForm === 'condition' && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <div>
                  <label
                    htmlFor="minimum-transaction"
                    className="text-sm font-semibold font-quicksand text-clinder"
                  >
                    Minimum Transaction
                  </label>
                  <input
                    type="number"
                    id="minimum-transaction"
                    value={minimumTransaction}
                    placeholder="Enter Minimum Transaction"
                    required
                    className="w-full px-4 mt-2 capitalize h-14 focusable-input"
                    onChange={(e) => setMinimumTransaction(e.target.value)}
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="payment-method"
                    className="block mb-2 text-sm font-semibold font-quicksand"
                  >
                    Payment Method
                  </label>
                  <SingleValueDropdown
                    label={'Enter Payment Method'}
                    selectedValue={paymentMethod}
                    setSelectedValue={setPaymentMethod}
                    options={['Debit/CC', 'QRIS', 'Cash']}
                  />
                </div>
                <div className="flex flex-col gap-4 mt-4 md:flex-row">
                  <div className="w-full">
                    <label
                      htmlFor="time-promo-start"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Time Promo Start{' '}
                      <span className="text-manatee">(Optional)</span>
                    </label>
                    <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                      <input
                        type="time"
                        value={timePromoStart}
                        id="time-promo-start"
                        placeholder="Time Promo Start"
                        required
                        className="w-full h-full text-sm font-semibold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none"
                        onChange={(e) => setTimePromoStart(e.target.value)}
                      />
                      {/* <figure className="p-2 bg-white rounded-lg">
                        <img src={clock} alt="calender" />
                      </figure> */}
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="expired-promo-end"
                      className="text-sm font-semibold font-quicksand text-clinder"
                    >
                      Time Promo End{' '}
                      <span className="text-manatee">(Optional)</span>
                    </label>
                    <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                      <input
                        type="time"
                        value={timePromoEnd}
                        id="expired-promo-end"
                        placeholder="Time Promo End"
                        required
                        className="w-full h-full text-sm font-semibold bg-transparent placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none"
                        onChange={(e) => setTimePromoEnd(e.target.value)}
                      />
                      {/* <figure className="p-2 bg-white rounded-lg">
                        <img src={clock} alt="calender" />
                      </figure> */}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-sm font-semibold font-quicksand text-clinder">
                    Day Promo
                  </h3>
                  <div className="flex flex-wrap gap-3 mt-2">
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isMondaySelected,
                          setIsMondaySelected,
                          'monday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isMondaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isMondaySelected,
                            setIsMondaySelected,
                            'monday'
                          )
                        }
                      />
                      <span className="ml-2">Monday</span>
                    </div>
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isTuesdaySelected,
                          setIsTuesdaySelected,
                          'tuesday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isTuesdaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isTuesdaySelected,
                            setIsTuesdaySelected,
                            'tuesday'
                          )
                        }
                      />
                      <span className="ml-2">Tuesday</span>
                    </div>
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isWednesdaySelected,
                          setIsWednesdaySelected,
                          'wednesday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isWednesdaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isWednesdaySelected,
                            setIsWednesdaySelected,
                            'wednesday'
                          )
                        }
                      />
                      <span className="ml-2">Wednesday</span>
                    </div>
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isThursdaySelected,
                          setIsThursdaySelected,
                          'thursday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isThursdaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isThursdaySelected,
                            setIsThursdaySelected,
                            'thursday'
                          )
                        }
                      />
                      <span className="ml-2">Thursday</span>
                    </div>
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isFridaySelected,
                          setIsFridaySelected,
                          'friday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isFridaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isFridaySelected,
                            setIsFridaySelected,
                            'friday'
                          )
                        }
                      />
                      <span className="ml-2">Friday</span>
                    </div>
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isSaturdaySelected,
                          setIsSaturdaySelected,
                          'saturday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isSaturdaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isSaturdaySelected,
                            setIsSaturdaySelected,
                            'saturday'
                          )
                        }
                      />
                      <span className="ml-2">Saturday</span>
                    </div>
                    <div
                      className="inline-block p-3 text-sm font-semibold capitalize font-quicksand text-clinder bg-dew rounded border-[0.5px] border-gray-x cursor-pointer"
                      onClick={() =>
                        toggleSelection(
                          isSundaySelected,
                          setIsSundaySelected,
                          'sunday'
                        )
                      }
                    >
                      <input
                        type="checkbox"
                        id="monday"
                        checked={isSundaySelected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() =>
                          toggleSelection(
                            isSundaySelected,
                            setIsSundaySelected,
                            'sunday'
                          )
                        }
                      />
                      <span className="ml-2">Sunday</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[10px] w-full bg-white mt-9 mb-10 xl:mb-0">
                <button
                  type="button"
                  className="w-1/2 button-secondary-lg"
                  onClick={() => {
                    setActiveForm('template');
                    modalContainerRef.current.scrollTop = 0;
                  }}
                >
                  <img
                    src={arrowLeftBlue}
                    alt="arrow left"
                    className="w-4 h-4"
                  />
                  <span className="text-base font-bold text-aurora-blue font-quicksand">
                    Back
                  </span>
                </button>
                <button
                  type="button"
                  className="w-1/2 button-primary-lg"
                  onClick={handlePostNewPromo}
                >
                  <span className="text-base font-bold text-white font-quicksand">
                    Next
                  </span>
                  <img src={arrowRightWhite} alt="arrow right white" />
                </button>
              </div>
            </div>
          )}

          {/* Finishing Section */}
          {activeForm === 'finishing' && (
            <div className="flex flex-col justify-between h-full">
              {isShowSuccessNotification ? (
                <div className="mt-9 3xl:mt-24 w-full xs:w-[390px] mx-auto">
                  <SuccessNotification
                    title={'Success! New promo has been created'}
                    message={''}
                    notificationCountdown={3}
                    path={'/admin-customer-promo'}
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-between h-full">
                  <div className="mt-4">
                    <div className="flex items-center justify-between mb-2">
                      <label className="inline-block mb-2 text-sm font-semibold font-quicksand text-clinder">
                        Promo Target Level
                      </label>
                      <button
                        type="button"
                        className="flex px-6 py-3 border-[1px] border-blue-400 hover:bg-forested-mind rounded-lg cursor-pointer gap-3"
                        onClick={() => setIsNewLevelModalOpen(true)}
                      >
                        <img
                          src={plusGradient}
                          alt="plus"
                          className="hidden md:block"
                        />
                        <span className="text-xs font-bold font-quicksand text-aurora-blue">
                          Add New Level
                        </span>
                      </button>
                    </div>
                    <button
                      type="button"
                      className="flex items-center justify-between w-full px-4 py-3 text-sm font-medium rounded-lg font-quicksand bg-neutral-gray"
                      onClick={() => setIsCustomerLevelSelectionModal(true)}
                    >
                      <span className="text-aurora-blue">
                        {promoLevels.length === 0
                          ? ' Choose this for wich level'
                          : `${promoLevels.length} Selected`}
                      </span>
                      <figure className="p-2 bg-white rounded-lg shadow-soft">
                        <HiOutlineDotsCircleHorizontal className="text-base text-blue-900" />
                      </figure>
                    </button>
                  </div>

                  <div className="flex gap-[10px] w-full bg-white mt-9 mb-10 xl:mb-0">
                    <button
                      type="button"
                      className="w-1/2 button-secondary-lg"
                      onClick={() => {
                        setActiveForm('condition');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={arrowLeftBlue}
                        alt="arrow left"
                        className="w-4 h-4"
                      />
                      <span className="text-base font-bold text-aurora-blue font-quicksand">
                        Back
                      </span>
                    </button>
                    <button
                      type="button"
                      className="w-1/2 button-primary-lg"
                      onClick={handlePostPromoLevels}
                    >
                      <span className="text-base font-bold text-white font-quicksand">
                        Next
                      </span>
                      <img src={arrowRightWhite} alt="arrow right white" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Customer Level Selection Modal to select customer level */}
      <CustomerLevelSelectionModal
        isModalOpen={isCustomerLevelSelectionModal}
        setIsModalOpen={setIsCustomerLevelSelectionModal}
        allCustomerLevels={allCustomerLevels}
        promoLevels={promoLevels}
        setPromoLevels={setPromoLevels}
        promoId={promoId !== null && promoId}
      />

      {/* New level modal to create new customer level */}
      <NewLevelModal
        isModalOpen={isNewLevelModalOpen}
        setIsModalOpen={setIsNewLevelModalOpen}
        isLevelUpdate={isCustomerLevelUpdate}
        setIsLevelUpdate={setIsCustomerLevelUpdate}
      />
    </>
  );
};

export default AddNewPromoModal;
