import React, { useState, useRef, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { AiOutlineRightCircle } from 'react-icons/ai';

import axiosInstance from '../../../utils/api/apiConfig';

import InfoBox from '../../InfoBox';
import SectionNavigator from '../../SectionNavigator';
import CategoryOptionsDropdown from './CategoryOptionsDropdown';
import TypeOptionsDropdown from './TypeOptionsDropdown';
import NewProductTypeModal from './NewProductTypeModal';
import MenuSelectionModal from './MenuSelectionModal';
import BaseProductSelectionModal from './BaseProductSelectionModal';

import close from '../../../assets/icons/close.svg';
import generalColorful from '../../../assets/icons/generalColorful.svg';
import generalColorless from '../../../assets/icons/generalColorless.svg';
import menuColorful from '../../../assets/icons/menuColorful.svg';
import menuColorless from '../../../assets/icons/menuColorless.svg';
import menuPhotoIcon from '../../../assets/icons/menuPhoto.svg';
import archiveTick from '../../../assets/icons/archiveTick.svg';
import menuRecipeBanner from '../../../assets/images/menuRecipeBanner.png';
import search from '../../../assets/icons/search.svg';
import book from '../../../assets/icons/book.svg';
import refreshBlue from '../../../assets/icons/refreshBlue.svg';
import boxAdd from '../../../assets/icons/boxAdd.svg';
import plusGradient from '../../../assets/icons/plusGradient.svg';
import searchBlue from '../../../assets/icons/searchBlue.svg';
import userAdd from '../../../assets/icons/userAdd.svg';
import arrowRight from '../../../assets/icons/arrow-right.svg';
import trush from '../../../assets/icons/trashRed.svg';
import EditSelectedMenusInput from './EditSelectedMenusInput';

const NewPackageMenuModal = ({
  isModalOpen,
  setIsModalOpen,
  categories,
  menus,
  productTypes,
  isProductTypeUpdate,
  setIsProductTypeUpdate,
  onGetAllMenus,
}) => {
  // === General Menu State ===
  const [packageName, setPackageName] = useState('');
  const [shortPackageName, setShortPackageName] = useState('');
  const [menuCategoryId, setMenuCategoryId] = useState();
  const [menuCategoryName, setMenuCategoryName] = useState('');
  const [menuPrice, setMenuPrice] = useState('');
  const [formattedMenuPrice, setFormattedMenuPrice] = useState('');
  const [productTypeName, setProductTypeName] = useState('');
  const [productTypeId, setProductTypeId] = useState();
  const [menuDescription, setMenuDescription] = useState('');
  const [menuPhoto, setMenuPhoto] = useState(null);
  const [menuPhotoPreview, setMenuPhotoPreview] = useState(null);
  const menuPhotoRef = useRef(null);
  // const [menuVideo, setMenuVideo] = useState(null);
  // const menuVideoRef = useRef(null);

  // ===  Menus State ===
  const [selectedBaseProductsToGet, setSelectedBaseProductsToGet] = useState(
    []
  ); // delete leter

  const [createdPackageMenuName, setCreatedPackageMenuName] = useState('');
  const [createdPackageMenuId, setCreatedPackageMenuId] = useState();
  const [allBaseProducts, setAllBaseProducts] = useState([]);
  const [selectedMenusSearchQuery, setSelectedMenusSearchQuery] = useState('');
  const [selectedMenusToGet, setSelectedMenusToGet] = useState([
    { productId: 2, menuName: 'nasi kukus', qty: 0 },
    { productId: 4, menuName: 'nasi bakar', qty: 0 },
    { productId: 5, menuName: 'nasi uduk', qty: 0 },
  ]);

  // === Variant Menu State ===
  const [variantMenu, setVariantMenu] = useState([]);

  // === Stock Menu State ===
  const [isCalculatedStockAutomatically, setIsCalculatedStockAutomatically] =
    useState(false);
  const [stockMenu, setStockMenu] = useState('');

  // === Condition State ===
  const [isLoadingPostMenu, setIsLoadingPostMenu] = useState(false);
  const [isLoadingGetAllBaseProducts, setIsLoadingGetAllBaseProducts] =
    useState(false);
  const [
    isLoadingGetSelectedBaseProducts,
    setIsLoadingGetSelectedBaseProducts,
  ] = useState(false);
  const [
    isBaseProductAddedToRecipeUpdated,
    setIsBaseProductAddedToRecipeUpdated,
  ] = useState(false);

  //  === Component State ===
  const modalContainerRef = useRef(null);
  const [activeNav, setActiveNav] = useState('General Menu');
  const [menuType, setMenuType] = useState('Single Menu');
  const [isNewTypeModalOpen, setIsNewTypeModalOpen] = useState(false);
  const [isBaseProductSelectionModalOpen, setIsBaseProductSelectionModalOpen] =
    useState(false);
  const [isChoosingMenuModalOpen, setIsChoosingMenuModalOpen] = useState(false);
  const menuNavs = [
    { title: 'General Menu', menu: 'General' },
    { title: 'Menus', menu: 'Recipes' },
    { title: 'Stock Menu', menu: 'Stock' },
    { title: 'Variant Menu', menu: 'Variant' },
  ];

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const creator = localStorage.getItem('userFullName');

  // Handle Generate Random Short Package Name
  const HandleGenerateShortPackageName = () => {
    const trimmedWhiteSpace = packageName.replace(/\s+/g, '');
    const randomPackageName = trimmedWhiteSpace
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('')
      .slice(0, 5);
    setShortPackageName(randomPackageName);
  };

  // fake data
  const categoryOptions = [
    'asian food',
    'asian drinks',
    'asian coffee',
    'asian breads',
  ];

  // data recipe di popup recipe di Admin panel-menu
  const fakeBaseProducts = [
    {
      id: 1,
      baseProductName: 'rice',
      stockOnTheInventory: 1000,
      qty: 0,
      unitSystem: 'Kilogram',
    },
    {
      id: 3,
      baseProductName: 'salt',
      stockOnTheInventory: 1000,
      qty: 0,
      unitSystem: 'kilogram',
    },
    {
      id: 4,
      baseProductName: 'cheese',
      stockOnTheInventory: 1000,
      qty: 0,
      unitSystem: 'gram',
    },
  ];

  const stockMenuFakeData = [
    {
      id: 1,
      baseProduct: 'Florence Fennel',
      recentStock: 5028,
      requiredStock: 5000,
    },
    {
      id: 2,
      baseProduct: 'Dusel',
      recentStock: 4856,
      requiredStock: 4000,
    },
    {
      id: 3,
      baseProduct: 'Salt',
      recentStock: 6456,
      requiredStock: 3000,
    },
    {
      id: 4,
      baseProduct: 'Pepper',
      recentStock: 4219,
      requiredStock: 1219,
    },
    {
      id: 5,
      baseProduct: 'Chilli',
      recentStock: 1234,
      requiredStock: 2000,
    },
    {
      id: 6,
      baseProduct: 'Meat',
      recentStock: 1346,
      requiredStock: 2000,
    },
    {
      id: 7,
      baseProduct: 'Egg',
      recentStock: 2123,
      requiredStock: 1000,
    },
  ];

  // === General Menu Method ===
  // Format Menu price value with commas (2000 --> 2.000)
  const formatNumberWithCommas = (inputValue) => {
    if (!inputValue) {
      return '';
    }

    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''));
    const formattedValue = numericValue.toLocaleString('id-ID');
    return formattedValue;
  };

  const handleInputMenuPrice = (e) => {
    const inputValue = e.target.value;
    setFormattedMenuPrice(formatNumberWithCommas(inputValue));
    setMenuPrice(inputValue.replace(/[^\d]/g, ''));
  };

  // handle photo input
  const handleFileChange = (e) => {
    setMenuPhoto(e.target.files[0]);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewImage = e.target.result;
        setMenuPhotoPreview(previewImage);
      };
      reader.readAsDataURL(file);
    } else {
      setMenuPhotoPreview(null);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setMenuPhoto(e.dataTransfer.files[0]);

    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewImage = e.target.result;
        setMenuPhotoPreview(previewImage);
      };
      reader.readAsDataURL(file);
    } else {
      setMenuPhotoPreview(null);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  // const handleMenuVideoChange = (e) => {
  //   setMenuVideo(e.target.files[0].name);
  // };

  // const handleMenuVideoDrop = (e) => {
  //   e.preventDefault();
  //   setMenuVideo(e.dataTransfer.files[0].name);
  // };

  // Handle Submit General Menu
  const handleSubmitGeneralMenu = async (e) => {
    e.preventDefault();
    setIsLoadingPostMenu(true);
    // const formData = new FormData();
    // formData.append('title', packageName);
    // formData.append('shortMenuName', shortMenuName);
    // formData.append('price', parseFloat(menuPrice));
    // formData.append('productCategoryId', menuCategoryId);
    // formData.append('menuTypeId', productTypeId);
    // formData.append('description', menuDescription);
    // formData.append('file', menuPhoto);

    // try {
    //   const response = await axiosInstance.post(`/products`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       'X-TENANT-ID': tenantId,
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    // setCreatedPackageMenuId(response.data.data.id);
    setCreatedPackageMenuId(1); //fake

    // setCreatedPackageMenuName(response.data.data.name);
    setCreatedPackageMenuName(packageName);

    setPackageName('');
    setShortPackageName('');
    setMenuCategoryId();
    setMenuCategoryName('');
    setMenuPrice('');
    setFormattedMenuPrice('');
    setProductTypeName('');
    setProductTypeId();
    setMenuDescription('');
    setMenuPhoto(null);
    setMenuPhotoPreview(null);

    setActiveNav('Menus');
    modalContainerRef.current.scrollTop = 0;
    //   onGetAllMenus();
    //   setIsLoadingPostMenu(false);
    // } catch (error) {
    //   // console.log(error.response.data.data);
    // }
  };

  //   // console.log({ packageName });
  //   // console.log({ shortPackageName });
  //   // console.log({ menuCategoryId });
  //   // console.log({ menuCategoryName });
  //   // console.log({ menuPrice });
  //   // console.log({ formattedMenuPrice });
  //   // console.log({ productTypeName });
  //   // console.log({ productTypeId });
  //   // console.log({ menuDescription });
  //   // console.log({ menuPhoto });
  //   // console.log({ menuPhotoPreview });

  // ==== Menus Method ====

  // Handle Get Selected Base Product by menu
  useEffect(() => {
    getSelectedBaseProducts();
  }, [isBaseProductAddedToRecipeUpdated]);

  const getSelectedBaseProducts = async () => {
    setIsLoadingGetAllBaseProducts(true);
    try {
      const response = await axiosInstance.get(
        `/product-details/product/${createdPackageMenuId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data.data.data || response.data.data.data.length === 0) {
        return setSelectedBaseProductsToGet([]);
      } else {
        setSelectedBaseProductsToGet(
          response.data.data.data[0].baseProductList
        );
      }
      setIsLoadingGetSelectedBaseProducts(false);
    } catch (error) {
      // console.log(error);
      setIsLoadingGetSelectedBaseProducts(false);
    }
  };

  // Handle Recipe search filter to filter base product
  const selectedMenusSearchFilter = (data) => {
    return data.filter((item) =>
      item.menuName
        .toLowerCase()
        .includes(selectedMenusSearchQuery.toLowerCase())
    );
  };

  // Handle Submit Variant
  const handleSubmitVariant = async (variantBodyRequest) => {
    // e.preventDefault();

    try {
      const response = await axiosInstance.post(
        `/varian-names`,
        variantBodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data.id);
      // setVariantId(response.data.data.id);
      return response.data.data.id;
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle Submit sub variant/toppings
  const handleSubmitSubVariant = async (subVariantBodyRequest) => {
    // e.preventDefault();
    // console.log(subVariantBodyRequest);
    try {
      const response = await axiosInstance.post(
        `/toppings`,
        subVariantBodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data);
      // console.log('toppins jalan');
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSubmitVariantToping = async (data) => {
    for (let i = 0; i < data.length; i++) {
      // Buat data untuk post variant
      const variantBodyRequest = {
        productId: createdPackageMenuId,
        varianName: data[i]['name'],
        isChoiseMoreThanOne: data[i]['isChooseMoreThanOne'],
        isRequired: data[i]['isRequired'],
      };

      let variantId = await handleSubmitVariant(variantBodyRequest);
      // console.log({ variantId });
      for (let x = 0; x < data[i]['subVariants'].length; x++) {
        // Buat data untuk post topping
        // console.log('variantId', variantId);
        let subVariantBodyRequest;
        subVariantBodyRequest = [
          {
            toppingName: data[i]['subVariants'][x]['name'],
            price: data[i]['subVariants'][x]['additionalPrice'],
            idVarianName: variantId,
          },
        ];
        // console.log(subVariantBodyRequest);
        await handleSubmitSubVariant(subVariantBodyRequest);
      }
    }

    setIsModalOpen(false);
    setSelectedBaseProductsToGet([]);
    setVariantMenu([]);
    setActiveNav('General Menu');
    modalContainerRef.current.scrollTop = 0;
  };

  // Handle Filter menu options to show menu which is not added
  const filterMenuOptions = (allMenus, selectedMenus) => {
    const menuOptions = allMenus.filter((menu) => {
      return !selectedMenus.some(
        (selectedMenu) => selectedMenu.productId === menu.id
      );
    });

    return menuOptions;
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`bg-white w-full md:w-[650px] lg:w-[800px] h-screen overflow-y-auto fixed top-0 right-0 z-50 duration-300 transition ${
          isModalOpen ? 'transalate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* main content */}
        <div
          ref={modalContainerRef}
          className="h-screen overflow-y-auto md:px-0"
        >
          <div className="block min-h-full md:flex">
            {/* Side Nav for Tablet and Desktop*/}
            <div className="hidden w-36 lg:w-48 md:block">
              <div className="border-gray-x/40 fixed top-0 border-r-[1px] w-36 lg:w-48 h-full">
                <ul className="py-6 lg:py-9">
                  <li>
                    <button
                      className={`flex duration-300 transition-all group hover:bg-dew items-center pl-7 lg:pl-9 w-full py-4 ${
                        activeNav === 'General Menu' && 'bg-dew'
                      }`}
                      onClick={() => {
                        setActiveNav('General Menu');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={
                          activeNav === 'General Menu'
                            ? generalColorful
                            : generalColorless
                        }
                        alt="general"
                      />
                      <span
                        className={`ml-3 text-sm group-hover:text-[#01639d] font-bold transition-all duration-300 ${
                          activeNav === 'General Menu'
                            ? 'text-aurora-blue'
                            : 'text-manatee'
                        } font-quicksand`}
                      >
                        General
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={`flex duration-300 transition-all group items-center hover:bg-dew pl-7 lg:pl-9 w-full py-4 ${
                        activeNav === 'Menus' && 'bg-dew'
                      }`}
                      onClick={() => {
                        setActiveNav('Menus');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={
                          activeNav === 'Menus' ? menuColorful : menuColorless
                        }
                        alt="menus"
                      />
                      <span
                        className={`ml-3 text-sm font-bold font-quicksand group-hover:text-[#01639d] transition-all duration-300 ${
                          activeNav === 'Menus'
                            ? 'text-aurora-blue'
                            : 'text-manatee'
                        }`}
                      >
                        Menus
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            {/* form*/}
            <div className="px-6 lg:px-9 pb-6 lg:pb-9 w-full md:w-[510px] lg:w-[608px] flex flex-col min-h-screen">
              <div className="border-b-[1px] pb-6 sticky top-0 bg-white z-20 pt-6 lg:pt-9 -mx-6 md:mx-0 px-6 md:px-0">
                <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
                  <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                    <span className="inline md:hidden">Add New Menu</span>
                    <span className="hidden md:inline">Package Menu</span>
                  </h2>
                  <button
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setIsModalOpen(false);
                    }}
                  >
                    <img src={close} alt="close" />
                  </button>
                </div>
                <p className="mt-2 text-sm font-medium font-satoshi text-manatee w-[85%] leading-[200%] hidden md:block">
                  In this window, you can create a menu package name and add any
                  menus that are inside this package.
                </p>
              </div>

              {/* Section Navigator for Mobile*/}
              <SectionNavigator
                modalContainerRef={modalContainerRef}
                menuNavs={menuNavs}
                currentNav={activeNav}
                setCurrentNav={setActiveNav}
              />

              {/* General Menu Form */}
              {activeNav === 'General Menu' && (
                <>
                  {/* Menu Typ Handler for Mobile */}
                  <div className="grid items-center grid-flow-col gap-2 md:hidden justify-stretch">
                    <button
                      type="button"
                      className={`px-4 py-2 rounded-lg font-quicksand text-sm font-medium ${
                        menuType === 'Single Menu'
                          ? 'bg-aurora-blue text-white'
                          : 'bg-neutral-gray text-gray-x'
                      }`}
                      onClick={() => setMenuType('Single Menu')}
                    >
                      Single Menu
                    </button>
                    <button
                      className={`px-4 py-2 rounded-lg font-quicksand text-sm font-medium ${
                        menuType === 'package-menu'
                          ? 'bg-aurora-blue text-white'
                          : 'bg-neutral-gray text-gray-x'
                      }`}
                      onClick={() => setMenuType('package-menu')}
                    >
                      Package Menu
                    </button>
                  </div>
                  {/* General Menu Form */}
                  <form className="flex flex-col justify-between flex-1 mt-4 md:mt-5">
                    <div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="package-nama"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Package Name
                        </label>{' '}
                        <br />
                        <input
                          type="text"
                          value={packageName}
                          id="package-nama"
                          required
                          placeholder="Package Name"
                          className="w-full px-4 py-3 mt-2 focusable-input"
                          onChange={(e) => setPackageName(e.target.value)}
                        />
                      </div>
                      <div className="mb-[18px] h-[85px]">
                        <div className="flex">
                          <label
                            htmlFor="short-menu-name"
                            className="text-sm font-semibold font-quicksand text-clinder mr-[6px]"
                          >
                            Short Package Name
                          </label>
                          <InfoBox
                            title={'Short Package Name'}
                            content={
                              'short menu name is an abbreviation of the main menu so when searching for the menu does not take a long time.'
                            }
                            customLeftPosition={
                              '-left-32 xs:-left-[100px] sm:left-5 md:left-4 lg:left-5'
                            }
                            customTopPosition={'top-4 sm:-top-3'}
                          />
                        </div>
                        <div className="relative mt-2">
                          <input
                            type="text"
                            value={shortPackageName}
                            id="short-menu-name"
                            placeholder="Short Version of Package Name"
                            required
                            className="absolute w-full py-3 focusable-input pl-4 pr-[101px] md:pr-[130px]"
                            onChange={(e) =>
                              setShortPackageName(e.target.value)
                            }
                          />
                          <button
                            type="button"
                            className={`absolute flex-none px-3 py-1 ml-4 text-xs font-semibold bg-white rounded shadow-soft font-quicksand top-3 right-4 ${
                              packageName.length < 5 && 'hidden'
                            }`}
                            onClick={HandleGenerateShortPackageName}
                          >
                            <span className="hidden sm:inline text-aurora-blue">
                              Auto{' '}
                            </span>
                            <span className="text-aurora-blue">Generate</span>
                          </button>
                        </div>
                      </div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="price-no-tax"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Price (No Tax)
                        </label>{' '}
                        <br />
                        <input
                          type="text"
                          value={formattedMenuPrice}
                          id="price-no-tax"
                          required
                          placeholder="General Price of The Package Menu"
                          className="w-full px-4 py-3 mt-2 focusable-input"
                          onChange={handleInputMenuPrice}
                        />
                      </div>
                      <div className="mb-[18px]">
                        <label className="inline-block mb-2 text-sm font-semibold font-quicksand text-clinder">
                          Category
                        </label>
                        <CategoryOptionsDropdown
                          options={categories}
                          label={'Choose the category'}
                          setSelectedId={setMenuCategoryId}
                          selectedValue={menuCategoryName}
                          setSelectedValue={setMenuCategoryName}
                          optionHeight={'h-[155px]'}
                        />
                      </div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="menu-description"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          {' '}
                          Menu Description{' '}
                          <span className="text-manatee">(optional)</span>
                        </label>{' '}
                        <br />
                        <textarea
                          value={menuDescription}
                          id="menu-description"
                          required
                          rows="5"
                          placeholder="Describe the menu"
                          className="w-full px-4 py-3 mt-2 resize-none focusable-input"
                          onChange={(e) => setMenuDescription(e.target.value)}
                        />
                      </div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="menu-photo"
                          className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                        >
                          Menu Photo
                          <div
                            className="relative inset-0 mt-2"
                            onDrop={handleFileDrop}
                            onDragOver={preventDefault}
                          >
                            <input
                              type="file"
                              id="menu-photo"
                              accept="image/*"
                              className="absolute cursor-pointer -z-10"
                              onChange={handleFileChange}
                              ref={menuPhotoRef}
                            />
                            <div className="bg-neutral-gray py-9  border-dashed border-2 border-[#080808]/10 rounded-lg">
                              <img
                                src={
                                  menuPhotoPreview
                                    ? menuPhotoPreview
                                    : menuPhotoIcon
                                }
                                alt="menu"
                                className={`mx-auto rounded object-cover ${
                                  menuPhotoPreview
                                    ? 'h-[100px] w-[100px]'
                                    : 'h-12 w-12'
                                }`}
                              />
                              <button
                                type="button"
                                className="flex items-center justify-center gap-3 px-6 py-3 mx-auto mt-6 bg-white border rounded-lg border-manatee"
                                onClick={() => menuPhotoRef.current.click()}
                              >
                                <img src={userAdd} alt="user add" />
                                <span className="text-xs font-bold text-aurora-blue font-quicksand">
                                  Choose File
                                </span>
                                <img src={arrowRight} alt="arrow" />
                              </button>
                              <p className="mt-2 text-xs font-medium text-center text-manatee font-quicksand">
                                {menuPhoto ? (
                                  <span>
                                    File Terpilih:{' '}
                                    <span className="text-aurora-blue">
                                      {menuPhoto.name}
                                    </span>
                                  </span>
                                ) : (
                                  'atau seret file foto kesini'
                                )}
                              </p>
                            </div>
                          </div>
                        </label>{' '}
                      </div>
                      {/* <div>
                        <label
                          htmlFor="menu-video"
                          className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                        >
                          Menu Video (Optional)
                          <div
                            className="relative inset-0 mt-2"
                            onDrop={handleMenuVideoDrop}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <input
                              type="file"
                              id="menu-video"
                              accept="video/*"
                              className="absolute cursor-pointer -z-10"
                              onChange={handleMenuVideoChange}
                              ref={menuVideoRef}
                            />
                            <div className="bg-neutral-gray py-9  border-dashed border-2 border-[#080808]/10 rounded-lg">
                              <img
                                src={menuPhotoIcon}
                                alt="menu"
                                className="mx-auto"
                              />
                              <button
                                type="button"
                                className="flex items-center justify-center gap-3 px-6 py-3 mx-auto mt-6 bg-white border rounded-lg border-manatee"
                                onClick={() => menuVideoRef.current.click()}
                              >
                                <img src={userAdd} alt="user add" />
                                <span className="text-xs font-bold text-aurora-blue font-quicksand">
                                  Choose File
                                </span>
                                <img src={arrowRight} alt="arrow" />
                              </button>
                              <p className="mt-2 text-xs font-medium text-center text-manatee font-quicksand">
                                {menuVideo
                                  ? `File Terpilih: ${menuVideo}`
                                  : 'atau seret file video kesini'}
                              </p>
                            </div>
                          </div>
                        </label>{' '}
                      </div> */}
                    </div>
                    <button
                      type="submit"
                      className="w-full mb-10 mt-9 xl:mb-0 button-primary-lg"
                      onClick={handleSubmitGeneralMenu}
                    >
                      <img src={archiveTick} alt="save" />
                      <span className="ml-3 text-base font-bold text-white font-quicksand">
                        Save Changes
                      </span>
                    </button>
                  </form>
                </>
              )}

              {/* Menus Form */}
              {activeNav === 'Menus' && (
                <form className="flex flex-col justify-between flex-1 h-full mt-0 md:mt-6">
                  <div className="flex flex-col flex-1">
                    {/* banner */}
                    <>
                      <div className="hidden md:block bg-aurora-blue h-[148px] rounded-lg relative overflow-hidden ">
                        <img
                          src={menuRecipeBanner}
                          alt="menu recipe banner"
                          className="absolute right-0 object-cover"
                        />
                        <div className="absolute mt-3 ml-3">
                          <h3 className="text-xl font-bold text-white capitalize font-quicksand">
                            {createdPackageMenuName}
                          </h3>
                          <p className="mt-2 text-xs font-medium text-white font-satoshi">
                            Total menu added:{' '}
                            <span className="font-bold">
                              {selectedMenusToGet.length} Menu
                              {selectedMenusToGet.length > 1 && 's'}
                            </span>
                          </p>
                        </div>
                        <div className="absolute bottom-0 flex justify-between w-full gap-3 px-3 mb-3">
                          <div className="flex items-center flex-1 w-full h-10 px-4 rounded-lg bg-neutral-gray">
                            <img src={search} alt="search" />
                            <input
                              type="text"
                              placeholder="Search menu"
                              className="flex-1 w-full h-full ml-4 text-sm font-medium font-quicksand text-clinder placeholder:text-gray-x focus:outline-none bg-neutral-gray"
                              onChange={(e) =>
                                setSelectedMenusSearchQuery(e.target.value)
                              }
                            />
                          </div>
                          <button
                            type="button"
                            className="flex py-[10px] px-4 items-center justify-center blur-5 bg-white/20 rounded-lg flex-none"
                            onClick={() => setIsChoosingMenuModalOpen(true)}
                          >
                            <img src={book} alt="book" />
                            <span className="ml-3 text-sm font-semibold text-white font-quicksand">
                              Add Menu
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="flex gap-4 px-6 py-2 bg-white border-y-[1px] border-gray-x  -mx-6 md:hidden ">
                        <img src={searchBlue} alt="search" />
                        <input
                          type="text"
                          placeholder="Search Base Product"
                          className="flex-1 text-sm font-semibold bg-white focus:outline-none font-quicksand text-clinder placeholder:text-manatee"
                        />
                        <button
                          type="button"
                          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
                        >
                          <img src={book} alt="book" className="mx-auto" />
                        </button>
                      </div>
                    </>

                    {/* base product table */}
                    {selectedMenusToGet.length === 0 ? (
                      <div className="flex items-center justify-center flex-1">
                        <div className="w-[370px] mx-auto mt-6">
                          <div className="bg-manatee/40 rounded-t-2xl flex justify-center items-center rounded-br-2xl rounded w-[60px] h-[60px] mx-auto">
                            <FaPlus className="text-manatee" size={25} />
                          </div>
                          <h4 className="mt-4 text-2xl font-bold text-center font-quicksand">
                            Oops, Nothing Here Yet!
                          </h4>
                          <p className="mt-4 text-center font-quicksand font-semibold text-sm leading-[200%] text-manatee">
                            Looks like this space is feeling a bit lonely right
                            now. Just hit that '
                            <span className="text-blue-800">Add Menu</span>'
                            button, and let the good times (and data) roll!
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-0 md:mt-6">
                        <div className="block md:hidden bg-neutral-gray border-b-[1px] border-gray-x px-6 py-4 -mx-6 mb-4">
                          <p className="text-sm font-semibold capitalize font-quicksand text-clinder">
                            Nasi goreng jamur{' '}
                          </p>
                        </div>
                        <table className="w-full border-collapse table-auto">
                          <thead className="hidden bg-neutral-gray md:table-header-group">
                            <tr>
                              <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Menu
                              </th>
                              <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Qty
                              </th>
                              <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedMenusSearchFilter(selectedMenusToGet).map(
                              (menu, index) => (
                                <EditSelectedMenusInput
                                  index={index}
                                  key={index}
                                  menu={menu}
                                  onGetSelectedBaseProducts={
                                    getSelectedBaseProducts
                                  }
                                  productDetailId={menu?.productDetailId}
                                />
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <button
                    disabled={
                      selectedBaseProductsToGet.length === 0 ? true : false
                    }
                    type="button"
                    className={`flex items-center justify-center w-full gap-3 px-3 py-4 font-bold transition duration-300 rounded-lg md:px-9 ${
                      selectedBaseProductsToGet.length === 0
                        ? 'bg-gray-x/60'
                        : 'bg-aurora-blue'
                    }`}
                    onClick={() => {
                      setActiveNav('Stock Menu');
                      window.scrollTo(0, 0);
                    }}
                  >
                    <AiOutlineRightCircle
                      className={`w-4 h-4 ${
                        selectedBaseProductsToGet.length === 0
                          ? 'text-manatee'
                          : 'text-white'
                      } `}
                    />
                    <span
                      className={`text-base font-bold  font-quicksand ${
                        selectedBaseProductsToGet.length === 0
                          ? 'text-manatee'
                          : 'text-white'
                      }`}
                    >
                      Next
                    </span>
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Menu Selection Modal */}
      <MenuSelectionModal
        isModalOpen={isChoosingMenuModalOpen}
        setIsModalOpen={setIsChoosingMenuModalOpen}
        allMenus={filterMenuOptions(menus, selectedMenusToGet)}
        selectedMenusToGet={selectedMenusToGet}
      />
    </>
  );
};

export default NewPackageMenuModal;
