import React, { useState } from 'react';
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2';

const Pagination = ({
  totalPages,
  currentPage,
  setCurrentPage,
  isPageNumberColorGray,
}) => {
  const handleClickNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const renderPagination = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    const startIndex = currentPage - 1;
    const endIndex = startIndex + 3;

    return pageNumbers.slice(startIndex, endIndex).map((pageNumber) => (
      <button
        key={pageNumber}
        type="button"
        className={`px-4 py-2 text-sm duration-300 transform rounded-sm font-quicksand xl:hover:text-clinder xl:hover:shadow-[2px_2px_4px_0px_rgba(0,0,0,0.05)] ${
          isPageNumberColorGray ? 'xl:hover:bg-gray-light' : 'xl:hover:bg-white'
        } ${
          pageNumber === currentPage
            ? `text-clinder shadow-[2px_2px_4px_0px_rgba(0,0,0,0.05)] font-bold ${
                isPageNumberColorGray ? 'bg-gray-light' : 'bg-white'
              }`
            : 'text-gray-dark font-semibold'
        }`}
        onClick={() => setCurrentPage(pageNumber)}
      >
        {pageNumber}
      </button>
    ));
  };

  return (
    <div className="flex items-center h-8 mx-auto lg:mx-0 lg:mt-0 sm:mx-0">
      {currentPage > 1 && (
        <button
          type="button"
          className={`flex items-center px-4 py-2 duration-300 transform text-gray-dark xl:hover:shadow-[2px_2px_4px_0px_rgba(0,0,0,0.05)] ${
            isPageNumberColorGray
              ? 'xl:hover:bg-gray-light'
              : 'xl:hover:bg-white'
          } xl:hover:text-clinder`}
          onClick={handleClickPrev}
        >
          <HiArrowLongLeft className="text-xl" />
          <span className="hidden ml-3 text-sm font-semibold font-quicksand sm:block">
            Previous
          </span>
        </button>
      )}
      {renderPagination()}
      {currentPage < totalPages - 2 && (
        <>
          <span className="px-4 py-2 text-sm font-semibold duration-300 transform rounded-sm font-quicksand text-gray-dark">
            ...
          </span>
          <span className="px-4 py-2 text-sm font-semibold duration-300 transform rounded-sm font-quicksand text-gray-dark">
            {totalPages}
          </span>
          <button
            type="button"
            className={`flex items-center px-4 py-2 duration-300 transform text-gray-dark xl:hover:shadow-[2px_2px_4px_0px_rgba(0,0,0,0.05) xl:hover:text-clinder ${
              isPageNumberColorGray
                ? 'xl:hover:bg-gray-light'
                : 'xl:hover:bg-white'
            }`}
            onClick={handleClickNext}
          >
            <span className="hidden mr-3 text-sm font-semibold font-quicksand sm:block">
              Next
            </span>
            <HiArrowLongRight className="text-xl" />
          </button>
        </>
      )}
    </div>
  );
};

Pagination.defaultProps = {
  isPageNumberColorGray: false,
};

export default Pagination;
