import React from "react";
import SideBar from "../components/Sidebar";
import NeedLoginAlert from "../components/alert/NeedLoginAlert";
import UnderConstructionBanner from "../components/banner/UnderConstructionBanner";

const Design = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] bg-white flex-none h-[86px] sticky top-0 z-50">
                <SideBar isDesign={true} />
            </aside>

            <UnderConstructionBanner />
        </div>
    );
};

export default Design;
