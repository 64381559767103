import React, { useState } from 'react';
import axiosInstance from '../utils/api/apiConfig';

import SuccessNotification from '../components/SuccessNotification';
import AuthRightSection from '../components/AuthRightSection';

import googleLogo from '../assets/icons/google-logo.svg';
import arrowRight from '../assets/icons/arrow-right.svg';
import mail from '../assets/icons/mail.svg';
import mailHighlight from '../assets/icons/mail-highlight.svg';
import key from '../assets/icons/key.svg';
import keyHighlight from '../assets/icons/key-highlight.svg';
import eyeSlash from '../assets/icons/eye-slash.svg';
import eye from '../assets/icons/eye.svg';
import login from '../assets/icons/login.svg';
import loginHighlight from '../assets/icons/login-highlight.svg';
import loading from '../assets/icons/loading.gif';

const Login = () => {
  // State Condition
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [isPasswordClicked, setIsPasswordClicked] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [showAuthSuccessMessage, setShowAuthSuccessMessage] = useState(false);

  // User Data
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Date on Local Storage
  const creator = localStorage.getItem('userFullName');

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoadingLogin(true);
    try {
      const response = await axiosInstance.post(
        `/auth/login`,
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
      localStorage.setItem('token', response?.data?.token);
      localStorage.setItem('tenantId', response?.data?.tenantId);
      localStorage.setItem('userFullName', response?.data?.userInfo?.fullName);
      localStorage.setItem('userId', response?.data?.userInfo?.id);
      localStorage.setItem('userRole', response?.data?.userInfo?.role);
      setIsLoadingLogin(false);
      setShowAuthSuccessMessage(true);
    } catch (error) {
      setIsLoadingLogin(false);
      // console.log(error?.response?.data?.message);
    }
  };

  return (
    <div className="flex min-h-screen lg:h-screen lg:overflow-y-hidden">
      {/* left section */}
      <div className="flex items-center justify-center w-full mx-2 sm:mx-auto sm:w-3/5 xl:mx-10 2xl:mx-0 xl:w-2/5">
        {!showAuthSuccessMessage ? (
          <div className="flex flex-col h-[70%] md:h-[60%] xl:h-[70%] 2xl:h-[80%] 4xl:h-3/5 w-full xl:w-[400px] 4xl:w-3/5">
            <div>
              <h2 className="text-2xl font-bold font-quicksand">
                <span className="text-clinder">
                  Welcome{creator !== null && ' Back'},
                </span>{' '}
                {creator !== null && (
                  <span className="capitalize text-aurora-blue">{creator}</span>
                )}
              </h2>
              <p className="mt-2 text-xs font-medium font-satoshi text-manatee">
                Please enter the details to start manage your restaurant.
              </p>
            </div>
            <div className="flex flex-col flex-grow ml-0">
              {/* login with google button */}
              <button className="flex items-center justify-center w-full py-4 mx-auto mt-12 ml-0 xl:ml-2 rounded-xl bg-dew">
                <img src={googleLogo} alt="google logo" />
                <h2 className="mx-4 text-base font-bold font-quicksand text-aurora-blue">
                  Login with Google
                </h2>
                <img src={arrowRight} alt="arrow right icon" />
              </button>
              <div className="flex items-center mt-4 ml-0 xl:ml-2">
                <span className="inline-block w-full h-px bg-gray-x" />
                <span className="px-4 text-xs font-bold font-quicksand text-manatee">
                  Or
                </span>
                <span className="inline-block w-full h-px bg-gray-x" />
              </div>
              {/* login form  */}
              <form
                className="flex flex-col justify-between flex-grow mt-6 ml-0 xl:ml-2"
                onSubmit={handleLogin}
              >
                <div>
                  <div
                    className="relative mt-1 h-14"
                    onClick={() => setIsEmailClicked(true)}
                    onBlur={() => setIsEmailClicked(false)}
                  >
                    <img
                      src={isEmailClicked ? mailHighlight : mail}
                      alt="email icon"
                      className="absolute z-10 w-5 h-5 top-[18px] left-4"
                    />
                    <input
                      type="email"
                      required
                      placeholder="Enter Customer Email"
                      className="absolute w-full pl-12 pr-4 h-14 focusable-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div
                    className="relative mt-4 h-14"
                    onClick={() => setIsPasswordClicked(true)}
                    onBlur={() => setIsPasswordClicked(false)}
                  >
                    <img
                      src={isPasswordClicked ? keyHighlight : key}
                      alt="password icon"
                      className="absolute z-10 w-5 h-5 top-[18px] left-4"
                    />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      required
                      placeholder="Password"
                      value={password}
                      className="absolute w-full px-12 h-14 focusable-input"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="bg-white shadow-[0_4px_8px_rgba(155,155,155,0.05)] rounded-lg p-2 absolute z-10 top-3 right-4"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <img
                        src={showPassword ? eye : eyeSlash}
                        alt={
                          showPassword
                            ? 'show password icon'
                            : 'hidden password icon'
                        }
                        className="w-4 h-4"
                      />
                    </button>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={email === '' && password === ''}
                    className={`flex justify-center items-center w-full py-4 text-base font-bold rounded-lg mt-20 md:mt-24 xl:mt-9 ${
                      isLoadingLogin
                        ? 'bg-gray-x'
                        : email !== '' && password !== ''
                        ? 'bg-aurora-blue text-white hover:bg-[#0170a0]'
                        : 'bg-gray-x text-manatee'
                    } `}
                  >
                    {isLoadingLogin ? (
                      <img src={loading} alt="loading" className="w-8 h-8" />
                    ) : (
                      <>
                        <img
                          src={
                            email !== '' && password !== ''
                              ? loginHighlight
                              : login
                          }
                          alt="login icon"
                        />
                        <span className="ml-3 font-quicksand">Login</span>
                      </>
                    )}
                  </button>
                  {/* <div className="m-auto mt-6 text-center font-quicksand">
                    <span className="text-xs font-medium text-clinder">
                      Don&#39;t have an account?
                    </span>
                    <Link
                      to={'/register'}
                      className="ml-1 text-xs font-bold text-orange"
                    >
                      Register Here
                    </Link>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="mx-auto w-[350px] xs:w-[380px]">
            <SuccessNotification
              title={'Login Successful'}
              message={
                'Your login process to the Betty Cashier application has been successful.'
              }
              notificationCountdown={5}
              path="/"
            />
          </div>
        )}
      </div>

      {/* right section */}
      <AuthRightSection cropImage={true} />
    </div>
  );
};

export default Login;
