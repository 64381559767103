import { BrowserRouter, Route, Routes } from "react-router-dom";
import StockCategory from "../pages/stock/StockCategory";
import Dashboard from "../pages/Dashboard";
import Design from "../pages/Design";
import IntegrationStock from "../pages/IntegrationStock";
import Login from "../pages/Login";
// import Register from "../pages/Register";
// import Report from "../pages/Report";
import Supply from "../pages/Supply";
import "../styles/App.css";
import StockBaseProduct from "../pages/stock/StockBaseProduct";
import StockMenu from "../pages/stock/StockMenu";
import StockPrice from "../pages/stock/StockPrice";
import StockFavoriteMenu from "../pages/stock/StockFavoriteMenu";
import StockAdditionalNotes from "../pages/stock/StockAdditionalNotes";
import Customer from "../pages/customer/Customer";
import CustomerPromo from "../pages/customer/CustomerPromo";
import CustomerVoucher from "../pages/customer/CustomerVoucher";
import RestaurantEmployee from "../pages/restaurant/RestaurantEmployee";
import RestaurantPreferences from "../pages/restaurant/RestaurantPreferences";
import RestaurantScreenCashier from "../pages/restaurant/RestaurantScreenCashier";
import ManageRole from "../pages/role_and_admin/ManageRole";
import AdministratorList from "../pages/role_and_admin/AdministratorList";
import Table from "../pages/stock/Table";
import SettingsBranch from "../pages/settings_and_preferences/SettingsBranch";
import SettingsTax from "../pages/settings_and_preferences/SettingsTax";
import SettingsQRIS from "../pages/settings_and_preferences/SettingsQRIS";
import SettingsNumberFormat from "../pages/settings_and_preferences/SettingsNumberFormat";
import ReportTransaction from "../pages/report/ReportTransaction";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                {/* Login Register & Dashboard */}
                <Route path="/login" element={<Login />} />
                {/* <Route path='/register' element={<Register />} /> */}
                <Route path="/" element={<Dashboard />} />

                {/* stock */}
                <Route path="/admin-stock-category" element={<StockCategory />} />
                <Route path="/admin-stock-base-product" element={<StockBaseProduct />} />
                <Route path="/admin-stock-menu" element={<StockMenu />} />
                <Route path="/admin-stock-price" element={<StockPrice />} />
                <Route path="/admin-stock-favorite-menu" element={<StockFavoriteMenu />} />
                <Route path="/admin-stock-additional-notes" element={<StockAdditionalNotes />} />

                {/* Customer */}
                <Route path="/admin-customer" element={<Customer />} />
                <Route path="/admin-customer-promo" element={<CustomerPromo />} />
                <Route path="/admin-customer-voucher" element={<CustomerVoucher />} />

                {/* Restaurant */}
                <Route path="/admin-restaurant-employee" element={<RestaurantEmployee />} />
                <Route path="/admin-restaurant-preferences" element={<RestaurantPreferences />} />
                <Route
                    path="/admin-restaurant-screen-cashier"
                    element={<RestaurantScreenCashier />}
                />
                <Route path="/admin-design" element={<Design />} />
                {/* Report */}
                <Route path="/admin-report-transaction" element={<ReportTransaction />} />

                {/* Role & Admin */}
                <Route path="/admin-role-and-admin-manage-role" element={<ManageRole />} />
                <Route
                    path="/admin-role-and-admin-administrator-list"
                    element={<AdministratorList />}
                />
                <Route path="/admin-table" element={<Table />} />

                {/* Settings */}
                <Route path="/admin-settings-branch" element={<SettingsBranch />} />
                <Route path="/admin-settings-tax" element={<SettingsTax />} />
                <Route path="/admin-settings-qris" element={<SettingsQRIS />} />
                <Route path="/admin-settings-number-format" element={<SettingsNumberFormat />} />

                {/* integration stock and supply skipped on 1st version */}
                <Route path="/admin-integration-stock" element={<IntegrationStock />} />
                <Route path="/admin-supply" element={<Supply />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
