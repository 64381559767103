import React, { useState } from 'react';

import InfoBox from '../../InfoBox';

const StatusPriceForm = ({
  statusPriceIndex,
  customerLevelName,
  defaultDiscountType,
  discountValue,
  statusPriceData,
  setStatusPriceData,
  onHandleDiscountTypeChange,
  onHandleDiscountValueChange,
  onHandleDecrementDiscountValue,
  onHandleIncrementDiscountValue,
  onHandleResetDiscountValue,
}) => {
  const [discountType, setDiscountType] = useState(defaultDiscountType);

  return (
    <div className="mt-4 border-[1px] border-gray-x/40 rounded-lg p-3">
      <div className="flex gap-4">
        <div className="flex-1">
          <label
            htmlFor="customer-status"
            className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
          >
            Customer Level
          </label>
          <div className="px-4 py-3 text-sm font-semibold capitalize border rounded-lg border-gray-x/40 font-quicksand">
            {customerLevelName}
          </div>
        </div>
      </div>
      <div className="flex p-2 mt-4 rounded-lg bg-neutral-gray gap-[10px]">
        <button
          type="button"
          className={`transition duration-300 ${
            discountType === 'Percentage'
              ? 'bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] font-bold text-clinder'
              : 'font-semibold text-manatee hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:font-bold hover:text-clinder'
          } py-[6px] w-full rounded font-quicksand  text-xs`}
          onClick={() => {
            onHandleDiscountTypeChange(
              statusPriceIndex,
              'Percentage',
              statusPriceData,
              setStatusPriceData
            );
            onHandleResetDiscountValue(
              statusPriceIndex,
              statusPriceData,
              setStatusPriceData
            );
            setDiscountType('Percentage');
          }}
        >
          Percentage
        </button>
        <button
          type="button"
          className={`duration-300 transition ${
            discountType === 'Percentage'
              ? 'font-semibold text-manatee hover:font-bold hover:bg-white hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] hover:text-clinder'
              : 'font-bold bg-white shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-clinder'
          } py-[6px] w-full rounded font-quicksand text-xs`}
          onClick={() => {
            onHandleDiscountTypeChange(
              statusPriceIndex,
              'Constant',
              statusPriceData,
              setStatusPriceData
            );
            onHandleResetDiscountValue(
              statusPriceIndex,
              statusPriceData,
              setStatusPriceData
            );
            setDiscountType('Constant');
          }}
        >
          Constant
        </button>
      </div>
      {/* percentage value */}
      {discountType === 'Percentage' ? (
        <div className="mt-4">
          <div className="flex flex-col gap-4 mt-2 sm:flex-row lg:flex-1">
            <div className="flex-1">
              <label className="flex flex-none w-[212px] gap-1">
                <span className="text-sm font-semibold text-clinder font-quicksand">
                  Discount Percentage Value
                </span>
                <InfoBox
                  title={'.............................'}
                  content={'............................................'}
                  customLeftPosition={
                    '-left-32 xs:-left-[100px] sm:left-5 md:left-4 md:-left-20 lg:left-5'
                  }
                  customTopPosition={'top-4 sm:-top-3 md:top-3 lg:-top-3'}
                />
              </label>
              <div className="flex items-center p-1 border-[1px] border-gray-x/40 rounded-lg gap-4 mt-2 h-[52px]">
                <div className="flex flex-none gap-2 p-1 rounded-lg bg-neutral-gray ">
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base leading-3 flex-1"
                    onClick={() =>
                      onHandleDecrementDiscountValue(
                        statusPriceIndex,
                        discountType,
                        discountValue,
                        statusPriceData,
                        setStatusPriceData
                      )
                    }
                  >
                    -
                  </button>
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base flex-1"
                    onClick={() =>
                      onHandleIncrementDiscountValue(
                        statusPriceIndex,
                        discountType,
                        discountValue,
                        statusPriceData,
                        setStatusPriceData
                      )
                    }
                  >
                    +
                  </button>
                </div>
                <div className="flex items-center justify-between flex-1 gap-2">
                  <input
                    type="number"
                    className="flex-1 text-sm font-semibold outline-none font-quicksand text-clinder"
                    value={discountValue}
                    onChange={(e) =>
                      onHandleDiscountValueChange(
                        statusPriceIndex,
                        Number(e.target.value),
                        statusPriceData,
                        setStatusPriceData
                      )
                    }
                  />
                  <span className="flex-none px-3 py-1 text-base text-center rounded-lg font-quicksand bg-neutral-gray">
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Constant Value
        <div className="mt-4">
          <div className="flex flex-col gap-4 mt-2 sm:flex-row md:flex-col lg:flex-row">
            <div className="flex-1">
              <label className="flex flex-none w-48 gap-1">
                <span className="text-sm font-semibold text-clinder font-quicksand">
                  Discount Constant Value
                </span>
                <InfoBox
                  title={'............'}
                  content={'..................................'}
                  customLeftPosition={
                    '-left-32 xs:-left-[100px] sm:left-5 md:left-4 md:-left-20 lg:left-5'
                  }
                  customTopPosition={'top-4 sm:-top-3 md:top-3 lg:-top-3'}
                />
              </label>
              <div className="flex items-center p-1 border-[1px] border-gray-x/40 rounded-lg gap-4 mt-2 h-[52px]">
                <div className="flex p-1 rounded-lg bg-neutral-gray">
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base flex-1"
                    onClick={() =>
                      onHandleDecrementDiscountValue(
                        statusPriceIndex,
                        discountType,
                        discountValue,
                        statusPriceData,
                        setStatusPriceData
                      )
                    }
                  >
                    -
                  </button>
                  <button
                    type="button"
                    className="w-8 rounded h-7 hover:bg-white transition-all duration-300 hover:shadow-[2px_2px_4px_0px_rgba(130,130,130,0.05)] text-manatee hover:text-clinder font-quicksand font-semibold text-base flex-1"
                    onClick={() =>
                      onHandleIncrementDiscountValue(
                        statusPriceIndex,
                        discountType,
                        discountValue,
                        statusPriceData,
                        setStatusPriceData
                      )
                    }
                  >
                    +
                  </button>
                </div>
                <input
                  type="number"
                  className="w-full text-sm font-semibold outline-none font-quicksand text-clinder"
                  value={discountValue}
                  onChange={(e) =>
                    onHandleDiscountValueChange(
                      statusPriceIndex,
                      Number(e.target.value),
                      statusPriceData,
                      setStatusPriceData
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusPriceForm;
