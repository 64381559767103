import React, { useState, useRef, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { AiOutlineRightCircle } from 'react-icons/ai';
import { GrNotes } from 'react-icons/gr';

import axiosInstance from '../../../utils/api/apiConfig';

import InfoBox from '../../InfoBox';
import SectionNavigator from '../../SectionNavigator';
import CategoryOptionsDropdown from './CategoryOptionsDropdown';
import BaseProductSelectionModal from './BaseProductSelectionModal';
import EditBaseProductInput from './EditBaseProductInput';

// /*-------- Hide menu Type  (for 1.0 version) -----------*/
// import TypeOptionsDropdown from './TypeOptionsDropdown';
// import NewProductTypeModal from './NewProductTypeModal';

import close from '../../../assets/icons/close.svg';
import generalColorful from '../../../assets/icons/generalColorful.svg';
import generalColorless from '../../../assets/icons/generalColorless.svg';
import recipesColorful from '../../../assets/icons/recipesColorful.svg';
import recipesColorless from '../../../assets/icons/recipesColorless.svg';
import stockColorful from '../../../assets/icons/stockColorful.svg';
import stockColorless from '../../../assets/icons/stockColorless.svg';
import variantColorful from '../../../assets/icons/variantColorful.svg';
import variantColorless from '../../../assets/icons/variantColorless.svg';
import menuPhotoIcon from '../../../assets/icons/menuPhoto.svg';
import archiveTick from '../../../assets/icons/archiveTick.svg';
import menuRecipeBanner from '../../../assets/images/menuRecipeBanner.png';
import search from '../../../assets/icons/search.svg';
import book from '../../../assets/icons/book.svg';
import boxAdd from '../../../assets/icons/boxAdd.svg';
import plusGradient from '../../../assets/icons/plusGradient.svg';
import searchBlue from '../../../assets/icons/searchBlue.svg';
import userAdd from '../../../assets/icons/userAdd.svg';
import arrowRight from '../../../assets/icons/arrow-right.svg';
import trush from '../../../assets/icons/trashRed.svg';

const DetailSingleMenuModal = ({
  isModalOpen,
  setIsModalOpen,
  categories,
  onGetAllMenus,
  menuId,
  // /*-------- Hide menu type (for 1.0 version) -----------*/
  // productTypes,
  // isProductTypeUpdate,
  // setIsProductTypeUpdate,
}) => {
  // === General Menu State ===
  const [menuName, setMenuName] = useState('');
  const [shortMenuName, setShortMenuName] = useState('');
  const [menuCategoryId, setMenuCategoryId] = useState();
  const [menuCategoryName, setMenuCategoryName] = useState('');
  const [menuPrice, setMenuPrice] = useState('');
  const [formattedMenuPrice, setFormattedMenuPrice] = useState('');

  const [menuDescription, setMenuDescription] = useState('');
  const [menuPhoto, setMenuPhoto] = useState(null);
  const [menuPhotoPreview, setMenuPhotoPreview] = useState(null);
  const menuPhotoRef = useRef(null);
  const [productNotes, setProductNotes] = useState(null);

  // *-------- Hide menu type (for the meantime) -----------*/
  // const [productTypeName, setProductTypeName] = useState('');
  // const [productTypeId, setProductTypeId] = useState();

  // *-------- Hide menu video (for the meantime) -----------*/
  // const [menuVideo, setMenuVideo] = useState(null);
  // const menuVideoRef = useRef(null);

  // ===  Menu Recipe State ===
  const [selectedBaseProductsToGet, setSelectedBaseProductsToGet] = useState(
    []
  );
  const [createdMenuName, setCreatedMenuName] = useState('');
  const [createdMenuId, setCreatedMenuId] = useState();
  const [allBaseProducts, setAllBaseProducts] = useState([]);
  const [recipeSearchQuery, setRecipeSearchQuery] = useState('');

  // === Variant Menu State ===
  const [variantMenu, setVariantMenu] = useState([]);

  // === Stock Menu State ===
  const [isCalculatedStockAutomatically, setIsCalculatedStockAutomatically] =
    useState(true);
  const [stockMenu, setStockMenu] = useState('');
  const [estimatedStock, setEstimatedStock] = useState('');
  const [createdMenuBaseProductList, setCreatedMenuBaseProductList] = useState(
    []
  );

  // === Condition State ===
  const [isLoadingPostMenu, setIsLoadingPostMenu] = useState(false);
  const [isLoadingGetAllBaseProducts, setIsLoadingGetAllBaseProducts] =
    useState(false);
  const [
    isLoadingGetSelectedBaseProducts,
    setIsLoadingGetSelectedBaseProducts,
  ] = useState(false);
  const [
    isBaseProductAddedToRecipeUpdated,
    setIsBaseProductAddedToRecipeUpdated,
  ] = useState(false);

  //  === Component State ===
  const modalContainerRef = useRef(null);
  const [activeNav, setActiveNav] = useState('General Menu');
  const [menuType, setMenuType] = useState('Single Menu');
  const [isBaseProductSelectionModalOpen, setIsBaseProductSelectionModalOpen] =
    useState(false);
  const menuNavs = [
    { title: 'General Menu', menu: 'General' },
    { title: 'Menu Recipe', menu: 'Recipes' },
    { title: 'Stock Menu', menu: 'Stock' },
    { title: 'Variant Menu', menu: 'Variant' },
  ];

  // *-------- Hide menu type (for the meantime) -----------*/
  // const [isNewTypeModalOpen, setIsNewTypeModalOpen] = useState(false);

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const creator = localStorage.getItem('userFullName');

  // Handle Generate Random Short Menu Name
  const HandleGenerateShortMenuName = () => {
    const trimmedWhiteSpace = menuName.replace(/\s+/g, '');
    const randomMenuName = trimmedWhiteSpace
      .split('')
      .sort(() => 0.5 - Math.random())
      .join('')
      .slice(0, 5);
    setShortMenuName(randomMenuName);
  };

  // fake data
  const categoryOptions = [
    'asian food',
    'asian drinks',
    'asian coffee',
    'asian breads',
  ];

  // data recipe di popup recipe di Admin panel-menu
  const fakeBaseProducts = [
    {
      id: 1,
      baseProductName: 'rice',
      stockOnTheInventory: 1000,
      qty: 0,
      unitSystem: 'Kilogram',
    },
    {
      id: 3,
      baseProductName: 'salt',
      stockOnTheInventory: 1000,
      qty: 0,
      unitSystem: 'kilogram',
    },
    {
      id: 4,
      baseProductName: 'cheese',
      stockOnTheInventory: 1000,
      qty: 0,
      unitSystem: 'gram',
    },
  ];

  const stockMenuFakeData = [
    {
      id: 1,
      baseProduct: 'Florence Fennel',
      recentStock: 5028,
      requiredStock: 5000,
    },
    {
      id: 2,
      baseProduct: 'Dusel',
      recentStock: 4856,
      requiredStock: 4000,
    },
    {
      id: 3,
      baseProduct: 'Salt',
      recentStock: 6456,
      requiredStock: 3000,
    },
    {
      id: 4,
      baseProduct: 'Pepper',
      recentStock: 4219,
      requiredStock: 1219,
    },
    {
      id: 5,
      baseProduct: 'Chilli',
      recentStock: 1234,
      requiredStock: 2000,
    },
    {
      id: 6,
      baseProduct: 'Meat',
      recentStock: 1346,
      requiredStock: 2000,
    },
    {
      id: 7,
      baseProduct: 'Egg',
      recentStock: 2123,
      requiredStock: 1000,
    },
  ];

  // === General Menu Method ===
  // Format Menu price value with commas (2000 --> 2.000)
  const formatNumberWithCommas = (inputValue) => {
    if (!inputValue) {
      return '';
    }

    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''));
    const formattedValue = numericValue.toLocaleString('id-ID'); // Ganti 'id-ID' dengan kode lokal yang sesuai
    return formattedValue;
  };

  const formatDefaultPriceWithCommas = (inputValue) => {
    if (!inputValue) {
      return '';
    }
    // console.log(inputValue);

    // const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''));
    const formattedValue = inputValue.toLocaleString('id-ID'); // Ganti 'id-ID' dengan kode lokal yang sesuai
    setFormattedMenuPrice(formattedValue);
    // console.log(formattedValue);
  };

  const handleInputMenuPrice = (e) => {
    const inputValue = e.target.value;
    setFormattedMenuPrice(formatNumberWithCommas(inputValue));
    setMenuPrice(inputValue.replace(/[^\d]/g, ''));
  };

  // handle photo input
  const handleFileChange = (e) => {
    setMenuPhoto(e.target.files[0]);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewImage = e.target.result;
        setMenuPhotoPreview(previewImage);
      };
      reader.readAsDataURL(file);
    } else {
      setMenuPhotoPreview(null);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setMenuPhoto(e.dataTransfer.files[0]);

    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewImage = e.target.result;
        setMenuPhotoPreview(previewImage);
      };
      reader.readAsDataURL(file);
    } else {
      setMenuPhotoPreview(null);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    getProductById();
    if (selectedBaseProductsToGet.length !== 0) {
      getEstimatedStock();
    }
  }, [menuId, selectedBaseProductsToGet]);

  // Get Product By Id
  const getProductById = async () => {
    try {
      const response = await axiosInstance.get(`/products/${menuId}`, {
        headers: {
          'Content-Type': 'aplication/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      const {
        name,
        shortMenuName,
        price,
        description,
        url,
        productCategoryId,
        productCategoryName,
        productStock,
        productNotes,
      } = response?.data?.data;
      setMenuName(name);
      setShortMenuName(shortMenuName);
      setMenuPrice(price);
      setMenuCategoryId(productCategoryId);
      setMenuCategoryName(productCategoryName);
      setMenuDescription(description);
      setMenuCategoryId(productCategoryId);
      setMenuCategoryName(productCategoryName);
      setMenuDescription(description);
      setMenuPhotoPreview(url);
      setStockMenu(productStock);
      setProductNotes(productNotes);
    } catch (error) {
      // console.log(error?.response?.data?.data);
    }
  };

  // Handle Submit General Menu
  const handleEditGeneralMenu = async (e) => {
    e.preventDefault();
    // setIsLoadingPostMenu(true);
    const formData = new FormData();
    if (menuPhoto instanceof File) {
      formData.append('file', menuPhoto);
    }
    formData.append('title', menuName);
    formData.append('shortMenuName', shortMenuName);
    formData.append('price', parseFloat(menuPrice));
    formData.append(
      'priceWithTax',
      parseFloat(menuPrice) + parseFloat(menuPrice) * 0.11
    );
    formData.append('productCategoryId', menuCategoryId);
    // formData.append('menuTypeId', productTypeId);
    formData.append('description', menuDescription);

    // console.log({ menuId });

    try {
      const response = await axiosInstance.patch(
        `/products/${menuId}`,
        formData,
        {
          headers: {
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // setActiveNav('Menu Recipe');
      modalContainerRef.current.scrollTop = 0;
      onGetAllMenus();
      setIsLoadingPostMenu(false);
    } catch (error) {
      // console.log(error.response.data);
    }
  };

  // ==== Menu Recipe Method ====
  // Handle Get All Base Products
  useEffect(() => {
    const getAllBaseProducts = async () => {
      setIsLoadingGetAllBaseProducts(true);
      try {
        const response = await axiosInstance.get(`/base-products`, {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setAllBaseProducts(response.data.data.data);
        setIsLoadingGetAllBaseProducts(false);
      } catch (error) {
        // console.log(error);
        setIsLoadingGetAllBaseProducts(false);
      }
    };

    getAllBaseProducts();
  }, []);

  // Handle Get Selected Base Product by menu
  useEffect(() => {
    getSelectedBaseProducts();
  }, [isBaseProductAddedToRecipeUpdated, menuId]);

  const getSelectedBaseProducts = async () => {
    setIsLoadingGetAllBaseProducts(true);
    try {
      const response = await axiosInstance.get(
        `/product-details/product/${menuId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.data.length === 0) {
        return setSelectedBaseProductsToGet([]);
      } else {
        setSelectedBaseProductsToGet(response?.data?.data[0]?.baseProductList);
      }
      setIsLoadingGetSelectedBaseProducts(false);
    } catch (error) {
      // console.log(error);
      setIsLoadingGetSelectedBaseProducts(false);
    }
  };

  // Handle Recipe search filter to filter base product
  const recipeSearchFilter = (data) => {
    return data.filter((item) =>
      item.baseProductName
        .toLowerCase()
        .includes(recipeSearchQuery.toLowerCase())
    );
  };

  // ==== Stock Menu Method ====

  // Handle Get Estimated Stock
  const getEstimatedStock = async () => {
    try {
      const response = await axiosInstance.get(
        `/products/estimatedStock/${menuId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEstimatedStock(response?.data?.data?.estimatedServing);
      setCreatedMenuBaseProductList(response?.data?.data?.baseProductList);
    } catch (error) {
      // console.log(error);
      setIsLoadingGetSelectedBaseProducts(false);
    }
  };

  const handleNextToStockMenu = () => {
    if (menuId !== undefined) {
      getEstimatedStock();
    }
    setActiveNav('Stock Menu');
    setIsCalculatedStockAutomatically(true);
    window.scrollTo(0, 0);
  };

  //  Handel submit Stock Product
  const handleSubmitStockProduct = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      productId: menuId,
      stock: stockMenu,
    };

    try {
      const response = await axiosInstance.post(
        `/product-stocks`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onGetAllMenus();
      setActiveNav('Variant Menu');
      modalContainerRef.current.scrollTop = 0;
      setIsCalculatedStockAutomatically(true);
    } catch (error) {
      // console.log(error);
    }
  };

  // ==== Variant menu Method ====
  // Handle Add New Variant
  const addVariant = () => {
    setVariantMenu((prevVariants) => [
      ...prevVariants,
      {
        name: '',
        isChooseMoreThanOne: false,
        isRequired: false,
        subVariants: [],
      },
    ]);
  };

  // Handle Change Variant Name
  const handleVariantChange = (variantIndex, event) => {
    const { name, value } = event.target;
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex][name] = value;
      return newVariants;
    });
  };

  // Handle remove variant
  const removeVariant = (variantIndex) => {
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants.splice(variantIndex, 1);
      return newVariants;
    });
  };

  // Handle Change Sub Variant
  const handleSubVariantChange = (variantIndex, subVariantIndex, event) => {
    const { name, value } = event.target;
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex].subVariants[subVariantIndex][name] = value;
      return newVariants;
    });
  };

  // Handle IsChoose Only One
  const handleIsChooseMoreThanOne = (variantIndex, value) => {
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex].isChooseMoreThanOne = value;
      return newVariants;
    });
  };

  // Handle isRequired
  const handleIsRequired = (variantIndex, value) => {
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex].isRequired = value;
      return newVariants;
    });
  };

  // Handle Add sub variant
  const addSubVariant = (variantIndex) => {
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex]?.subVariants?.push({
        name: '',
        additionalPrice: 0,
      });
      return newVariants;
    });
  };

  // Handle Remove Sub Variant
  const removeSubVariant = (variantIndex, subVariantIndex) => {
    setVariantMenu((prevVariants) => {
      const newVariants = [...prevVariants];
      newVariants[variantIndex].subVariants.splice(subVariantIndex, 1);
      return newVariants;
    });
  };

  const fakeDataVariant = [
    {
      varianName: 'Size',
      isChoiseMoreThanOne: true,
      isRequired: true,
      toppings: [
        {
          toppingName: 'M',
          price: 10000,
        },
        {
          toppingName: 'L',
          price: 10000,
        },
        {
          toppingName: 'XL',
          price: 10000,
        },
      ],
    },
    {
      varianName: 'Sauce',
      isChoiseMoreThanOne: true,
      isRequired: true,
      toppings: [
        {
          toppingName: 'tomat',
          price: 10000,
        },
        {
          toppingName: 'cabe',
          price: 10000,
        },
      ],
    },
  ];

  // Handle Submit Variant
  const handleSubmitVariant = async (variantBodyRequest) => {
    // e.preventDefault();

    try {
      const response = await axiosInstance.post(
        `/varian-names`,
        variantBodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data.id;
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle Submit sub variant/toppings
  const handleSubmitSubVariant = async (subVariantBodyRequest) => {
    // e.preventDefault();
    // console.log(subVariantBodyRequest);
    try {
      const response = await axiosInstance.post(
        `/toppings`,
        subVariantBodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSubmitVariantToping = async (data) => {
    for (let i = 0; i < data.length; i++) {
      // Buat data untuk post variant
      const variantBodyRequest = {
        productId: createdMenuId,
        varianName: data[i]['name'],
        isChoiseMoreThanOne: data[i]['isChooseMoreThanOne'],
        isRequired: data[i]['isRequired'],
      };

      let variantId = await handleSubmitVariant(variantBodyRequest);
      // console.log({ variantId });
      for (let x = 0; x < data[i]['subVariants'].length; x++) {
        // Datas for post topping
        let subVariantBodyRequest;
        subVariantBodyRequest = [
          {
            toppingName: data[i]['subVariants'][x]['name'],
            price: data[i]['subVariants'][x]['additionalPrice'],
            idVarianName: variantId,
          },
        ];
        // console.log(subVariantBodyRequest);
        await handleSubmitSubVariant(subVariantBodyRequest);
      }
    }

    setIsModalOpen(false);
    setSelectedBaseProductsToGet([]);
    setVariantMenu([]);
    setActiveNav('General Menu');
    modalContainerRef.current.scrollTop = 0;
  };

  // Handle Filter Base Product options to Post
  const filterBaseProductOptions = (allBaseProducts, selectedBaseProduct) => {
    const baseProductOptions = allBaseProducts.filter((baseProduct) => {
      return !selectedBaseProduct.some(
        (selectedProduct) =>
          selectedProduct.baseProductId === baseProduct.baseProductId
      );
    });

    return baseProductOptions;
  };

  return (
    <>
      <div
        className={`fixed inset-0 z-50 flex justify-end bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`bg-white w-full md:w-[650px] lg:w-[800px] h-screen overflow-y-auto fixed top-0 right-0 z-50 duration-300 transition ${
          isModalOpen ? 'transalate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* main content */}
        <div
          ref={modalContainerRef}
          className="h-screen overflow-y-auto md:px-0"
        >
          <div className="block min-h-full md:flex">
            {/* Side Nav for Tablet and Desktop*/}
            <div className="hidden w-36 lg:w-48 md:block">
              <div className="border-gray-x/40 fixed top-0 border-r-[1px] w-36 lg:w-48 h-full">
                <ul className="py-6 lg:py-9">
                  <li>
                    <button
                      className={`flex duration-300 transition-all group hover:bg-dew items-center pl-7 lg:pl-9 w-full py-4 ${
                        activeNav === 'General Menu' && 'bg-dew'
                      }`}
                      onClick={() => {
                        setActiveNav('General Menu');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={
                          activeNav === 'General Menu'
                            ? generalColorful
                            : generalColorless
                        }
                        alt="general"
                      />
                      <span
                        className={`ml-3 text-sm group-hover:text-[#01639d] font-bold transition-all duration-300 ${
                          activeNav === 'General Menu'
                            ? 'text-aurora-blue'
                            : 'text-manatee'
                        } font-quicksand`}
                      >
                        General
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={`flex duration-300 transition-all group items-center hover:bg-dew pl-7 lg:pl-9 w-full py-4 ${
                        activeNav === 'Menu Recipe' && 'bg-dew'
                      }`}
                      onClick={() => {
                        setActiveNav('Menu Recipe');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={
                          activeNav === 'Menu Recipe'
                            ? recipesColorful
                            : recipesColorless
                        }
                        alt="recipes"
                      />
                      <span
                        className={`ml-3 text-sm font-bold font-quicksand group-hover:text-[#01639d] transition-all duration-300 ${
                          activeNav === 'Menu Recipe'
                            ? 'text-aurora-blue'
                            : 'text-manatee'
                        }`}
                      >
                        Recipes
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={`flex duration-300 transition-all group items-center hover:bg-dew w-full py-4 pl-7 lg:pl-9 ${
                        activeNav === 'Stock Menu' && 'bg-dew'
                      }`}
                      onClick={() => {
                        getEstimatedStock();
                        setActiveNav('Stock Menu');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={
                          activeNav === 'Stock Menu'
                            ? stockColorful
                            : stockColorless
                        }
                        alt="stock"
                      />
                      <span
                        className={`ml-3 text-sm font-bold font-quicksand group-hover:text-[#01639d] transition-all duration-300 ${
                          activeNav === 'Stock Menu'
                            ? 'text-aurora-blue'
                            : 'text-manatee'
                        }`}
                      >
                        Stock
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={`flex duration-300 transition-all group items-center hover:bg-dew w-full py-4 pl-7 lg:pl-9 ${
                        activeNav === 'Variant Menu' && 'bg-dew'
                      }`}
                      onClick={() => {
                        setActiveNav('Variant Menu');
                        modalContainerRef.current.scrollTop = 0;
                      }}
                    >
                      <img
                        src={
                          activeNav === 'Variant Menu'
                            ? variantColorful
                            : variantColorless
                        }
                        alt="variant"
                      />
                      <span
                        className={`ml-3 text-sm font-bold font-quicksand transition-all duration-300 group-hover:text-[#01639d] ${
                          activeNav === 'Variant Menu'
                            ? 'text-aurora-blue'
                            : 'text-manatee'
                        }`}
                      >
                        Variant
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            {/* form*/}
            <div className="px-6 lg:px-9 pb-6 lg:pb-9 w-full md:w-[510px] lg:w-[608px] flex flex-col min-h-screen">
              <div className="border-b-[1px] pb-6 sticky top-0 bg-white z-20 pt-6 lg:pt-9 -mx-6 md:mx-0 px-6 md:px-0">
                <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
                  <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                    <span className="inline md:hidden">Add New Menu</span>
                    <span className="hidden capitalize md:inline">
                      {menuName}
                    </span>
                  </h2>
                  <button
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setIsModalOpen(false);
                    }}
                  >
                    <img src={close} alt="close" />
                  </button>
                </div>
                <p className="mt-2 text-sm font-medium font-satoshi text-manatee w-[85%] leading-[200%] tracking-[.01em] hidden md:block">
                  {menuDescription}
                </p>
                {productNotes !== null && (
                  <button className="flex gap-4 px-4 py-2 mt-3 rounded shadow-soft">
                    <GrNotes />
                    <span className="text-xs font-semibold text-clinder font-quicksand">
                      See Notes
                    </span>
                  </button>
                )}
              </div>

              {/* Section Navigator for Mobile*/}
              <SectionNavigator
                modalContainerRef={modalContainerRef}
                menuNavs={menuNavs}
                currentNav={activeNav}
                setCurrentNav={setActiveNav}
              />

              {/* General Menu Form */}
              {activeNav === 'General Menu' && (
                <>
                  {/* Menu Typ Handler for Mobile */}
                  <div className="grid items-center grid-flow-col gap-2 md:hidden justify-stretch">
                    <button
                      type="button"
                      className={`px-4 py-2 rounded-lg font-quicksand text-sm font-medium ${
                        menuType === 'Single Menu'
                          ? 'bg-aurora-blue text-white'
                          : 'bg-neutral-gray text-gray-x'
                      }`}
                      onClick={() => setMenuType('Single Menu')}
                    >
                      Single Menu
                    </button>
                    <button
                      className={`px-4 py-2 rounded-lg font-quicksand text-sm font-medium ${
                        menuType === 'package-menu'
                          ? 'bg-aurora-blue text-white'
                          : 'bg-neutral-gray text-gray-x'
                      }`}
                      onClick={() => setMenuType('package-menu')}
                    >
                      Package Menu
                    </button>
                  </div>
                  {/* General Menu Form */}
                  <form className="flex flex-col justify-between flex-1 mt-4 md:mt-5">
                    <div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="menu-nama"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Menu Name
                        </label>{' '}
                        <br />
                        <input
                          type="text"
                          value={menuName}
                          id="menu-nama"
                          required
                          placeholder="Menu Name"
                          className="w-full px-4 py-3 mt-2 focusable-input"
                          onChange={(e) => setMenuName(e.target.value)}
                        />
                      </div>
                      <div className="mb-[18px] h-[85px]">
                        <div className="flex">
                          <label
                            htmlFor="short-menu-name"
                            className="text-sm font-semibold font-quicksand text-clinder mr-[6px]"
                          >
                            Short Menu Name
                          </label>
                          <InfoBox
                            title={'Short Menu Name'}
                            content={
                              'short menu name is an abbreviation of the main menu so when searching for the menu does not take a long time.'
                            }
                            customLeftPosition={
                              '-left-32 xs:-left-[100px] sm:left-5 md:left-4 lg:left-5'
                            }
                            customTopPosition={'top-4 sm:-top-3'}
                          />
                        </div>
                        <div className="relative mt-2">
                          <input
                            type="text"
                            value={shortMenuName}
                            id="short-menu-name"
                            placeholder="Short Version of Menu Name"
                            required
                            className="absolute w-full py-3 focusable-input pl-4 pr-[101px] md:pr-[130px]"
                            onChange={(e) => setShortMenuName(e.target.value)}
                          />
                          <button
                            type="button"
                            className={`absolute flex-none px-3 py-1 ml-4 text-xs font-semibold bg-white rounded shadow-soft font-quicksand top-3 right-4 ${
                              menuName.length < 5 && 'hidden'
                            }`}
                            onClick={HandleGenerateShortMenuName}
                          >
                            <span className="hidden sm:inline text-aurora-blue">
                              Auto{' '}
                            </span>
                            <span className="text-aurora-blue">Generate</span>
                          </button>
                        </div>
                      </div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="price-no-tax"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Price (No Tax)
                        </label>{' '}
                        <br />
                        <input
                          type="text"
                          value={
                            formattedMenuPrice === ''
                              ? formatNumberWithCommas(menuPrice.toString())
                              : formattedMenuPrice
                          }
                          id="price-no-tax"
                          required
                          placeholder="General Price of The Menu"
                          className="w-full px-4 py-3 mt-2 focusable-input"
                          onChange={handleInputMenuPrice}
                        />
                      </div>
                      <div className="mb-[18px]">
                        <label className="inline-block mb-2 text-sm font-semibold font-quicksand text-clinder">
                          Category
                        </label>
                        <CategoryOptionsDropdown
                          options={categories}
                          label={'Choose the category'}
                          setSelectedId={setMenuCategoryId}
                          selectedValue={menuCategoryName}
                          setSelectedValue={setMenuCategoryName}
                          optionHeight={'h-[155px]'}
                        />
                      </div>
                      {/*-------- Hide menu type  (for 1.0 version) -----------*/}
                      {/* <div className="mb-[18px]">
                        <div className="flex items-center justify-between mb-2">
                          <label className="inline-block mb-2 text-sm font-semibold font-quicksand text-clinder">
                            Type
                          </label>
                          <button
                            type="button"
                            className="flex px-6 py-3 border-[1px] border-blue-400 hover:bg-forested-mind rounded-lg cursor-pointer gap-3"
                            onClick={() => setIsNewTypeModalOpen(true)}
                          >
                            <img
                              src={plusGradient}
                              alt="plus"
                              className="hidden md:block"
                            />
                            <span className="text-xs font-bold font-quicksand text-aurora-blue">
                              Add New Type
                            </span>
                          </button>
                        </div>
                        <TypeOptionsDropdown
                          options={productTypes}
                          label={'Choose the type'}
                          setSelectedId={setProductTypeId}
                          selectedValue={productTypeName}
                          setSelectedValue={setProductTypeName}
                          optionHeight={'h-[155px]'}
                        />
                      </div> */}
                      <div className="mb-[18px]">
                        <label
                          htmlFor="menu-description"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          {' '}
                          Menu Description{' '}
                          <span className="text-manatee">(optional)</span>
                        </label>{' '}
                        <br />
                        <textarea
                          value={menuDescription}
                          id="menu-description"
                          required
                          rows="5"
                          placeholder={
                            menuDescription === '' && 'No description added'
                          }
                          className="w-full px-4 py-3 mt-2 resize-none focusable-input"
                          onChange={(e) => setMenuDescription(e.target.value)}
                        />
                      </div>
                      <div className="mb-[18px]">
                        <label
                          htmlFor="menu-photo"
                          className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                        >
                          Menu Photo
                          <div
                            className="relative inset-0 mt-2"
                            onDrop={handleFileDrop}
                            onDragOver={preventDefault}
                          >
                            <input
                              type="file"
                              id="menu-photo"
                              accept="image/*"
                              className="absolute z-10 w-full h-full opacity-0 cursor-pointer"
                              onChange={handleFileChange}
                              ref={menuPhotoRef}
                            />
                            <div className="bg-neutral-gray py-9  border-dashed border-2 border-[#080808]/10 rounded-lg">
                              <img
                                src={
                                  menuPhotoPreview
                                    ? menuPhotoPreview
                                    : menuPhotoIcon
                                }
                                alt="menu"
                                className={`mx-auto rounded object-cover ${
                                  menuPhotoPreview
                                    ? 'h-[100px] w-[100px]'
                                    : 'h-12 w-12'
                                }`}
                              />
                              <button
                                type="button"
                                className="flex items-center justify-center gap-3 px-6 py-3 mx-auto mt-6 bg-white border rounded-lg border-manatee"
                                onClick={() => menuPhotoRef.current.click()}
                              >
                                <img src={userAdd} alt="user add" />
                                <span className="text-xs font-bold text-aurora-blue font-quicksand">
                                  Choose File
                                </span>
                                <img src={arrowRight} alt="arrow" />
                              </button>
                              <p className="mt-2 text-xs font-medium text-center text-manatee font-quicksand">
                                {menuPhoto ? (
                                  <span>
                                    File Terpilih:{' '}
                                    <span className="text-aurora-blue">
                                      {menuPhoto.name}
                                    </span>
                                  </span>
                                ) : (
                                  'atau seret file foto kesini'
                                )}
                              </p>
                            </div>
                          </div>
                        </label>{' '}
                      </div>
                      {/* <div>
                        <label
                          htmlFor="menu-video"
                          className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                        >
                          Menu Video (Optional)
                          <div
                            className="relative inset-0 mt-2"
                            onDrop={handleMenuVideoDrop}
                            onDragOver={(e) => e.preventDefault()}
                          >
                            <input
                              type="file"
                              id="menu-video"
                              accept="video/*"
                              className="absolute cursor-pointer -z-10"
                              onChange={handleMenuVideoChange}
                              ref={menuVideoRef}
                            />
                            <div className="bg-neutral-gray py-9  border-dashed border-2 border-[#080808]/10 rounded-lg">
                              <img
                                src={menuPhotoIcon}
                                alt="menu"
                                className="mx-auto"
                              />
                              <button
                                type="button"
                                className="flex items-center justify-center gap-3 px-6 py-3 mx-auto mt-6 bg-white border rounded-lg border-manatee"
                                onClick={() => menuVideoRef.current.click()}
                              >
                                <img src={userAdd} alt="user add" />
                                <span className="text-xs font-bold text-aurora-blue font-quicksand">
                                  Choose File
                                </span>
                                <img src={arrowRight} alt="arrow" />
                              </button>
                              <p className="mt-2 text-xs font-medium text-center text-manatee font-quicksand">
                                {menuVideo
                                  ? `File Terpilih: ${menuVideo}`
                                  : 'atau seret file video kesini'}
                              </p>
                            </div>
                          </div>
                        </label>{' '}
                      </div> */}
                    </div>
                    <button
                      type="submit"
                      className="w-full mb-10 mt-9 xl:mb-0 button-primary-lg"
                      onClick={handleEditGeneralMenu}
                    >
                      <img src={archiveTick} alt="save" />
                      <span className="ml-3 text-base font-bold text-white font-quicksand">
                        Save Changes
                      </span>
                    </button>
                  </form>
                </>
              )}

              {/* Recipe form */}
              {activeNav === 'Menu Recipe' && (
                <form className="flex flex-col justify-between flex-1 h-full mt-0 md:mt-6">
                  <div className="flex flex-col flex-1">
                    {/* banner */}
                    <>
                      <div className="hidden md:block bg-aurora-blue h-[148px] rounded-lg relative overflow-hidden ">
                        <img
                          src={menuRecipeBanner}
                          alt="menu recipe banner"
                          className="absolute right-0 object-cover"
                        />
                        <div className="absolute mt-3 ml-3">
                          <h3 className="text-xl font-bold text-white capitalize font-quicksand">
                            {menuName}
                          </h3>
                          <p className="mt-1 text-xs font-medium text-white font-satoshi">
                            Estimated Stock:{' '}
                            <span className="font-semibold">
                              {estimatedStock === '' ||
                              estimatedStock === null ||
                              selectedBaseProductsToGet.length === 0
                                ? 'Unknown'
                                : estimatedStock}
                            </span>
                          </p>
                        </div>
                        <div className="absolute bottom-0 flex justify-between w-full gap-3 px-3 mb-3">
                          <div className="flex items-center flex-1 w-full h-10 px-4 rounded-lg bg-neutral-gray">
                            <img src={search} alt="search" />
                            <input
                              type="text"
                              placeholder="Search Recipe"
                              className="flex-1 w-full h-full ml-4 text-sm font-medium font-quicksand text-clinder placeholder:text-gray-x focus:outline-none bg-neutral-gray"
                              onChange={(e) =>
                                setRecipeSearchQuery(e.target.value)
                              }
                            />
                          </div>
                          <button
                            type="button"
                            className="flex py-[10px] px-4 items-center justify-center blur-5 bg-white/20 rounded-lg flex-none"
                            onClick={() =>
                              setIsBaseProductSelectionModalOpen(true)
                            }
                          >
                            <img src={book} alt="book" />
                            <span className="ml-3 text-sm font-semibold text-white font-quicksand">
                              Add Base
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="flex gap-4 px-6 py-2 bg-white border-y-[1px] border-gray-x  -mx-6 md:hidden ">
                        <img src={searchBlue} alt="search" />
                        <input
                          type="text"
                          placeholder="Search Base Product"
                          className="flex-1 text-sm font-semibold bg-white focus:outline-none font-quicksand text-clinder placeholder:text-manatee"
                        />
                        <button
                          type="button"
                          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
                        >
                          <img src={book} alt="book" className="mx-auto" />
                        </button>
                      </div>
                    </>

                    {/* base product table */}
                    {selectedBaseProductsToGet.length === 0 ? (
                      <div className="flex items-center justify-center flex-1">
                        <div className="mt-6 w-[380px] mx-auto">
                          <div className="bg-manatee/40 rounded-t-2xl flex justify-center items-center rounded-br-2xl rounded w-[60px] h-[60px] mx-auto">
                            <FaPlus className="text-manatee" size={25} />
                          </div>
                          <h4 className="mt-4 text-2xl font-bold text-center font-quicksand">
                            Oops, Nothing Here Yet!
                          </h4>
                          <p className="mt-4 text-center font-quicksand font-semibold text-sm leading-[200%] text-manatee">
                            Looks like this space is feeling a bit lonely right
                            now. Just hit that '
                            <span className="text-blue-800">Add Base</span>'
                            button, and let the good times (and data) roll!
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-0 md:mt-6">
                        <div className="block md:hidden bg-neutral-gray border-b-[1px] border-gray-x px-6 py-4 -mx-6 mb-4">
                          <p className="text-sm font-semibold capitalize font-quicksand text-clinder">
                            Nasi goreng jamur{' '}
                          </p>
                        </div>
                        <table className="w-full border-collapse table-auto">
                          <thead className="hidden bg-neutral-gray md:table-header-group">
                            <tr>
                              <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Base Product
                              </th>
                              <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Qty
                              </th>
                              {/* <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Multiple
                              </th> */}
                              <th className="w-1/4 py-2 px-[6px] text-sm font-medium text-start font-quicksand text-manatee">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {recipeSearchFilter(selectedBaseProductsToGet).map(
                              (baseProduct, index) => (
                                <EditBaseProductInput
                                  index={index}
                                  key={index}
                                  baseProduct={baseProduct}
                                  onGetSelectedBaseProducts={
                                    getSelectedBaseProducts
                                  }
                                  productDetailId={baseProduct.productDetailId}
                                />
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-center mb-10 xl:mb-0 gap-[10px]">
                    <button
                      type="button"
                      className="w-full px-1 py-4 text-[15px] font-medium border rounded-lg border-tiffany-blue font-quicksand"
                      onClick={() => {
                        setActiveNav('Stock Menu');
                        window.scrollTo(0, 0);
                        setIsCalculatedStockAutomatically(false);
                      }}
                    >
                      <span className="text-aurora-blue">
                        Skip, i don't want to add recipes
                      </span>
                    </button>
                    <button
                      disabled={
                        selectedBaseProductsToGet.length === 0 ? true : false
                      }
                      type="button"
                      className={`flex items-center justify-center w-full gap-3 px-3 py-4 font-bold transition duration-300 rounded-lg md:px-9 ${
                        selectedBaseProductsToGet.length === 0
                          ? 'bg-gray-x/60'
                          : 'bg-aurora-blue'
                      }`}
                      onClick={handleNextToStockMenu}
                    >
                      <AiOutlineRightCircle
                        className={`w-4 h-4 ${
                          selectedBaseProductsToGet.length === 0
                            ? 'text-manatee'
                            : 'text-white'
                        } `}
                      />
                      <span
                        className={`text-base font-bold  font-quicksand ${
                          selectedBaseProductsToGet.length === 0
                            ? 'text-manatee'
                            : 'text-white'
                        }`}
                      >
                        Next
                      </span>
                    </button>
                  </div>
                </form>
              )}

              {/* stock form */}
              {activeNav === 'Stock Menu' && (
                <form
                  className={`mt-0 md:mt-4 flex-1 flex flex-col justify-between`}
                >
                  <div>
                    <div className="flex p-2 rounded-lg bg-neutral-gray">
                      {isCalculatedStockAutomatically ? (
                        <div
                          type="button"
                          className={`${
                            isCalculatedStockAutomatically
                              ? 'flex items-center justify-center gap-2 bg-white px-3 py-[6px] shadow-[2px_2px_4px_rgba(130,130,130,0.05)] font-bold rounded'
                              : 'font-semibold font-quicksand text-manatee'
                          } text-xs font-quicksand w-1/2`}
                        >
                          <div
                            className={`w-2 h-2 rounded-full bg-aurora-blue ${
                              isCalculatedStockAutomatically
                                ? 'block'
                                : 'hidden'
                            }`}
                          />
                          <span>Calculated Stock Automatically</span>
                        </div>
                      ) : (
                        <div
                          type="button"
                          className={`${
                            isCalculatedStockAutomatically
                              ? 'font-semibold font-quicksand text-manatee'
                              : 'flex items-center justify-center gap-2 bg-white px-3 py-[6px] shadow-[2px_2px_4px_rgba(130,130,130,0.05)] font-bold rounded'
                          } text-xs font-quicksand w-1/2`}
                          onClick={() =>
                            setIsCalculatedStockAutomatically(false)
                          }
                        >
                          <div
                            className={`w-2 h-2 rounded-full bg-aurora-blue ${
                              isCalculatedStockAutomatically
                                ? 'hidden'
                                : 'block'
                            }`}
                          />
                          <span>Calculated Stock Manually</span>
                        </div>
                      )}
                    </div>
                    {isCalculatedStockAutomatically ? (
                      <div className="mt-6">
                        <div>
                          <div className="flex">
                            <label
                              htmlFor="estimated-stock"
                              className="mr-[6px] mb-2 text-sm font-semibold font-quicksand text-clinder"
                            >
                              Estimated Maximal Stock
                            </label>
                            <InfoBox
                              title={'Estimated Stock'}
                              content={
                                'Stock estimates are stocks estimated by Betty based on the availability of raw materials and recipes that have been included.'
                              }
                              customLeftPosition={
                                '-left-32 xs:-left-[100px] sm:left-5 md:left-4 lg:left-5'
                              }
                              customTopPosition={'top-4 sm:-top-3'}
                            />
                          </div>
                          <input
                            type="text"
                            id="estimated-stock"
                            placeholder={estimatedStock}
                            disabled
                            value={estimatedStock === null ? 0 : estimatedStock}
                            className="w-full px-4 py-3 focusable-input"
                          />
                        </div>
                        <div className="mt-6 mb-4">
                          <div className="flex">
                            <label
                              htmlFor="expected-stock"
                              className="mr-[6px] mb-2 text-sm font-semibold font-quicksand text-clinder"
                            >
                              Expected Stock
                            </label>
                            <InfoBox
                              title={'Expected Stock'}
                              content={
                                'Stock Expectations are menu stocks that the restaurant wants or needs. You can fill this stock with any amount.'
                              }
                              customLeftPosition={
                                '-left-32 xs:-left-[100px] sm:left-5 md:left-4 lg:left-5'
                              }
                              customTopPosition={'top-4 sm:-top-3'}
                            />
                          </div>
                          <input
                            type="text"
                            value={stockMenu}
                            id="stock"
                            placeholder="Enter stock"
                            className="w-full px-4 py-3 focusable-input"
                            onChange={(e) => setStockMenu(e.target.value)}
                          />
                        </div>
                        <div
                          className={`mb-4 ${
                            createdMenuBaseProductList.length === 0
                              ? 'hidden'
                              : 'flex'
                          }`}
                        >
                          <span className="mr-[6px] mb-2 text-sm font-semibold font-quicksand text-clinder">
                            Base Product Needed
                          </span>
                          <InfoBox
                            title={'Base Product Needed'}
                            content={
                              'Base Product needed is the base material needed in making the menu and which is recorded in the recipe.'
                            }
                            customLeftPosition={
                              '-left-32 xs:-left-28 sm:left-5 md:left-1 lg:left-5'
                            }
                            customTopPosition={
                              'top-4 sm:-top-3 md:top-2 lg:-top-3'
                            }
                          />
                        </div>
                        {/* Table of base product needed */}
                        <div>
                          {/* Table for mobile view */}
                          <div className="block md:hidden">
                            {createdMenuBaseProductList.map(
                              (product, index) => (
                                <div key={index} className="w-full">
                                  <div className="-mx-6 px-6 py-4  bg-neutral-gray border-gray-x border-y-[1px]">
                                    <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder ">
                                      {product.baseProduct}
                                    </p>
                                  </div>
                                  <div className="py-4">
                                    <div className="flex justify-between">
                                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                        Recent Stock
                                      </p>
                                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                        {product.recentStock
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.'
                                          )}{' '}
                                        Kg
                                      </p>
                                    </div>
                                    <div className="flex justify-between mt-4">
                                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                                        Required Stock
                                      </p>
                                      <p
                                        className={`text-sm font-semibold leading-[150%] font-quicksand`}
                                      >
                                        {product.requiredStock
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.'
                                          )}{' '}
                                        Kg
                                      </p>
                                    </div>
                                    <div className="flex items-center justify-between mt-4">
                                      <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                                        Status
                                      </p>
                                      <div
                                        className={`px-3 py-1 rounded font-quicksand text-sm font-semibold ${
                                          product.recentStock >
                                          product.requiredStock
                                            ? 'bg-ivory-cream'
                                            : 'bg-coral'
                                        }`}
                                      >
                                        {product.recentStock >
                                        product.requiredStock ? (
                                          <span className="text-lime-dark">
                                            Enough
                                          </span>
                                        ) : (
                                          <span className="text-red-dark">
                                            Need Restock
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>

                          {/* Table for tablet and desktop view */}
                          <table
                            className={`hidden w-full border-collapse table-auto ${
                              createdMenuBaseProductList.length === 0
                                ? 'hidden'
                                : 'md:table'
                            }`}
                          >
                            <thead className="bg-nautral-gray">
                              <tr className="mb-1 bg-neutral-gray">
                                <th className="p-2 text-sm font-medium font-quicksand text-start text-manatee">
                                  Base Product
                                </th>
                                <th className="p-2 text-sm font-medium font-quicksand text-start text-manatee">
                                  {' '}
                                  Recent Stock{' '}
                                </th>
                                <th className="p-2 text-sm font-medium font-quicksand text-start text-manatee">
                                  Required Stock{' '}
                                </th>
                                <th className="p-2 text-sm font-medium font-quicksand text-start text-manatee">
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {createdMenuBaseProductList.map(
                                (baseProduct, index) => (
                                  <tr key={index} className=" mt-96">
                                    <td className="p-2 mt-1 text-sm font-semibold capitalize font-quicksand text-clinder">
                                      {baseProduct?.name}
                                    </td>
                                    <td className="p-2 text-sm font-semibold font-quicksand text-clinder">
                                      {baseProduct?.recentStock
                                        .toString()
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          '.'
                                        )}{' '}
                                      {baseProduct?.unitSystem === 'kilogram'
                                        ? 'Kg'
                                        : baseProduct?.unitSystem === 'gram'
                                        ? 'g'
                                        : 'Pcs'}
                                    </td>
                                    <td className="p-2 text-sm font-semibold font-quicksand text-clinder">
                                      {stockMenu === ''
                                        ? baseProduct?.qty
                                            .toString()
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              '.'
                                            )
                                        : stockMenu * baseProduct?.qty}{' '}
                                      {baseProduct?.unitSystem === 'kilogram'
                                        ? 'Kg'
                                        : baseProduct?.unitSystem === 'gram'
                                        ? 'g'
                                        : 'Pcs'}
                                    </td>
                                    <td
                                      className={`p-2 text-sm font-semibold ${
                                        baseProduct?.recentStock >=
                                        stockMenu * baseProduct?.qty
                                          ? 'text-lime-dark'
                                          : 'text-red-dark'
                                      }`}
                                    >
                                      <div
                                        className={`w-full px-3 py-2 text-center rounded font-quicksand ${
                                          baseProduct?.recentStock >=
                                          stockMenu * baseProduct?.qty
                                            ? 'bg-creamy-white'
                                            : 'bg-coral'
                                        }`}
                                      >
                                        {baseProduct?.recentStock >=
                                        stockMenu * baseProduct?.qty
                                          ? 'Enough '
                                          : 'Need Restock'}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-6 mb-4">
                        <label
                          htmlFor="stock"
                          className="mr-[6px] mb-2 text-sm font-semibold font-quicksand text-clinder"
                        >
                          Stock
                        </label>
                        <input
                          type="text"
                          value={stockMenu}
                          id="stock"
                          placeholder="Enter stock"
                          className="w-full px-4 py-3 focusable-input"
                          onChange={(e) => setStockMenu(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="w-full mb-10 mt-9 xl:mb-0 button-primary-lg"
                    onClick={handleSubmitStockProduct}
                  >
                    <img src={archiveTick} alt="save" />
                    <span className="ml-3 text-base font-bold text-white font-quicksand">
                      Save Changes
                    </span>
                  </button>
                </form>
              )}

              {/* variant form */}
              {activeNav === 'Variant Menu' && (
                <form className="flex flex-col justify-between flex-1 mt-0 md:mt-4">
                  <div>
                    <button
                      type="button"
                      className="w-full button-secondary-sm"
                      onClick={addVariant}
                    >
                      <img src={boxAdd} alt="box add" />
                      <span className="text-xs text-aurora-blue font-quicksand">
                        Add New Variant
                      </span>
                    </button>

                    {variantMenu.map((variant, variantIndex) => (
                      <div
                        key={variantIndex}
                        className="mt-4 rounded-lg border-[1px] border-gray-x/40 p-3"
                      >
                        <div>
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-semibold font-quicksand">
                              Variant Name
                            </label>
                            <button
                              type="button"
                              className="p-2 rounded bg-coral"
                              onClick={() => removeVariant(variantIndex)}
                            >
                              <img src={trush} alt="delete" />
                            </button>
                          </div>
                          <input
                            placeholder="Enter variant name"
                            type="text"
                            className="text-sm mt-2 block w-full font-semibold font-quicksand border-[1px] border-gray-x/40 rounded-lg p-4 outline-none"
                            name="name"
                            value={variant.name}
                            onChange={(e) =>
                              handleVariantChange(variantIndex, e)
                            }
                          />

                          <button
                            type="button"
                            className={`flex items-center w-full justify-center gap-[10px] mt-4 py-2 border rounded-[4px] shadow-[2px_2px_4px_rgba(130, 130,130,0.05)] hover:bg-forested-mind duration-300 transition ${
                              variant.name === '' ? 'hidden' : 'block'
                            }`}
                            onClick={() => addSubVariant(variantIndex)}
                          >
                            <img
                              src={plusGradient}
                              alt="plus"
                              className="w-4 h-4"
                            />
                            <span className="text-xs font-bold capitalize text-aurora-blue font-quicksand">
                              Add New {variant.name}
                            </span>
                          </button>
                          {/* variant change form  */}
                          <div
                            className={`mt-4 border-[1px] border-gray-x/40 rounded-lg p-3 ${
                              variant.name === '' ? 'hidden' : 'block'
                            }`}
                          >
                            <div className="flex p-2 rounded-lg bg-neutral-gray">
                              <button
                                type="button"
                                className={`${
                                  !variantMenu[variantIndex].isChooseMoreThanOne
                                    ? 'bg-white px-3 py-[6px] shadow-[2px_2px_4px_rgba(130,130,130,0.05)] font-bold rounded'
                                    : 'font-semibold font-quicksand text-manatee'
                                } text-xs font-quicksand w-1/2`}
                                onClick={() =>
                                  handleIsChooseMoreThanOne(variantIndex, false)
                                }
                              >
                                Choose Only 1
                              </button>
                              <button
                                type="button"
                                className={`${
                                  variantMenu[variantIndex].isChooseMoreThanOne
                                    ? 'bg-white px-3 py-[6px] shadow-[2px_2px_4px_rgba(130,130,130,0.05)] font-bold rounded'
                                    : 'font-semibold font-quicksand text-manatee'
                                } text-xs font-quicksand w-1/2`}
                                onClick={() =>
                                  handleIsChooseMoreThanOne(variantIndex, true)
                                }
                              >
                                Choose More than 1
                              </button>
                            </div>
                            <div className="mt-[10px]">
                              {variant?.subVariants?.map(
                                (subVariant, subVariantIndex) => (
                                  <div
                                    key={subVariantIndex}
                                    className="flex gap-4 pb-4 border-b-[1px] border-gray-x mt-2"
                                  >
                                    <div className="w-1/2">
                                      <label
                                        htmlFor={`variant-name-${subVariantIndex}`}
                                        className="text-sm font-semibold capitalize cursor-pointer font-quicksand text-clinder"
                                      >
                                        {variant.name} {subVariantIndex + 1}
                                      </label>

                                      <input
                                        type="text"
                                        name="name"
                                        value={subVariant.name}
                                        id={`variant-name-${subVariantIndex}`}
                                        required
                                        className="w-full p-4 text-sm font-semibold font-quicksand placeholder:text-gray-x text-clinder focus:outline-none border-[1px] border-gray-x rounded-lg mt-2
                                    "
                                        onChange={(e) =>
                                          handleSubVariantChange(
                                            variantIndex,
                                            subVariantIndex,
                                            e
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <div className="flex items-center justify-between">
                                        <label
                                          htmlFor={`addtional-price-${subVariantIndex}`}
                                          className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                                        >
                                          Additional Price
                                        </label>
                                        <button
                                          type="button"
                                          className="px-2 py-1 rounded bg-coral"
                                          onClick={() =>
                                            removeSubVariant(variantIndex)
                                          }
                                        >
                                          <img src={trush} alt="delete" />
                                        </button>
                                      </div>
                                      <input
                                        type="number"
                                        name="additionalPrice"
                                        id={`addtional-price-${subVariantIndex}`}
                                        value={subVariant.additionalPrice}
                                        required
                                        className="w-full p-4 text-sm font-semibold font-quicksand placeholder:text-gray-x text-clinder focus:outline-none border-[1px] border-gray-x rounded-lg mt-2
                                    appearance-none
                                    "
                                        onChange={(e) =>
                                          handleSubVariantChange(
                                            variantIndex,
                                            subVariantIndex,
                                            e
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`flex items-center justify-between mt-4 ${
                            variant.name === '' ? 'hidden' : 'block'
                          }`}
                        >
                          <h4 className="font-quicksand text-sm font-semibold leading-[150%]">
                            Required
                          </h4>
                          <button
                            type="button"
                            className={`w-12 p-1 rounded-full transition-all duration-300 ${
                              variantMenu[variantIndex].isRequired
                                ? 'bg-forested-mind'
                                : 'bg-gray-x'
                            }`}
                            onClick={() =>
                              handleIsRequired(
                                variantIndex,
                                !variantMenu[variantIndex].isRequired
                              )
                            }
                          >
                            <div
                              className={`w-5 h-5 rounded-full transition-all duration-300 ${
                                variantMenu[variantIndex].isRequired
                                  ? 'bg-aurora-blue translate-x-full'
                                  : 'bg-manatee translate-x-0'
                              }`}
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <button
                    type="button"
                    className="w-full py-4 mb-10 mt-9 xl:mb-0 button-primary-lg"
                    onClick={() => {
                      handleSubmitVariantToping(variantMenu);
                      // window.scrollTo(0, 0);
                      // setIsModalOpen(false);
                    }}
                  >
                    <img src={archiveTick} alt="save" />
                    <span className="ml-3 text-base font-bold text-white font-quicksand">
                      Save Changes
                    </span>
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*-------- Hide menu videeo  (for 1.0 version) -----------*/}
      {/*New Menu Type  Modal*/}
      {/* <NewProductTypeModal
        isModalOpen={isNewTypeModalOpen}
        setIsModalOpen={setIsNewTypeModalOpen}
        setIsNewMenuModalOpen={setIsModalOpen}
        isProductTypeUpdate={isProductTypeUpdate}
        setIsProductTypeUpdate={setIsProductTypeUpdate}
      /> */}

      {/* Base Product Selection Modal */}
      <BaseProductSelectionModal
        isModalOpen={isBaseProductSelectionModalOpen}
        setIsModalOpen={setIsBaseProductSelectionModalOpen}
        productId={menuId}
        allBaseProducts={filterBaseProductOptions(
          allBaseProducts,
          selectedBaseProductsToGet
        )}
        // allBaseProducts={allBaseProducts}
        isBaseProductAddedToRecipeUpdated={isBaseProductAddedToRecipeUpdated}
        setIsBaseProductAddedToRecipeUpdated={
          setIsBaseProductAddedToRecipeUpdated
        }
      />
    </>
  );
};

export default DetailSingleMenuModal;
