import React from "react";

import SelectOptionDropdown from "../../../components/DoubleIndicatorDropdown";
// import shieldSearch from "../../../assets/icons/shieldSearch.svg";
// import share from "../../../assets/icons/share.svg";
// import edit from "../../../assets/icons/edit.svg";
// import trashRed from "../../../assets/icons/trashRed.svg";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const TabletDesktopEmployeeTable = ({
    employeeData,
    employeeNameOptions,
    roleOptions,
    dateAddedOptions,
    status,
}) => {
    return (
        <div className="hidden w-full mt-6 mb-3 md:block">
            <table className="w-full border-collapse table-auto">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th
                            id="employee-name"
                            className="w-[21.84%] lg:w-[22.75%] xl:w-[22.47%] 4xl:w-1/5"
                        >
                            <SelectOptionDropdown options={employeeNameOptions} />
                        </th>
                        <th id="role" className="w-[21.84%] lg:w-[22.75%] xl:w-[17.12%] 4xl:w-1/5">
                            <SelectOptionDropdown options={roleOptions} />
                        </th>
                        <th
                            id="date-added"
                            className="w-[21.84%] lg:w-[22.75%] xl:w-[22.47%] 4xl:w-1/5"
                        >
                            <SelectOptionDropdown options={dateAddedOptions} />
                        </th>
                        <th
                            id="status"
                            className="w-[21.84%] lg:w-[22.75%] xl:w-[15.44%] 4xl:w-1/5"
                        >
                            <SelectOptionDropdown options={status} />
                        </th>
                        <th
                            id="action"
                            className="text-sm font-medium font-quicksand text-manatee text-start w-[12.66%] lg:w-[9.01%] xl:w-[22.47%] 4xl:w-1/5"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="mt-[14px]">
                    {/* {employeeData.map((employee, index) => (
            <tr key={index}>
              <td aria-labelledby="employee-name" className="p-[10px]">
                <div
                  className={`flex gap-3 items-center ${
                    index === 0 ? 'mt-[14px]' : 'mt-2'
                  }`}
                >
                  <figure className="flex-none overflow-hidden rounded-full w-9 h-9">
                    <img src={employee.profilePicture} alt={employee.name} />
                  </figure>
                  <div>
                    <h3 className="text-sm font-semibold text-clinder font-quicksand leading-[150%]">
                      {employee.name}
                    </h3>
                    <h4 className="text-xs font-satoshi text-manatee leading-[200%]">
                      {employee.noHP}
                    </h4>
                  </div>
                </div>
              </td>
              <td
                aria-labelledby="role"
                className="w-[200px] text-center px-[10px]"
              >
                <div className={`${index === 0 ? 'mt-[14px]' : 'mt-2'}`}>
                  <p className="font-quicksand text-sm font-semibold bg-creamy-white capitalize py-2 text-[#f09448] rounded">
                    {employee.role}
                  </p>
                </div>
              </td>
              <td aria-labelledby="date-added" className="w-[250px] px-[10px]">
                <div
                  className={`text-clinder font-quicksand text-sm ${
                    index === 0 ? 'mt-[14px]' : 'mt-2'
                  }`}
                >
                  {employee.dateAdded}
                </div>
              </td>
              <td aria-labelledby="status" className="w-40 px-[10px]">
                <div
                  className={`text-sm font-semibold text-lime-green bg-creamy-white p-[10px] font-quicksand ${
                    index === 0 ? 'mt-[14px]' : 'mt-2'
                  }`}
                >
                  {employee.status}
                </div>
              </td>
              <td aria-labelledby="action" className="p-[10px]">
                <div
                  className={`flex items-center justify-center gap-1 mt-2 ${
                    index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                  }`}
                >
                  <button
                    type="button"
                    className="block px-3 mx-auto xl:hidden"
                  >
                    <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                    <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                    <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                  </button>

                  <button
                    type="button"
                    className="items-center justify-center flex-1 hidden py-1 duration-300 transform rounded xl:flex bg-neutral-gray hover:bg-gray-200"
                  >
                    <img
                      src={shieldSearch}
                      alt="shield search"
                      className="w-3 h-3"
                    />
                    <span className="ml-2 text-sm font-semibold font-quicksand text-clinder">
                      Detail
                    </span>
                  </button>
                  <button
                    type="button"
                    className="hidden xl:block px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200"
                  >
                    <img src={share} alt="edit" className="w-3 h-3" />
                  </button>
                  <button
                    type="button"
                    className="hidden xl:block px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200"
                  >
                    <img src={edit} alt="edit" className="w-3 h-3" />
                  </button>
                  <button
                    type="button"
                    className="hidden xl:block px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200"
                  >
                    <img src={trashRed} alt="trash red" className="w-3 h-3" />
                  </button>
                </div>
              </td>
            </tr>
          ))} */}
                </tbody>
            </table>

            <UnderConstructionBanner />
        </div>
    );
};

export default TabletDesktopEmployeeTable;
