import React, { useState } from 'react';

import infoCircle from '../assets/icons/infoCircle.svg';
import arrowLeftWhite from '../assets/icons/arrowLeftWhite.svg';

const InfoBox = ({ title, content, customTopPosition, customLeftPosition }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button type="button" onClick={() => setIsOpen(!isOpen)}>
        <img
          src={infoCircle}
          alt="info circle"
          className="w-3 h-3 cursor-pointer"
        />
      </button>

      {isOpen && (
        <div
          className={`absolute z-20 py-2 mt-2 rounded-lg p-3 w-[300px] xs:w-[323px] bg-midnight blur-10 ${customTopPosition} ${customLeftPosition}`}
        >
          <h2 className="text-base font-bold leading-6 text-white font-quicksand">
            {title}
          </h2>
          <p className="mt-2 text-sm font-medium leading-7 font-satoshi text-gray-x">
            {content}
          </p>
          <button
            className="flex items-center justify-center py-[10px] bg-white/20 w-full rounded-lg mt-3"
            onClick={() => setIsOpen(false)}
          >
            <img src={arrowLeftWhite} alt="arrow left" />
            <span className="ml-3 text-xs font-bold text-white font-quicksand">
              Close this information
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

InfoBox.defaultProps = {
  customTopPosition: 'top-0',
  customLeftPosition: 'left-0',
};

export default InfoBox;
