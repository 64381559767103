import React from 'react';

import DoubleIndicatorDropdown from '../../../components/DoubleIndicatorDropdown';
import SingleIndicatorDropdown from '../../../components/SingleIndicatorDropdown';

import shieldSearch from '../../../assets/icons/shieldSearch.svg';
import edit from '../../../assets/icons/edit.svg';
import trashRed from '../../../assets/icons/trashRed.svg';
import share from '../../../assets/icons/share.svg';

const TabletDesktopVoucherTable = ({
  voucherData,
  voucherNameOptions,
  roleOptions,
  dateAddedOptions,
  dateExpireddOptions,
  kuotaOptions,
  statusOptions,
}) => {
  return (
    <div className="hidden w-full mt-6 mb-3 md:block">
      <table className="w-full table-auto border-collaplse">
        <thead className="bg-neutral-gray">
          <tr>
            <th
              id="voucher-name"
              className="w-[26.27%] lg:w-[20.55%] xl:w-[16.61%]"
            >
              <DoubleIndicatorDropdown options={voucherNameOptions} />
            </th>
            <th id="role" className="w-[26.27%] lg:w-[16.55%] 2xl:w-[16.61%]">
              <DoubleIndicatorDropdown options={roleOptions} />
            </th>
            <th
              id="date-added"
              className="w-[11.74%] lg:w-[14.55%] 2xl:w-[11.74%] hidden lg:table-cell"
            >
              <DoubleIndicatorDropdown options={dateAddedOptions} />
            </th>
            <th
              id="date-expired"
              className="w-[11.74%] lg:w-[14.55%] 2xl:w-[11.74%] hidden lg:table-cell"
            >
              <DoubleIndicatorDropdown options={dateExpireddOptions} />
            </th>
            <th
              id="kuota"
              className="w-[15.82%] lg:w-[11.26%] xl:w-[11.74%] 2xl:w-[10.07%]"
            >
              <DoubleIndicatorDropdown options={kuotaOptions} />
            </th>
            <th
              id="status"
              className="w-[18.99%] lg:w-[13.51%] xl:w-[10.07%] 2xl:w-[16.61%]"
            >
              <DoubleIndicatorDropdown options={statusOptions} />
            </th>
            <th
              id="action"
              className="text-sm font-medium font-quicksand text-manatee text-start w-[12.66%] lg:w-[9.01%] xl:w-[16.61%]"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {voucherData.map((voucher, index) => {
            let roleTextColor = '';
            let roleBackground = '';

            if (voucher.role === 'juragan') {
              roleTextColor = 'text-bluetty';
              roleBackground = 'bg-soft-aqua';
            } else if (voucher.role === 'sultan') {
              roleTextColor = 'text-greentty';
              roleBackground = 'bg-pastel-green';
            } else if (voucher.role === 'konglomerat') {
              roleTextColor = 'text-purpletty';
              roleBackground = 'bg-tender-rose';
            } else if (voucher.role === 'warga') {
              roleTextColor = 'text-orangetty';
              roleBackground = 'bg-coral';
            } else {
              roleTextColor = 'text-black';
              roleBackground = 'bg-neutral-gray';
            }

            return (
              <tr key={index}>
                <td aria-labelledby="voucher-name" className="px-[10px]">
                  <div
                    className={`flex items-center gap-3  text-clinder font-quicksand text-sm font-semibold capitalize ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    {voucher.voucherName}
                  </div>
                </td>
                <td aria-labelledby="role" className="px-[10px]">
                  <div
                    className={`flex gap-[10px] ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    <div className={`${roleBackground} rounded py-2`}>
                      <span
                        className={`${roleTextColor} text-center text-sm font-semibold capitalize font-quicksand px-3 xl:px-6 flex-none`}
                      >
                        {voucher.role}
                      </span>
                    </div>
                    {voucher.countOfVoucher > 0 && (
                      <span className="px-3 py-2 text-sm font-semibold rounded bg-neutral-gray text-clinder font-quicksand">
                        +{voucher.countOfVoucher}
                      </span>
                    )}
                  </div>
                </td>
                <td
                  aria-labelledby="date-added"
                  className="px-[10px]] hidden lg:table-cell"
                >
                  <div
                    className={`flex items-center gap-3  text-clinder font-quicksand text-sm font-semibold ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    {voucher.dateAdded}
                  </div>
                </td>
                <td
                  aria-labelledby="date-expired"
                  className="px-[10px]] hidden lg:table-cell"
                >
                  <div
                    className={`text-clinder font-quicksand text-sm font-semibold px-[10px] ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    {voucher.dateExpired}
                  </div>
                </td>
                <td aria-labelledby="kuota" className="px-[10px]">
                  <div
                    className={`text-clinder font-quicksand text-sm font-semibold  px-[10px] ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    {voucher.kuota}
                  </div>
                </td>
                <td aria-labelledby="status" className="px-[10px]">
                  <div
                    className={`py-[10px] px-[10px] bg-ivory-cream flex justify-between items-center gap-[10px] rounded ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    <span
                      className={`text-sm font-semibold font-quicksand text-lime-green capitalize ${
                        voucher.status === 'Active'
                          ? 'text-lime-green'
                          : 'text-scarlet-red'
                      }`}
                    >
                      {voucher.status}
                    </span>
                    <SingleIndicatorDropdown
                      options={['Active', 'Inactive']}
                      showResultOption={false}
                      height={'h-auto'}
                      width={'w-auto'}
                      iconSize={'w-5 h-5'}
                      radius={'rounded'}
                      optionsWidth={'w-[100px]'}
                      padding={'px-0'}
                    />
                  </div>
                </td>
                <td aria-labelledby="action" className="p-[10px]">
                  <div
                    className={`flex items-center justify-center gap-1 mt-2 ${
                      index === 0 ? 'mb-[3px] mt-4' : 'my-[3px]'
                    }`}
                  >
                    <button
                      type="button"
                      className="block px-3 mx-auto xl:hidden"
                    >
                      <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                      <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                      <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                    </button>

                    <button
                      type="button"
                      className="items-center justify-center flex-1 hidden py-1 duration-300 transform rounded xl:flex bg-neutral-gray hover:bg-gray-200"
                    >
                      <img
                        src={shieldSearch}
                        alt="shield search"
                        className="w-3 h-3"
                      />
                      <span className="ml-2 text-sm font-semibold font-quicksand text-clinder">
                        Detail
                      </span>
                    </button>
                    <button
                      type="button"
                      className="hidden xl:block px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200"
                    >
                      <img src={share} alt="edit" className="w-3 h-3" />
                    </button>
                    <button
                      type="button"
                      className="hidden xl:block px-3 py-[7px] duration-300 transform bg-neutral-gray rounded-md hover:bg-gray-200"
                    >
                      <img src={edit} alt="edit" className="w-3 h-3" />
                    </button>
                    <button
                      type="button"
                      className="hidden xl:block px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200"
                    >
                      <img src={trashRed} alt="trash red" className="w-3 h-3" />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TabletDesktopVoucherTable;
