import React, { useEffect, useState } from 'react';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import axiosInstance from '../../../utils/api/apiConfig';

import MonthOptionDropdown from './MonthOptionDropdown';
import SingleIndicatorDropdown from '../../SingleIndicatorDropdown';

import close from '../../../assets/icons/close.svg';
import lock from '../../../assets/icons/lock.svg';
import lockColorful from '../../../assets/icons/lockColorful.svg';
import chart from '../../../assets/icons/chart.svg';
import chartColorful from '../../../assets/icons/chartColorful.svg';
import personalCard from '../../../assets/icons/personalcard.svg';
import personalCardColorful from '../../../assets/icons/personalcardColorful.svg';
import moneySend from '../../../assets/icons/money-send.svg';
import moneySendColorful from '../../../assets/icons/moneySendColorful.svg';
import archiveTick from '../../../assets/icons/archiveTick.svg';
import coffee from '../../../assets/icons/coffee.svg';
import reserveGoldenRod from '../../../assets/icons/reserveGoldenRod.svg';
import wallet from '../../../assets/icons/wallet.svg';
import timer from '../../../assets/icons/timer.svg';
import plusGradient from '../../../assets/icons/plusGradient.svg';
import CustomerLevelOptionsDropdown from './CustomerLevelOptionsDropdown';
import NewLevelModal from '../promo/NewLevelModal';

const EditCustomerModal = ({
  isModalOpen,
  setIsModalOpen,
  onGetAllCustomers,
  customerCredentialId,
}) => {
  // === Component State ====
  const [activeSideNav, setActiveSideNav] = useState('Credential');
  const [isNewCustomerLevelModalOpen, setIsNewCustomerLevelModalOpen] =
    useState(false);
  const [isCustomerLevelUpdate, setIsCustomerLevelUpdate] = useState(false);

  // === Credential State ===
  const [customerLevelName, setCustomerLevelName] = useState('');
  const [customerLevelId, setCustomerLevelId] = useState(null);
  const [customerLevels, setCustomerLevels] = useState([]);
  const [customerLevelRelationId, setCustomerLevelRelationId] = useState(null);
  const [generatedCustomerId, setGeneratedCustomerId] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [shortenEmail, setShortenEmail] = useState('');

  // === Personal Data State ===
  const [customerPersonalId, setCustomerPersonalId] = useState(null);
  const [fullName, setFullName] = useState('');
  const [gender, setGender] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [birthMonthTextValue, setBirthMonthTextValue] = useState('');
  const [birthMonthNumberValue, setBirthMonthNumberValue] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [fullBirthDate, setFullBirthDate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState('');
  const [address, setAddress] = useState('');

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  // fake data
  const frequentlyOrder = [
    {
      icon: coffee,
      frequentlyOrders: 'Frequently Drink Order',
      frequentlyOrderedDetails: 'hazelnut latte',
    },
    {
      icon: reserveGoldenRod,
      frequentlyOrders: 'Frequently Food Order',
      frequentlyOrderedDetails: 'wagyu steak 200 gr',
    },
    {
      icon: wallet,
      frequentlyOrders: 'Frequently Payment Method',
      frequentlyOrderedDetails: 'Gopay',
    },
    {
      icon: timer,
      frequentlyOrders: 'Frequently Time Order',
      frequentlyOrderedDetails: '12.00 WIB',
    },
  ];
  const transactionData = [
    {
      FoodMenu: 'fried rice with special egg,',
      otherOrders: 'meatballl, crab soup, ice cream chocolate',
      totalItems: 4,
      price: 'IDR 740,920',
      tanggal: '21 Agustus 2023',
    },
    {
      FoodMenu: 'wagyu steak 200 gr,',
      otherOrders: 'meatballl, crab soup, ice cream chocolate',
      totalItems: 4,
      price: 'IDR 1,040,920',
      tanggal: '22 Agustus 2023',
    },
    {
      FoodMenu: 'chicken & ribs combo,',
      otherOrders: 'meatballl, crab soup, ice cream chocolate',
      totalItems: 4,
      price: 'IDR 1,040,920',
      tanggal: '22 Agustus 2023',
    },
    {
      FoodMenu: 'chicken & ribs combo,',
      otherOrders: 'meatballl, crab soup, ice cream chocolate',
      totalItems: 4,
      price: 'IDR 1,040,920',
      tanggal: '22 Agustus 2023',
    },
  ];

  // Tab Description Handler
  let tabDescription = '';
  if (activeSideNav === 'Credential') {
    tabDescription =
      'In this window you can set up and edit the credential data from your customer if they are need it.';
  } else if (activeSideNav === 'Personal') {
    tabDescription =
      'In this window you can set up and edit the personal data from your customer if they are need it.';
  }

  // === Credential Data method ===
  // Handle Get customer credential by Id
  useEffect(() => {
    getCustomerCredentialById(customerCredentialId);
  }, [customerCredentialId]);
  const getCustomerCredentialById = async (id) => {
    // setIsLoadingGetCategoryById(true);
    try {
      const response = await axiosInstance.get(`/customer-credentials/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setCustomerLevelRelationId(response?.data?.data?.customerLevelRelationId);
      setCustomerLevelName(
        response?.data?.data?.CustomerLevel?.customerLevelName
      );
      setCustomerLevelId(response?.data?.data?.CustomerLevel?.id);
      setGeneratedCustomerId(
        response?.data?.data?.CustomerCredential?.customerId
      );
      setUsername(response?.data?.data?.CustomerCredential?.username);
      setEmail(response?.data?.data?.CustomerCredential?.email);
      setShortenEmail(
        response?.data?.data?.CustomerCredential?.email.split('@')[0]
      );
      setFullName(response?.data?.data?.CustomerCredential?.Customer?.fullName);
      setGender(response?.data?.data?.CustomerCredential?.Customer?.gender);
      setFullBirthDate(
        response?.data?.data?.CustomerCredential?.Customer?.birthDate
      );
      setPhoneNumber(response?.data?.data?.CustomerCredential?.Customer?.phone);
      setAddress(response?.data?.data?.CustomerCredential?.Customer?.address);
    } catch (error) {
      // console.log(error);
      //   setIsLoadingGetCategoryById(false);
    }
  };

  // Handle Get All Customer Levels
  useEffect(() => {
    const getAllCustomerLevels = async () => {
      try {
        const response = await axiosInstance.get(`/customer-levels`, {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setCustomerLevels(response.data.data);
        // // console.log(response.data.data);
      } catch (error) {
        // console.log(error);
      }
    };

    getAllCustomerLevels();
  }, [isCustomerLevelUpdate]);

  // Handle edit customer level
  const handleEditCustomerLevel = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      customerCredentialId,
      customerLevelId,
    };

    try {
      const response = await axiosInstance.patch(
        `/customer-level-relations/${customerLevelRelationId}`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getCustomerCredentialById(customerCredentialId);
      onGetAllCustomers();
      // setActiveSideNav('Personal');
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle edit customer credential
  const handleEditCustomerCredentialData = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      customerId: generatedCustomerId,
      username,
      email: shortenEmail + '@gmail.com',
    };

    try {
      const response = await axiosInstance.put(
        `/customer-credentials/${customerCredentialId}`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getCustomerCredentialById(customerCredentialId);
      onGetAllCustomers();
      // setActiveSideNav('Personal');
    } catch (error) {
      // console.log(error);
    }
  };

  // Handle generate customer id
  const generateRandomAlphanumeric = (length) => {
    const characters =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  };

  // === Personal Data method ===
  // Handle Date Value
  const getYears = (yearsBack) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - yearsBack; i--) {
      years.push(i.toString());
    }
    return years;
  };

  const daysOptions = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  const monthOptions = [
    { textValue: 'January', numberValue: 1 },
    { textValue: 'February', numberValue: 2 },
    { textValue: 'March', numberValue: 3 },
    { textValue: 'April', numberValue: 4 },
    { textValue: 'May', numberValue: 5 },
    { textValue: 'June', numberValue: 6 },
    { textValue: 'July', numberValue: 7 },
    { textValue: 'August', numberValue: 8 },
    { textValue: 'September', numberValue: 9 },
    { textValue: 'October', numberValue: 10 },
    { textValue: 'November', numberValue: 11 },
    { textValue: 'December', numberValue: 12 },
  ];

  const monthOptionsTextValueTextValue = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const yearOptions = getYears(80);

  // Handle Get Customer Personal Data by Id
  useEffect(() => {
    if (customerPersonalId) {
      getCustomerPersonalDataById(customerPersonalId);
    }
  }, [customerPersonalId]);
  const getCustomerPersonalDataById = async (id) => {
    // setIsLoadingGetCategoryById(true);
    try {
      const response = await axiosInstance.get(`/customers/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      const { fullName, gender, birthDate, phone, address } =
        response?.data?.data;

      // console.log(response.data.data);
      setFullName(fullName);
      setGender(gender);
      setFullBirthDate(birthDate);
      setDefaultPhoneNumber(phone);
      setPhoneNumber(phone);
      setAddress(address);
    } catch (error) {
      // console.log(error);
      //   setIsLoadingGetCategoryById(false);
    }
  };

  //Handle Split Full birth date input to get customer birth data
  useEffect(() => {
    const birthDateParts = fullBirthDate.split('-');
    setBirthDate(birthDateParts[2]);
    setBirthMonthNumberValue(parseInt(birthDateParts[1]));
    setBirthMonthTextValue(
      monthOptionsTextValueTextValue[parseInt(birthDateParts[1] - 1)]
    );
    setBirthYear(birthDateParts[0]);
  }, [fullBirthDate]);

  // Handle edit customer credential
  const handleEditCustomerPersonalData = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      fullName,
      birthDate: `${birthYear}-${
        birthMonthNumberValue?.toString().length === 1
          ? `0${birthMonthNumberValue}`
          : birthMonthNumberValue
      }-${birthDate?.length === 1 ? `0${birthDate}` : birthDate}`,
      gender,
      ...(defaultPhoneNumber !== phoneNumber && { phone: phoneNumber }),
      address,
    };

    try {
      const response = await axiosInstance.patch(
        `/customers/${customerPersonalId}`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data.data);
      getCustomerPersonalDataById(customerPersonalId);
      onGetAllCustomers();
      setActiveSideNav('Personal');
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex justify-end bg-clinder/60"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="h-screen overflow-y-auto bg-white w-[768px] lg:w-[800px] flex flex-col md:flex-row"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Side Nav */}
            <aside className="hidden md:block pt-6 lg:pt-9 border-r-[1px] sticky top-0 border-gray-x/40 w-[26%]">
              <ul>
                <li>
                  <button
                    className={`py-4 flex pl-6 md:pl-9 gap-3 w-full ${
                      activeSideNav === 'Credential' && 'bg-dew'
                    }`}
                    onClick={() => setActiveSideNav('Credential')}
                  >
                    <img
                      src={activeSideNav === 'Credential' ? lockColorful : lock}
                      alt="lock"
                    />
                    <span
                      className={`font-quicksand text-base ${
                        activeSideNav === 'Credential'
                          ? 'font-bold text-aurora-blue'
                          : 'text-manatee font-semibold'
                      }`}
                    >
                      Credential
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    className={`py-4 mt-2 flex pl-6 md:pl-9 gap-3 w-full ${
                      activeSideNav === 'Personal' && 'bg-dew'
                    }`}
                    onClick={() => setActiveSideNav('Personal')}
                  >
                    <img
                      src={
                        activeSideNav === 'Personal'
                          ? personalCardColorful
                          : personalCard
                      }
                      alt="personal card"
                    />
                    <span
                      className={`font-quicksand text-base ${
                        activeSideNav === 'Personal'
                          ? 'font-bold text-aurora-blue'
                          : 'text-manatee font-semibold'
                      }`}
                    >
                      Personal
                    </span>
                  </button>
                </li>
              </ul>
            </aside>

            {/* Main Content */}
            <div className="w-full md:w-[74%] px-6 pt-0 md:pt-6 lg:px-9 lg:pt-9 relative flex flex-col">
              {/* Detail Transaction Header (hidden on mobile) */}
              <header className="fixed top-0 pb-4 border-b-[1px] border-gray-x/40 justify-between md:w-[515px] bg-white pt-6 lg:pt-9 hidden md:flex">
                <div className="flex items-center gap-3 -mt-6">
                  <button
                    type="button"
                    onClick={() => setActiveSideNav('Transactions')}
                  >
                    <HiOutlineChevronLeft className="text-2xl text-manatee" />
                  </button>
                  <h2 className="text-2xl font-bold font-quicksand text-clinder">
                    Details
                  </h2>
                </div>
                <div>
                  <p className="text-manatee text-sm font-satoshi leading-[200%] font-medium">
                    20 Agustus 2023, 11 : 16 AM
                  </p>
                  <p className="text-end font-satoshi text-sm leading-[200%] font-medium text-manatee mt-1">
                    Pesanan F-200689
                  </p>
                </div>
              </header>

              {/* Credential, personal, and analitics,  transaction and details transaction (on mobile) Header */}
              <header
                className={`pb-6 border-b-[1px] border-gray-x/40 fixed top-0 bg-white w-full md:w-[515px] lg:w-[520px] pt-6 lg:pt-9 z-50 px-6 md:px-0 -mx-6 md:mx-0 ${
                  activeSideNav === 'detail-transaction' && 'block md:hidden'
                }`}
              >
                <div className="flex flex-row-reverse items-center justify-end gap-6 md:justify-between md:flex-row">
                  <h2 className="block text-xl font-semibold md:hidden font-quicksand text-aurora-blue">
                    Edit Customer
                  </h2>
                  <h2 className="hidden text-2xl font-bold md:block font-quicksand">
                    <span className="text-aurora-blue">{activeSideNav}</span>{' '}
                    {'  '}
                    <span className="capitalize text-manatee">{fullName}</span>
                  </h2>
                  <button onClick={() => setIsModalOpen(false)}>
                    <img src={close} alt="close" />
                  </button>
                </div>
                <p className="font-satoshi text-sm font-medium leading-[200%] text-manatee mt-2 w-96 hidden md:block">
                  {tabDescription}
                </p>
              </header>

              {/* Navigation Menu for Mobile */}
              <div className="fixed top-[77px] border-b-[1px] border-gray-x/40 w-full px-6 -mx-6 bg-white z-50 block md:hidden">
                <ul className="flex py-4 overflow-x-scroll">
                  <li>
                    <button
                      type="button"
                      className={`py-3 rounded-lg flex items-center justify-center px-6 gap-3 w-full ${
                        activeSideNav === 'Credential' && 'bg-dew'
                      }`}
                      onClick={() => setActiveSideNav('Credential')}
                    >
                      <img
                        src={
                          activeSideNav === 'Credential' ? lockColorful : lock
                        }
                        alt="lock"
                      />
                      <span
                        className={`font-quicksand text-base ${
                          activeSideNav === 'Credential'
                            ? 'font-bold text-aurora-blue'
                            : 'text-manatee font-semibold'
                        }`}
                      >
                        Credential
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={`py-3 rounded-lg items-center justify-center flex px-6 gap-3 w-full ${
                        activeSideNav === 'Personal' && 'bg-dew'
                      }`}
                      onClick={() => setActiveSideNav('Personal')}
                    >
                      <img
                        src={
                          activeSideNav === 'Personal'
                            ? personalCardColorful
                            : personalCard
                        }
                        alt="personal card"
                      />
                      <span
                        className={`font-quicksand text-base ${
                          activeSideNav === 'Personal'
                            ? 'font-bold text-aurora-blue'
                            : 'text-manatee font-semibold'
                        }`}
                      >
                        Personal
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={`py-3 rounded-lg items-center justify-center flex px-6 gap-3 w-full ${
                        activeSideNav === 'Analytics Data' && 'bg-dew'
                      }`}
                      onClick={() => setActiveSideNav('Analytics Data')}
                    >
                      <img
                        src={
                          activeSideNav === 'Analytics Data'
                            ? chartColorful
                            : chart
                        }
                        alt="chart"
                      />
                      <span
                        className={`font-quicksand text-base ${
                          activeSideNav === 'Analytics Data'
                            ? 'font-bold text-aurora-blue'
                            : 'text-manatee font-semibold'
                        }`}
                      >
                        Analytics
                      </span>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={`py-3 rounded-lg items-center justify-center flex px-6 gap-3 w-full ${
                        (activeSideNav === 'Transactions' ||
                          activeSideNav === 'detail-transaction') &&
                        'bg-dew'
                      }`}
                      onClick={() => setActiveSideNav('Transactions')}
                    >
                      <img
                        src={
                          activeSideNav === 'Transactions' ||
                          activeSideNav === 'detail-transaction'
                            ? moneySendColorful
                            : moneySend
                        }
                        alt="money send"
                      />
                      <span
                        className={`font-quicksand text-base ${
                          activeSideNav === 'Transactions' ||
                          activeSideNav === 'detail-transaction'
                            ? 'font-bold text-aurora-blue'
                            : 'text-manatee font-semibold'
                        }`}
                      >
                        Transaction
                      </span>
                    </button>
                  </li>
                </ul>
              </div>

              <form className="flex-1">
                {/* Credential Form */}
                {activeSideNav === 'Credential' && (
                  <div className="flex flex-col justify-between h-full pb-20 md:pb-6 lg:pb-9">
                    <div className="mt-[175px] md:mt-36">
                      <div>
                        <div className="flex items-center justify-between mb-2">
                          <label className="inline-block mb-2 text-sm font-semibold font-quicksand text-clinder">
                            Choose Customer Level
                          </label>
                          <button
                            type="button"
                            className="flex px-6 py-3 border-[1px] border-blue-400 hover:bg-forested-mind rounded-lg cursor-pointer gap-3"
                            onClick={() => setIsNewCustomerLevelModalOpen(true)}
                          >
                            <img
                              src={plusGradient}
                              alt="plus"
                              className="hidden md:block"
                            />
                            <span className="text-xs font-bold font-quicksand text-aurora-blue">
                              Add New Level
                            </span>
                          </button>
                        </div>
                        <CustomerLevelOptionsDropdown
                          label={'Choose level for customer'}
                          selectedValue={customerLevelName}
                          setSelectedValue={setCustomerLevelName}
                          setSelectedId={setCustomerLevelId}
                          options={customerLevels}
                          optionHeight={'h-[190px]'}
                        />
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="customer-id"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Customer ID
                        </label>
                        <div className="relative mt-2 h-14">
                          <input
                            type="text"
                            id="customer-id"
                            value={generatedCustomerId}
                            placeholder="Create Customer ID"
                            required
                            className="absolute w-full py-2 pl-4  pr-[130px] h-14 focusable-input"
                            onChange={(e) =>
                              setGeneratedCustomerId(e.target.value)
                            }
                          />
                          <button
                            className="absolute flex-none px-3 py-1 text-xs font-semibold bg-white rounded shadow-soft top-4 right-4 button-generate"
                            type="button"
                            onClick={() =>
                              setGeneratedCustomerId(
                                generateRandomAlphanumeric(8)
                              )
                            }
                          >
                            <span className="font-quicksand text-aurora-blue">
                              Auto Generate
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="username"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          id="username"
                          value={username}
                          placeholder="Create customer username"
                          required
                          className="w-full px-4 mt-2 text-sm font-semibold rounded-lg h-14 placeholder:font-medium focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none bg-neutral-gray"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="email"
                          className="text-sm font-semibold font-quicksand text-clinder"
                        >
                          Email
                        </label>
                        <div className="flex items-center gap-4 px-4 mt-2 rounded-lg h-14 bg-neutral-gray">
                          <input
                            type="text"
                            id="email"
                            value={shortenEmail}
                            placeholder="Enter Customer Email"
                            className="flex-1 py-2 text-sm font-semibold focus:font-medium font-quicksand placeholder:text-gray-x text-clinder focus:outline-none bg-neutral-gray placeholder:font-medium"
                            onChange={(e) => setShortenEmail(e.target.value)}
                          />
                          <p className="px-3 py-1 text-xs font-semibold bg-white rounded shadow-soft">
                            <span className="font-quicksand text-aurora-blue">
                              @gmail.com
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="flex items-center justify-center w-full gap-3 py-4 rounded-lg bg-aurora-blue px-9 mt-9"
                      onClick={(e) => {
                        handleEditCustomerCredentialData(e);
                        handleEditCustomerLevel(e);
                      }}
                    >
                      <img src={archiveTick} alt="save" />
                      <span className="text-base font-bold text-white font-quicksand">
                        Save Changes
                      </span>
                    </button>
                  </div>
                )}

                {/* Personal Form */}
                {activeSideNav === 'Personal' && (
                  <div className="flex flex-col justify-between h-full pb-20 md:pb-9 lg:pb-9">
                    <div>
                      <div className="mt-[175px] md:mt-36">
                        <div>
                          <label
                            htmlFor="full-name"
                            className="text-sm font-semibold font-quicksand text-clinder"
                          >
                            Full Name
                          </label>
                          <input
                            type="text"
                            value={fullName}
                            id="full-name"
                            placeholder="Enter Customer Full Name"
                            required
                            className="w-full px-4 mt-2 h-14 focusable-input"
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="customer-gender"
                            className="text-sm font-semibold font-quicksand text-clinder"
                          >
                            Choose Customer Gender{' '}
                            <span className="text-manatee">(optional)</span>
                          </label>
                          <div className="flex gap-2 mt-2">
                            <button
                              type="button"
                              className={`flex-1 flex gap-4 px-4 py-3 transition-all duration-500 text-start rounded-lg group ${
                                gender === 'men'
                                  ? 'bg-aurora-blue'
                                  : 'bg-neutral-gray hover:bg-[#0079a1]'
                              }`}
                              onClick={() => setGender('men')}
                            >
                              <div
                                className={`${
                                  gender === 'men'
                                    ? 'bg-white/20'
                                    : 'bg-transparent border-[0.5px] group-hover:border-0 border-gray-x group-hover:bg-white/20'
                                } shadow-gentle rounded-full flex p-1 w-6 h-6`}
                              >
                                <div
                                  className={`${
                                    gender === 'men'
                                      ? 'bg-white'
                                      : 'bg-transparent  group-hover:bg-white'
                                  }  rounded-full w-4 h-4`}
                                />
                              </div>
                              <span
                                className={`font-quicksand text-base font-bold ${
                                  gender === 'men'
                                    ? 'text-white font-bold'
                                    : 'text-manatee font-semibold group-hover:text-white group-hover:font-bold'
                                }`}
                              >
                                Men
                              </span>
                            </button>
                            <button
                              type="button"
                              className={`flex-1 flex px-4 gap-4 py-3 rounded-lg transition-all duration-500 text-start group ${
                                gender === 'women'
                                  ? 'bg-aurora-blue'
                                  : 'bg-neutral-gray hover:bg-[#0079a1]'
                              }`}
                              onClick={() => setGender('women')}
                            >
                              <div
                                className={`${
                                  gender === 'women'
                                    ? 'bg-white/20'
                                    : 'bg-transparent border-[0.5px] group-hover:border-0 border-gray-x group-hover:bg-white/20'
                                } shadow-gentle rounded-full flex p-1 w-6 h-6`}
                              >
                                <div
                                  className={`${
                                    gender === 'women'
                                      ? 'bg-white'
                                      : 'bg-transparent group-hover:bg-white'
                                  }  rounded-full w-4 h-4`}
                                />
                              </div>
                              <span
                                className={`font-quicksand text-base font-bold ${
                                  gender === 'women'
                                    ? 'text-white font-bold'
                                    : 'text-manatee group-hover:text-white group-hover:font-bold font-semibold'
                                }`}
                              >
                                Women
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="birth-date"
                            className="text-sm font-semibold font-quicksand"
                          >
                            Birth Date
                          </label>
                          <div className="flex flex-col gap-3 mt-2 sm:flex-row">
                            <div className="flex w-full gap-3 sm:w-2/3">
                              <SingleIndicatorDropdown
                                label={'Date'}
                                options={daysOptions}
                                radius={'rounded-[4px]'}
                                selected={birthDate}
                                setSelected={setBirthDate}
                                optionsWidth={'w-[100px]'}
                                optionHeight={'h-[150px]'}
                              />
                              <MonthOptionDropdown
                                label={'Month'}
                                options={monthOptions}
                                radius={'rounded-[4px]'}
                                selectedTextValue={birthMonthTextValue}
                                setSelectedTextValue={setBirthMonthTextValue}
                                selectedNumberValue={birthMonthNumberValue}
                                setSelectedNumberValue={
                                  setBirthMonthNumberValue
                                }
                                optionHeight={'h-[150px]'}
                              />
                            </div>
                            <div className="w-full sm:w-1/3">
                              <SingleIndicatorDropdown
                                label={'Year'}
                                options={yearOptions}
                                radius={'rounded-[4px]'}
                                selected={birthYear}
                                setSelected={setBirthYear}
                                optionHeight={'h-[150px]'}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-4 h-[100px]">
                          <label
                            htmlFor="phone-number"
                            className="text-sm font-semibold font-quicksand text-clinder"
                          >
                            Phone Number
                          </label>
                          <div className="relative items-center mt-2">
                            <p className="absolute z-10 px-3 py-3 text-xs font-semibold bg-white rounded top-3 left-4 shadow-soft">
                              <span className="font-quicksand text-aurora-blue">
                                +62
                              </span>
                            </p>
                            <input
                              type="text"
                              id="phone-number"
                              value={phoneNumber}
                              maxLength={11}
                              placeholder="Enter Customer Phone Number"
                              required
                              className="w-full absolute text-sm h-[66px] focusable-input pl-[74px]"
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="address"
                            className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
                          >
                            Address{' '}
                            <span className="text-manatee">(optional)</span>
                          </label>
                          <textarea
                            value={address}
                            placeholder="Enter Customer Address"
                            name="address"
                            id="address"
                            className="w-full h-40 px-4 py-3 resize-none focusable-input"
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="flex items-center justify-center w-full gap-3 py-4 rounded-lg bg-aurora-blue px-9 mt-9"
                      onClick={handleEditCustomerPersonalData}
                    >
                      <img src={archiveTick} alt="save" />
                      <span className="text-base font-bold text-white font-quicksand">
                        Save Changes
                      </span>
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}

      {/* New level modal to create new customer level */}
      <NewLevelModal
        isModalOpen={isNewCustomerLevelModalOpen}
        setIsModalOpen={setIsNewCustomerLevelModalOpen}
        isLevelUpdate={isCustomerLevelUpdate}
        setIsLevelUpdate={setIsCustomerLevelUpdate}
      />
    </>
  );
};

export default EditCustomerModal;
