import React from "react";
import qrisIcon from "../../../assets/icons/qris-icon.svg";
import cashIcon from "../../../assets/icons/moneys.svg";
import cardIcon from "../../../assets/icons/cards.svg";

const PaymentMethodBadge = ({ paymentMethod }) => {
    return (
        <>
            {paymentMethod.toLowerCase() === "qris" ? (
                <div className="w-fit h-fit">
                    <img width={48} height={48} src={qrisIcon} alt="qris-icon" />
                </div>
            ) : paymentMethod.toLowerCase() === "cash" ? (
                <div className="w-fit h-fit flex gap-2 items-center">
                    <img width={28} height={28} src={cashIcon} alt="cash-icon" />

                    <span className="text-base font-medium font-quicksand text-[#110D17]">
                        cash
                    </span>
                </div>
            ) : paymentMethod.toLowerCase() === "debit" ? (
                <div className="w-fit h-fit flex gap-2 items-center">
                    <img width={28} height={28} src={cardIcon} alt="debit-icon" />

                    <span className="text-base font-medium font-quicksand text-[#110D17]">
                        debit
                    </span>
                </div>
            ) : null}
        </>
    );
};

export default PaymentMethodBadge;
