import React from 'react';
import searchBlue from '../../../assets/icons/searchBlue.svg';
import plus from '../../../assets/icons/plus.svg';

const MobileCategoryTable = ({ categoryData, setIsNewCategoryModalOpen }) => {
  return (
    <div className="block md:hidden">
      <div className="flex gap-4 px-6 py-2 bg-white border-b-[1px] border-gray-x sticky top-[164px]">
        <img src={searchBlue} alt="search" />
        <input
          type="text"
          placeholder="Find your categories here"
          className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
        />
        <button
          type="button"
          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
          onClick={() => setIsNewCategoryModalOpen(true)}
        >
          <img src={plus} alt="add" className="mx-auto" />
        </button>
      </div>
      {categoryData.map((stock, index) => {
        let locationBgColor;
        let locationTextColor;
        if (stock.location === 'kitchen') {
          locationTextColor = 'text-purpletty';
          locationBgColor = 'bg-coral';
        } else if (stock.location === 'bar') {
          locationTextColor = 'text-greentty';
          locationBgColor = 'bg-pastel-green';
        } else {
          locationTextColor = 'text-orangetty';
          locationBgColor = 'bg-linen';
        }

        return (
          <div key={index} className="w-full">
            <div
              className={`flex items-center justify-between w-full py-4 pl-6 pr-3 bg-neutral-gray border-gray-x ${
                index === 0 ? 'border-b-[1px]' : 'border-y-[1px]'
              }`}
            >
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder ">
                {stock.categoryName}
              </p>
              <button type="button" className="px-3">
                <div className="w-[4px] h-[4px] bg-black rounded-full" />
                <div className="w-[4px] h-[4px] bg-black rounded-full my-1" />
                <div className="w-[4px] h-[4px] bg-black rounded-full" />
              </button>
            </div>
            <div className="flex justify-between px-6 py-4">
              <div className="">
                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                  Product
                </p>
                <p className="mt-4 text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                  Location
                </p>
              </div>
              <div>
                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                  {stock.product} Menu
                </p>
                <p
                  className={`mt-4 text-center px-4 rounded ${locationBgColor}`}
                >
                  <span
                    className={`text-sm font-semibold leading-[150%] font-quicksand ${locationTextColor}`}
                  >
                    {stock.location}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileCategoryTable;
