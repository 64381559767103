import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({
  activeSideNav,
  activeTopNav,
  setActiveTopNav,
  menuDescription,
  topNavs,
}) => {
  return (
    <header className="pb-4 pt-6 h-auto hidden md:flex flex-col md:flex-row items-end justify-between xl:h-28 border-b-[1px] border-gray-lighter sticky top-0 z-50 bg-white">
      <div className="flex flex-col justify-center w-full h-full md:w-auto">
        <h2 className="mt-0 md:mt-1 font-quicksand text-2xl text-black leading-[36px] font-bold">
          {activeSideNav}
        </h2>
        <p className="mt-0 text-sm md:mt-1 font-satoshi text-manatee">
          {menuDescription}
        </p>
      </div>
      <div className="xl:grid grid-flow-col gap-2 justify-stretch min-w-[608px] hidden -mb-4">
        {topNavs.map((nav, index) => (
          <Link
            key={index}
            to={`/${nav.route}`}
            className={`px-6 py-3 text-sm font-semibold font-quicksand text-manatee leading-5 duration-300 transform hover:border-b-2 hover:border-blue-500 hover:text-blue-600 text-center ${
              activeTopNav === nav.value
                ? 'border-b-2 border-blue-500 text-aurora-blue'
                : 'text-gray-light'
            }`}
            onClick={() => setActiveTopNav(nav.value)}
          >
            {nav.nav}
          </Link>
        ))}
      </div>
    </header>
  );
};

export default Header;
