import React from "react";
import FilledSuccessButton from "../molecules/button/FilledSuccessButton";
import { useNavigate } from "react-router-dom";

const NeedLoginAlert = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    const goToLoginPage = () => {
        navigate("/login");
    };

    return (
        <div className={`fixed inset-0 flex items-start justify-center mt-40 z-50`}>
            <div className="fixed inset-0 bg-black backdrop-blur-[2px] bg-opacity-50"></div>

            <div className="w-[624px] bg-white border-none rounded-xl shadow-lg z-50 overflow-y-auto">
                <div className="pt-10 pb-8 px-8 flex flex-col items-center">
                    <div className="w-full h-full flex items-center mb-3 text-yellow-500">
                        <p className="text-lg text-slate-700 font-bold capitalize">
                            {token && tenantId ? "Session Expired" : "Login Required"}
                        </p>
                    </div>

                    <div className="w-full h-auto py-4 px-4 border-t-1 border-b-1">
                        <span className="text-slate-600 text-sm">
                            {token && tenantId
                                ? "Your session has expired. Please log in again to continue."
                                : "Please log in to access this feature."}
                        </span>
                    </div>

                    <div className="w-full h-auto mt-3 px-4 flex gap-2 justify-end">
                        <FilledSuccessButton
                            typeOfBtn={"button"}
                            label={"Login"}
                            actionHandler={goToLoginPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NeedLoginAlert;
