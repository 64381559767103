import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineShop } from 'react-icons/ai';

import axiosInstance from '../../../utils/api/apiConfig';

import SuccessNotification from '../../SuccessNotification';

import arrowRight from '../../../assets/icons/arrow-right-white.svg';
import close from '../../../assets/icons/close.svg';
import userAdd from '../../../assets/icons/userAdd.svg';
import menuPhotoIcon from '../../../assets/icons/menuPhoto.svg';
import arrowLeftBlue from '../../../assets/icons/arrowLeftBlue.svg';
import whiteBettyLogo from '../../../assets/icons/whiteBettyLogo.svg';

const NewBranchModal = ({ isModalOpen, setIsModalOpen }) => {
  // ===  Branch State ===
  const [branchName, setBranchName] = useState('');
  const [address, setAddress] = useState('');
  const [branchIcon, setBranchIcon] = useState(null);
  const [branchIconPreview, setBranchIconPreview] = useState(null);
  const [branchBackgroundImage, setBranchBackgroundImage] = useState(null);
  const [branchBackgroundImagePreview, setBranchBackgroundImagePreview] =
    useState(null);

  // === Component State ===
  const [activeTab, setActiveTab] = useState('form-input');
  const branchIconRef = useRef(null);
  const branchBackgroundImageRef = useRef(null);
  const [isShowSuccessNotification, setIsShowSuccessNotification] =
    useState(false);
  const [countDown, setCountDown] = useState(5);

  // Hanlde close modal by timer
  useEffect(() => {
    if (isShowSuccessNotification) {
      const timer = setInterval(() => {
        setCountDown((pervCountDowon) => pervCountDowon - 1);
      }, 1000);
      if (countDown === 0) {
        clearInterval(timer);
        window.scrollTo(0, 0);
        setIsModalOpen(false);
        setActiveTab('form-input');
        setCountDown(5);
        setIsShowSuccessNotification(false);
      }

      return () => {
        clearInterval(timer);
      };
    }
  }, [countDown, isShowSuccessNotification]);

  // handle photo input
  const handleFileChange = (e, setImage, setImagePreview) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewImage = e.target.result;
        setImagePreview(previewImage);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  // Handle drag and drop image input
  const handleFileDrop = (e, setImage, setImagePreview) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewImage = e.target.result;
        setImagePreview(previewImage);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  // Handle active button
  const isDisableButton = () => {
    if (
      branchName === '' ||
      address === '' ||
      branchIcon === null ||
      branchBackgroundImage === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleResetForm = () => {
    setBranchName('');
    setAddress('');
    setBranchIcon(null);
    setBranchIconPreview(null);
    setBranchBackgroundImage(null);
    setBranchBackgroundImagePreview(null);
  };

  // Handle Submit Branch
  const handleSubmitBranch = () => {
    // console.log({ branchName, address, branchIcon, branchBackgroundImage });

    handleResetForm();

    setIsShowSuccessNotification(true);
  };

  return (
    <>
      <div
        className={`fixed inset-0 top-0 z-50 right-0 bg-clinder/60 flex justify-end ${
          !isModalOpen && 'hidden'
        } `}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
          activeTab === 'preview' && setCountDown(5);
          setIsShowSuccessNotification(false);
          setActiveTab('form-input');
          handleResetForm();
        }}
      />
      <div
        className={`fixed top-0 right-0 z-50 px-6 pb-6 md:px-9 md:pb-9 bg-white rounded-none w-full md:w-[600px] duration-300 transition-all overflow-y-auto flex flex-col h-screen ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 md:px-0 md:pt-9 pb-6 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              Add New Branch
            </h2>
            <button
              onClick={() => {
                setIsModalOpen(false);
                window.scrollTo(0, 0);
                activeTab === 'preview' && setCountDown(5);
                setIsShowSuccessNotification(false);
                setActiveTab('form-input');
                handleResetForm();
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this section, you can manage easily set up information for your
            new restaurant branch and keep everything organized.
          </p>
        </div>
        {/* Form Input */}
        {activeTab === 'form-input' && (
          <form className="flex flex-col justify-between flex-1 pt-6">
            <div>
              <div className="mb-6">
                <label
                  htmlFor="branch-nama"
                  className="text-sm font-semibold font-quicksand text-clinder"
                >
                  Branch Name
                </label>{' '}
                <br />
                <input
                  type="text"
                  id="branch-nama"
                  value={branchName}
                  required
                  placeholder="Create branch name"
                  className="w-full px-4 py-3 mt-2 focusable-input"
                  onChange={(e) => setBranchName(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="address"
                  className="text-sm font-semibold font-quicksand text-clinder"
                >
                  Address
                </label>{' '}
                <br />
                <input
                  type="text"
                  id="address"
                  value={address}
                  required
                  placeholder="Branch address"
                  className="w-full px-4 py-3 mt-2 focusable-input"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="branch-icon"
                  className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                >
                  Logo / Icon
                  <div
                    className="relative inset-0 mt-2"
                    onDrop={(e) =>
                      handleFileDrop(e, setBranchIcon, setBranchIconPreview)
                    }
                    onDragOver={preventDefault}
                  >
                    <input
                      type="file"
                      required
                      id="branch-icon"
                      accept="image/*"
                      className="absolute cursor-pointer -z-10"
                      onChange={(e) =>
                        handleFileChange(e, setBranchIcon, setBranchIconPreview)
                      }
                      ref={branchIconRef}
                    />
                    <div className="bg-neutral-gray py-9  border-dashed border-2 border-[#080808]/10 rounded-lg">
                      <img
                        src={
                          branchIconPreview ? branchIconPreview : menuPhotoIcon
                        }
                        alt="menu"
                        className={`mx-auto rounded object-cover ${
                          branchIconPreview
                            ? 'h-[100px] w-[100px]'
                            : 'h-12 w-12'
                        }`}
                      />
                      <button
                        type="button"
                        className="flex items-center justify-center gap-3 px-6 py-3 mx-auto mt-6 bg-white border rounded-lg border-manatee"
                        onClick={() => branchIconRef.current.click()}
                      >
                        <img src={userAdd} alt="user add" />
                        <span className="text-xs font-bold text-aurora-blue font-quicksand">
                          Choose File
                        </span>
                        <img src={arrowRight} alt="arrow" />
                      </button>
                      <p className="mt-2 text-xs font-medium text-center text-manatee font-quicksand">
                        {branchIcon ? (
                          <span>
                            Selected File:{' '}
                            <span className="text-aurora-blue">
                              {branchIcon.name}
                            </span>
                          </span>
                        ) : (
                          'or Drag them here!'
                        )}
                      </p>
                    </div>
                  </div>
                </label>{' '}
              </div>
              <div className="mb-[18px]">
                <label
                  htmlFor="branch-background"
                  className="text-sm font-semibold cursor-pointer font-quicksand text-clinder"
                >
                  Background / Branch Photos
                  <div
                    className="relative inset-0 mt-2"
                    onDrop={(e) =>
                      handleFileDrop(
                        e,
                        setBranchBackgroundImage,
                        setBranchBackgroundImagePreview
                      )
                    }
                    onDragOver={preventDefault}
                  >
                    <input
                      type="file"
                      required
                      id="branch-background"
                      accept="image/*"
                      className="absolute cursor-pointer -z-10"
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setBranchBackgroundImage,
                          setBranchBackgroundImagePreview
                        )
                      }
                      ref={branchBackgroundImageRef}
                    />
                    <div className="bg-neutral-gray py-9  border-dashed border-2 border-[#080808]/10 rounded-lg">
                      <img
                        src={
                          branchBackgroundImagePreview
                            ? branchBackgroundImagePreview
                            : menuPhotoIcon
                        }
                        alt="menu"
                        className={`mx-auto rounded object-cover ${
                          branchBackgroundImagePreview
                            ? 'h-[100px] w-[100px]'
                            : 'h-12 w-12'
                        }`}
                      />
                      <button
                        type="button"
                        className="flex items-center justify-center gap-3 px-6 py-3 mx-auto mt-6 bg-white border rounded-lg border-manatee"
                        onClick={() => branchBackgroundImageRef.current.click()}
                      >
                        <img src={userAdd} alt="user add" />
                        <span className="text-xs font-bold text-aurora-blue font-quicksand">
                          Choose File
                        </span>
                        <img src={arrowRight} alt="arrow" />
                      </button>
                      <p className="mt-2 text-xs font-medium text-center text-manatee font-quicksand">
                        {branchBackgroundImage ? (
                          <span>
                            Selected File:{' '}
                            <span className="text-aurora-blue">
                              {branchBackgroundImage.name}
                            </span>
                          </span>
                        ) : (
                          'or Drag them here!'
                        )}
                      </p>
                    </div>
                  </div>
                </label>{' '}
              </div>
            </div>
            <button
              disabled={isDisableButton()}
              type="button"
              className={`w-full py-4 mb-10 xl:mb-0 mt-9 px-9 ${
                isDisableButton()
                  ? 'button-primary-lg-disable'
                  : 'button-primary-lg'
              }`}
              onClick={() => setActiveTab('preview')}
            >
              <span
                className={`ml-3 font-bold font-quicksand ${
                  isDisableButton() ? 'text-manatee' : 'text-white'
                }`}
              >
                {' '}
                Preview{' '}
              </span>
              <img src={arrowRight} alt="arrow right" />
            </button>
          </form>
        )}

        {/* Preview */}
        {activeTab === 'preview' && (
          <div
            className={`flex flex-col flex-1 pt-6 ${
              isShowSuccessNotification ? 'justify-center' : 'justify-between'
            }`}
          >
            {isShowSuccessNotification ? (
              <SuccessNotification
                title={'New branch has been added'}
                message={''}
                notificationCountdown={5}
                path={'/admin-settings-branch'}
              />
            ) : (
              <>
                <div className="bg-neutral-gray p-14 rounded-2xl">
                  <div className="h-[480px] relative">
                    <img
                      src={branchBackgroundImagePreview}
                      className={`h-[480px] w-full absolute top-0 left-0 object-cover rounded-lg bg-${branchBackgroundImagePreview}`}
                    />
                    <div className="absolute top-0 left-0 flex flex-col justify-between w-full h-full p-8">
                      <div>
                        <h4 className="w-full text-5xl font-bold text-center text-white font-quicksand">
                          10:10
                        </h4>
                        <div className="flex justify-between px-3 py-2 mt-4 rounded-lg item-center bg-black/20 blur-10">
                          <div>
                            <h4 className="text-2xl font-bold text-white capitalize font-quicksand">
                              {branchName}
                            </h4>
                            <h6 className="font-normal font-quicksand text-neutral-gray text-[10px]">
                              {address}
                            </h6>
                          </div>
                          <img
                            src={branchIconPreview}
                            className="object-cover w-12 h-12 rounded-full"
                            alt="branch icon"
                          />
                        </div>
                        <div className="flex items-center justify-center gap-2 p-2 mt-3 rounded-lg bg-black/20 blur-10">
                          <AiOutlineShop className="text-white" />
                          <span className="font-quicksand font-medium text-white text-[10px]">
                            Switch Branch
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="flex p-2 rounded bg-neutral-gray">
                          <button
                            disabled
                            type="button"
                            className="px-2 py-1 font-bold bg-white rounded text-aurora-blue font-quicksand shadow-sm text-[10px]"
                          >
                            EN
                          </button>
                          <button
                            disabled
                            type="button"
                            className="px-2 text-[10px] py-1 font-bold text-manatee font-quicksand"
                          >
                            ID
                          </button>
                        </div>
                        <div className="flex items-center gap-1">
                          <img src={whiteBettyLogo} alt="betty" />
                          <span className="font-bold text-white font-quicksand">
                            betty
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3 mt-6">
                  <button
                    className="flex-none button-secondary-lg"
                    onClick={() => {
                      setActiveTab('form-input');
                    }}
                  >
                    <img src={arrowLeftBlue} alt="back" />
                    <span className="font-bold text-aurora-blue font-quicksand">
                      Back
                    </span>
                  </button>
                  <button
                    type="button"
                    className="flex-1 button-primary-lg"
                    onClick={handleSubmitBranch}
                  >
                    <span className="font-bold text-white font-quicksand">
                      Save
                    </span>
                    <img src={arrowRight} alt="next" />
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NewBranchModal;
