import React, { useState } from 'react';
import axiosInstance from '../../../utils/api/apiConfig';

import CorlorOptionDropdown from '../../CorlorOptionDropdown';

import plus from '../../../assets/icons/plus.svg';
import close from '../../../assets/icons/close.svg';

const NewLevelModal = ({
  isModalOpen,
  setIsModalOpen,
  isLevelUpdate,
  setIsLevelUpdate,
}) => {
  // === Product Type Data ===
  const [levelName, setLevelName] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [fontColor, setFontColor] = useState('');

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  // Handle Submit Area of Category Data
  const handleSubmintProductTypeData = async () => {
    const bodyRequest = {
      customerLevelName: levelName,
      colorFont: fontColor,
      colorBackground: backgroundColor,
    };

    try {
      const response = await axiosInstance.post(
        `/customer-levels`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLevelUpdate(!isLevelUpdate);
      setLevelName('');
      setBackgroundColor('');
      setFontColor('');
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div
        className={`fixed inset-0 top-0 z-50 right-0 bg-clinder/60 flex justify-end ${
          !isModalOpen && 'hidden'
        } `}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`fixed top-0 right-0 z-50 px-6 pb-6 md:px-9 md:pb-9 bg-white rounded-none w-[600px] duration-300 transition-all overflow-y-auto flex flex-col h-screen ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 md:px-0 md:pt-9 pb-12 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex items-center justify-between gap-6 md:flex-row md:justify-between">
            <div className="flex items-center gap-4">
              <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
                Add New Level
              </h2>
            </div>
            <button
              onClick={() => {
                setIsModalOpen(false);
                window.scrollTo(0, 0);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this window you can manage to add new level to list.
          </p>
        </div>
        <form className="flex flex-col justify-between flex-1">
          <div>
            <div className="mt-6">
              <label
                htmlFor="type-nama"
                className="text-sm font-semibold font-quicksand text-clinder"
              >
                Level Name
              </label>{' '}
              <br />
              <input
                type="text"
                id="type-nama"
                required
                value={levelName}
                placeholder="Level Name"
                className="w-full px-4 py-3 mt-2 focusable-input"
                onChange={(e) => setLevelName(e.target.value)}
              />
            </div>
            <div className="mt-6">
              <label
                htmlFor="area-nama"
                className="block mb-2 text-sm font-semibold font-quicksand text-clinder"
              >
                Color
              </label>{' '}
              <CorlorOptionDropdown
                backgroundColor={backgroundColor}
                setBackgroundColor={setBackgroundColor}
                fontColor={fontColor}
                setFontColor={setFontColor}
                label={'Level name for customer'}
                displayedValue={levelName}
                optionHeight={'h-[170px]'}
              />
            </div>
          </div>
          <button
            type="button"
            className="w-full py-4 mb-10 xl:mb-0 mt-9 px-9 button-primary-lg"
            onClick={() => {
              setIsModalOpen(false);
              handleSubmintProductTypeData();
            }}
          >
            <img src={plus} alt="plus icon" />
            <span className="ml-3 font-bold text-white font-quicksand">
              {' '}
              Add New Level
            </span>
          </button>
        </form>
      </div>
    </>
  );
};

export default NewLevelModal;
