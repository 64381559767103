import React, { useState } from "react";

import DoubleIndicatorDropdown from "../../DoubleIndicatorDropdown";

import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import DeleteWithPasswordConfirmationModal from "../../DeleteWithPasswordConfirmationModal";
import ConfirmationPasswordModal from "../../ConfirmationPasswordModal";
import SmallSuccessNotification from "../../SmallSuccessNotification";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const TabletDesktopBranchTable = ({
    branchData,
    onDeleteBranch,
    setBranchId,
    setIsDetailBranchModalOpen,
}) => {
    const [branchIdToDelete, setBranchIdToDelete] = useState(null);
    const [isConfirmationPasswordModalOpen, setIsConfirmationPasswordModalOpen] = useState(false);
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [isDeleteSuccessNotificationOpen, setIsDeleteSuccessNotificationOpen] = useState(false);

    const handleDeleteBranch = (id) => {
        // console.log(`Branch with id ${id} was deleted`);
        setIsDeleteSuccessNotificationOpen(true);
        setIsDeleteConfirmationModalOpen(false);
        setIsConfirmationPasswordModalOpen(false);
    };

    return (
        <div className="hidden mt-6 md:block">
            <table className="w-full border-collapse table-auto">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th id="branch-name" className="w-1/3">
                            <DoubleIndicatorDropdown options={[{ label: "Branch Name" }]} />
                        </th>
                        <th id="address" className="w-1/3">
                            <DoubleIndicatorDropdown options={[{ label: "Address" }]} />
                        </th>
                        <th
                            id="action"
                            className="text-start p-[10px] text-sm font-medium hover:bg-gray-50 text-manatee font-quicksand w-1/3"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {branchData?.map((branch, index) => (
                        <tr key={index}>
                            <td
                                aria-labelledby="branch-name"
                                className="text-sm font-semibold leading-5 font-quicksand px-[10px] py-5 capitalize flex items-center justify-between"
                            >
                                <h4>{branch?.name}</h4>
                                {branch?.isOpen && (
                                    <span className="px-2 py-1 rounded text-lime-dark bg-ivory-cream">
                                        Current
                                    </span>
                                )}
                            </td>
                            <td
                                aria-labelledby="address"
                                className="text-sm font-semibold leading-5 font-quicksand px-[10px] py-5"
                            >
                                {branch?.address}
                            </td>
                            <td
                                aria-labelledby="action"
                                className="flex items-center justify-center py-5 gap-x-1"
                            >
                                <button
                                    type="button"
                                    className="block w-full px-3 mx-auto xl:hidden"
                                >
                                    <div className="w-1 h-1 mx-auto my-1 bg-black rounded-full" />
                                    <div className="w-1 h-1 mx-auto my-1 bg-black rounded-full" />
                                    <div className="w-1 h-1 mx-auto my-1 bg-black rounded-full" />
                                </button>
                                <button
                                    type="button"
                                    className="items-center justify-center hidden w-full gap-2 px-3 py-1 duration-300 transform rounded-md xl:flex bg-neutral-gray hover:bg-gray-200"
                                    onClick={() => {
                                        setBranchId(branch?.id);
                                        setIsDetailBranchModalOpen(true);
                                    }}
                                >
                                    <img src={shieldSearch} alt="detail" />
                                    <span className="text-sm font-semibold font-quicksand">
                                        Detail
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="items-center justify-center hidden w-full gap-2 px-3 py-1 duration-300 transform rounded-md xl:flex bg-neutral-gray hover:bg-gray-200"
                                    onClick={() => {
                                        setBranchIdToDelete(branch?.id);
                                        setIsDeleteConfirmationModalOpen(true);
                                    }}
                                >
                                    <img src={trashRed} alt="delete" />
                                    <span className="text-sm font-semibold font-quicksand text-scarlet-red">
                                        Delete
                                    </span>
                                </button>
                            </td>
                        </tr>
                    ))} */}
                </tbody>
            </table>

            <UnderConstructionBanner />

            {/* Delete Confirmation Modal */}
            <DeleteWithPasswordConfirmationModal
                isModalOpen={isDeleteConfirmationModalOpen}
                setIsModalOpen={setIsDeleteConfirmationModalOpen}
                setIsConfirmationPasswordModalOpen={setIsConfirmationPasswordModalOpen}
                shortDeleteConfirmatonMessage={"Delete this branch?"}
                longDeleteConfirmatonMessage={"Aryou sure you want to delete this branch?"}
                longDeleteButtonLabel={"Yes, Delete this branch"}
            />

            {/* Confirmation password modal */}
            <ConfirmationPasswordModal
                isModalOpen={isConfirmationPasswordModalOpen}
                setIsModalOpen={setIsConfirmationPasswordModalOpen}
                setIsConfirmationPasswordModalOpen={setIsConfirmationPasswordModalOpen}
                onDeleteBranch={handleDeleteBranch}
                id={branchIdToDelete}
            />

            {/* Delete Success Notification  */}
            <SmallSuccessNotification
                notificationCountDown={2}
                isModalOpen={isDeleteSuccessNotificationOpen}
                setIsModalOpen={setIsDeleteSuccessNotificationOpen}
                notificationMessage={"Branch has been deleted"}
            />
        </div>
    );
};

export default TabletDesktopBranchTable;
