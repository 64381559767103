import React, { useEffect, useState } from 'react';
import { IoCheckmark } from 'react-icons/io5';

import axiosInstance from '../../../utils/api/apiConfig';

import InfoBox from '../../InfoBox';
import ProductOptionsDropdownWithImage from './ProductOptionsDropdownWithImage';

import close from '../../../assets/icons/close.svg';
import restock from '../../../assets/icons/restock.svg';

const FilterBaseProductModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsOptionFilterActive,
  isOptionFilterToggelActive,
  setIsOptionFilterToggleActive,
  filterNeedRestock,
  setFilterNeedRestock,
  selectedMenuName,
  setSelectedMenuName,
  selectedMenuId,
  setSelectedMenuId,
  baseProducts,
  setFilteredBaseProductsByOptionFilter,
}) => {
  const [menus, setMenus] = useState([]);

  const handleFilterBaseProducts = (
    baseProducts,
    filterNeedRestock,
    selectedMenuId
  ) => {
    const filteredBaseProducts = baseProducts.filter((baseProduct) => {
      if (
        filterNeedRestock !== null &&
        baseProduct?.status !== filterNeedRestock
      ) {
        return false;
      }
      if (
        selectedMenuId !== null &&
        baseProduct?.menuList?.every(
          (item) => item?.productId !== selectedMenuId
        )
      ) {
        return false;
      }
      return true;
    });
    if (filterNeedRestock !== null || selectedMenuId !== null) {
      setIsOptionFilterActive(true);
    }
    setFilteredBaseProductsByOptionFilter(filteredBaseProducts);
    setIsOptionFilterToggleActive(!isOptionFilterToggelActive);
  };

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');

  useEffect(() => {
    const getAllMenus = async () => {
      try {
        const response = await axiosInstance.get('/products', {
          headers: {
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        });
        setMenus(response?.data?.data);
      } catch (error) {
        // console.log(error);
      }
    };

    getAllMenus();
  }, []);

  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              Filter by
            </h2>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setIsModalOpen(false);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[90%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this window you can add filters for what data you want to display
            in the table.
          </p>
        </div>
        <div className="flex flex-col justify-between flex-1 pt-6">
          <div>
            {/* Filter by Status */}
            <div className="mb-5">
              <p className="text-sm font-semibold font-quicksand text-clinder">
                Status
              </p>{' '}
              <div className="flex flex-wrap gap-4 mt-4">
                <button
                  className={`text-sm rounded flex gap-4 py-[10px] px-5 font-quicksand items-center ${
                    filterNeedRestock === 'Need Restock'
                      ? 'border border-tiffany-blue bg-forested-mind'
                      : 'bg-white'
                  }`}
                  onClick={() => {
                    filterNeedRestock === 'Need Restock'
                      ? setFilterNeedRestock(null)
                      : setFilterNeedRestock('Need Restock');
                  }}
                >
                  <img src={restock} alt="restock" />
                  <span
                    className={`${
                      filterNeedRestock === 'Need Restock'
                        ? 'text-aurora-blue font-semibold'
                        : 'text-clinder font-medium'
                    }`}
                  >
                    Need Restock
                  </span>
                  <IoCheckmark
                    className={`text-blue-800 font-bold ${
                      filterNeedRestock === 'Need Restock' ? 'block' : 'hidden'
                    }`}
                  />
                </button>
              </div>
            </div>
            {/* Filter By Menu */}
            <div>
              <div className="flex items-center gap-2 mb-4">
                <label className="text-sm font-semibold font-quicksand text-clinder">
                  Product / Menu
                </label>
                <InfoBox
                  title={'Filter by Menu'}
                  content={
                    'Choose your menu, and it will display the base products needed in the table list.'
                  }
                />
              </div>
              <ProductOptionsDropdownWithImage
                label={'Menu'}
                options={menus}
                selectedValue={selectedMenuName}
                setSelectedValue={setSelectedMenuName}
                setSelectedId={setSelectedMenuId}
              />
            </div>
          </div>
          <div className="flex items-center gap-4 font-bold mt-9 font-quicksand ">
            <button
              type="button"
              className="w-full text-aurora-blue"
              onClick={() => {
                setFilterNeedRestock(null);
                setSelectedMenuName('');
                setSelectedMenuId(null);
                setFilteredBaseProductsByOptionFilter([]);
                setIsOptionFilterActive(false);
                setIsOptionFilterToggleActive(!isOptionFilterToggelActive);
              }}
            >
              Clear
            </button>
            <button
              type="button"
              className="w-full text-white button-primary-lg xl:mb-0"
              onClick={() => {
                handleFilterBaseProducts(
                  baseProducts,
                  filterNeedRestock,
                  selectedMenuId
                );
                setIsModalOpen(false);
              }}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterBaseProductModal;
