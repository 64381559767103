import React, { useState } from 'react';
import axiosInstance from '../../../utils/api/apiConfig';

import InfoBox from '../../InfoBox';
import UniySystemsOption from './UniySystemsOption';

import close from '../../../assets/icons/close.svg';
import plus from '../../../assets/icons/plus.svg';

const NewBaseModal = ({
  isModalOpen,
  setIsModalOpen,
  onGetAllBaseProducts,
}) => {
  // === Category Data ===
  const [baseProductName, setBaseProductName] = useState('');
  const [unitSystem, setUnitSystem] = useState('');
  const unitSystemsOption = ['kilogram', 'gram', 'pieces'];
  const [requiredStock, setRequiredStock] = useState('');
  const [thousandFormattedRequiredStock, setThousandFormattedRequiredStock] =
    useState('');
  const [recentStock, setRecentStock] = useState('');
  const [thousandFormattedRecentStock, setThousandFormattedRecentStock] =
    useState('');
  const [productBasePrice, setProductBasePrice] = useState('');
  const [formattedProductBasePrice, setFormattedProductBasePrice] =
    useState('');

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const userId = localStorage.getItem('userId');

  // Handle Submit base product Data
  const handleSubmitBaseProductData = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      name: baseProductName,
      unitSystem: unitSystem,
      requiredStock: parseFloat(requiredStock),
      recentStock: parseFloat(recentStock),
      price: parseFloat(productBasePrice),
    };

    try {
      const response = await axiosInstance.post(`/base-products`, bodyRequest, {
        headers: {
          'Content-Type': 'application/json',
          'X-TENANT-ID': tenantId,
          Authorization: `Bearer ${token}`,
        },
      });
      setBaseProductName('');
      setUnitSystem('Pick the International Unit System for Base Product');
      setRequiredStock('');
      setThousandFormattedRequiredStock('');
      setRecentStock('');
      setThousandFormattedRecentStock('');
      setProductBasePrice('');
      setFormattedProductBasePrice('');
      onGetAllBaseProducts();
      setIsModalOpen(false);
      window.scrollTo(0, 0);
    } catch (error) {
      // console.log(error);
    }
  };

  // Format base product price value with commas (2000 --> 2.000)
  const formatNumberWithCommas = (inputValue) => {
    if (!inputValue) {
      return '';
    }

    const numericValue = parseFloat(inputValue.replace(/[^\d]/g, ''));
    const formattedValue = numericValue.toLocaleString('id-ID'); // Ganti 'id-ID' dengan kode lokal yang sesuai
    return formattedValue;
  };

  // Handle input base product price to  handle original value and thousand formatted value
  const handleInputBaseProductPrice = (e) => {
    const inputValue = e.target.value;
    setFormattedProductBasePrice(formatNumberWithCommas(inputValue));
    setProductBasePrice(inputValue.replace(/[^\d]/g, ''));
  };

  // Handle input recent stock on inventory to  handle original value and thousand formatted value
  const handleInputRequiredStock = (e) => {
    const inputValue = e.target.value;
    setThousandFormattedRequiredStock(formatNumberWithCommas(inputValue));
    setRequiredStock(inputValue.replace(/[^\d]/g, ''));
  };

  // Handle input recent stock on inventory to  handle original value and thousand formatted value
  const handleInputRecentStock = (e) => {
    const inputValue = e.target.value;
    setThousandFormattedRecentStock(formatNumberWithCommas(inputValue));
    setRecentStock(inputValue.replace(/[^\d]/g, ''));
  };

  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          window.scrollTo(0, 0);
          setIsModalOpen(false);
        }}
      />
      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              Add New Base
            </h2>
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                setIsModalOpen(false);
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[90%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this window you can add raw materials in your warehouse. Raw
            goods mean materials that do not require prior processing.
          </p>
        </div>
        <form className="flex flex-col justify-between flex-1 pt-6">
          <div>
            <div className="mb-6">
              <label
                htmlFor="baseproduct-name"
                className="text-sm font-semibold font-quicksand text-clinder"
              >
                Base Product Name
              </label>{' '}
              <br />
              <input
                value={baseProductName}
                type="text"
                id="baseproduct-name"
                required
                placeholder="Base product name"
                className="w-full px-4 py-3 mt-2 focusable-input"
                onChange={(e) => setBaseProductName(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <div className="flex mb-2">
                <label
                  htmlFor="international-unit"
                  className="text-sm font-semibold font-quicksand text-clinder mr-[10px] to3"
                >
                  International Unit System
                </label>{' '}
                <InfoBox
                  title={'International Unit System'}
                  content={'The unit used for the base product'}
                  customLeftPosition={'-left-44 xs:-left-40 sm:left-6'}
                  customTopPosition={'top-3 sm:-top-2'}
                />
              </div>
              <UniySystemsOption
                label={'Pick the International Unit System for Base Product'}
                selected={unitSystem}
                setSelected={setUnitSystem}
                options={unitSystemsOption}
              />
            </div>
            <div className="mb-6 h-[85px]">
              <div className="flex">
                <label
                  htmlFor="required-stock"
                  className="text-sm font-semibold font-quicksand text-clinder mr-[10px]"
                >
                  Required Stock on Inventory
                </label>{' '}
                <InfoBox
                  title={'Maximum Stock on Inventory'}
                  content={'................................................'}
                  customLeftPosition={'-left-52 xs:-left-44 sm:left-6'}
                  customTopPosition={'top-3 sm:-top-2'}
                />
              </div>
              <div className="relative mt-2">
                <input
                  value={thousandFormattedRequiredStock}
                  type="text"
                  id="required-stock"
                  required
                  placeholder="Enter Required Stock on Inventory"
                  className="w-full py-[15px] focusable-input absolute pl-4 pr-[72px]"
                  onChange={handleInputRequiredStock}
                />
                <span className="absolute top-3 right-4 text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none">
                  {unitSystem === 'kilogram'
                    ? 'Kg'
                    : unitSystem === 'gram'
                    ? 'g'
                    : 'Pcs'}
                </span>
              </div>
            </div>
            <div className="mb-6 h-[85px]">
              <div className="flex">
                <label
                  htmlFor="recent-stock"
                  className="text-sm font-semibold font-quicksand text-clinder mr-[10px]"
                >
                  Recent Stock on Inventory
                </label>{' '}
                <InfoBox
                  title={'Recent Stock on Inventory'}
                  content={'................................................'}
                  customLeftPosition={'-left-44 sm:left-4'}
                  customTopPosition={'top-3 sm:-top-2'}
                />
              </div>
              <div className="relative mt-2">
                <input
                  value={thousandFormattedRecentStock}
                  type="text"
                  id="recent-stock"
                  required
                  placeholder="Enter Recent Stock on Inventory"
                  className="w-full py-[15px] focusable-input absolute pr-[72px] pl-4"
                  onChange={handleInputRecentStock}
                />
                <span className="text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none absolute top-3 right-4">
                  {unitSystem === 'kilogram'
                    ? 'Kg'
                    : unitSystem === 'gram'
                    ? 'g'
                    : 'Pcs'}
                </span>
              </div>
            </div>
            <div className="mb-6 h-[85px]">
              <div className="flex">
                <label
                  htmlFor="product-base-price"
                  className="text-sm font-semibold font-quicksand text-clinder mr-[10px]"
                >
                  Product Base Price
                </label>{' '}
                <InfoBox
                  title={'Product Base Price'}
                  content={'................................................'}
                  customLeftPosition={'-left-32 sm:left-6'}
                  customTopPosition={'top-3 sm:-top-2'}
                />
              </div>
              <div className="relative mt-2">
                <input
                  value={formattedProductBasePrice}
                  type="text"
                  id="product-base-price"
                  required
                  placeholder="Enter Product Base Price"
                  className="w-full py-[15px] focusable-input absolute pr-[94px] pl-4"
                  onChange={handleInputBaseProductPrice}
                />
                <span className="text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none absolute top-3 right-4">
                  {unitSystem === 'kilogram'
                    ? 'Per Kg'
                    : unitSystem === 'gram'
                    ? 'Per Gram'
                    : 'Per Pcs'}
                </span>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full mb-10 mt-9 button-primary-lg xl:mb-0"
            onClick={handleSubmitBaseProductData}
          >
            <img src={plus} alt="plus icon" />
            <span className="font-bold text-white font-quicksand">
              {' '}
              Add New Base{' '}
            </span>
          </button>
        </form>
      </div>
    </>
  );
};

export default NewBaseModal;
