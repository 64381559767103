import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import gojekLogo from '../../../assets/icons/gojekLogo.svg';
import grabLogo from '../../../assets/icons/grabLogo.svg';

const AppPriceDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('gojek');

  return (
    <div className="relative">
      <button
        type="button"
        className="flex items-center justify-between w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        <figure className="min-w-[76px] w-full">
          <img
            src={selected === 'gojek' ? gojekLogo : grabLogo}
            alt={selected === 'gojek' ? 'gojek' : 'grab'}
          />
        </figure>
        <div className="text-manatee">
          <HiChevronUp className="-mb-2" aria-hidden="true" />
          <HiChevronDown aria-hidden="true" />
        </div>
      </button>
      <div
        className={`absolute z-10 mt-1 bg-white shadow-lg rounded-md w-full ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <ul>
          <li>
            <button
              type="button"
              className={`${
                selected === 'gojek' && 'bg-gray-200'
              } w-full p-2 hover:bg-gray-100`}
              onClick={() => {
                setSelected('gojek');
                setIsOpen(false);
              }}
            >
              <img src={gojekLogo} alt="gojek" />
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`${
                selected === 'grab' && 'bg-gray-200'
              } w-full p-2 hover:bg-gray-100`}
              onClick={() => {
                setSelected('grab');
                setIsOpen(false);
              }}
            >
              <img src={grabLogo} alt="grab" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppPriceDropdown;
