import React from 'react';

import searchBlue from '../../../assets/icons/searchBlue.svg';
import plus from '../../../assets/icons/plus.svg';
import LevelOptionDropdown from './LevelOptionDropdown';
import AppPriceDropdown from './AppPriceDropdown';

const MobilePriceTable = ({ priceData, setIsNewPriceModalOpen }) => {
  return (
    <div className="block md:hidden">
      <div className="flex justify-between gap-4 px-6 py-2 bg-white border-b-[1px] border-gray-x sticky top-[164px] z-20">
        <div className="flex w-full gap-4">
          <img src={searchBlue} alt="search" />
          <input
            type="text"
            placeholder="Find your menu here"
            className="flex-1 overflow-hidden text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
          />
        </div>
        <button
          type="button"
          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
          onClick={() => setIsNewPriceModalOpen(true)}
        >
          <img src={plus} alt="add" className="mx-auto" />
        </button>
      </div>
      {priceData.map((product, index) => (
        <div key={index} className="w-full">
          <div
            className={`flex items-center justify-between w-full py-4 pl-6 pr-3 bg-neutral-gray border-gray-x ${
              index === 0 ? 'border-b-[1px]' : 'border-y-[1px]'
            }`}
          >
            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder capitalize">
              {product.menuName}
            </p>
            <button type="button" className="px-3">
              <div className="w-[4px] h-[4px] bg-black rounded-full" />
              <div className="w-[4px] h-[4px] bg-black rounded-full my-1" />
              <div className="w-[4px] h-[4px] bg-black rounded-full" />
            </button>
          </div>
          <div className="px-6 py-4">
            <div className="flex items-center justify-between">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Base Product
              </p>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {product.baseProduct} Items
              </p>
            </div>
            <div className="flex items-center justify-between mt-4">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Price with Tax
              </p>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {product.priceWithTax
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="min-w-[89px]">
                <LevelOptionDropdown />
              </div>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {product.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div className="px-2 py-1 rounded-[4px] bg-white border-[0.5px] border-gray-x/40">
                <AppPriceDropdown />
              </div>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {product.appPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobilePriceTable;
