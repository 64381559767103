import React, { useEffect, useState } from 'react';

import { ImSpinner8 } from 'react-icons/im';
import { AiOutlineClose } from 'react-icons/ai';
import axiosInstance from '../../../utils/api/apiConfig';
import formatNumberWithCommas from '../../../utils/formatNumber';
import useGetDetailTransaction from '../../../hooks/report/transaction/useDetDetailTransaction';
import formatDefaultDate from '../../../utils/dateFormate';
import printerBlue from '../../../assets/icons/printerBlue.svg';
import documentDownload from '../../../assets/icons/document-download.svg';

const DetailTransactionModal = ({
	isModalOpen,
	setIsModalOpen,
	isTransactionId,
	customerName
}) => {
	// === Data on Local Storage ===
	const token = localStorage.getItem('token');
	const tenantId = localStorage.getItem('tenantId');
	const userId = localStorage.getItem('userId');

	/* HOOKS */
	const { loading, detailTransactionData } = useGetDetailTransaction(
		token,
		tenantId,
		isTransactionId
	);

	return (
		<>
			<div
				className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
					!isModalOpen && 'hidden'
				}`}
				onClick={() => {
					setIsModalOpen(false);
				}}
			/>

			<div
				className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
					isModalOpen ? 'translate-x-0' : 'translate-x-full'
				}`}
				onClick={e => e.stopPropagation()}>
				{loading ? (
					<div className='flex justify-center items-center h-full'>
						<ImSpinner8
							className='animate-spin text-aurora-blue text-manatee'
							size={32}
						/>
					</div>
				) : (
					<>
						<div className='px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30'>
							<div className='flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between'>
								<h2 className='text-xl font-semibold capitalize md:text-2xl md:font-bold text-aurora-blue font-quicksand'>
									Order ID #{isTransactionId}
								</h2>
								<div className='flex items-start justify-end gap-4'>
									<div className='text-end hidden md:block'>
										<h3 className='font-quicksand text-sm text-manatee font-semibold'>
											{' '}
											{formatDefaultDate(detailTransactionData?.createdAt)}
										</h3>
										<h4 className='font-quicksand text-sm text-manatee font-semibold'>
											{detailTransactionData?.invoiceNumber}
										</h4>
									</div>
									<button
										onClick={() => {
											setIsModalOpen(false);
										}}>
										<AiOutlineClose size={22} className='text-manatee' />
									</button>
								</div>
							</div>
						</div>
						<div className='flex gap-3 items-center border-b-[1px] border-gray-x/40 py-4'>
							<figure className='flex-none overflow-hidden rounded-full w-9 h-9'>
								<img
									src={`https://avatar.iran.liara.run/public/boy?username=${detailTransactionData?.accountData?.owner}`}
									alt='profile'
									className='rounded-full w-9 h-9'
								/>
							</figure>
							<div>
								<h4 className='text-sm font-bold text-clinder'>
									{detailTransactionData?.accountData?.owner}
								</h4>
								<span className='text-xs text-manatee font-satoshi'>
									Owner | {detailTransactionData?.accountData?.phone}
								</span>
							</div>
						</div>
						<div className='py-4 border-b-[1px] border-gray-x/40'>
							<p className='font-quicksand text-sm text-manatee font-semibold mb-4'>
								Detail Transaction
							</p>
							<div className='flex gap-2 flex-col'>
								<div className='flex justify-between font-quicksand font-semibold text-sm'>
									<p>Payment Method</p>
									<p>{detailTransactionData?.paymentMethod}</p>
								</div>
								<div className='flex justify-between items-center font-quicksand font-semibold text-sm'>
									<p>Status</p>
									<p className='text-lime-green bg-creamy-white p-[10px]'>
										Success
									</p>
								</div>
								<div className='flex justify-between font-quicksand font-semibold text-sm'>
									<p>Customer</p>
									<p>{customerName}</p>
								</div>
							</div>
						</div>
						<div className='py-4 border-b-[1px] border-gray-x/40'>
							<p className='font-quicksand text-sm text-manatee font-semibold mb-4'>
								Order
							</p>
							<div className='flex gap-3 flex-col'>
								{/* Refund */}
								{/* <div className='font-quicksand font-semibold text-sm'>
										<div className='flex justify-between text-[#8F0A13]'>
											<p>Nasi Goreng Mentega Saus Asam Manis</p>
											<div className='flex gap-4 items-center justify-between'>
												<p>Refund</p>
												<div className='min-w-[105px] flex flex-row justify-between'>
													<p className='text-manatee'>x1</p>
													<p>IDR 20.000</p>
												</div>
											</div>
										</div>
									</div> */}
								{detailTransactionData?.menuCart?.map((product, index) => {
									// sum quantity of the product
									const totalQtyPerProduct = product.menuItem.reduce(
										(total, item) => total + item.itemqty,
										0
									);
									return (
										<React.Fragment key={index}>
											<div className='font-quicksand font-semibold text-sm'>
												<div className='flex justify-between '>
													<p>{product.name}</p>
													<div className='flex items-center justify-between min-w-[105px]'>
														<p className='text-manatee'>
															x{totalQtyPerProduct}
														</p>
														<p>
															IDR{' '}
															{formatNumberWithCommas(
																parseFloat(product?.price).toLocaleString()
															)}
														</p>
													</div>
												</div>
												{/* Sub Item */}
												{product.menuItem?.length > 0 &&
													product.menuItem?.map((menuItem, index) => {
														return (
															<React.Fragment key={index}>
																{menuItem.varian?.length > 0 && (
																	<div
																		key={index}
																		className='h-full px-1 mt-1 flex gap-2 items-center'>
																		<span className='text-xs font-semibold font-quicksand text-manatee'>
																			x{menuItem.itemqty}
																		</span>

																		<div className='py-1 pl-3 pr-1 border-l-[1px] border-gray-x mt-1'>
																			{menuItem.varian.map((variant, index) => {
																				return (
																					<p
																						key={index}
																						className='text-xs font-normal font-satoshi text-clinder leading-[200%]'>
																						{variant.varianName} :{' '}
																						{variant.Toppings?.length > 0 &&
																							variant.Toppings.map(
																								(topping, index) => {
																									return (
																										<span
																											key={index}
																											className='font-medium capitalize'>
																											{topping.toppingName}
																											{variant.Toppings.length -
																												1 !==
																												index && ', '}
																										</span>
																									);
																								}
																							)}
																					</p>
																				);
																			})}
																		</div>
																	</div>
																)}
															</React.Fragment>
														);
													})}
											</div>
										</React.Fragment>
									);
								})}
							</div>
						</div>
						<div className='py-4 border-b-[1px] border-gray-x/40'>
							<p className='font-quicksand text-sm text-manatee font-semibold mb-4'>
								Payment Details
							</p>
							{/* <div className='flex gap-3 flex-col'>
								<div className='flex justify-between font-quicksand font-semibold text-sm'>
									<p>Harga</p>
									<p className='text-[#F17300]'>
										IDR{' '}
										{formatNumberWithCommas(
											parseFloat(
												detailTransactionData?.subtotal
											).toLocaleString()
										)}
									</p>
								</div>
								<div className='flex justify-between font-quicksand font-semibold text-sm'>
									<p>Refund</p>
									<p className='text-[#8F0A13]'>
										- IDR {formatNumberWithCommas('20000')}
									</p>
								</div>
								<div className='flex justify-between font-quicksand font-semibold text-sm'>
									<p>Biaya Layanan</p>
									<p className='text-[#F17300]'>
										IDR {formatNumberWithCommas('1500')}
									</p>
								</div>
								<div className='flex justify-between font-quicksand font-semibold text-sm'>
									<p>Diskon</p>
									<p className='text-[#F17300]'>
										- IDR {formatNumberWithCommas('100000')}
									</p>
								</div>
							</div> */}
							<div className='flex justify-between font-quicksand font-semibold text-sm'>
								<p className='font-extrabold'>Total</p>
								<p className='text-[#F17300]'>
									IDR{' '}
									{formatNumberWithCommas(
										parseFloat(detailTransactionData?.subtotal).toLocaleString()
									)}
								</p>
							</div>
						</div>
						<div className='flex gap-[10px] bg-white mt-9 mb-6 lg:mb-9'>
							<button
								type='button'
								className='flex flex-1 border-[1px] border-grad-bluetty rounded-lg py-4 justify-center items-center gap-3 w-[259px]'>
								<img src={printerBlue} alt='print' />
								<span className='hidden text-base font-bold sm:inline text-aurora-blue font-quicksand'>
									Print Receipt
								</span>
								<span className='inline text-base font-bold sm:hidden text-aurora-blue font-quicksand'>
									Print
								</span>
							</button>
							<button
								type='button'
								className='flex items-center justify-center flex-1 gap-3 py-4 rounded-lg bg-aurora-blue w-[259px]'>
								<img src={documentDownload} alt='download' />
								<span className='hidden text-base font-bold text-white sm:inline font-quicksand'>
									Download Receipt
								</span>
								<span className='inline text-base font-bold text-white sm:hidden font-quicksand'>
									Download
								</span>
							</button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default DetailTransactionModal;
