import { useEffect, useState } from "react";
import axiosInstance from "../../utils/api/apiConfig";

const useGetDetailCustomerTransaction = (token, tenantId, customerId, transactionId) => {
    const [loading, setLoading] = useState(true);
    const [detailCustomerTransactionData, setDetailCustomerTransactionData] = useState();

    useEffect(() => {
        const getCustomerDetailTransaction = async () => {
            try {
                const response = await axiosInstance.get(
                    `/transactions/by-customer/analytic/${customerId}/transaction/${transactionId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-TENANT-ID": tenantId,
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setDetailCustomerTransactionData(response.data.data);
            } catch (error) {
                // console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getCustomerDetailTransaction();
    }, [token, tenantId, customerId, transactionId]);

    return { loading, detailCustomerTransactionData };
};

export default useGetDetailCustomerTransaction;
