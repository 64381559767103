import React from "react";

// import SingleIndicatorDropdown from "../../SingleIndicatorDropdown";

import plus from "../../../assets/icons/plus.svg";
import searchBlue from "../../../assets/icons/searchBlue.svg";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const MobileEmployeeTable = ({ employeeData, setIsNewAdministratorOpen }) => {
    return (
        <div className="block md:hidden">
            <div className="flex gap-4 px-6 py-2 sticky top-[164px] bg-white border-b-[1px] border-gray-x z-40">
                <img src={searchBlue} alt="search" />
                <input
                    disabled // disabled due to unready feature
                    type="text"
                    placeholder="Search Role"
                    className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
                />
                <button
                    disabled // disabled due to unready feature
                    type="button"
                    className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
                    onClick={() => setIsNewAdministratorOpen(true)}
                >
                    <img src={plus} alt="add" className="mx-auto" />
                </button>
            </div>
            {/* {employeeData.map((employee, index) => {
                let roleTextColor = "";
                let roleBackground = "";

                if (employee.role === "food quality") {
                    roleTextColor = "text-[#9100ff]";
                    roleBackground = "bg-[#FEF5FF]";
                } else if (employee.role === "food tester") {
                    roleTextColor = "text-[#27c984]";
                    roleBackground = "bg-[#F6FEF9]";
                } else if (employee.role === "cashier") {
                    roleTextColor = "text-[#ef9f48]";
                    roleBackground = "bg-[#FEFBF6]";
                } else {
                    roleTextColor = "text-[#3fa0ef]";
                    roleBackground = "bg-[#F6FCFE]";
                }

                return (
                    <div key={index} className="w-full">
                        <div
                            className={`flex items-center justify-between w-full py-4 px-6 bg-neutral-gray ${
                                index === 0 ? "border-b-[1px]" : "border-y-[1px]"
                            }`}
                        >
                            <div className="flex items-center gap-4">
                                <figure className="overflow-hidden rounded-full w-9 h-9">
                                    <img src={employee.profilePicture} alt={employee.name} />
                                </figure>
                                <div className="text-sm font-semibold leading-[150%] font-quicksand text-clinder capitalize">
                                    {employee.name}
                                </div>
                            </div>
                            <button type="button" className="px-3">
                                <div className="w-[4px] h-[4px] bg-black rounded-full" />
                                <div className="w-[4px] h-[4px] bg-black rounded-full my-1" />
                                <div className="w-[4px] h-[4px] bg-black rounded-full" />
                            </button>
                        </div>
                        <div className="flex items-center justify-between px-6 mt-4">
                            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                ID
                            </p>
                            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                                {employee.noHP}
                            </p>
                        </div>
                        <div className="flex items-center justify-between px-6 mt-4">
                            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                Role
                            </p>
                            <p
                                className={`py-2 px-4 text-sm font-semibold text-center capitalize ${roleBackground} ${roleTextColor} rounded font-quicksand`}
                            >
                                {employee.role}
                            </p>
                        </div>
                        <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                            <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                Status
                            </p>
                            <div
                                className={`px-3 py-[10px] flex justify-between items-center gap-[10px] rounded ${
                                    employee.status === "Active" ? "bg-ivory-cream" : "bg-coral"
                                }`}
                            >
                                <span
                                    className={`text-sm font-semibold font-quicksand text-lime-green capitalize ${
                                        employee.status === "Active"
                                            ? "text-lime-green"
                                            : "text-scarlet-red"
                                    }`}
                                >
                                    {employee.status}
                                </span>
                                <SingleIndicatorDropdown
                                    options={["Active", "Inactive"]}
                                    showResultOption={false}
                                    height={"h-auto"}
                                    width={"w-auto"}
                                    iconSize={"w-5 h-5"}
                                    radius={"rounded"}
                                    optionsWidth={"w-[100px]"}
                                    padding={"px-0"}
                                />
                            </div>
                        </div>
                    </div>
                );
            })} */}

            <UnderConstructionBanner />
        </div>
    );
};

export default MobileEmployeeTable;
