import React from 'react';

import { AiOutlineClose } from 'react-icons/ai';

const DetailCategory = ({ isModalOpen, setIsModalOpen, category }) => {
  //   handle area of category color
  let locationBgColor;
  let locationTextColor;
  if (category?.areaOfCategoryName === 'kitchen') {
    locationTextColor = 'text-purpletty';
    locationBgColor = 'bg-[#FEF5FF]';
  } else if (category?.areaOfCategoryName === 'bar') {
    locationTextColor = 'text-greentty';
    locationBgColor = 'bg-pastel-green';
  } else {
    locationTextColor = 'text-orangetty';
    locationBgColor = 'bg-linen';
  }

  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          setIsModalOpen(false);
        }}
      />

      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold capitalize md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              {category?.name}
            </h2>
            <button
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              <AiOutlineClose size={22} />
            </button>
          </div>
          <p className="w-[80%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            {category.description}
          </p>
        </div>
        <div className="flex items-center justify-between mt-6">
          <h3 className="text-sm font-quicksand font-semibold text-clinder leading-[150%]">
            Total {category.totalProduct} Menus
          </h3>
          <div className={`px-4 py-[10px] rounded ${locationBgColor}`}>
            <span
              className={`leading-5 text-sm font-semibold font-quicksand ${locationTextColor}`}
            >
              {category?.areaOfCategoryName}
            </span>
          </div>
        </div>
        <div className="flex flex-wrap gap-6 mt-9">
          {category?.products?.map((product, index) => (
            <figure key={index} className="w-[155px] h-[198px]">
              <img
                src={product?.image}
                alt={product?.image}
                className="w-full h-[100px] rounded object-cover"
              />
              <h3 className="font-quicksand capitalize text-sm font-semibold leading-[150%]">
                {product?.name}
              </h3>
              <div className="h-[75px] overflow-hidden">
                <p className="text-manatee leading-[150%] text-xs font-medium font-quicksand line-clamp-3">
                  {product?.description}
                </p>
              </div>
            </figure>
          ))}
        </div>
      </div>
    </>
  );
};

export default DetailCategory;
