import React from 'react';
import { Link } from 'react-router-dom';

const TopNavbar = ({ topNavs, activeTopNav, setActiveTopNav }) => {
  return (
    <div className="bg-white xl:hidden flex justify-between w-full gap-2 px-6 py-4 md:py-0 md:px-0 overflow-x-auto border-y-[1px] border-gray-x md:border-none md:pt-6 sticky top-[86px] md:top-72 z-20">
      {topNavs.map((nav, index) => (
        <Link
          key={index}
          to={`/${nav.route}`}
          className={`px-6 py-3 text-sm font-semibold font-quicksand text-manatee leading-5 duration-300 transform md:hover:border-b-2 md:hover:border-blue-500 hover:text-blue-600 text-center ${
            topNavs.length > 3 ? 'flex-none ' : 'flex-1'
          } ${
            activeTopNav === nav.value
              ? 'md:border-b-2 md:border-blue-500 text-aurora-blue'
              : 'text-gray-light'
          }`}
          onClick={() => {
            setActiveTopNav(nav.value);
            window.scrollTo(0, 0);
          }}
        >
          {nav.nav}
        </Link>
      ))}
    </div>
  );
};

export default TopNavbar;
