import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdChairAlt } from "react-icons/md";

import bettyLogo from "../assets/icons/bettyLogo.svg";
import dashboardIcon from "../assets/icons/dashboardIcon.svg";
import stockIcon from "../assets/icons/stockIcon.svg";
import stockIconColorful from "../assets/icons/stockIconColorful.svg";
import customerColorless from "../assets/icons/customerColorless.svg";
import customerColorful from "../assets/icons/customerColorful.svg";
import restaurantIcon from "../assets/icons/restaurantIcon.svg";
import restaurantIconColorful from "../assets/icons/restaurantIconColorful.svg";
import roleAdminIcon from "../assets/icons/roleAdminIcon.svg";
import roleAdminIconColorful from "../assets/icons/roleAdminIconColorful.svg";
import settingsIcon from "../assets/icons/settingsIcon.svg";
import settingIconColorful from "../assets/icons/settingColorful.svg";
import settingIconColorless from "../assets/icons/settingColorless.svg";
import stockIntegrationIcon from "../assets/icons/stockIntegrationIcon.svg";
import logoutIcon from "../assets/icons/logoutIcon.svg";

const SideBar = ({
    isDashboard,
    isStock,
    isCustomer,
    isRestaurant,
    isTable,
    isReport,
    isRoleAndAdmin,
    isSettings,
    isIntegrationStock,
    isSupply,
}) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const navigate = useNavigate();
    const userFullName = localStorage.getItem("userFullName");
    const userRole = localStorage.getItem("userRole");

    // Handling Active Side Navigation
    let activeSideNav = "";
    if (isDashboard) {
        activeSideNav = "Dashboard";
    } else if (isStock) {
        activeSideNav = "Stock";
    } else if (isCustomer) {
        activeSideNav = "Customer";
    } else if (isRestaurant) {
        activeSideNav = "Restaurant";
    } else if (isTable) {
        activeSideNav = "Table";
    } else if (isReport) {
        activeSideNav = "Report";
    } else if (isRoleAndAdmin) {
        activeSideNav = "Role & Admin";
    } else if (isSettings) {
        activeSideNav = "Settings";
    } else if (isIntegrationStock) {
        activeSideNav = "Stock";
    } else {
        activeSideNav = "Supply";
    }

    // handle logout
    const handleLogout = async () => {
        localStorage.removeItem("token");
        navigate("/");
    };

    return (
        <>
            {/* Tablet and Desktop View */}
            <div className="h-screen overflow-y-hidden hover:overflow-y-auto 2xl:w-[200px]  border-r-[1px] border-gray-x/40 min-h-screen md:flex flex-col justify-between fixed top-0 w-[88px]  hidden ">
                <div className="ml-7">
                    {/* Name and Logo App */}
                    <div className="sticky top-0 flex items-center justify-between py-6 mr-1 bg-white">
                        <div className="flex items-center">
                            <img src={bettyLogo} alt="betty logo" />
                            <h1 className="hidden 2xl:block ml-1 text-[28px] font-bold text-aurora-blue font-quicksand">
                                betty
                            </h1>
                        </div>
                    </div>
                    {/* side nav */}
                    <nav className="mt-14 2xl:mt-0">
                        {/* menu */}
                        <ul>
                            <li>
                                <h2 className="font-satoshi text-[10px] text-manatee hidden 2xl:block">
                                    {" "}
                                    Menu
                                </h2>
                            </li>
                            <li className="mt-4">
                                <Link
                                    to={"/"}
                                    className="flex items-center justify-start gap-0 2xl:gap-3 group"
                                >
                                    <img
                                        src={dashboardIcon}
                                        alt="dashboard"
                                        className="w-[14px] h-[14px]"
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isDashboard ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Dashboard
                                    </span>
                                    {/* <span className="font-quicksand block text-[10px] font-semibold text-white  bg-gradient-to-r from-[#EEA849] to-[#F46B45] w-[17px] h-[17px] text-center leading-[17px] rounded-full -ml-2 2xl:ml-0 -mt-4 2xl:mt-0">
                                        4
                                    </span> */}
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    className="flex items-center justify-start gap-3 group"
                                    to={"/admin-stock-category"}
                                >
                                    <img
                                        src={stockIcon}
                                        alt="stock"
                                        className={`${
                                            isStock ? "hidden" : "block"
                                        } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                    />
                                    <img
                                        src={stockIconColorful}
                                        alt="stock"
                                        className={`${
                                            isStock ? "block" : "hidden"
                                        } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isStock ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Product
                                    </span>
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    to={"/admin-customer"}
                                    className="flex items-center justify-start gap-3 group"
                                >
                                    <img
                                        src={customerColorless}
                                        alt="customer"
                                        className={`${
                                            isCustomer ? "hidden" : "block"
                                        } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                    />
                                    <img
                                        src={customerColorful}
                                        alt="customer"
                                        className={`${
                                            isCustomer ? "block" : "hidden"
                                        } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isCustomer ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Customer
                                    </span>
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    to={"/admin-restaurant-employee"}
                                    className="flex items-center justify-start gap-3 group"
                                >
                                    <img
                                        src={restaurantIcon}
                                        alt="restaurant icon"
                                        className={`${
                                            isRestaurant ? "hidden" : "block"
                                        } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                    />
                                    <img
                                        src={restaurantIconColorful}
                                        alt="restaurant icon"
                                        className={`${
                                            isRestaurant ? "block" : "hidden"
                                        } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isRestaurant ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Restaurant
                                    </span>
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    to={"/admin-table"}
                                    className="flex items-center justify-start gap-3 group"
                                >
                                    <MdChairAlt
                                        className={`${
                                            isTable
                                                ? "text-blue-800"
                                                : "group-hover:text-blue-800 text-manatee"
                                        }`}
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isTable ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Table
                                    </span>
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    to={"/admin-report-transaction"}
                                    className="flex items-center justify-start gap-3 group"
                                >
                                    <svg
                                        className="transition-all duration-300 group-hover:hidden"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.83335 3.5H8.16669C9.33335 3.5 9.33335 2.91667 9.33335 2.33333C9.33335 1.16667 8.75002 1.16667 8.16669 1.16667H5.83335C5.25002 1.16667 4.66669 1.16667 4.66669 2.33333C4.66669 3.5 5.25002 3.5 5.83335 3.5Z"
                                            stroke={`${isReport ? "#5D5FEF" : "#98949E"}`}
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M9.33333 2.345C11.2758 2.45 12.25 3.1675 12.25 5.83333V9.33333C12.25 11.6667 11.6667 12.8333 8.75 12.8333H5.25C2.33333 12.8333 1.75 11.6667 1.75 9.33333V5.83333C1.75 3.17333 2.72417 2.45 4.66667 2.345"
                                            stroke={`${isReport ? "#5D5FEF" : "#98949E"}`}
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <svg
                                        className="hidden transition-all duration-300 group-hover:block"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.83335 3.5H8.16669C9.33335 3.5 9.33335 2.91667 9.33335 2.33333C9.33335 1.16667 8.75002 1.16667 8.16669 1.16667H5.83335C5.25002 1.16667 4.66669 1.16667 4.66669 2.33333C4.66669 3.5 5.25002 3.5 5.83335 3.5Z"
                                            stroke="#5D5FEF"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M9.33333 2.345C11.2758 2.45 12.25 3.1675 12.25 5.83333V9.33333C12.25 11.6667 11.6667 12.8333 8.75 12.8333H5.25C2.33333 12.8333 1.75 11.6667 1.75 9.33333V5.83333C1.75 3.17333 2.72417 2.45 4.66667 2.345"
                                            stroke="#5D5FEF"
                                            strokeMiterlimit="10"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isReport ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Report
                                    </span>
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    to={"/admin-role-and-admin-manage-role"}
                                    className="flex items-center justify-start gap-3 group"
                                >
                                    <img
                                        src={roleAdminIcon}
                                        alt="role & admin"
                                        className={`${
                                            isRoleAndAdmin ? "hidden" : "block"
                                        } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                    />
                                    <img
                                        src={roleAdminIconColorful}
                                        alt="role & admin"
                                        className={`${
                                            isRoleAndAdmin ? "block" : "hidden"
                                        } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isRoleAndAdmin ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Role &amp; Admin
                                    </span>
                                </Link>
                            </li>
                            <li className="mt-7">
                                <Link
                                    to={"/admin-settings-branch"}
                                    className="flex items-center justify-start gap-3 group"
                                >
                                    <img
                                        src={settingIconColorless}
                                        alt="setting"
                                        className={`w-[14px] h-[14px] group-hover:hidden ${
                                            isSettings ? "hidden" : "block"
                                        }`}
                                    />
                                    <img
                                        src={settingIconColorful}
                                        alt="setting"
                                        className={`w-[14px] h-[14px] group-hover:block ${
                                            isSettings ? "block" : "hidden"
                                        }`}
                                    />
                                    <span
                                        className={`w-[95px] hidden 2xl:block text-sm font-semibold transition-all duration-300 font-quicksand ${
                                            isSettings ? "text-aurora-blue" : "text-manatee"
                                        } group-hover:text-blue-500`}
                                    >
                                        Settings & Preferences
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>

                {/* user profile */}
                <div className="2xl:border-t-[1px] 2xl:border-gray-lighter py-6 mt-80 2xl:mt-10">
                    <div className="flex items-center ml-6">
                        <p className="overflow-hidden font-bold leading-9 text-center text-white uppercase rounded-full bg-aurora-blue avatar w-9 h-9 font-quicksand">
                            {userFullName[0]}
                        </p>
                        <div className="hidden 2xl:block">
                            <div className="ml-3">
                                <h3 className="text-sm font-semibold capitalize font-quicksand text-black-lighter">
                                    {userFullName}
                                </h3>
                                <h5 className="text-[10px] font-satoshi font-normal text-manatee">
                                    {userRole}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="flex w-1/2 2xl:w-[90%] px-0 py-2 mx-auto mt-8 duration-300 transform rounded-md hover:bg-gray-200 bg-coral 2xl:px-11 gap-2 justify-center"
                        onClick={handleLogout}
                    >
                        <img src={logoutIcon} alt="logout" className="w-4 h-4" />
                        <span className="hidden text-xs font-semibold 2xl:block font-quicksand text-red-dark">
                            Logout
                        </span>
                    </button>
                </div>
            </div>

            {/* Mobile View */}
            <div className="z-50 block w-full bg-white md:hidden">
                <div className="flex items-center justify-between p-6">
                    <p className="flex-none overflow-hidden font-bold leading-9 text-center text-white uppercase rounded-full bg-aurora-blue avatar w-9 h-9 font-quicksand">
                        {userFullName[0]}
                    </p>
                    <h2 className="text-xl font-semibold font-quicksand">{activeSideNav}</h2>
                    <button type="button" className="relative" onClick={() => setShowSidebar(true)}>
                        <div className="absolute top-0 right-0 z-10 w-3 h-3 rounded-full bg-orangetty" />
                        <svg
                            className="fill-current swap-off"
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 512 512"
                        >
                            <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                        </svg>
                    </button>
                </div>
                {/* bidang slide */}
                <div
                    className={`z-[999] flex flex-col justify-between fixed top-0 right-0 h-full  w-full border-l-[1px] border-gray-lighter/40 bg-white text-gray-800 transition-all duration-300 overflow-auto p-6 ${
                        showSidebar ? "translate-x-0" : "translate-x-full"
                    } xl:translate-x-0 xl:block xl:static`}
                >
                    <div>
                        {/* button close */}
                        <div className="flex">
                            <button
                                onClick={() => setShowSidebar(false)}
                                type="button"
                                className="-mr-2 duration-300 transform xl:hidden btn-sm hover:text-gray-400"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <h3 className="ml-4 text-xl font-semibold font-quicksand">Menu</h3>
                        </div>
                        {/* side nav */}
                        <nav className="mt-6">
                            <ul>
                                <li>
                                    <h2 className="font-satoshi text-[10px] text-manatee font-bold">
                                        {" "}
                                        Main Menu
                                    </h2>
                                </li>
                                <li className="mt-4">
                                    <Link
                                        to={"/"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <img
                                            src={dashboardIcon}
                                            alt="dashboard"
                                            className="w-[14px] h-[14px]"
                                        />
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isDashboard ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Dashboard
                                        </span>
                                        <span className="font-quicksand block text-[10px] font-semibold text-white  bg-gradient-to-r from-[#EEA849] to-[#F46B45] w-[17px] h-[17px] text-center leading-[17px] rounded-full -ml-2 2xl:ml-0">
                                            4
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        className="flex items-center justify-start gap-3 group"
                                        to={"/admin-stock-category"}
                                    >
                                        <img
                                            src={stockIcon}
                                            alt="stock"
                                            className={`${
                                                isStock ? "hidden" : "block"
                                            } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                        />
                                        <img
                                            src={stockIconColorful}
                                            alt="stock"
                                            className={`${
                                                isStock ? "block" : "hidden"
                                            } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                        />
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isStock ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Product
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        to={"/admin-customer"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <img
                                            src={customerColorless}
                                            alt="customer"
                                            className={`${
                                                isCustomer ? "hidden" : "block"
                                            } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                        />
                                        <img
                                            src={customerColorful}
                                            alt="customer"
                                            className={`${
                                                isCustomer ? "block" : "hidden"
                                            } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                        />
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isCustomer ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Customer
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        to={"/admin-restaurant-employee"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <img
                                            src={restaurantIcon}
                                            alt="restaurant icon"
                                            className={`${
                                                isRestaurant ? "hidden" : "block"
                                            } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                        />
                                        <img
                                            src={restaurantIconColorful}
                                            alt="restaurant icon"
                                            className={`${
                                                isRestaurant ? "block" : "hidden"
                                            } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                        />
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isRestaurant ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Restaurant
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        to={"/admin-table"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <MdChairAlt
                                            className={`${
                                                isTable
                                                    ? "text-blue-800"
                                                    : "group-hover:text-blue-800 text-manatee"
                                            }`}
                                        />
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isTable ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Table
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        to={"/admin-report-transaction"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <svg
                                            className="transition-all duration-300 group-hover:hidden"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.83335 3.5H8.16669C9.33335 3.5 9.33335 2.91667 9.33335 2.33333C9.33335 1.16667 8.75002 1.16667 8.16669 1.16667H5.83335C5.25002 1.16667 4.66669 1.16667 4.66669 2.33333C4.66669 3.5 5.25002 3.5 5.83335 3.5Z"
                                                stroke={`${isReport ? "#5D5FEF" : "#98949E"}`}
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M9.33333 2.345C11.2758 2.45 12.25 3.1675 12.25 5.83333V9.33333C12.25 11.6667 11.6667 12.8333 8.75 12.8333H5.25C2.33333 12.8333 1.75 11.6667 1.75 9.33333V5.83333C1.75 3.17333 2.72417 2.45 4.66667 2.345"
                                                stroke={`${isReport ? "#5D5FEF" : "#98949E"}`}
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <svg
                                            className="hidden transition-all duration-300 group-hover:block"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.83335 3.5H8.16669C9.33335 3.5 9.33335 2.91667 9.33335 2.33333C9.33335 1.16667 8.75002 1.16667 8.16669 1.16667H5.83335C5.25002 1.16667 4.66669 1.16667 4.66669 2.33333C4.66669 3.5 5.25002 3.5 5.83335 3.5Z"
                                                stroke="#5D5FEF"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M9.33333 2.345C11.2758 2.45 12.25 3.1675 12.25 5.83333V9.33333C12.25 11.6667 11.6667 12.8333 8.75 12.8333H5.25C2.33333 12.8333 1.75 11.6667 1.75 9.33333V5.83333C1.75 3.17333 2.72417 2.45 4.66667 2.345"
                                                stroke="#5D5FEF"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isReport ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Report
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        to={"/admin-role-and-admin-manage-role"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <img
                                            src={roleAdminIcon}
                                            alt="role & admin"
                                            className={`${
                                                isRoleAndAdmin ? "hidden" : "block"
                                            } transition-all duration-1000 group-hover:hidden w-[14px] h-[14px]`}
                                        />
                                        <img
                                            src={roleAdminIconColorful}
                                            alt="role & admin"
                                            className={`${
                                                isRoleAndAdmin ? "block" : "hidden"
                                            } transition-all duration-1000 group-hover:block w-[14px] h-[14px]`}
                                        />
                                        <span
                                            className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isRoleAndAdmin ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Role &amp; Admin
                                        </span>
                                    </Link>
                                </li>
                                <li className="mt-7">
                                    <Link
                                        to={"/admin-settings-branch"}
                                        className="flex items-center justify-start gap-3 group"
                                    >
                                        <img
                                            src={settingIconColorless}
                                            alt="setting"
                                            className={`w-[14px] h-[14px] group-hover:hidden ${
                                                isSettings ? "hidden" : "block"
                                            }`}
                                        />
                                        <img
                                            src={settingIconColorful}
                                            alt="setting"
                                            className={`w-[14px] h-[14px] group-hover:block ${
                                                isSettings ? "block" : "hidden"
                                            }`}
                                        />
                                        <span
                                            className={` text-sm font-semibold transition-all duration-300 font-quicksand ${
                                                isSettings ? "text-aurora-blue" : "text-manatee"
                                            }`}
                                        >
                                            Settings & Preferences
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                            {/* integration */}
                            {/* <ul>
                <li>
                  <h3 className="font-satoshi text-[10px] text-manatee mt-8 font-bold">
                    Integration
                  </h3>
                </li>
                <li>
                  <Link
                    to={'/admin-integration-stock'}
                    className="flex items-center justify-start gap-3 mt-4 group"
                  >
                    <img src={stockIntegrationIcon} alt="stock" />
                    <span
                      className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand text-clinder`}
                    >
                      Stock
                    </span>
                  </Link>
                </li>
                <li className="mt-7">
                  <Link
                    to={'/admin-supply'}
                    className="flex items-center justify-start gap-3 group"
                  >
                    <svg
                      className="transition-all duration-300 group-hover:hidden"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M8.75002 1.16667V7.00001C8.75002 7.64167 8.22502 8.16667 7.58335 8.16667H1.16669V3.50001C1.16669 2.21084 2.21085 1.16667 3.50002 1.16667H8.75002Z"
                        stroke={`${isSupply ? '#5D5FEF' : '#98949E'}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8334 8.16667V9.91667C12.8334 10.885 12.0517 11.6667 11.0834 11.6667H10.5C10.5 11.025 9.97502 10.5 9.33335 10.5C8.69169 10.5 8.16669 11.025 8.16669 11.6667H5.83335C5.83335 11.025 5.30835 10.5 4.66669 10.5C4.02502 10.5 3.50002 11.025 3.50002 11.6667H2.91669C1.94835 11.6667 1.16669 10.885 1.16669 9.91667V8.16667H7.58335C8.22502 8.16667 8.75002 7.64167 8.75002 7V2.91667H9.82335C10.2434 2.91667 10.6283 3.14418 10.8383 3.50584L11.8359 5.25001H11.0834C10.7625 5.25001 10.5 5.5125 10.5 5.83334V7.58334C10.5 7.90417 10.7625 8.16667 11.0834 8.16667H12.8334Z"
                        stroke={`${isSupply ? '#5D5FEF' : '#98949E'}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.66667 12.8333C5.311 12.8333 5.83333 12.311 5.83333 11.6667C5.83333 11.0223 5.311 10.5 4.66667 10.5C4.02233 10.5 3.5 11.0223 3.5 11.6667C3.5 12.311 4.02233 12.8333 4.66667 12.8333Z"
                        stroke={`${isSupply ? '#5D5FEF' : '#98949E'}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.33335 12.8333C9.97769 12.8333 10.5 12.311 10.5 11.6667C10.5 11.0223 9.97769 10.5 9.33335 10.5C8.68902 10.5 8.16669 11.0223 8.16669 11.6667C8.16669 12.311 8.68902 12.8333 9.33335 12.8333Z"
                        stroke={`${isSupply ? '#5D5FEF' : '#98949E'}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8333 7V8.16667H11.0833C10.7625 8.16667 10.5 7.90417 10.5 7.58333V5.83333C10.5 5.5125 10.7625 5.25 11.0833 5.25H11.8358L12.8333 7Z"
                        stroke={`${isSupply ? '#5D5FEF' : '#98949E'}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <svg
                      className="hidden transition-all duration-300 group-hover:block"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M8.75002 1.16667V7.00001C8.75002 7.64167 8.22502 8.16667 7.58335 8.16667H1.16669V3.50001C1.16669 2.21084 2.21085 1.16667 3.50002 1.16667H8.75002Z"
                        stroke="#5D5FEF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8334 8.16667V9.91667C12.8334 10.885 12.0517 11.6667 11.0834 11.6667H10.5C10.5 11.025 9.97502 10.5 9.33335 10.5C8.69169 10.5 8.16669 11.025 8.16669 11.6667H5.83335C5.83335 11.025 5.30835 10.5 4.66669 10.5C4.02502 10.5 3.50002 11.025 3.50002 11.6667H2.91669C1.94835 11.6667 1.16669 10.885 1.16669 9.91667V8.16667H7.58335C8.22502 8.16667 8.75002 7.64167 8.75002 7V2.91667H9.82335C10.2434 2.91667 10.6283 3.14418 10.8383 3.50584L11.8359 5.25001H11.0834C10.7625 5.25001 10.5 5.5125 10.5 5.83334V7.58334C10.5 7.90417 10.7625 8.16667 11.0834 8.16667H12.8334Z"
                        stroke="#5D5FEF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.66667 12.8333C5.311 12.8333 5.83333 12.311 5.83333 11.6667C5.83333 11.0223 5.311 10.5 4.66667 10.5C4.02233 10.5 3.5 11.0223 3.5 11.6667C3.5 12.311 4.02233 12.8333 4.66667 12.8333Z"
                        stroke="#5D5FEF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.33335 12.8333C9.97769 12.8333 10.5 12.311 10.5 11.6667C10.5 11.0223 9.97769 10.5 9.33335 10.5C8.68902 10.5 8.16669 11.0223 8.16669 11.6667C8.16669 12.311 8.68902 12.8333 9.33335 12.8333Z"
                        stroke="#5D5FEF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8333 7V8.16667H11.0833C10.7625 8.16667 10.5 7.90417 10.5 7.58333V5.83333C10.5 5.5125 10.7625 5.25 11.0833 5.25H11.8358L12.8333 7Z"
                        stroke="#5D5FEF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span
                      className={`w-[95px] text-sm font-semibold transition-all duration-300 font-quicksand text-clinder`}
                    >
                      Supply
                    </span>
                  </Link>
                </li>
              </ul> */}
                        </nav>
                    </div>

                    <button
                        className="mt-10 flex bg-coral w-full items-center justify-center mx-auto py-3 rounded gap-[10px]"
                        onClick={handleLogout}
                    >
                        <img src={logoutIcon} alt="logout" />
                        <span className="text-base font-bold text-red-dark font-quicksand">
                            Logout
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
};

SideBar.defaultProps = {
    isDashboard: false,
    isStock: false,
    isCustomer: false,
    isRestaurant: false,
    isTable: false,
    isReport: false,
    isRoleAndAdmin: false,
    isSettings: false,
    isIntegrationStock: false,
    isSupply: false,
};

export default SideBar;
