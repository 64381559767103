import React from "react";
import { useState } from "react";

import DoubleIndicatorDropdown from "../../../components/DoubleIndicatorDropdown";

import shieldSearch from "../../../assets/icons/shieldSearch.svg";
import trashRed from "../../../assets/icons/trashRed.svg";
import DeleteConfirmationModal from "../../DeleteConfirmationModal";
import PromoStatusOptionsDropdown from "./PromoStatusOptionsDropdown";
import PromoLevelsOverviewModal from "./PromoLevelsOverviewModal";
import DataFilterEmptyInfo from "../../DataFilterEmptyInfo";
import DataApiEmptyInfo from "../../DataApiEmptyInfo";
import UnderConstructionBanner from "../../banner/UnderConstructionBanner";

const TabletDesktopPromoTable = ({
    promoData,
    allPromos,
    promoNameOptions,
    roleOptions,
    dateAddedOptions,
    dateExpireddOptions,
    kuotaOptions,
    statusOptions,
    onDeletePromo,
    onGetAllPromos,
    promoId,
    setPromoId,
    setIsDetailPromoModalOPen,
}) => {
    const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
    const [isShowAllPromoLevels, setIsShowAllPromoLevels] = useState(false);
    const [allPromoLevels, setAllPromoLevels] = useState([]);

    const yyyymmddFormatDate = (inputDate) => {
        const dateObject = new Date(inputDate);

        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    return (
        <div className="hidden w-full mt-6 mb-3 md:block">
            <table className="w-full table-auto border-collaplse">
                <thead className="bg-neutral-gray">
                    <tr>
                        <th id="promo-name" className="w-[26.27%] lg:w-[20.55%] xl:w-[16.61%]">
                            <DoubleIndicatorDropdown options={promoNameOptions} />
                        </th>
                        <th id="role" className="w-[26.27%] lg:w-[16.55%] xl:w-[16.61%]">
                            <DoubleIndicatorDropdown options={roleOptions} />
                        </th>
                        <th
                            id="date-added"
                            className="w-[11.74%] lg:w-[14.55%] 2xl:w-[11.74%] hidden lg:table-cell"
                        >
                            <DoubleIndicatorDropdown options={dateAddedOptions} />
                        </th>
                        <th
                            id="date-expired"
                            className="w-[11.74%] lg:w-[14.55%] 2xl:w-[11.74%] hidden lg:table-cell"
                        >
                            <DoubleIndicatorDropdown options={dateExpireddOptions} />
                        </th>
                        <th
                            id="kuota"
                            className="w-[15.82%] lg:w-[11.26%] xl:w-[11.74%] 2xl:w-[10.07%]"
                        >
                            <DoubleIndicatorDropdown options={kuotaOptions} />
                        </th>
                        <th
                            id="status"
                            className="w-[18.99%] lg:w-[13.51%] xl:w-[10.07%] 2xl:w-[16.61%]"
                        >
                            <DoubleIndicatorDropdown options={statusOptions} />
                        </th>
                        <th
                            id="action"
                            className="text-sm font-medium font-quicksand text-manatee text-start w-[12.66%] lg:w-[9.01%] xl:w-[16.61%]"
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                {/* If promo data is not empty */}
                {promoData?.length > 0 && (
                    <tbody>
                        {promoData?.map((promo, index) => {
                            return (
                                <tr key={index}>
                                    <td aria-labelledby="promo-name" className="px-[10px]">
                                        <div
                                            className={`flex items-center gap-3  text-clinder font-quicksand text-sm font-semibold capitalize ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            {promo?.name}
                                        </div>
                                    </td>
                                    <td aria-labelledby="role" className="px-[10px]">
                                        <div
                                            className={`flex gap-[10px] ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            <div
                                                className={`bg-[${promo?.promoLevels[0]?.colorBackground}] rounded py-2`}
                                            >
                                                <span
                                                    className={`text-${promo?.promoLevels[0]?.colorFont} text-center text-sm font-semibold capitalize font-quicksand px-6 flex-none`}
                                                >
                                                    {promo?.promoLevels[0]?.customerLevelName}
                                                </span>
                                            </div>
                                            {promo?.promoLevels?.length > 1 && (
                                                <button
                                                    className="px-3 py-2 text-sm font-semibold rounded bg-neutral-gray text-clinder font-quicksand"
                                                    onClick={() => {
                                                        setAllPromoLevels(promo?.promoLevels);
                                                        setIsShowAllPromoLevels(true);
                                                    }}
                                                >
                                                    +{promo?.promoLevels?.length - 1}
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                    <td
                                        aria-labelledby="date-added"
                                        className="px-[10px]] hidden lg:table-cell"
                                    >
                                        <div
                                            className={`flex items-center gap-3  text-clinder font-quicksand text-sm font-semibold ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            {yyyymmddFormatDate(promo?.promoStartDate)}
                                        </div>
                                    </td>
                                    <td
                                        aria-labelledby="date-expired"
                                        className="px-[10px]] hidden lg:table-cell"
                                    >
                                        <div
                                            className={`text-clinder font-quicksand text-sm font-semibold px-[10px] ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            {yyyymmddFormatDate(promo?.promoEndDate)}
                                        </div>
                                    </td>
                                    <td aria-labelledby="kuota" className="px-[10px]">
                                        <div
                                            className={`text-clinder font-quicksand text-sm font-semibold  px-[10px] ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            {promo?.promoKuota}
                                        </div>
                                    </td>
                                    <td aria-labelledby="status" className="px-[10px]">
                                        <div
                                            className={` px-[10px] py-[10px] flex justify-between items-center gap-[10px] rounded ${
                                                promo?.status ? "bg-ivory-cream" : "bg-coral"
                                            } ${index === 0 ? "mb-[3px] mt-4" : "my-[3px]"}`}
                                        >
                                            <span
                                                className={`text-sm font-semibold font-quicksand text-lime-green capitalize ${
                                                    promo?.status
                                                        ? "text-lime-green"
                                                        : "text-scarlet-red"
                                                }`}
                                            >
                                                {promo?.status ? "Active" : "Inactive"}
                                            </span>
                                            <PromoStatusOptionsDropdown
                                                options={[
                                                    { name: "Active", value: true },
                                                    { name: "Inactive", value: false },
                                                ]}
                                                onGetAllPromos={onGetAllPromos}
                                                promoId={promo?.id}
                                                showResultOption={false}
                                                height={"h-auto"}
                                                width={"w-auto"}
                                                iconSize={"w-6 h-6"}
                                                radius={"rounded"}
                                                optionsWidth={"w-[100px]"}
                                                padding={"px-0"}
                                                defaultSelectedStatusName={
                                                    promo?.status ? "Active" : "Inactive"
                                                }
                                                defaultSelectedStatusValue={promo?.status}
                                            />
                                        </div>
                                    </td>
                                    <td aria-labelledby="action" className="p-[10px]">
                                        <div
                                            className={`flex items-center justify-center gap-1 mt-2 ${
                                                index === 0 ? "mb-[3px] mt-4" : "my-[3px]"
                                            }`}
                                        >
                                            <button
                                                type="button"
                                                className="block px-3 mx-auto xl:hidden"
                                            >
                                                <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                                <div className="w-[4px] h-[4px] bg-black rounded-full my-1 mx-auto" />
                                                <div className="w-[4px] h-[4px] bg-black rounded-full mx-auto" />
                                            </button>
                                            <button
                                                type="button"
                                                className="items-center justify-center flex-1 hidden py-1 duration-300 transform rounded bg-neutral-gray hover:bg-gray-200 xl:flex"
                                                onClick={() => {
                                                    setPromoId(promo?.id);
                                                    setIsDetailPromoModalOPen(true);
                                                }}
                                            >
                                                <img
                                                    src={shieldSearch}
                                                    alt="shield search"
                                                    className="w-3 h-3"
                                                />
                                                <span className="ml-2 text-sm font-semibold font-quicksand text-clinder">
                                                    Detail
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className="px-3 py-[7px] duration-300 transform rounded-md bg-neutral-gray hover:bg-gray-200 hidden xl:flex"
                                                onClick={() => {
                                                    setIsDeleteConfirmationModalOpen(true);
                                                    setPromoId(promo.id);
                                                }}
                                            >
                                                <img
                                                    src={trashRed}
                                                    alt="trash red"
                                                    className="w-3 h-3"
                                                />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                )}

                {/* If no promo data match the search filter criteria */}
                {/* 
          NOTES : 
          - disabled due to unready feature

        {allPromos?.length !== 0 && promoData?.length === 0 && (
          <tbody>
            <td colSpan={6} className="pt-4">
              <DataFilterEmptyInfo
                emptyInfoHeading={'Sorry, No Results Found'}
                emptyInfoDescription={
                  "Well, we've searched high and low, but it seems like what your're looking for isn't our database (yet!)"
                }
              />
            </td>
          </tbody>
        )} */}

                {/* If promo data  on data base is empty */}
                {/* 
        NOTES : 
          - disabled due to unready feature
        
        {allPromos?.length === 0 && promoData?.length === 0 && (
          <tbody>
            <td colSpan="6" className="pt-4">
              <DataApiEmptyInfo
                emptyInfoHeading={'Oops, Nothing Here Yet!'}
                firstEmptyInfoDescription={
                  'Looks like this space is feeling a bit lonely right now. Why not kick things off by adding some info?'
                }
                secondEmptyInfoDescription={
                  "Just hit that 'Add New Promo' button, and let the good times (and data) roll!"
                }
              />
            </td>
          </tbody>
        )} */}
            </table>

            <UnderConstructionBanner />

            {/* Delete Confirmation  */}
            <DeleteConfirmationModal
                isDeleteConfirmationModalOpen={isDeleteConfirmationModalOpen}
                setIsDeleteConfirmationModalOpen={setIsDeleteConfirmationModalOpen}
                shortDeleteConfirmatonMessage={"Delete this promo"}
                longDeleteConfirmatonMessage={"Are you sure you want to delete this promo?"}
                longDeleteButtonLabel={"Yes, Delete this promo"}
                onDelete={onDeletePromo}
                id={promoId}
            />

            {/* See All Promo Levels  */}
            <PromoLevelsOverviewModal
                isModalOpen={isShowAllPromoLevels}
                setIsModalOpen={setIsShowAllPromoLevels}
                allPromoLevels={allPromoLevels}
            />
        </div>
    );
};

export default TabletDesktopPromoTable;
