import React, { useState } from 'react';

import axiosInstance from '../../../utils/api/apiConfig';

import close from '../../../assets/icons/close.svg';

const RestockModal = ({
  isModalOpen,
  setIsModalOpen,
  baseProductCurrentStock,
  baseProductUnitSystem,
  totalStock,
  setTotalStock,
  onGetAllBaseProducts,
  baseProductId,
}) => {
  // === base product data Data ===
  const [additionalStock, setAdditionalStock] = useState('');

  // === Data on Local Storage ===
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  const userId = localStorage.getItem('userId');

  // Handle Submit Category Data
  const handleRestockBaseProduct = async (e) => {
    e.preventDefault();

    const bodyRequest = {
      recentStock: totalStock,
    };

    try {
      const response = await axiosInstance.put(
        `/base-products/${baseProductId}`,
        bodyRequest,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-TENANT-ID': tenantId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAdditionalStock('');
      onGetAllBaseProducts();
      setIsModalOpen(false);
      // console.log(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      <div
        className={`fixed top-0 inset-0 z-50 justify-end flex bg-clinder/60 ${
          !isModalOpen && 'hidden'
        }`}
        onClick={() => {
          setIsModalOpen(false);
          setAdditionalStock('');
        }}
      />
      <div
        className={`px-6 pb-6 md:px-9 md:pb-9 bg-white w-full md:w-[600px] overflow-y-auto h-screen flex flex-col fixed top-0 right-0 z-50 duration-300 transition-all ${
          isModalOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="px-6 pt-6 pb-6 md:px-0 md:pt-9 border-b-[1px] border-gray-x/40 -mx-6 md:mx-0 sticky top-0 bg-white z-30">
          <div className="flex flex-row-reverse items-center justify-end gap-6 md:flex-row md:justify-between">
            <h2 className="text-xl font-semibold md:text-2xl md:font-bold text-aurora-blue font-quicksand">
              Restock
            </h2>
            <button
              onClick={() => {
                setIsModalOpen(false);
                setAdditionalStock('');
              }}
            >
              <img src={close} alt="close" />
            </button>
          </div>
          <p className="w-[90%] mt-2 text-sm font-medium leading-7 font-satoshi text-manatee hidden md:block">
            In this window you can add stock to this base product.
          </p>
        </div>
        <form className="flex flex-col justify-between flex-1 pt-6">
          <div>
            <div className="mb-6">
              <label
                htmlFor="category-name"
                className="text-sm font-semibold font-quicksand text-clinder"
              >
                Curent Stock
              </label>{' '}
              <br />
              <div className="w-full px-4 py-3 mt-2 text-sm font-medium rounded-lg text-manatee bg-gray-x font-quicksand">
                <span>{baseProductCurrentStock}</span>{' '}
                <span>
                  {baseProductUnitSystem === 'kilogram'
                    ? 'Kg'
                    : baseProductUnitSystem === 'gram'
                    ? 'g'
                    : 'Pcs'}
                </span>
              </div>
            </div>
            <div className="mb-6 h-[85px]">
              <div className="flex">
                <label
                  htmlFor="maximum-stock"
                  className="text-sm font-semibold font-quicksand text-clinder mr-[10px]"
                >
                  Add Stock
                </label>{' '}
              </div>
              <div className="relative mt-2">
                <input
                  type="number"
                  id="maximum-stock"
                  value={additionalStock}
                  step={0.1}
                  required
                  placeholder="Enter how much you want to add to this base product"
                  className="w-full py-[15px] focusable-input absolute pl-4 pr-[72px]"
                  onChange={(e) => {
                    const input = Number(e.target.value);
                    setAdditionalStock(input);
                    setTotalStock(baseProductCurrentStock + input);
                  }}
                />
                <span className="absolute top-3 right-4 text-aurora-blue shadow-soft bg-white rounded-[4px] font-quicksand font-semibold text-xs px-3 py-1 ml-4 flex-none">
                  {baseProductUnitSystem === 'kilogram'
                    ? 'Kg'
                    : baseProductUnitSystem === 'gram'
                    ? 'g'
                    : 'Pcs'}
                </span>
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="category-name"
                className="text-sm font-semibold font-quicksand text-clinder"
              >
                Total Stock After Restock
              </label>{' '}
              <br />
              <div className="w-full px-4 py-3 mt-2 text-sm font-medium rounded-lg text-manatee bg-gray-x font-quicksand">
                <span>{totalStock}</span>{' '}
                <span>
                  {baseProductUnitSystem === 'kilogram'
                    ? 'Kg'
                    : baseProductUnitSystem === 'gram'
                    ? 'g'
                    : 'Pcs'}
                </span>
              </div>
            </div>
          </div>
          <div className="flex gap-[10px]">
            <button
              type="button"
              className="w-full mb-10 transition duration-300 bg-white rounded-lg hover:bg-forested-mind mt-9 xl:mb-0"
              onClick={() => {
                setIsModalOpen(false);
                setAdditionalStock('');
              }}
            >
              <span className="font-bold text-aurora-blue font-quicksand">
                {' '}
                Cancel
              </span>
            </button>
            <button
              type="submit"
              className="w-full mb-10 mt-9 button-primary-lg xl:mb-0"
              onClick={handleRestockBaseProduct}
            >
              <span className="font-bold text-white font-quicksand">
                Restock
              </span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RestockModal;
