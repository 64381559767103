import React from "react";

import searchBlue from "../../../assets/icons/searchBlue.svg";
import plus from "../../../assets/icons/plus.svg";

const MobileCustomerTable = ({ customerData, setIsNewCustomerModalOpen }) => {
    console.log(
        "🚀 ~ MobileCustomerTable ~ customerData:",
        customerData,
        customerData.totalTransaction
    );
    return (
        <div className="block md:hidden">
            <div className="flex gap-4 px-6 py-2 sticky top-[164px] bg-white border-b-[1px] border-gray-x">
                <img src={searchBlue} alt="search" />
                <input
                    type="text"
                    placeholder="Find your categories here"
                    className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
                />
                <button
                    type="button"
                    className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
                    onClick={() => setIsNewCustomerModalOpen(true)}
                >
                    <img src={plus} alt="add" className="mx-auto" />
                </button>
            </div>
            {customerData &&
                customerData.map((customer, index) => {
                    let levelBackGroundColor = "";
                    let levelTextColor = "";
                    if (customer.level === "Warga") {
                        levelTextColor = "text-grad-orangetty";
                        levelBackGroundColor = "bg-creamy-white";
                    } else if (customer.level === "Juragan") {
                        levelTextColor = "text-grad-bluetty";
                        levelBackGroundColor = "bg-soft-aqua";
                    } else if (customer.level === "Sultan") {
                        levelTextColor = "text-grad-greentty";
                        levelBackGroundColor = "bg-pastel-green";
                    } else {
                        levelTextColor = "text-grad-purpletty";
                        levelBackGroundColor = "bg-tender-rose";
                    }

                    return (
                        <div key={index} className="w-full">
                            <div
                                className={`flex items-center justify-between w-full py-4 px-6 bg-neutral-gray ${
                                    index === 0 ? "border-b-[1px]" : "border-y-[1px]"
                                }`}
                            >
                                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                    {customer.customerName}
                                </p>
                                <button></button>
                                <button type="button" className="px-3">
                                    <div className="w-[4px] h-[4px] bg-black rounded-full" />
                                    <div className="w-[4px] h-[4px] bg-black rounded-full my-1" />
                                    <div className="w-[4px] h-[4px] bg-black rounded-full" />
                                </button>
                            </div>
                            <div className="flex items-center justify-between px-6 mt-4">
                                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                    Level
                                </p>
                                <p className={`px-4 py-1 ${levelBackGroundColor}`}>
                                    <span
                                        className={`text-sm font-semibold text-center capitalize ${levelTextColor} rounded font-quicksand`}
                                    >
                                        {customer.level}
                                    </span>
                                </p>
                            </div>
                            <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                    Favorite Menu
                                </p>
                                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                                    {customer.favoriteMenu}
                                </p>
                            </div>
                            <div className="flex items-center justify-between px-6 mt-4 mb-4 xs:justify-between">
                                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                                    Total Transaction
                                </p>
                                <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                                    {customer?.totalTransaction
                                        ?.toString()
                                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </p>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default MobileCustomerTable;
