import React, { useState } from "react";

import SideBar from "../../components/Sidebar";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import TabletDesktopBranchTable from "../../components/settings_and_preferences/branch/TabletDesktopBranchTable";
import Pagination from "../../components/Pagination";
import DetailBranchModal from "../../components/settings_and_preferences/branch/DetailBranchModal";
import NewBranchModal from "../../components/settings_and_preferences/branch/NewBranchModal";

import branchBanner from "../../assets/images/branchBanner.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const SettingsBranch = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    // === Branch State ===
    const [branchId, setBranchId] = useState(null);
    const [allBranches, setAllBranches] = useState([]);

    // === Pagination State ===
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // === component state ===
    const [isDetailBranchModalOpen, setIsDetailBranchModalOpen] = useState(false);
    const [isNewBranchModalOpen, setIsNewBranchModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("branch");

    const topNavs = [
        { nav: "Branch", value: "branch", route: "admin-settings-branch" },
        {
            nav: "Tax Settings",
            value: "tax-settings",
            route: "admin-settings-tax",
        },
        { nav: "QRIS", value: "qris", route: "admin-settings-qris" },
        {
            nav: "Number Format",
            value: "number-format",
            route: "admin-settings-number-format",
        },
    ];

    const fakeBranchData = [
        {
            id: 1,
            name: "monday cofee",
            address: "Jl. Jend. Sudirman, Jakarta",
            isOpen: true,
        },
        {
            id: 2,
            name: "tuesday cofee",
            address: "Jl. Juanda Jakarta",
            isOpen: false,
        },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] bg-white flex-none h-[86px] sticky top-0 z-50">
                <SideBar isSettings={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full min-h-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Settings & Preferences"}
                    menuDescription={"You can manage and setting the restaurant other things here"}
                    topNavs={topNavs}
                />
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        <Banner
                            title={"Branch"}
                            description={
                                "On this menu you will be able to create, edit, and also delete the Branch. Also you can manage it easily"
                            }
                            onOpenModal={() => setIsNewBranchModalOpen(true)}
                            image={branchBanner}
                            openModalButtonLabel={"Add New Branch"}
                            showSearchInput={false}
                            showFilterButton={false}
                            showRefreshButton={false}
                            showPrintButton={false}
                            isDisable={true}
                        />

                        {/* Branch Table for Tablet and Desktop */}
                        <TabletDesktopBranchTable
                            branchData={fakeBranchData}
                            setBranchId={setBranchId}
                            setIsDetailBranchModalOpen={setIsDetailBranchModalOpen}
                        />
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing{" "}
                                <span className="text-clinder">{fakeBranchData.length}</span> out of{" "}
                                <span className="text-clinder">{totalData}</span> items
                            </p>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isPageNumberColorGray={true}
                            />
                        </div>
                    </div>
                </main>

                {/* New Branch Modal */}
                <NewBranchModal
                    isModalOpen={isNewBranchModalOpen}
                    setIsModalOpen={setIsNewBranchModalOpen}
                />

                {/* Detail Branch Modal */}
                <DetailBranchModal
                    isModalOpen={isDetailBranchModalOpen}
                    setIsModalOpen={setIsDetailBranchModalOpen}
                    branchId={branchId}
                />
            </div>
        </div>
    );
};

export default SettingsBranch;
