import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const ProductOptionsDropdown = ({
    label,
    options,
    background,
    radius,
    width,
    height,
    iconSize,
    showResultOption,
    optionsWidth,
    optionHeight,
    padding,
    setSelectedId,
    selectedValue,
    setSelectedValue,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`relative ${width}`}>
            <div
                className={`flex items-center justify-between rounded-lg cursor-pointer font-quicksand ${padding} ${height} ${background}`}
                onClick={toggleDropdown}
            >
                <span
                    className={`text-sm font-semibold  font-quicksand ${
                        showResultOption ? "block" : "hidden"
                    } ${selectedValue === "" ? "text-gray-x" : "text-clinder capitalize"} `}
                >
                    {selectedValue === "" ? label : selectedValue}
                </span>
                <span
                    className={`${radius} ${iconSize} flex items-center justify-center bg-white text-clinder shadow-soft flex-none`}
                >
                    {isOpen ? <FiChevronUp /> : <FiChevronDown />}
                </span>
            </div>
            {isOpen && (
                <div
                    className={`absolute right-0 z-10 mt-2 py-2 rounded-lg bg-neutral-gray overflow-y-auto ${optionsWidth} ${optionHeight}`}
                >
                    {!options || options?.length === 0 ? (
                        <div className="w-full h-full flex items-center justify-center">
                            <span className="text-base text-gray-x">There are no menu found</span>
                        </div>
                    ) : (
                        options?.map((option, index) => (
                            <div
                                key={index}
                                className={`px-4 py-2 text-sm font-medium capitalize cursor-pointer font-quicksand hover:bg-gray-200 transition-all duration-300 ${
                                    option.name === selectedValue && "bg-gray-200"
                                }`}
                                onClick={() => {
                                    setSelectedId(option?.id);
                                    setSelectedValue(option?.name);
                                    setIsOpen(false);
                                }}
                            >
                                <p className={`text-sm font-semibold font-quicksand capitalize`}>
                                    {option?.name}
                                </p>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

ProductOptionsDropdown.defaultProps = {
    background: "bg-neutral-gray",
    radius: "rounded-lg",
    height: "h-14",
    width: "w-full",
    iconSize: "w-8 h-8",
    showResultOption: true,
    label: null,
    optionsWidth: "w-full",
    optionHeight: "h-auto",
    padding: "px-4",
};

export default ProductOptionsDropdown;
