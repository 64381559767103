"use client";

import { useEffect, useState } from "react";
import axiosInstance from "../../utils/api/apiConfig";

const useGetLatestTransaction = (token, tenantId, limit) => {
    const [loading, setLoading] = useState(true);
    const [tokenHasExpired, setTokenHasExpired] = useState(false);
    const [latestTransactionData, setLatestTransactionData] = useState();

    useEffect(() => {
        const getLatestTransaction = async () => {
            try {
                const response = await axiosInstance.get(
                    `/transactions/all/latest?limit=${limit}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-TENANT-ID": tenantId,
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                // console.log("🚀 ~ getLatestTransaction ~ response:", response);
                if (response.status === 401) return setTokenHasExpired(true);

                setLatestTransactionData(response.data.data);
            } catch (error) {
                // console.log("🚀 ~ getLatestTransaction ~ error:", error);
            } finally {
                setLoading(false);
            }
        };

        getLatestTransaction();
    }, [token, tenantId, limit]);

    return { loading, latestTransactionData, tokenHasExpired };
};

export default useGetLatestTransaction;
