import React from 'react';
import { FaPlus } from 'react-icons/fa';

const DataApiEmptyInfo = ({
  emptyInfoHeading,
  firstEmptyInfoDescription,
  secondEmptyInfoDescription,
}) => {
  const splittedSecondDescription = secondEmptyInfoDescription.split("'");

  return (
    <div>
      <div className="bg-manatee/40 rounded-t-2xl flex justify-center items-center rounded-br-2xl rounded w-[60px] h-[60px] mx-auto">
        <FaPlus className="text-manatee" size={25} />
      </div>
      <h3 className="my-4 text-2xl font-bold text-center font-quicksand">
        {emptyInfoHeading}
      </h3>
      <p className="text-sm font-medium text-center text-manatee font-quicksand">
        {firstEmptyInfoDescription}
      </p>
      <p className="mt-2 text-sm font-medium text-center text-manatee font-quicksand">
        {splittedSecondDescription[0]}{' '}
        <span className="text-aurora-blue">
          '{splittedSecondDescription[1]}'
        </span>{' '}
        {splittedSecondDescription[2]}
      </p>
    </div>
  );
};

export default DataApiEmptyInfo;
