import { useState } from "react";

import SideBar from "../../components/Sidebar";
import Banner from "../../components/Banner";

import reportTransactionBanner from "../../assets/images/repotTransaction.png";
import TabletDesktopTransactionTable from "../../components/report/transaction/TabletDesktopTransactionTable";
import MobileTransactionTable from "../../components/report/transaction/MobileTransactionTable";
import DetailTransactionModal from "../../components/report/transaction/DetailTransactionModal";
import useGetAllTransaction from "../../hooks/report/transaction/useGetAllTransaction";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const ReportTransaction = () => {
    const [isTransactionId, setTransactionId] = useState();
    const [isCustomerName, setCustomerName] = useState("");
    const [isDetailTransactionModalOpen, setDetailTransactionModalOpen] = useState(false);

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("userId");

    /* HOOKS */
    const { loading, allTransactionData } = useGetAllTransaction(token, tenantId);

    // Table Header
    const transactionOrderIdOptions = [{ label: "Order Id", value: "order-id", disabled: true }];
    const transactionCustomerOptions = [{ label: "Customer", value: "customer", disabled: true }];
    const transactionItemOptions = [{ label: "Items", value: "items", disable: true }];
    const transactionDateOptions = [{ label: "Date", value: "date", disable: true }];
    const transactionTotalOptions = [
        { label: "Total Transaction", value: "total-transaction", disable: true },
    ];

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] bg-white flex-none h-[86px] sticky top-0 z-50">
                <SideBar isReport={true} />
            </aside>

            <div className="flex flex-col w-full ml-0 md:ml-6 md:mr-4">
                <header className="pb-4 pt-6 h-auto hidden md:flex flex-col md:flex-row items-end justify-between xl:h-28 border-b-[1px] border-gray-lighter sticky top-0 z-50 bg-white">
                    <div className="flex flex-col justify-center w-full h-full md:w-auto">
                        <h2 className="mt-0 md:mt-1 font-quicksand text-2xl text-black leading-[36px] font-bold">
                            Report
                        </h2>
                        <p className="mt-0 text-sm md:mt-1 font-satoshi text-manatee">
                            Get the scoop on your business with these main reporting categories
                        </p>
                    </div>
                </header>
                <main className="flex flex-col justify-between flex-1">
                    <div>
                        {/* Banner */}
                        <Banner
                            title="Transaction"
                            description="Check out what's selling! See your daily transactions, what people are buying, and how your sales are doing."
                            showOpenModalButton={false}
                            searchPlaceholder={"Search Order ID"}
                            image={reportTransactionBanner}
                            shortPrintButtonLabel={"Print Transaction"}
                            longPrintButtonLabel={"Print List of Transaction"}
                            showFilterButton={false}
                        />

                        {/* Employee Table for Mobile */}
                        <MobileTransactionTable
                            transactionData={allTransactionData}
                            setTransactionId={setTransactionId}
                            setDetailTransactionModalOpen={setDetailTransactionModalOpen}
                        />

                        {/* Transaction Table for Tablet and Desktop */}
                        <TabletDesktopTransactionTable
                            transactionData={allTransactionData}
                            transactionOrderIdOptions={transactionOrderIdOptions}
                            transactionCustomerOptions={transactionCustomerOptions}
                            transactionItemOptions={transactionItemOptions}
                            transactionDateOptions={transactionDateOptions}
                            transactionTotalOptions={transactionTotalOptions}
                            setTransactionId={setTransactionId}
                            setDetailTransactionModalOpen={setDetailTransactionModalOpen}
                            setCustomerName={setCustomerName}
                        />
                    </div>
                    {/* Detail Transaction Modal */}
                    <DetailTransactionModal
                        isModalOpen={isDetailTransactionModalOpen}
                        setIsModalOpen={setDetailTransactionModalOpen}
                        isTransactionId={isTransactionId}
                        customerName={isCustomerName}
                    />
                </main>
            </div>
        </div>
    );
};

export default ReportTransaction;
