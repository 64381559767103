import React, { useEffect, useState } from "react";

import Header from "../../components/Header";
import SideBar from "../../components/Sidebar";
import Pagination from "../../components/Pagination";
import NewCategoryModal from "../../components/stock/category/NewCategoryModal";
import Banner from "../../components/Banner";
import TopNavbar from "../../components/TopNavbar";
import MobileCategoryTable from "../../components/stock/category/MobileCategoryTable";
import TabletDesktopCategoryTable from "../../components/stock/category/TabletDesktopCategoryTable";
import Loading from "../../components/Loading";
import DetailCategory from "../../components/stock/category/DetailCategory";

import stockCategoryBanner from "../../assets/images/stockCategoryBanner.png";
import EditCategoryModal from "../../components/stock/category/EditCategory";
import axiosInstance from "../../utils/api/apiConfig";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const StockCategory = () => {
    // === Stock-Category State ===
    const [categories, setCategories] = useState([]);
    const [displayedCategories, setDisplayedCategories] = useState(categories);
    const [detailCategory, setDetailCategory] = useState({});
    const [areasOfCategory, setAreasOfCategory] = useState([]);
    const [categoryId, setCategoryId] = useState();

    // === Component State ===
    const [activeTopNav, setActiveTopNav] = useState("category");
    const [isNewCategoryModalOpen, setIsNewCategoryModalOpen] = useState(false);
    const [isDetailCategoryModalOpen, setIsDetailCategoryModalOpen] = useState(false);
    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
    const topNavs = [
        { nav: "Category", value: "category", route: "admin-stock-category" },
        {
            nav: "Base Product",
            value: "base-product",
            route: "admin-stock-base-product",
        },
        { nav: "Menu", value: "menu", route: "admin-stock-menu" },
        { nav: "Price", value: "price", route: "admin-stock-price" },
        {
            nav: "Favorite Menu",
            value: "favorite-menu",
            route: "admin-stock-favorite-menu",
        },
        {
            nav: "Additional Notes",
            value: "additional-notes",
            route: "admin-stock-additional-notes",
        },
    ];

    // === State Condition ===
    const [isLoadingGetCategories, setIsLoadingGetCategories] = useState(false);
    const [isLoadingDeleteCategory, setIsLoadingDeleteCategory] = useState(false);
    const [isLoadingGetCategoryById, setIsLoadingGetCategoryById] = useState(false);
    const [isAreaOfCategoryUpdate, setIsAreaOfCategoryUpdate] = useState(false);

    // === Filtering State ===
    const [searchQuery, setSearchQuery] = useState("");
    const [isRefreshActive, setIsRefreshActive] = useState(false);

    // === Pagination State ===
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const creator = localStorage.getItem("userFullName");

    // Fake Data
    const nameSortOptions = [
        { label: "Category Name", value: "customer name", disabled: true },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];

    const creatorSortOptions = [
        { label: "Creator", value: "customer name", disabled: true },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];

    const productSortOptions = [
        { label: "product", value: "product", disabled: true },
        { label: "Less than 10", value: "<10" },
        { label: "10 - 20", value: "10-20" },
        { label: "21 - 30", value: "21-30" },
        { label: "41 - 50", value: "41-50" },
        { label: "More than 50", value: ">50" },
    ];

    const locationSortOptions = [
        { label: "Location", value: "location", disabled: true },
        { label: "Sort By Name A-Z", value: "ascending" },
        { label: "Sort By Name Z-A", value: "descending" },
    ];

    const categoryData = [
        {
            name: "Asian Food",
            creator: {
                creatorName: "savannah nguyen",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1618641986557-1ecd230959aa?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODU5NDcwODE&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "kitchen",
        },
        {
            name: "Asian Drinks",
            creator: {
                creatorName: "kris roher",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1530268729831-4b0b9e170218?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEyMzg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "bar",
        },
        {
            name: "Brazil Drinks",
            creator: {
                creatorName: "Serenity Fisher",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1519456264917-42d0aa2e0625?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEyNjY&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "bar",
        },
        {
            name: "Asian Drinks",
            creator: {
                creatorName: "Brooklyn Warren",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzMDc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "bar",
        },
        {
            name: "Asian Coffee",
            creator: {
                creatorName: "Franco Delort",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1463453091185-61582044d556?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzMzQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "coffe shop",
        },
        {
            name: "Asian Drinks",
            creator: {
                creatorName: "Serenity Fisher",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODE0MzQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "bar",
        },
        {
            name: "Asian Drinks",
            creator: {
                creatorName: "Brooklyn Warren",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNjc&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "bar",
        },
        {
            name: "Asian Breads",
            creator: {
                creatorName: "Franco Delort",
                creatorRole: "Food Quality Manager",
                creatorProfilePicture:
                    "https://images.unsplash.com/photo-1594751439417-df8aab2a0c11?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=100&ixid=MnwxfDB8MXxyYW5kb218MHx8cHJvZmlsZXx8fHx8fDE2ODYwODEzNzg&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=100",
            },
            totalProduct: 26,
            areaOfCategory: "Coffe Shop",
        },
    ];

    // Handle display categories based on searching filter and pagination
    const handleDisplayCategories = () => {
        if (searchQuery !== "") {
            const searchedCategories = categories?.filter((item) =>
                item?.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setIsRefreshActive(true);
            setDisplayedCategories(searchedCategories?.slice(startIndex, endIndex));
            setTotalPages(Math.ceil(searchedCategories?.length / itemsPerPage));
            setTotalData(searchedCategories?.length);
        } else {
            setDisplayedCategories(categories?.slice(startIndex, endIndex));
            setTotalPages(Math.ceil(categories?.length / itemsPerPage));
            setTotalData(categories?.length);
        }
    };

    useEffect(() => {
        handleDisplayCategories();
        // to hide refresh button if search query is empty
        if (searchQuery === "") {
            setIsRefreshActive(false);
        }
    }, [categories, currentPage, searchQuery]);

    useEffect(() => {
        getAllCategories();
    }, []);

    useEffect(() => {
        getAllAreaOfCategory();
    }, [isAreaOfCategoryUpdate]);

    // Handle Get All Categories
    const getAllCategories = async () => {
        setIsLoadingGetCategories(true);
        try {
            const response = await axiosInstance.get(`/product-categories`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setCategories(response.data.data.results);
            setIsLoadingGetCategories(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingGetCategories(false);
        }
    };

    // Handle Delete Category
    const deleteCategory = async (id) => {
        setIsLoadingDeleteCategory(true);
        try {
            await axiosInstance.delete(`/product-categories/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllCategories();
            setIsLoadingDeleteCategory(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingDeleteCategory(false);
        }
    };

    // Handle Get Category by Id
    const getCategoryById = async (id) => {
        setIsLoadingGetCategoryById(true);
        try {
            const response = await axiosInstance.get(`/product-categories/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setDetailCategory(response.data.data);
            setIsLoadingGetCategoryById(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingGetCategoryById(false);
        }
    };

    // Handle Get All of areas of category
    const getAllAreaOfCategory = async () => {
        try {
            const response = await axiosInstance.get(`/area-of-categories`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setAreasOfCategory(response.data.data);
        } catch (error) {
            // console.log(error);
        }
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="sticky top-0 bg-white z-50 w-full h-[86px] md:w-[88px] 2xl:w-[200px] flex-none">
                <SideBar isStock={true} />
            </aside>

            <div className="flex flex-col flex-1 w-full min-h-full ml-0 mr-0 md:w-auto md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Product"}
                    menuDescription={"You can manage and organize your product stock here"}
                    topNavs={topNavs}
                />

                <main className="flex flex-col justify-between flex-1">
                    <div className="flex flex-col flex-1">
                        <Banner
                            title="Category"
                            description="On this menu you will be able to create, edit, and also delete the category of the food. Also you can manage it easily."
                            onOpenModal={() => setIsNewCategoryModalOpen(true)}
                            openModalButtonLabel="Create New Category"
                            searchPlaceholder={"Find your categories here"}
                            image={stockCategoryBanner}
                            showFilterButton={false}
                            shortPrintButtonLabel={"Print List"}
                            longPrintButtonLabel={"Print List of Category"}
                            isRefreshActive={isRefreshActive}
                            onHandleDeleteFilter={() => {
                                setSearchQuery("");
                                setIsRefreshActive(false);
                            }}
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />

                        {/* Navbar for Mobile & Tablet */}
                        <TopNavbar
                            topNavs={topNavs}
                            activeTopNav={activeTopNav}
                            setActiveTopNav={setActiveTopNav}
                        />

                        {/* Stock Category Table Mobile*/}
                        <MobileCategoryTable
                            categoryData={categoryData}
                            setIsNewCategoryModalOpen={setIsNewCategoryModalOpen}
                        />

                        {/* Stock Category Table for Tablet & Desktop */}
                        {isLoadingGetCategories ? (
                            <Loading />
                        ) : (
                            <TabletDesktopCategoryTable
                                nameSortOptions={nameSortOptions}
                                creatorSortOptions={creatorSortOptions}
                                productSortOptions={productSortOptions}
                                locationSortOptions={locationSortOptions}
                                categoryData={displayedCategories}
                                allCategories={categories}
                                onDeleteCategory={deleteCategory}
                                onGetCategoryById={getCategoryById}
                                setIsDetailCategoryModalOpen={setIsDetailCategoryModalOpen}
                                setIsEditCategoryModalOpen={setIsEditCategoryModalOpen}
                                setCategoryId={setCategoryId}
                            />
                        )}
                    </div>

                    {/* pagination */}
                    <div className="static bottom-0 pb-6 bg-white md:sticky">
                        <div className="flex items-center justify-center w-full px-3 py-2 rounded-lg md:justify-between">
                            <p className="hidden text-base font-semibold text-manatee font-quicksand md:block">
                                Showing{" "}
                                <span className="text-clinder">{displayedCategories?.length}</span>{" "}
                                out of <span className="text-clinder">{totalData}</span> Menus
                            </p>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                isPageNumberColorGray={true}
                            />
                        </div>
                    </div>

                    {/* Create New Category Modal */}
                    <NewCategoryModal
                        isModalOpen={isNewCategoryModalOpen}
                        setIsModalOpen={setIsNewCategoryModalOpen}
                        onGetAllCategories={getAllCategories}
                        areasOfCategory={areasOfCategory}
                        isAreaOfCategoryUpdate={isAreaOfCategoryUpdate}
                        setIsAreaOfCategoryUpdate={setIsAreaOfCategoryUpdate}
                    />

                    {/* Detail Category Modal */}
                    <DetailCategory
                        isModalOpen={isDetailCategoryModalOpen}
                        setIsModalOpen={setIsDetailCategoryModalOpen}
                        category={detailCategory}
                    />

                    {/* Edit Category Modal */}
                    <EditCategoryModal
                        isModalOpen={isEditCategoryModalOpen}
                        setIsModalOpen={setIsEditCategoryModalOpen}
                        areasOfCategory={areasOfCategory}
                        isAreaOfCategoryUpdate={isAreaOfCategoryUpdate}
                        setIsAreaOfCategoryUpdate={setIsAreaOfCategoryUpdate}
                        categoryId={categoryId}
                        onGetAllCategories={getAllCategories}
                    />
                </main>
            </div>
        </div>
    );
};

export default StockCategory;
