import React from 'react';

const MenuSelectionCheckbox = ({
  menuIndex,
  menuName,
  menuId,
  selectedMenusToPost,
  setSelectedMenusToPost,
}) => {
  const handleSelectMenuToPost = () => {
    if (!selectedMenusToPost.some((item) => item.productId === menuId)) {
      setSelectedMenusToPost((prevSelected) => [
        ...prevSelected,
        {
          productId: menuId,
          menuName: menuName,
          qty: 0,
        },
      ]);
    } else {
      setSelectedMenusToPost((prevSelected) =>
        prevSelected.filter((item) => item.productId !== menuId)
      );
    }
  };

  return (
    <div
      className={`flex items-center justify-between w-full gap-3 p-3 rounded cursor-pointer ${
        menuIndex !== 0 && 'mt-1'
      } ${
        selectedMenusToPost.some((item) => item.productId === menuId) &&
        'border border-blue-500'
      }`}
      onClick={handleSelectMenuToPost}
    >
      <label
        htmlFor={`menu-${menuIndex}`}
        className="text-sm font-semibold capitalize text-clinder font-quicksand"
      >
        {menuName}
      </label>
      <input
        id={`menu-${menuIndex}`}
        type="checkbox"
        required
        checked={selectedMenusToPost.some((item) => item.productId === menuId)}
        className="w-4 h-4 mt-1 hover:cursor-pointer"
        onClick={(e) => e.stopPropagation()}
        onChange={handleSelectMenuToPost}
      />
    </div>
  );
};

export default MenuSelectionCheckbox;
