"use client";

import { useEffect, useState } from "react";
import axiosInstance from "../../utils/api/apiConfig";

const useGetCustomerAnalytics = (token, tenantId, customerId, periodTime) => {
    const [loading, setLoading] = useState(true);
    const [customerAnalyticData, setCustomerAnalyticData] = useState();

    useEffect(() => {
        const getCustomerAnalytic = async () => {
            try {
                const response = await axiosInstance.get(
                    `/transactions/by-customer/analytic?customerId=${customerId}&periodTime=${periodTime}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-TENANT-ID": tenantId,
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setCustomerAnalyticData(response.data.data);
            } catch (error) {
                // console.log(error);
            } finally {
                setLoading(false);
            }
        };

        getCustomerAnalytic();
    }, [token, tenantId, customerId, periodTime]);

    return { loading, customerAnalyticData };
};

export default useGetCustomerAnalytics;
