import React from 'react';

import searchBlue from '../../../assets/icons/searchBlue.svg';
import plus from '../../../assets/icons/plus.svg';
import SingleIndicatorDropdown from '../../SingleIndicatorDropdown';

const MobileVoucherTable = ({ voucherData, onOpenModal }) => {
  return (
    <div className="block md:hidden">
      <div className="flex gap-4 px-6 py-2 sticky top-[164px] bg-white border-b-[1px] border-gray-x z-30">
        <img src={searchBlue} alt="search" />
        <input
          type="text"
          placeholder="Find your voucher here"
          className="flex-1 text-sm font-medium bg-transparent focus:outline-none font-quicksand text-clinder placeholder:text-gray-x placeholder:font-quicksand placeholder:text-sm placeholder:font-medium"
        />
        <button
          type="button"
          className="rounded-[7.5px] bg-aurora-blue w-8 h-8 flex-none"
          onClick={() => onOpenModal(true)}
        >
          <img src={plus} alt="add" className="mx-auto" />
        </button>
      </div>
      {voucherData.map((voucher, index) => {
        let roleTextColor = '';
        let roleBackground = '';

        if (voucher.role === 'juragan') {
          roleTextColor = 'text-bluetty';
          roleBackground = 'bg-soft-aqua';
        } else if (voucher.role === 'sultan') {
          roleTextColor = 'text-greentty';
          roleBackground = 'bg-pastel-green';
        } else if (voucher.role === 'konglomerat') {
          roleTextColor = 'text-purpletty';
          roleBackground = 'bg-tender-rose';
        } else if (voucher.role === 'warga') {
          roleTextColor = 'text-orangetty';
          roleBackground = 'bg-coral';
        } else {
          roleTextColor = 'text-black';
          roleBackground = 'bg-neutral-gray';
        }

        return (
          <div key={index} className="w-full">
            <div
              className={`flex items-center justify-between w-full py-4 px-6 bg-neutral-gray ${
                index === 0 ? 'border-b-[1px]' : 'border-y-[1px]'
              }`}
            >
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder capitalize">
                {voucher.voucherName}
              </p>
              <button type="button" className="px-3">
                <div className="w-[4px] h-[4px] bg-black rounded-full" />
                <div className="w-[4px] h-[4px] bg-black rounded-full my-1" />
                <div className="w-[4px] h-[4px] bg-black rounded-full" />
              </button>
            </div>
            <div className="flex items-center justify-between px-6 mt-4">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Role
              </p>
              <div className="flex gap-[10px] items-center">
                <p className={`px-4 py-1 ${roleBackground}`}>
                  <span
                    className={`text-sm font-semibold leading-[150%] capitalize font-quicksand flex-initial text-end ${roleTextColor}`}
                  >
                    {voucher.role}
                  </span>
                </p>
                {voucher.countOfvoucher > 0 && (
                  <button
                    type="button"
                    className="px-3 py-[6px] text-sm font-semibold rounded bg-neutral-gray text-clinder font-quicksand"
                  >
                    +{voucher.countOfvoucher}
                  </button>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between px-6 mt-4">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Date Added
              </p>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {voucher.dateAdded}
              </p>
            </div>
            <div className="flex items-center justify-between px-6 mt-4">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Date Expired
              </p>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {voucher.dateExpired}
              </p>
            </div>
            <div className="flex items-center justify-between px-6 mt-4">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Kuota
              </p>
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder flex-initial text-end">
                {voucher.kuota}
              </p>
            </div>
            <div className="flex items-center justify-between px-6 mt-4 mb-4">
              <p className="text-sm font-semibold leading-[150%] font-quicksand text-clinder">
                Status
              </p>
              <div
                className={` px-[10px] py-[10px] flex justify-between items-center gap-[10px] rounded ${
                  voucher.status === 'Active' ? 'bg-ivory-cream' : 'bg-coral'
                }`}
              >
                <span
                  className={`text-sm font-semibold font-quicksand text-lime-green capitalize ${
                    voucher.status === 'Active'
                      ? 'text-lime-green'
                      : 'text-scarlet-red'
                  }`}
                >
                  {voucher.status}
                </span>
                <SingleIndicatorDropdown
                  options={['Active', 'Inactive']}
                  showResultOption={false}
                  height={'h-auto'}
                  width={'w-auto'}
                  iconSize={'w-5 h-5'}
                  radius={'rounded'}
                  optionsWidth={'w-[100px]'}
                  padding={'px-0'}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileVoucherTable;
