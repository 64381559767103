import React from "react";
import SideBar from "../components/Sidebar";
import NeedLoginAlert from "../components/alert/NeedLoginAlert";
import UnderConstructionBanner from "../components/banner/UnderConstructionBanner";

const Supply = () => {
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="w-full md:w-[88px] 2xl:w-[200px] bg-white flex-none h-[86px] sticky top-0 z-50">
                <SideBar isSupply={true} />
            </aside>

            {/* <div className="flex flex-col justify-center flex-1 w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <p className="text-2xl font-bold text-center mdfont-quicksand 2xl:text-3xl">
                    Coming Soon
                </p>
            </div> */}

            <UnderConstructionBanner />
        </div>
    );
};

export default Supply;
