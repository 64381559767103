import React, { useEffect, useState } from "react";

import axiosInstance from "../../utils/api/apiConfig";

import AddNotesModal from "../../components/stock/additional_notes/AddNotesModal";
import Banner from "../../components/Banner";
import Header from "../../components/Header";
import SideBar from "../../components/Sidebar";
import TopNavbar from "../../components/TopNavbar";
import Notes from "../../components/stock/additional_notes/Notes";
import Loading from "../../components/Loading";
import NotesFilterModal from "../../components/stock/additional_notes/NotesFilterModal";

import stockAdditionalNotesBanner from "../../assets/images/stockAdditionalNotes.png";
import NeedLoginAlert from "../../components/alert/NeedLoginAlert";

const StockAdditionalNotes = () => {
    // === Notes State ===
    const [notes, setNotes] = useState([]);
    const [noteId, setNoteId] = useState(null);
    const [displayedNotes, setDisplayedNotes] = useState([]);

    // === State Condition ===
    const [isLoadingGetNotes, setIsLoadingGetNotes] = useState(false);
    const [isLoadingDeleteNotes, setIsLoadingDeleteNotes] = useState(false);

    // === Filtering State ===
    const [searchQuery, setSearchQuery] = useState("");
    const [notesFilteredByDate, setNotesFilteredByDate] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState("");
    const currentDate = new Date().toISOString().split("T")[0];
    const [filterEndDate, setFilterEndDate] = useState(currentDate);
    const [isFilteringByDateActive, setIsFilteringByDateActive] = useState(false);
    const [isFilteringByDateToggleActive, setIsFilteringByDateToggleActive] = useState(false);
    const [isRefreshActive, setIsRefreshActive] = useState(false);

    // === Component State ===
    const [isAddNotesModalOpen, setIsAddNotesModalOpen] = useState(false);
    const [isNotesFilterModalOpen, setIsNotesFilterModalOpen] = useState(false);
    const [activeTopNav, setActiveTopNav] = useState("additional-notes");
    const topNavs = [
        { nav: "Category", value: "category", route: "admin-stock-category" },
        {
            nav: "Base Product",
            value: "base-product",
            route: "admin-stock-base-product",
        },
        { nav: "Menu", value: "menu", route: "admin-stock-menu" },
        { nav: "Price", value: "price", route: "admin-stock-price" },
        {
            nav: "Favorite Menu",
            value: "favorite-menu",
            route: "admin-stock-favorite-menu",
        },
        {
            nav: "Additional Notes",
            value: "additional-notes",
            route: "admin-stock-additional-notes",
        },
    ];

    // === Data on Local Storage ===
    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");
    const creator = localStorage.getItem("userFullName");

    // fake data
    const fakeNotes = [
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya harus digoreng", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
        { note: "Masaknya Direbus ya", date: "12 Agustus 2023" },
        { note: "Tolong sajikan prioritas", date: "12 Agustus 2023" },
        { note: "pisahkan kuah dan nasi", date: "12 Agustus 2023" },
    ];

    useEffect(() => {
        getAllNotes();
    }, []);

    // Handle Get All Categories
    const getAllNotes = async () => {
        setIsLoadingGetNotes(true);
        try {
            const response = await axiosInstance.get(`/product-notes`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            setNotes(response.data.data);
            setIsLoadingGetNotes(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingGetNotes(false);
        }
    };

    // Handle Delete Note
    const handleDeleteNote = async (id) => {
        setIsLoadingDeleteNotes(true);
        try {
            await axiosInstance.delete(`/product-notes/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "X-TENANT-ID": tenantId,
                    Authorization: `Bearer ${token}`,
                },
            });
            getAllNotes();
            setIsLoadingDeleteNotes(false);
        } catch (error) {
            // console.log(error);
            setIsLoadingDeleteNotes(false);
        }
    };

    // Handle Display Menus based on filtering and pagination
    useEffect(() => {
        const handleDisplayNotes = () => {
            if (searchQuery !== "" && filterStartDate === "") {
                const searchedNotes = notes?.filter((note) =>
                    note?.additionalNotes?.toLowerCase().includes(searchQuery?.toLowerCase())
                );
                setDisplayedNotes(searchedNotes);
            } else if (filterStartDate !== "") {
                if (searchQuery !== "") {
                    const filteredNotesByDateSearch = notesFilteredByDate?.filter((note) =>
                        note?.additionalNotes?.toLowerCase().includes(searchQuery?.toLowerCase())
                    );
                    setDisplayedNotes(filteredNotesByDateSearch);
                } else {
                    setDisplayedNotes(notesFilteredByDate);
                }
            } else {
                setDisplayedNotes(notes);
            }
        };

        handleDisplayNotes();
    }, [notes, searchQuery, isFilteringByDateToggleActive]);

    // Handle active refresh (when search quer and date filter is active)
    useEffect(() => {
        const handleActiveRefresh = () => {
            if (searchQuery !== "" || isFilteringByDateActive) {
                setIsRefreshActive(true);
            } else {
                setIsRefreshActive(false);
            }
        };

        handleActiveRefresh();
    }, [searchQuery, isFilteringByDateToggleActive]);

    // Handle delete filter
    const handleDeleteFilter = () => {
        setSearchQuery("");
        setFilterStartDate("");
        setFilterEndDate(currentDate);
        setIsFilteringByDateActive(false);
        setIsFilteringByDateToggleActive(!isFilteringByDateToggleActive);
    };

    if (!token || !tenantId) return <NeedLoginAlert />;

    return (
        <div className="flex flex-col min-h-screen md:flex-row">
            <aside className="md:w-[88px] 2xl:w-[200px] flex-none bg-white z-50 w-full h-[86px] sticky top-0">
                <SideBar isStock={true} />
            </aside>

            <div className="flex-1 w-full ml-0 mr-0 md:ml-6 md:mr-4">
                <Header
                    activeTopNav={activeTopNav}
                    setActiveTopNav={setActiveTopNav}
                    activeSideNav={"Product"}
                    menuDescription={"You can manage and organize your product stock here"}
                    topNavs={topNavs}
                />
                <main>
                    <Banner
                        title="Additional Notes"
                        description="On this menu you will be able to create, edit, and also delete the Additional Notes. Also you can manage it easily."
                        onOpenModal={() => setIsAddNotesModalOpen(true)}
                        // onOpenFilterModal={() => setIsNotesFilterModalOpen(true)}
                        openModalButtonLabel="Add Notes"
                        searchPlaceholder={"Find your notes"}
                        image={stockAdditionalNotesBanner}
                        showPrintButton={false}
                        // isRefreshActive={isRefreshActive}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        onHandleDeleteFilter={handleDeleteFilter}
                    />

                    {/* Navbar for Mobile & Tablet */}
                    <TopNavbar
                        topNavs={topNavs}
                        activeTopNav={activeTopNav}
                        setActiveTopNav={setActiveTopNav}
                    />

                    {/* Notes */}
                    {isLoadingGetNotes ? (
                        <Loading />
                    ) : (
                        <Notes
                            displayedNotes={displayedNotes}
                            allNotes={notes}
                            setIsAddNotesModal={setIsAddNotesModalOpen}
                            noteId={noteId}
                            setNoteId={setNoteId}
                            onDeleteNote={handleDeleteNote}
                        />
                    )}

                    {/* Add Notes Modal */}
                    <AddNotesModal
                        isModalOpen={isAddNotesModalOpen}
                        setIsModalOpen={setIsAddNotesModalOpen}
                        onGetAllNotes={getAllNotes}
                    />

                    {/* Notes Filter Modal */}
                    <NotesFilterModal
                        isModalOpen={isNotesFilterModalOpen}
                        setIsModalOpen={setIsNotesFilterModalOpen}
                        setIsFilteringByDateActive={setIsFilteringByDateActive}
                        isFilteringByDateToggleActive={isFilteringByDateToggleActive}
                        setIsFilteringByDateToggleActive={setIsFilteringByDateToggleActive}
                        filterStartDate={filterStartDate}
                        setFilterStartDate={setFilterStartDate}
                        currentDate={currentDate}
                        filterEndDate={filterEndDate}
                        setFilterEndDate={setFilterEndDate}
                        notesFilteredByDate={notesFilteredByDate}
                        setNotesFilteredByDate={setNotesFilteredByDate}
                        notes={notes}
                    />
                </main>
            </div>
        </div>
    );
};

export default StockAdditionalNotes;
